<template>
  <div class="fixed inset-0 z-30 h-full" v-if="open">
    <div
      class="flex items-end justify-center h-full min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0"
      id="modal-container"
    >
      <transition
        enter-active-class="duration-300 ease-out"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div @click="clickAwayHandler" class="fixed inset-0">
          <div class="absolute inset-0"></div>
        </div>
      </transition>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <transition
        name="modal-transition"
        enter-active-class="duration-300 ease-out"
        enter-from-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
        enter-to-class="translate-y-0 opacity-100 sm:scale-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="translate-y-0 opacity-100 sm:scale-100"
        leave-to-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
      >
        <div
          class="fixed flex flex-col py-4 overflow-y-auto text-left align-bottom transition-all transform rounded-sm shadow-2xl bg-sd-base02-black"
          role="dialog"
          aria-modal="true"
          id="help-panel"
          :style="modalPosition"
          @keydown.enter="keyDownEnterHandler"
        >
          <div
            class="flex flex-row px-4"
          >
            <div
              class="flex w-8 my-auto mr-2 font-sans text-xs border rounded-lg shadow-2xl h-7 border-sd-base01-brgreen text-sd-base0-brblue"
            >
              <div class="m-auto">alt</div>
            </div>
            <div
              class="flex w-8 my-auto mr-4 font-sans text-xs border rounded-lg shadow-2xl h-7 border-sd-base01-brgreen text-sd-base0-brblue"
            >
              <iconSimpleMouse :size="4" class="m-auto text-sd-base0-brblue" />
            </div>
            <div
              class="my-auto font-sans text-xs text-sd-base0-brblue"
            >
              to drag
            </div>
          </div>
          <div
            class="flex flex-row px-4 mt-3"
          >
            <div
              class="flex w-8 my-auto mr-2 font-sans text-xs border rounded-lg shadow-2xl h-7 border-sd-base01-brgreen text-sd-base0-brblue"
            >
              <div class="m-auto">alt</div>
            </div>
            <div
              class="flex w-8 my-auto mr-4 font-sans text-xs border rounded-lg shadow-2xl h-7 border-sd-base01-brgreen text-sd-base0-brblue"
            >
              <div class="m-auto">S</div>
            </div>
            <div
              class="my-auto font-sans text-xs text-sd-base0-brblue"
            >
              to search
            </div>
          </div>
          <div class="inline-block my-4 border-b border-opacity-50 border-sd-base01-brgreen"></div>
          <a
            href="https://www.notion.so/summerbud/Welcome-e57965cd37ea4399a4e37cf161b3d600"
            class="flex h-8 px-4 font-sans text-sm font-normal text-sd-base0-brblue hover:bg-sd-base2-white hover:bg-opacity-5"
            target="_blank" 
            rel="noopener noreferrer"
          >
            <div class="my-auto">Welcome</div> 
          </a>
          <div class="inline-block my-4 border-b border-opacity-50 border-sd-base01-brgreen"></div>
          <div
            class="h-8 px-4 font-sans text-sm font-normal text-sd-base00-bryellow"
          >
            Totuslink {{ totuslink_config.current_version }}
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import totuslink_config from "../../totuslink.config"
import { ref, onMounted, onUnmounted } from "vue";
import iconSimpleMouse from "../icon/iconSimpleMouse"
//import iconTrashCanFillVue from '../icon/iconTrashCanFill.vue';
export default {
  name: "helpPanel",
  components: {
    iconSimpleMouse
  },
  props:{
    open: {
      type: Boolean,
      require: true
    },
    position: {
      type: Object,
      require: true
    }
  },
  emits:[ "toggleHelpPanel" ],
  setup(props, context) {
    const modalPosition = ref(null);
    const modalWidth = ref(180);
    const modalHeight = ref(210);

    const prepare = () => {
      modalPosition.value = {
        left: props.position.x - modalWidth.value + props.position.width + "px",
        top: props.position.y - 25 - modalHeight.value + "px",
        width: modalWidth.value + "px",
        height: modalHeight.value + "px"
      };
      window.addEventListener("keydown", keydownEscHandler)
    }

    onMounted(prepare)

    const keydownEscHandler = (event) => {
      if ( event.code !== "Escape" ) return;
      if ( !props.open ) return;
      clickAwayHandler()
    };

    const clickAwayHandler = () => {
      context.emit("toggleHelpPanel", false)
    }
    
    const clear = () => {
      window.removeEventListener("keydown", keydownEscHandler)
    }
    
    onUnmounted(clear)

    return {
      clickAwayHandler,
      modalPosition,
      totuslink_config
    }

  }
};
</script>

<style></style>