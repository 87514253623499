import { v4 as uuidv4 } from 'uuid';
import _ from "lodash";
import { API, graphqlOperation } from "aws-amplify";

import { createHologramEdge as createHologramEdgeMutation } from "../../../graphql/custom/customMutations";
import { deleteHologramEdge as deleteHologramEdgeMutation } from "../../../graphql/custom/customMutations";
import { byStartNode as getEdgeByStartNodeQuery } from "../../../graphql/queries";
import { createUserEdge as createUserEdgeMutation } from "../../../graphql/custom/customMutations";
import { deleteUserEdge as deleteUserEdgeMutation } from "../../../graphql/custom/customMutations";

import { createEdge as createEdgeMutation } from "../../../graphql/custom/customMutations";
import { deleteEdge as deleteEdgeMutation } from "../../../graphql/custom/customMutations";
import { getEdge, getHologramEdge, getHologramNode, getHologramNodeWeight } from "../../../graphql/helper/Get";
import { getOrCreateUserEdge } from "../../../graphql/helper/GetOrCreate";
import { handle } from "../../../utilities/utilities";

export const edge = {
  state: {
    currentHologramEdges:[],
    userEdges:[],
  },
  mutations: {
    mutateCurrentHologramEdges(state, payload){
      state.currentHologramEdges = payload;
    },
    mutateUserEdges(state, payload){
      state.userEdges = payload;
    },
  },
  actions: {
    // Unit actions
    addNewItemToUserEdges: (context, data) => {
      let temp = _.cloneDeep(context.state.userEdges);
      temp.splice(0, 0, data);
      context.commit("mutateUserEdges", temp);
    },
    resetCurrentHologramEdges: (context) => {
      context.commit("mutateCurrentHologramEdges", []);
    },
    setCurrentHologramEdges: (context, hologramEdges) => {
      context.commit("mutateCurrentHologramEdges", hologramEdges);
    },
    addNewItemToCurrentHologramEdges: (context, hologramEdge) => {
      let temp = _.cloneDeep(context.state.currentHologramEdges);
      temp.splice(0 ,0 ,hologramEdge);
      context.commit("mutateCurrentHologramEdges", temp);
    },
    updateItemInCurrentHologramEdges: (context, hologramEdge) => {
      const targetID = hologramEdge.id;
      let temp = _.cloneDeep(context.state.currentHologramEdges);
      let index = temp.findIndex( e => e.id === targetID);
      temp.splice(index, 1, hologramEdge);
      context.commit("mutateCurrentHologramEdges", temp);
    },
    removeItemFromCurrentHologramEdges: (context, hologramEdgeID) => {
      let temp = _.cloneDeep(context.state.currentHologramEdges);
      let index = temp.findIndex( e => e.id === hologramEdgeID);
      temp.splice(index, 1);
      console.log(temp, index)
      context.commit("mutateCurrentHologramEdges", temp);
    },


    // Request actions
    getEdge: async (_, { startNodeID, endNodeID }) => {
      const variables = {
        startNodeID: startNodeID,
        endNodeID: {
          eq: endNodeID
        }
      }
      let result;
      try {
        const res = await API.graphql({
          query: getEdgeByStartNodeQuery,
          variables: variables,
          authMode: "AWS_IAM"
        })
        if ( res.data.byStartNode.items.length > 0 ){
          result = {
            status: "Found",
            result: res.data.byStartNode.items[0]
          }
        } else {
          result = {
            status: "NotFound"
          }
        }
        return Promise.resolve(result)
      } catch(err){
        return Promise.reject(err)
      }
    },
    createEdge: async (_, {id, startNodeID, endNodeID}) => {
      const variables = {
        input: {
          id: id,
          baseType: "Edge",
          startNodeID: startNodeID,
          endNodeID: endNodeID
        }
      }
      try {
        const result = await API.graphql({
          query: createEdgeMutation,
          variables: variables,
          authMode: "AWS_IAM"
        })
        return Promise.resolve(result.data.createEdge)
      } catch(err){
        return Promise.reject(err)
      }
    },
    deleteEdge: async (_, edgeID) => {
      const variables = {
        input: {
          id: edgeID,
        }
      }
      try {
        const result = await API.graphql({
          query: deleteEdgeMutation,
          variables: variables,
          authMode: "AWS_IAM"
        })
        return Promise.resolve(result.data.deleteEdge)
      } catch(err){
        return Promise.reject(err)
      }
    },
    createUserEdge: async (_, { id, userID, edgeID, owner }) => {
      const variables = {
        input: {
          id: id,
          userID: userID,
          edgeID: edgeID,
          owner: owner
        }
      }
      try {
        const res = await API.graphql({
          query: createUserEdgeMutation,
          variables: variables,
          authMode: "AWS_IAM"
        })
        return Promise.resolve(res.data.createUserEdge)
      } catch(err){
        return Promise.reject(err)
      }
    },
    deleteUserEdge: async (_, userEdgeID) => {
      const variables = {
        input: {
          id: userEdgeID,
        }
      };
      try {
        const res = await API.graphql(graphqlOperation(deleteUserEdgeMutation, variables));
        return Promise.resolve(res.data.deleteUserEdge)
      } catch(err){
        return Promise.reject(err)
      }
    },
    createHologramEdge: async (_, {id, hologramID, edgeID, accessPolicy, startNodeID, endNodeID, userID}) => {
      const variables = {
        input: {
          id: id,
          hologramID: hologramID,
          edgeID: edgeID,
          createdByID: userID,
          accessPolicy: accessPolicy,
          startNodeID: startNodeID,
          endNodeID: endNodeID,
          owner: userID
        }
      };
      console.log(variables)
      try {
        const result = await API.graphql({
          query: createHologramEdgeMutation,
          variables: variables,
          authMode: "AWS_IAM"
        })
        return Promise.resolve(result.data.createHologramEdge)
      } catch(err){
        return Promise.reject(err)
      }
    },
    deleteHologramEdge: async (_, hologramEdgeID) => {
      const variables = {
        input: {
          id: hologramEdgeID
        }
      }
      try {
        const result = await API.graphql({
          query: deleteHologramEdgeMutation,
          variables: variables,
          authMode: "AWS_IAM"
        })    
        return Promise.resolve(result.data.deleteHologramEdge);
      } catch(err){
        return Promise.reject(err);
      }
    },

    // Business actions

    advancedBuildEdge: async (context) => {
      const startTime = Date.now()
      const currentHologramNodes = context.rootState.hologram.node.currentHologramNodes
      const userID = context.rootState.auth.userData.id;
      const currentHologram = context.rootState.hologram.currentHologram;
      const currentHologramEdges = context.state.currentHologramEdges;
      const currentHologramNodeWeights = context.rootState.weight.currentHologramNodeWeights;

      const startNodeID = context.rootState.hologram.control.connectionStartNodeID;
      const startNode = findNode(currentHologramNodes, startNodeID);
      if ( startNode === "NotFound" ){
        return Promise.reject("Can't find target start node when building the edge")
      }

      const endNodeID = context.rootState.hologram.control.connectionEndNodeID;
      const endNode = findNode(currentHologramNodes, endNodeID)
      if ( endNode === "NotFound" ){
        return Promise.reject("Can't find target end node when building the edge")
      }
      
      let edge;

      const checkEdgeInput = {
        startNodeID: startNodeID,
        endNodeID: endNodeID
      }

      const [checkEdgeError, checkEdge] = await handle(context.dispatch("getEdge", checkEdgeInput));

      if ( checkEdgeError ){
        checkEdgeError.context = "Can't check whether edge exist in DB";
        return Promise.reject(checkEdgeError)
      }

      let userEdge, userEdgeIsNew, edgeIsNew; // eslint-disable-line no-unused-vars

      if ( checkEdge.status === "Found" ){
        edge = checkEdge.result
        console.log(currentHologramEdges)
        const hologramEdgeIndex = currentHologramEdges.findIndex( e => e.edgeID === edge.id );
        if ( hologramEdgeIndex !== -1 ){
          return Promise.reject("DupicatedEdge")
        }
        const userEdges = context.state.userEdges;
        userEdge = findUserEdge(userEdges, edge.id);
        if ( userEdge === "NotFound" ){
          userEdgeIsNew = true;
          userEdge = {
            id: uuidv4,
            baseType: "UserEdge",
            userID: userID,
            edgeID: edge.id,
            edge: edge
          }
        }
      } else {
        edgeIsNew = true;
        edge = {
          id: uuidv4(),
          baseType: "Edge",
          startNodeID: startNodeID,
          startNode: startNode,
          endNodeID: endNodeID,
          endNode: endNode,
          createdByID: userID
        };
        
        userEdgeIsNew = true;
        userEdge = {
          id: uuidv4,
          baseType: "UserEdge",
          userID: userID,
          edgeID: edge.id,
          edge: edge
        };
      }
      context.dispatch("addNewItemToUserEdges", userEdge);

      let hologramEdge = {
        id: uuidv4(),
        baseType: "HologramEdge",
        hologramID: currentHologram.id,
        edgeID: edge.id,
        edge: edge,
        createdByID: userID,
        accessPolicy: currentHologram.accessPolicy
      }
      context.dispatch("addNewItemToCurrentHologramEdges", hologramEdge);

      const newStartWeight = constructNewWeightByAddingEdge(currentHologramNodes, currentHologramNodeWeights, startNodeID);
      if ( newStartWeight === "NotFound" ){
        return Promise.reject("Can't find necessary start element when building the edge")
      }
      context.dispatch("weight/addItemIntoCurrentHologramNodeWeight", newStartWeight, {root:true});

      const newEndWeight = constructNewWeightByAddingEdge(currentHologramNodes, currentHologramNodeWeights, endNodeID);
      if ( newEndWeight === "NotFound" ){
        return Promise.reject("Can't find necessary end element when building the edge")
      }
      context.dispatch("weight/addItemIntoCurrentHologramNodeWeight", newEndWeight, {root:true});

      const input = {
        currentHologram: currentHologram, 
        edgeID: edge.id, 
        startNodeID: startNodeID, 
        endNodeID: endNodeID, 
        hologramEdge: hologramEdge, 
        startWeight: newStartWeight, 
        endWeight: newEndWeight,
      }

      const newHologram = constructHologramGraphAndAction(input);

      context.dispatch("setCurrentHologram", newHologram);
      context.commit("mutateRenderHologramGraph", true);
      console.log(Date.now() - startTime)

      

      const uploadInput = {
        edgeIsNew: edgeIsNew,
        userEdgeIsNew: userEdgeIsNew,
        edge: edge,
        userEdge: userEdge,
        hologramEdge: hologramEdge,
        startWeight: newStartWeight,
        endWeight: newEndWeight,
        hologram: newHologram,
        userID: userID
      }

      try {
        context.dispatch("uploadEdgeRelatedData", uploadInput);
      } catch(err){
        console.log(err)
      }

      


    },
    buildConnection: async (context) =>{
      const startTime = Date.now()
      const startNodeID = context.rootState.hologram.control.connectionStartNodeID;
      const endNodeID = context.rootState.hologram.control.connectionEndNodeID;
      const userID = context.rootState.auth.userData.id
      const hologramID = context.state.currentHologram.id
      const currentHologram = context.rootState.hologram.currentHologram
      const currentHologramEdges = context.state.currentHologramEdges
      //const username = context.rootState.auth.user.username

      let action = {
        version: null,
        timeStamp: null,
        type: null,
        add: {
          hologramNode: [],
          hologramNodeWeight: [],
          hologramNodeGroup: [],
          hologramEdge: [],
        },
        remove: {
          hologramNode: [],
          hologramNodeWeight: [],
          hologramNodeGroup: [],
          hologramEdge: []
        },
        update: {
          hologramNode: [],
          hologramNodeWeight: []
        }
      }
      
      try {
        let edge;
        const checkEdge = await getEdge([], startNodeID, endNodeID);
        const checkBackEdge = await getEdge([], endNodeID, startNodeID);
        if (checkEdge.status === "notFound"){
          const createEdgeInput = {
            startNodeID: startNodeID,
            endNodeID: endNodeID
          }
          console.log(createEdgeInput)
          edge = await context.dispatch("createEdge", createEdgeInput)
        } else {
          edge = checkEdge.result
        }

        const checkHologramEdge = await getHologramEdge(currentHologramEdges, hologramID, edge.id);
        if (checkBackEdge.status === "found"){
          const checkBackHologramEdge = await getHologramEdge(currentHologramEdges, hologramID, checkBackEdge.result.id);
          if ( checkBackHologramEdge.status === "found"){
            return "duplicated";
          }
        }

        if (checkHologramEdge.status === "found"){
          return "duplicated";
        }

        const createHologramEdgeInput = {
          hologramID: hologramID,
          edgeID: edge.id,
          createdByID: userID,
          accessPolicy: currentHologram.accessPolicy,
          startNodeID: startNodeID,
          endNodeID: endNodeID,
        }
        
        const hologramEdge = await context.dispatch("createHologramEdge", createHologramEdgeInput);
        context.dispatch("addNewItemToCurrentHologramEdges", hologramEdge);

        // Calculate and update startNode weight
        const startHologramNode = await getHologramNode([], hologramID, startNodeID);
        const startNodeWeight = await getHologramNodeWeight([], startHologramNode.result.id);
        let startNodeWeightVersion = JSON.parse(startNodeWeight.result.version);
        const startNodeWeightPreviousVersion = startNodeWeightVersion[0]
        const startNodeNewVersion = {
          "times": Date.now(),
          "counts": {
            "link": startNodeWeightPreviousVersion.counts.link + 1,
            "note": startNodeWeightPreviousVersion.counts.note,
          },
          "weight": {
            "link": 1,
            "note": 0.01,
          }
        }
        const startNodeNewValue = startNodeNewVersion.counts.link*startNodeNewVersion.weight.link
          +startNodeNewVersion.counts.note*startNodeNewVersion.weight.note;

        startNodeWeightVersion.splice(0,0, startNodeNewVersion);
        const updateStartNodeWeight = {
          weight: {
            id: startNodeWeight.result.id,
            version: JSON.stringify(startNodeWeightVersion),
            value: startNodeNewValue
          }
        }
        const updateStartNodeWeightResult = await context.dispatch("weight/updateHologramNodeWeight", updateStartNodeWeight, {root:true})
        context.dispatch("weight/updateItemInCurrentHologramNodeWeight", updateStartNodeWeightResult, {root:true});

        // Calculate and update endNode weight
        const endHologramNode = await getHologramNode([], hologramID, endNodeID);
        const endNodeWeight = await getHologramNodeWeight([], endHologramNode.result.id);
        let endNodeWeightVersion = JSON.parse(endNodeWeight.result.version);
        const endNodePreviousVersion = endNodeWeightVersion[0]
        const endNodeNewVersion = {
          "times": Date.now(),
          "counts": {
            "link": endNodePreviousVersion.counts.link + 1,
            "note": endNodePreviousVersion.counts.note,
          },
          "weight": {
            "link": 1,
            "note": 0.01,
          }
        }
        const endNodeNewValue = endNodeNewVersion.counts.link*endNodeNewVersion.weight.link
          +endNodeNewVersion.counts.note*endNodeNewVersion.weight.note;

        endNodeWeightVersion.splice(0,0, endNodeNewVersion);
        const updateEndNodeWeight = {
          weight: {
            id: endNodeWeight.result.id,
            version: JSON.stringify(endNodeWeightVersion),
            value: endNodeNewValue
          } 
        }
        const updateEndNodeWeightResult = await context.dispatch("weight/updateHologramNodeWeight", updateEndNodeWeight, {root:true})
        context.dispatch("weight/updateItemInCurrentHologramNodeWeight", updateEndNodeWeightResult, {root:true});
        
        const userEdge = await getOrCreateUserEdge(userID, edge.id, userID);
        if (userEdge.status === "create"){
          context.dispatch("addNewItemToUserEdges", userEdge.result);
        }

        const d3Edge = {
          edgeID: edge.id,
          source: startNodeID,
          target: endNodeID
        }
        
        let hologramGraph;

        if (currentHologram.graph){
          hologramGraph = JSON.parse(currentHologram.graph);
        } else { 
          hologramGraph = {
            "nodes":[],
            "links":[]
          };
        }

        hologramGraph.links.splice(0, 0, d3Edge);

        let actions = JSON.parse(currentHologram.action)
        let previousAction = actions[0];
        //const currentHologramNodeWeights = context.rootState.weight.currentHologramNodeWeights

        if ( actions.length > 3 ){
          actions.pop();
        }

        action.type = "BuildEdge";
        action.timeStamp = Date.now();
        action.version = previousAction.version + 1;

        action.add.hologramEdge.splice(0, 0, hologramEdge);
        action.update.hologramNodeWeight.splice(0, 0, updateStartNodeWeightResult);
        action.update.hologramNodeWeight.splice(0, 0, updateEndNodeWeightResult);

        actions.splice(0, 0, action);

        const updateHologramInput = {
          id: currentHologram.id,
          graph: JSON.stringify(hologramGraph),
          action: JSON.stringify(actions)
        }

        const updateHologramResult = await context.dispatch("updateHologram", updateHologramInput);
      
        //const updateHologramGraph = await addEdgeToHologramGraph(currentHologram, edge.id, startNodeID, endNodeID);

        let newHologram = currentHologram;
        newHologram.graph = updateHologramResult.graph;
        newHologram.action = updateHologramResult.action;
        
        context.dispatch("setCurrentHologram", newHologram);
        context.commit("mutateRenderHologramGraph", true);
        console.log(Date.now() - startTime)
        return Promise.resolve("Success")
      } catch(err){
        return Promise.reject(err);
      }
    },
    uploadEdgeRelatedData: async (context, {edgeIsNew, userEdgeIsNew, edge, userEdge, hologramEdge, startWeight, endWeight, hologram, userID}) => {
      let updatedData = [];

      if ( edgeIsNew ){
        const createEdgeInput = {
          id: edge.id,
          startNodeID: edge.startNodeID,
          endNodeID: edge.endNodeID
        };

        const [createEdgeError, createEdgeResult] = await handle(context.dispatch("createEdge", createEdgeInput));

        if ( createEdgeError ){
          createEdgeError.context = "Can't create edge";
          return Promise.reject(createEdgeError)
        }

        updatedData.splice(0, 0, createEdgeResult);
      }

      if ( userEdgeIsNew ){
        const createUserEdgeInput = {
          id: userEdge.id,
          userID: userID,
          edgeID: edge.id,
          owner: userID
        };

        const [createUserEdgeError, createUserEdgeResult] = await handle(context.dispatch("createUserEdge", createUserEdgeInput));

        if ( createUserEdgeError ){
          createUserEdgeError.context = "Can't create user edge";
          await cleanUpEdgeRelatedBadData(context, updatedData);
          return Promise.reject(createUserEdgeError)
        }

        updatedData.splice(0, 0, createUserEdgeResult);
      }

      const createHologramEdgeInput = {
        id: hologramEdge.id,
        hologramID: hologram.id,
        edgeID: edge.id,
        accessPolicy: hologram.accessPolicy,
        startNodeID: edge.startNodeID,
        endNodeID: edge.endNodeID,
        userID: userID
      };

      console.log(createHologramEdgeInput)

      const [createHologramEdgeError, createHologramEdgeResult] = await handle(context.dispatch("createHologramEdge", createHologramEdgeInput));

      if ( createHologramEdgeError ){
        createHologramEdgeError.context = "Can't create hologramEdge"
        await cleanUpEdgeRelatedBadData(context, updatedData);
        return Promise.reject(createHologramEdgeError)
      }

      updatedData.splice(0, 0, createHologramEdgeResult);

      const updateStartWeightInput = {
        weight: {
          id: startWeight.id,
          version: startWeight.version,
          value: startWeight.value
        }
      };

      const [updateStartWeightError, updateStartWeightResult] = await handle(context.dispatch("weight/updateHologramNodeWeight", updateStartWeightInput, {root:true}));

      if ( updateStartWeightError ){
        updateStartWeightError.context = "Can't update start weight"
        await cleanUpEdgeRelatedBadData(context, updatedData);
        return Promise.reject(updateStartWeightError) 
      }

      updatedData.splice(0, 0, updateStartWeightResult);

      const updateEndWeightInput = {
        weight: {
          id: endWeight.id,
          version: endWeight.version,
          value: endWeight.value
        }
      };

      const [updateEndWeightError, updateEndWeightResult] = await handle(context.dispatch("weight/updateHologramNodeWeight", updateEndWeightInput, {root:true}));

      if ( updateEndWeightError ){
        updateEndWeightError.context = "Can't update start weight";
        await cleanUpEdgeRelatedBadData(context, updatedData);
        return Promise.reject(updateEndWeightError) 
      }

      updatedData.splice(0, 0, updateEndWeightResult);

      const updateHologramInput = {
        id: hologram.id,
        action: hologram.action,
        graph: hologram.graph
      }

      const [updateHologramError, updateHologramResult] = await handle(context.dispatch("updateHologram", updateHologramInput));

      if ( updateHologramError ){
        updateHologramError.context = "Can't update hologram";
        await cleanUpEdgeRelatedBadData(context, updatedData);
        return Promise.reject(updateHologramError) 
      }

      updatedData.splice(0, 0, updateHologramResult);

      if ( userEdgeIsNew ){
        context.dispatch("addNewItemToUserEdges", userEdge);
      }

      context.dispatch("updateItemInCurrentHologramEdges", createHologramEdgeResult);
      context.dispatch("weight/updateItemInCurrentHologramNodeWeight", updateStartWeightResult, {root:true});
      context.dispatch("weight/updateItemInCurrentHologramNodeWeight", updateEndWeightResult, {root:true});

      let newHologram = hologram;
      newHologram.graph = updateHologramResult.graph;
      newHologram.action = updateHologramResult.action;

      context.dispatch("setCurrentHologram", newHologram);

      return Promise.resolve("Success")
    },






  },
  getters: {
    userEdges: (state) => state.userEdges,
    currentHologramEdges: (state) => state.currentHologramEdges,
  }
};

const findNode = (hologramNodes, nodeID) => {
  const index = hologramNodes.findIndex( e => e.nodeID === nodeID );

  if ( index === -1 ){
    return "NotFound"
  }

  const hologramNode = hologramNodes[index];
  return hologramNode.node
};

const plusLinkInWeight = (weight) => {
  let newWeight = weight;
  let versions = JSON.parse(weight.version);
  const previousVersion = versions[0]

  if ( versions.length > 5){
    versions.pop();
  }

  const newVersion = {
    "times": Date.now(),
    "counts": {
      "link": previousVersion.counts.link + 1,
      "note": previousVersion.counts.note,
    },
    "weight": {
      "link": 1,
      "note": 0.01,
    }
  }

  const newWeightValue = newVersion.counts.link*newVersion.weight.link
    +newVersion.counts.note*newVersion.weight.note;

  versions.splice(0,0, newVersion);

  newWeight.value = newWeightValue;
  newWeight.version = JSON.stringify(versions);

  return newWeight
}

const constructNewWeightByAddingEdge = (currentHologramNodes, currentHologramNodeWeights, nodeID) => {
  const hologramNode = findHologramNode(currentHologramNodes, nodeID);
  if ( hologramNode === "NotFound" ){
    return "NotFound"
  }
  const weight = findHologramNodeWeight(currentHologramNodeWeights, hologramNode.id);
  if ( weight === "NotFound" ){
    return "NotFound"
  }
  const newWeight = plusLinkInWeight(weight);
  return newWeight
}

const constructHologramGraphAndAction = ({currentHologram, edgeID, startNodeID, endNodeID, hologramEdge, startWeight, endWeight}) => {
  let action = {
    version: null,
    timeStamp: null,
    type: null,
    add: {
      hologramNode: [],
      hologramEdge: [],
      hologramGroup: [],
      hologramNodeGroup: [],
      hologramNodeWeight: [],
    },
    remove: {
      hologramNode: [],
      hologramEdge: [],
      hologramGroup: [],
      hologramNodeGroup: [],
      hologramNodeWeight: [],
    },
    update: {
      group: [],
      hologramNode: [],
      hologramEdge: [],
      hologramGroup: [],
      hologramNodeGroup: [],
      hologramNodeWeight: []
    }
  };
  
  const d3Edge = {
    edgeID: edgeID,
    source: startNodeID,
    target: endNodeID
  }
  
  let hologramGraph;

  if (currentHologram.graph){
    hologramGraph = JSON.parse(currentHologram.graph);
  } else { 
    hologramGraph = {
      "nodes":[],
      "links":[]
    };
  }

  hologramGraph.links.splice(0, 0, d3Edge);

  let actions = JSON.parse(currentHologram.action)

  if ( !actions ){
    action.version = 1;
    actions = []
  } else {
    let previousAction = actions[0];
    action.version = previousAction.version + 1;
    if ( actions.length > 3 ){
      actions.pop();
    }
  }

  

  action.type = "BuildEdge";
  action.timeStamp = Date.now();

  action.add.hologramEdge.splice(0, 0, hologramEdge);

  const updateStartWeight = {
    id: startWeight.id,
    value: startWeight.value,
    version: startWeight.version
  }

  action.update.hologramNodeWeight.splice(0, 0, updateStartWeight);

  const updateEndWeight = {
    id: endWeight.id,
    value: endWeight.value,
    version: endWeight.version
  }
  
  action.update.hologramNodeWeight.splice(0, 0, updateEndWeight);

  actions.splice(0, 0, action);

  let newHologram = currentHologram;
  newHologram.graph = JSON.stringify(hologramGraph);
  newHologram.action = JSON.stringify(actions)

  return newHologram
}

const cleanUpEdgeRelatedBadData = async (context, badData) => {
  console.log(badData)
  try {
    for ( const data of badData ){
      switch (data.baseType) {
        case "Edge": {
          context.dispatch("deleteEdge", data.id);
          break
        }
        case "UserEdge": {
          context.dispatch("deleteUserEdge", data.id);
          break
        }
        case "HologramEdge": {
          context.dispatch("deleteHologramEdge", data.id);
          break
        }
        case "Weight": {
          const newWeight = rollBackWeightVersion(data);
  
          let updateWeightInput = {
            weight: {
              id: newWeight.id,
              value: newWeight.value,
              version: newWeight.version
            }
          };
  
          context.dispatch("weight/updateHologramNodeWeight", updateWeightInput, {root:true})
          break
        }
      }
  
      if ( data.hologramID && data.edgeID ){
        context.dispatch("deleteHologramEdge", data.id)
        continue;
      }
  
      if ( data.userID && data.edgeID ){
        context.dispatch("deleteUserEdge", data.id);
        continue;
      }
  
    }
  } catch(err){
    console.error("Something went wrong when delete bad data", err)
  }
  
}

const findHologramNode = (hologramNodes, nodeID) => {
  const index = hologramNodes.findIndex( e => e.nodeID === nodeID );

  if ( index === -1 ){
    return "NotFound"
  }

  const hologramNode = hologramNodes[index];
  return hologramNode
};

const findHologramNodeWeight = (hologramNodeWeights, hologramNodeID) => {
  const index = hologramNodeWeights.findIndex( e => e.hologramNodeID === hologramNodeID );

  if ( index === -1 ){
    return "NotFound"
  }

  const hologramNodeWeight = hologramNodeWeights[index];
  return hologramNodeWeight
}

const findUserEdge = (userEdges, edgeID) => {
  const index = userEdges.findIndex( e => e.edgeID === edgeID );

  if ( index === -1 ){
    return "NotFound"
  }

  const userEdge = userEdges[index];
  return userEdge
}

const rollBackWeightVersion = (weight) => {
  let newWeight = weight;
  let versions = JSON.parse(newWeight.version);
  let previousVersion = versions[1];

  const newWeightValue = previousVersion.counts.link * previousVersion.weight.link
    + previousVersion.counts.note * previousVersion.weight.note;

  versions.splice(0, 1);

  newWeight.version = JSON.stringify(versions);
  newWeight.value = newWeightValue;

  return newWeight
}
