<template>
  <div 
    v-bind:class="avatarStyle"
    class="flex items-center justify-center font-sans font-medium rounded-full"
  >
    {{ usernameFirstCharacter }}
  </div>
</template>
<script>
import { onMounted, ref, computed } from "vue";
export default {
  name: "tempAvatar",
  props: {
    username: {
      type: String,
      require: true,
    },
    size: {
      type: Number,
      require: true,
    },
    bgColor: {
      type: String,
      require: true,
    },
    textColor: {
      type: String,
      require: true,
    },
    fontSize: {
      type: String,
      require: true,
    }
  },
  setup(props) {
    const usernameFirstCharacter = ref(null);

    const prepare = () => {
      usernameFirstCharacter.value = props.username[0].toUpperCase();
    };

    onMounted(prepare);

    const avatarStyle = computed(() => {
      return `w-${props.size} h-${props.size} ${props.bgColor} ${props.textColor} ${props.fontSize}`;
    });

    return {
      usernameFirstCharacter,
      avatarStyle,
    };
  },
};
</script>
<style>
</style>