export const colors = [
  {
    "name": "White",
    "bgCode": "bg-sd-base1-brcyan",
    "bgHexCode": "#93a1a1",
    "textCode": "text-sd-base02-black",
    "textHexCode": "#073642"
  },
  {
    "name": "Yellow",
    "bgCode": "bg-sd-yellow",
    "bgHexCode": "#b58900",
    "textCode": "text-sd-base2-white",
    "textHexCode": "#eee8d5",
  },
  {
    "name": "Orange",
    "bgCode": "bg-sd-orange",
    "bgHexCode": "#cb4b16",
    "textCode": "text-sd-base2-white",
    "textHexCode": "#eee8d5",
  },
  {
    "name": "Red",
    "bgCode": "bg-sd-red",
    "bgHexCode": "#d30102",
    "textCode": "text-sd-base2-white",
    "textHexCode": "#eee8d5",
  },
  {
    "name": "Magenta",
    "bgCode": "bg-sd-magenta",
    "bgHexCode": "#d33682",
    "textCode": "text-sd-base2-white",
    "textHexCode": "#eee8d5",
  },
  {
    "name": "Violet",
    "bgCode": "bg-sd-violet",
    "bgHexCode": "#6c71c4",
    "textCode": "text-sd-base2-white",
    "textHexCode": "#eee8d5",
  },
  {
    "name": "Blue",
    "bgCode": "bg-sd-blue",
    "bgHexCode": "#268bd2",
    "textCode": "text-sd-base2-white",
    "textHexCode": "#eee8d5",
  },
  {
    "name": "Cyan",
    "bgCode": "bg-sd-cyan",
    "bgHexCode": "#2aa198",
    "textCode": "text-sd-base2-white",
    "textHexCode": "#eee8d5",
  },
  {
    "name": "Green",
    "bgCode": "bg-sd-green",
    "bgHexCode": "#859900",
    "textCode": "text-sd-base2-white",
    "textHexCode": "#eee8d5",
  }
]