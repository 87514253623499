export const notification = {
  namespaced: true,
  state: {
    hologramOperationNotification:{}
  },
  mutations: {
    mutateHologramOperationNotification(state, payload){
      state.hologramOperationNotification = payload;
    }
  },
  actions: {
    /**
     * 
     * @param {object} context - vuex context object
     * @param {object} data - {location, type}
     * @param {string} type - update/reset
     */
    updateHologramOperationNotification(context, {data, type}){
      switch (type) {
        case "update":{
          context.commit("mutateHologramOperationNotification", data)
          break;
        }
        case "reset":{
          context.commit("mutateHologramOperationNotification", {})
          break;
        }
      }
    }
  },
  getters: {
    hologramOperationNotification: (state) => state.hologramOperationNotification
  }
}