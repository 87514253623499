<template>
  <div 
    class="flex flex-col w-full h-full p-5 bg-sd-base03-brblack"
    v-if="userData.isAdmin"
  >
    <div
      class="w-full Admin-navbar"
    >
      <ul class="flex justify-start w-full">
        <li class="mr-6" v-if="user">
          <div class="font-sans font-bold text-sd-base3-brwhite">
            Admin panel
          </div>
        </li>
        <li class="mr-6" v-if="user">
          <div class="font-sans font-bold text-sd-base3-brwhite">
            {{ user.username }}
          </div>
        </li>
      </ul>
    </div>
    <div
      class="w-full mt-20"
    >
      <div
        class="w-full font-sans text-xl font-bold text-left text-sd-base1-brcyan"
      >
        Upload Questionnaire
      </div>
      <div
        class="w-full font-sans text-lg font-medium text-left text-sd-base00-bryellow"
      >
        Please put questionnaire json into public dist and make sure you follow the questionnaire template.
      </div>
      <div
        class="flex flex-row mt-4"
      >
        <input
          type="text"
          name="questionnaire file name"
          v-model="questionnaireFileName"
          class="mr-2 bg-sd-base02-black border border-sd-base00-bryellow font-sans rounded font-normal text-sm text-sd-base0-brblue py-1.5 px-3 focus:outline-none focus:ring-1 focus:ring-sd-base1-brcyan"
        />
        <button
          @click="uploadQuestionnaire"
          class="px-4 py-2 mr-2 font-sans text-sm font-medium border rounded-md border-sd-base01-brgreen text-sd-base2-white hover:bg-sd-base2-white hover:bg-opacity-5 focus:outline-none"
        >
          Upload
        </button>
        <div
          v-if="dataTransacting"
        >
          <svg class="w-5 h-5 animate-spin text-sd-base0-brblue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
        
      </div>
    </div>
    <div
      class="w-full mt-20"
    >
      <div
        class="w-full font-sans text-xl font-bold text-left text-sd-base1-brcyan"
      >
        Fix hologram graph
      </div>
      <div
        class="w-full font-sans text-lg font-medium text-left text-sd-base00-bryellow"
      >
        Fix hologram if it graph is not consistent with hologramNodes
      </div>
      <div
        class="flex flex-row mt-4"
      >
        <input
          type="text"
          name="hologram ID"
          v-model="fixhologramID"
          class="mr-2 bg-sd-base02-black border border-sd-base00-bryellow font-sans rounded font-normal text-sm text-sd-base0-brblue py-1.5 px-3 focus:outline-none focus:ring-1 focus:ring-sd-base1-brcyan"
        />
        <button
          @click="fixHologramGraph"
          class="px-4 py-2 mr-2 font-sans text-sm font-medium border rounded-md border-sd-base01-brgreen text-sd-base2-white hover:bg-sd-base2-white hover:bg-opacity-5 focus:outline-none"
        >
          Fix hologram
        </button>
        <div
          v-if="dataTransacting"
        >
          <svg class="w-5 h-5 animate-spin text-sd-base0-brblue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
      </div>
    </div>
    <div
      class="w-full mt-20"
    >
      <div
        class="w-full font-sans text-xl font-bold text-left text-sd-base1-brcyan"
      >
        Fix DynamoDB IdentityClaim
      </div>
      <div
        class="w-full font-sans text-lg font-medium text-left text-sd-base00-bryellow"
      >
        Fix DynamoDB IdentityClaim
      </div>
      <div
        class="flex flex-row mt-4"
      >
        <input
          type="text"
          name="DynamoDB TableName"
          v-model="dynamoDBTableName"
          class="mr-2 bg-sd-base02-black border border-sd-base00-bryellow font-sans rounded font-normal text-sm text-sd-base0-brblue py-1.5 px-3 focus:outline-none focus:ring-1 focus:ring-sd-base1-brcyan"
        />
        <button
          @click="fixDynamoDBIdentityClaim"
          class="px-4 py-2 mr-2 font-sans text-sm font-medium border rounded-md border-sd-base01-brgreen text-sd-base2-white hover:bg-sd-base2-white hover:bg-opacity-5 focus:outline-none"
        >
          Fix
        </button>
        <div
          v-if="identityClaimIsFixing"
        >
          <svg class="w-5 h-5 animate-spin text-sd-base0-brblue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const axios = require('axios').default;
import { API, graphqlOperation } from "aws-amplify";
import { listQuestions as listQuestionsQuery } from "../graphql/queries"
import { createQuestion as createQuestionMutation } from "../graphql/mutations"
import { updateQuestion as updateQuestionMutation } from "../graphql/mutations"
import { listQuestionnaires as listQuestionnairesQuery } from "../graphql/queries"
import { updateQuestionnaire as updateQuestionnaireMutation } from "../graphql/mutations"
import { createQuestionnaire as createQuestionnaireMutation } from "../graphql/mutations"
import { listQuestionnaireQuestions as listQuestionnaireQuestionsQuery } from "../graphql/queries"
import { createQuestionnaireQuestion as createQuestionnaireQuestionMutation } from "../graphql/mutations"
import { listUsers as listUsersQuery } from "../graphql/queries"
import { listDomains as listDomainsQuery } from "../graphql/queries"
import { listHolograms as listHologramsQuery } from "../graphql/queries"
import { listEdges as listEdgesQuery } from "../graphql/queries"
import { listNodes as listNodesQuery } from "../graphql/queries"
import { listWeights as listWeightsQuery } from "../graphql/queries"
import { listGroups as listGroupsQuery } from "../graphql/queries"
import { listIssues as listIssuesQuery } from "../graphql/queries"
import { listUserNodes as listUserNodesQuery } from "../graphql/queries"
import { listUserEdges as listUserEdgesQuery } from "../graphql/queries"
import { listHologramNodes as listHologramNodesQuery } from "../graphql/queries"
import { listHologramEdges as listHologramEdgesQuery } from "../graphql/queries"
import { listHologramNodeGroups as listHologramNodeGroupsQuery } from "../graphql/queries"


import { getPublicHologram as getPublicHologramQuery } from "../graphql/custom/customQueries"
import { updateDomain as updateDomainMutation } from "../graphql/mutations"
import { updateUser as updateUserMutation } from "../graphql/mutations"
import { updateHologram as updateHologramMutation } from "../graphql/mutations"
import { updateEdge as updateEdgeMutation } from "../graphql/mutations"
import { updateNode as updateNodeMutation } from "../graphql/mutations"
import { updateWeight as updateWeightMutation } from "../graphql/mutations"
import { updateGroup as updateGroupMutation } from "../graphql/mutations"
import { updateIssue as updateIssueMutation } from "../graphql/mutations"
import { updateUserNode as updateUserNodeMutation } from "../graphql/mutations"
import { updateUserEdge as updateUserEdgeMutation } from "../graphql/mutations"
import { updateHologramEdge as updateHologramEdgeMutation } from "../graphql/mutations"
import { updateHologramNode as updateHologramNodeMutation } from "../graphql/mutations"
import { updateHologramNodeGroup as updateHologramNodeGroupMutation } from "../graphql/mutations"


import { mapGetters } from "vuex";
export default {
  name:"Admin",
  data() {
    return {
      currentJsonFile: null,
      questionnaireFileName: null,
      dataTransacting: false,
      questions:[],
      questionnaire: {},
      questionnaireQuestions:[],
      fixhologramID: null,
      dynamoDBTableName: null,
      identityClaimIsFixing: false,
      allUserData: [],
    }
  },
  mounted() {
    if (this.userData && !this.userData.isAdmin){
      this.$router.push("/")
    }
    this.getAllUserData()
  },
  methods: {
    getAllUserData: async function(){
      const varialbes = {
        "limit": 1000
      }
      const users = await API.graphql(graphqlOperation(listUsersQuery, varialbes))
      this.allUserData = users.data.listUsers.items
    },
    fixDynamoDBIdentityClaim: async function(){
      this.identityClaimIsFixing = true;
      switch (this.dynamoDBTableName) {
        case "User":{
          for ( const user of this.allUserData ){
            const updateVariables = {
              "userID": user.id,
              "owner": user.id
            }
            try {
              await updateUserOwner(updateVariables)
            } catch(err){
              console.error(err)
            }
          }
          this.identityClaimIsFixing = false;
          break;
        }
        case "Hologram": {
          const listVariables = {
            "limit": 1000
          }
          try {
            const result = await API.graphql(graphqlOperation(listHologramsQuery, listVariables))
            for ( const hologram of result.data.listHolograms.items ){
              const currentOwner = hologram.owner;
              const userIndex = this.allUserData.findIndex( e => e.username === currentOwner );
              if ( userIndex !== -1 ){
                const user = this.allUserData[userIndex];
                const newOwner = user.id
                const updateVariables = {
                  "hologramID": hologram.id,
                  "owner": newOwner
                }
                await updateHologramOwner(updateVariables)
              }
            }
          } catch(err){
            console.error(err)
          }
          this.identityClaimIsFixing = false;
          break;
        }
        case "Domain":{
          const listVariables = {
            "limit": 1000
          }
          const result = await API.graphql(graphqlOperation(listDomainsQuery, listVariables))
          console.log(result)
          for ( const domain of result.data.listDomains.items ){

            const currentOwner = domain.owner;
            const userIndex = this.allUserData.findIndex( e => e.username === currentOwner );
            if ( userIndex !== -1 ){
              const user = this.allUserData[userIndex];
              const newOwner = user.id
              const updateVariables = {
                "domainID": domain.id,
                "owner": newOwner
              }
              await updateDomainOwner(updateVariables)
            }
          }
          this.identityClaimIsFixing = false
          break;
        }
        case "Edge": {
          const listVariables = {
            "limit": 1000
          }
          try {
            const result = await API.graphql(graphqlOperation(listEdgesQuery, listVariables));
            for ( const edge of result.data.listEdges.items ){
              const currentOwner = edge.owner;
              const userIndex = this.allUserData.findIndex( e => e.username === currentOwner );
              if ( userIndex !== -1 ){
                const user = this.allUserData[userIndex];
                const newOwner = user.id
                const updateVariables = {
                  "edgeID": edge.id,
                  "owner": newOwner
                }
                await updateEdgeOwner(updateVariables)
              }
            }
          } catch(err){
            console.error(err)
          }
          this.identityClaimIsFixing = false;
          break;
        }
        case "Node": {
          const listVariables = {
            "limit": 1000
          }
          try {
            const result = await API.graphql(graphqlOperation(listNodesQuery, listVariables));
            for ( const node of result.data.listNodes.items ){
              const currentOwner = node.owner;
              const userIndex = this.allUserData.findIndex( e => e.username === currentOwner );
              if ( userIndex !== -1 ){
                const user = this.allUserData[userIndex];
                const newOwner = user.id
                const updateVariables = {
                  "nodeID": node.id,
                  "owner": newOwner
                }
                await updateNodeOwner(updateVariables)
              }
            }
          } catch(err){
            console.error(err)
          }
          this.identityClaimIsFixing = false;
          break;
        }
        case "Weight": {
          const listVariables = {
            "limit": 1000
          }
          try {
            const result = await API.graphql(graphqlOperation(listWeightsQuery, listVariables));
            for ( const weight of result.data.listWeights.items ){
              const currentOwner = weight.owner;
              const userIndex = this.allUserData.findIndex( e => e.username === currentOwner );
              if ( userIndex !== -1 ){
                const user = this.allUserData[userIndex];
                const newOwner = user.id
                const updateVariables = {
                  "weightID": weight.id,
                  "owner": newOwner
                }
                await updateWeightOwner(updateVariables)
              }
            }
          } catch(err){
            console.error(err)
          }
          this.identityClaimIsFixing = false;
          break;
        }
        case "Group": {
          const listVariables = {
            "limit": 1000
          }
          try {
            const result = await API.graphql(graphqlOperation(listGroupsQuery, listVariables));
            for ( const group of result.data.listGroups.items ){
              const currentOwner = group.owner;
              const userIndex = this.allUserData.findIndex( e => e.username === currentOwner );
              if ( userIndex !== -1 ){
                const user = this.allUserData[userIndex];
                const newOwner = user.id
                const updateVariables = {
                  "groupID": group.id,
                  "owner": newOwner
                }
                await updateGroupOwner(updateVariables)
              }
            }
          } catch(err){
            console.error(err)
          }
          this.identityClaimIsFixing = false;
          break;
        }
        case "Issue": {
          const listVariables = {
            "limit": 1000
          }
          try {
            const result = await API.graphql(graphqlOperation(listIssuesQuery, listVariables));
            for ( const issue of result.data.listIssues.items ){
              const currentOwner = issue.owner;
              const userIndex = this.allUserData.findIndex( e => e.username === currentOwner );
              if ( userIndex !== -1 ){
                const user = this.allUserData[userIndex];
                const newOwner = user.id
                const updateVariables = {
                  "issueID": issue.id,
                  "owner": newOwner
                }
                await updateIssueOwner(updateVariables)
              }
            }
          } catch(err){
            console.error(err)
          }
          this.identityClaimIsFixing = false;
          break;
        }
        case "UserNode": {
          const listVariables = {
            "limit": 1000
          }
          try {
            const result = await API.graphql(graphqlOperation(listUserNodesQuery, listVariables));
            for ( const userNode of result.data.listUserNodes.items ){
              const currentOwner = userNode.owner;
              const userIndex = this.allUserData.findIndex( e => e.username === currentOwner );
              if ( userIndex !== -1 ){
                const user = this.allUserData[userIndex];
                const newOwner = user.id
                const updateVariables = {
                  "userNodeID": userNode.id,
                  "owner": newOwner
                }
                await updateUserNodeOwner(updateVariables)
              }
            }
          } catch(err){
            console.error(err)
          }
          this.identityClaimIsFixing = false;
          break;
        }
        case "UserEdge": {
          const listVariables = {
            "limit": 1000
          }
          try {
            const result = await API.graphql(graphqlOperation(listUserEdgesQuery, listVariables));
            for ( const userEdge of result.data.listUserEdges.items ){
              const currentOwner = userEdge.owner;
              const userIndex = this.allUserData.findIndex( e => e.username === currentOwner );
              if ( userIndex !== -1 ){
                const user = this.allUserData[userIndex];
                const newOwner = user.id
                const updateVariables = {
                  "userEdgeID": userEdge.id,
                  "owner": newOwner
                }
                await updateUserEdgeOwner(updateVariables)
              }
            }
          } catch(err){
            console.error(err)
          }
          this.identityClaimIsFixing = false;
          break;
        }
        case "HologramEdge": {
          const listVariables = {
            "limit": 1000
          }
          try {
            const result = await API.graphql(graphqlOperation(listHologramEdgesQuery, listVariables));
            for ( const hologramEdge of result.data.listHologramEdges.items ){
              const currentOwner = hologramEdge.owner;
              const userIndex = this.allUserData.findIndex( e => e.username === currentOwner );
              if ( userIndex !== -1 ){
                const user = this.allUserData[userIndex];
                const newOwner = user.id
                const updateVariables = {
                  "hologramEdgeID": hologramEdge.id,
                  "owner": newOwner
                }
                await updateHologramEdgeOwner(updateVariables)
              }
            }
          } catch(err){
            console.error(err)
          }
          this.identityClaimIsFixing = false;
          break;
        }
        case "HologramNode": {
          const listVariables = {
            "limit": 1000
          }
          try {
            const result = await API.graphql(graphqlOperation(listHologramNodesQuery, listVariables));
            for ( const hologramNode of result.data.listHologramNodes.items ){
              const currentOwner = hologramNode.owner;
              const userIndex = this.allUserData.findIndex( e => e.username === currentOwner );
              if ( userIndex !== -1 ){
                const user = this.allUserData[userIndex];
                const newOwner = user.id
                const updateVariables = {
                  "hologramNodeID": hologramNode.id,
                  "owner": newOwner
                }
                await updateHologramNodeOwner(updateVariables)
              }
            }
          } catch(err){
            console.error(err)
          }
          this.identityClaimIsFixing = false;
          break;
        }
        case "HologramNodeGroup": {
          const listVariables = {
            "limit": 1000
          }
          try {
            const result = await API.graphql(graphqlOperation(listHologramNodeGroupsQuery, listVariables));
            for ( const hologramNodeGroup of result.data.listHologramNodeGroups.items ){
              const currentOwner = hologramNodeGroup.owner;
              const userIndex = this.allUserData.findIndex( e => e.username === currentOwner );
              if ( userIndex !== -1 ){
                const user = this.allUserData[userIndex];
                const newOwner = user.id
                const updateVariables = {
                  "hologramNodeGroupID": hologramNodeGroup.id,
                  "owner": newOwner
                }
                await updateHologramNodeGroupOwner(updateVariables)
              }
            }
          } catch(err){
            console.error(err)
          }
          this.identityClaimIsFixing = false;
          break;
        }
      }
    },
    uploadQuestionnaire: async function(){
      if (!this.questionnaireFileName){
        return;
      }
      this.dataTransacting = true;
      const path = `questionnaire/${this.questionnaireFileName}.json`
      let self = this
      await getJsonFile(path)
        .then( data => self.currentJsonFile = data)

      // Begin to upload - create question first
      // get/update question or create

      for (var i=0; i<this.currentJsonFile.questions.length; i++){
        const question = this.currentJsonFile.questions[i];
        question.owner = this.user.username
        const updateOrCreateQuestionResult = await updateOrCreateQuestion(question);
        this.questions.splice(0, 0, updateOrCreateQuestionResult)
      }
      
      // update or create Questionnaire

      const updateOrCreateQuestionnaireResult = await updateOrCreateQuestionnaire(this.currentJsonFile);
      this.questionnaire = updateOrCreateQuestionnaireResult;

      // create QuestionnaireQuestion
      const updateOrCreateQuestionnaireQuestionResult = await updateOrCreateQuestionnaireQuestion(this.questionnaire, this.questions);
      this.questionnaireQuestions = updateOrCreateQuestionnaireQuestionResult;

      this.dataTransacting = false;

    },
    fixHologramGraph: async function(){
      
      try {
        const hologram = await API.graphql(graphqlOperation(getPublicHologramQuery, {"id": this.fixhologramID}));

        const filter = {
          "hologramID": {
            "eq": hologram.data.getHologram.id
          }
        }

        const hologramNodes = await API.graphql({
          query: listHologramNodesQuery,
          variables: {
            filter:filter,
            limit: 1000,
          },
          authMode: "AWS_IAM"
        })
        let graph = JSON.parse(hologram.data.getHologram.graph);

        hologramNodes.data.listHologramNodes.items.forEach( element => {
          const index = graph.nodes.findIndex( e => e.id === element.nodeID );
          if ( index === -1 ){
            const d3Node = {
              "id": element.nodeID,
              "title": element.node.title,
              "url": element.node.url, 
            }
            graph.nodes.splice(0, 0, d3Node)
            console.log(element.id)
          }
        })

        console.log(JSON.stringify(graph))
        
      } catch(err){
        console.error("Something went wrong when get hologram", err);
      }
      
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      userData: "auth/userData"
    }),
  },
};

const getJsonFile = async function(path){ // eslint-disable-line no-unused-vars
  const response = await axios.get(path);
  const data = response.data
  return data
}

const updateOrCreateQuestion = async function(question){
  let result;
  try {
    const listQuestionsResult = await API.graphql({
      query: listQuestionsQuery,
      variables: {filter:{id:{eq:`${question.id}`}}},
      authMode: "AWS_IAM"
    });
    if (listQuestionsResult.data.listQuestions.items.length != 0){
      question.id = listQuestionsResult.data.listQuestions.items[0].id;
      question.options = JSON.stringify(question.options);
      console.log(question);
      const updateResult = await API.graphql({
        query: updateQuestionMutation,
        variables: {input:question},
        authMode: "AWS_IAM"
      });
      result = updateResult.data.updateQuestion;
    } else {
      question.options = JSON.stringify(question.options);
      const createResult = await API.graphql({
        query: createQuestionMutation,
        variables: {input:question},
        authMode: "AWS_IAM"
      });
      result = createResult.data.createQuestion;
    }
    return result;
  } catch(err){
    console.log(err);
    return Promise.reject(err);
  }
}

const updateOrCreateQuestionnaire = async function(questionnaire){
  let result;
  try {
    const listQuestionnairesResult = await API.graphql({
      query: listQuestionnairesQuery,
      variables: {filter:{id:{eq:`${questionnaire.id}`}}},
      authMode: "AWS_IAM"
    });
    if (listQuestionnairesResult.data.listQuestionnaires.items.length != 0){
      questionnaire.id = listQuestionnairesResult.data.listQuestionnaires.items[0].id;
      let questionsOrder = [];
      for (var i=0; i<questionnaire.questions.length; i++){
        questionsOrder.splice(questionsOrder.length, 0, questionnaire.questions[i].id)
      }
      questionnaire.questionsOrder = JSON.stringify(questionsOrder);
      questionnaire.ending = JSON.stringify(questionnaire.ending);
      delete questionnaire.questions;
      const updateResult = await API.graphql({
        query: updateQuestionnaireMutation,
        variables: {input:questionnaire},
        authMode: "AWS_IAM"
      });
      result = updateResult.data.updateQuestionnaire;
    } else {
      let questionsOrder = [];
      for (var j=0; j<questionnaire.questions.length; j++){
        questionsOrder.splice(questionsOrder.length, 0, questionnaire.questions[j].id)
      }
      questionnaire.questionsOrder = JSON.stringify(questionsOrder);
      questionnaire.ending = JSON.stringify(questionnaire.ending);
      delete questionnaire.questions
      const createResult = await API.graphql({
        query: createQuestionnaireMutation,
        variables: {input:questionnaire},
        authMode: "AWS_IAM"
      });
      result = createResult.data.createQuestionnaire;
    }
    return result
  } catch(err){
    console.log(err)
    return Promise.reject(err);
  }
}

const updateOrCreateQuestionnaireQuestion = async function(questionnaire, questions){
  let result = [];
  for (var i=0; i<questions.length; i++){
    try {
      const filter = {
        and:[
          {
            questionnaireID:{
              eq:`${questionnaire.id}`
            },
            questionID:{
              eq:`${questions[i].id}`
            }
          }
        ]
      }
      const listQuestionnairesResult = await API.graphql({
        query: listQuestionnaireQuestionsQuery,
        variables: {filter:filter},
        authMode: "AWS_IAM"
      });
      
      if (listQuestionnairesResult.data.listQuestionnaireQuestions.items.length === 0){
        const newQuestionnaireQuestion = {
          questionnaireID: questionnaire.id,
          questionID: questions[i].id
        }
        const createResult = await API.graphql({
          query: createQuestionnaireQuestionMutation,
          variables: {input:newQuestionnaireQuestion},
          authMode: "AWS_IAM"
        });
        result.splice(0, 0, createResult.data.createQuestionnaireQuestions);
      } else {
        result.splice(0, 0, listQuestionnairesResult.data.listQuestionnaireQuestions.items[0])
      }
    } catch(err){
      console.log(err)
      return Promise.reject(err);
    }
  }
  return result
}

const updateDomainOwner = async ({ domainID, owner }) => {
  try{
    const variables = {
      "input": {
        id: domainID,
        owner: owner
      }
    };
    console.log(variables);
    const result = await API.graphql(graphqlOperation(updateDomainMutation, variables));
    return Promise.resolve(result.data.updateDomain);
  } catch(err){
    return Promise.reject(err)
  } 
};

const updateUserOwner = async ({ userID, owner }) => {
  try {
    const variables = {
      "input": {
        id: userID,
        owner: owner
      }
    };
    const result = await API.graphql(graphqlOperation(updateUserMutation, variables));
    return Promise.resolve(result.data.updateUser);
  } catch(err){
    return Promise.reject(err)
  }
};

const updateHologramOwner = async ({ hologramID, owner }) => {
  try {
    const variables = {
      "input": {
        id: hologramID,
        owner: owner
      }
    };
    const result = await API.graphql(graphqlOperation(updateHologramMutation, variables));
    return Promise.resolve(result.data.updateHologram);
  } catch(err){
    return Promise.reject(err)
  }
};

const updateEdgeOwner = async ({ edgeID, owner }) => {
  try {
    const variables = {
      "input": {
        id: edgeID,
        owner: owner
      }
    };
    const result = await API.graphql(graphqlOperation(updateEdgeMutation, variables));
    return Promise.resolve(result.data.updateEdge);
  } catch(err){
    return Promise.reject(err)
  }
};

const updateNodeOwner = async ({ nodeID, owner }) => {
  try {
    const variables = {
      "input": {
        id: nodeID,
        owner: owner
      }
    };
    const result = await API.graphql(graphqlOperation(updateNodeMutation, variables));
    return Promise.resolve(result.data.updateNode);
  } catch(err){
    return Promise.reject(err)
  }
};

const updateWeightOwner = async ({ weightID, owner }) => {
  try {
    const variables = {
      "input": {
        id: weightID,
        owner: owner
      }
    };
    const result = await API.graphql(graphqlOperation(updateWeightMutation, variables));
    return Promise.resolve(result.data.updateWeight);
  } catch(err){
    return Promise.reject(err)
  }
};

const updateGroupOwner = async ({ groupID, owner }) => {
  try {
    const variables = {
      "input": {
        id: groupID,
        owner: owner
      }
    };
    const result = await API.graphql(graphqlOperation(updateGroupMutation, variables));
    return Promise.resolve(result.data.updateGroup);
  } catch(err){
    return Promise.reject(err);
  }
};

const updateIssueOwner = async ({ issueID, owner }) => {
  try {
    const variables = {
      "input": {
        id: issueID,
        owner: owner
      }
    };
    const result = await API.graphql(graphqlOperation(updateIssueMutation, variables));
    return Promise.resolve(result.data.updateIssue);
  } catch(err){
    return Promise.reject(err);
  }
};

const updateUserNodeOwner = async ({ userNodeID, owner }) => {
  try {
    const variables = {
      "input": {
        id: userNodeID,
        owner: owner
      }
    };
    const result = await API.graphql(graphqlOperation(updateUserNodeMutation, variables));
    return Promise.resolve(result.data.updateUserNode);
  } catch(err){
    return Promise.reject(err);
  }
};

const updateUserEdgeOwner = async ({ userEdgeID, owner }) => {
  try {
    const variables = {
      "input": {
        id: userEdgeID,
        owner: owner
      }
    };
    const result = await API.graphql(graphqlOperation(updateUserEdgeMutation, variables));
    return Promise.resolve(result.data.updateUserEdge);
  } catch(err){
    return Promise.reject(err)
  }
};

const updateHologramEdgeOwner = async ({ hologramEdgeID, owner }) => {
  try {
    const variables = {
      "input": {
        id: hologramEdgeID,
        owner: owner
      }
    };
    const result = await API.graphql(graphqlOperation(updateHologramEdgeMutation, variables));
    return Promise.resolve(result.data.updateHologramEdge);
  } catch(err){
    return Promise.reject(err)
  }
};

const updateHologramNodeOwner = async ({ hologramNodeID, owner }) => {
  try {
    const variables = {
      "input": {
        id: hologramNodeID,
        owner: owner
      }
    };
    const result = await API.graphql(graphqlOperation(updateHologramNodeMutation, variables));
    return Promise.resolve(result.data.updateHologramNode);
  } catch(err){
    return Promise.reject(err)
  }
};

const updateHologramNodeGroupOwner = async ({ hologramNodeGroupID, owner }) => {
  try {
    const variables = {
      "input": {
        id: hologramNodeGroupID,
        owner: owner
      }
    };
    const result = await API.graphql(graphqlOperation(updateHologramNodeGroupMutation, variables));
    return Promise.resolve(result.data.updateHologramNodeGroup);
  } catch(err){
    return Promise.reject(err)
  }
};



</script>
<style>
</style>