export const onUpdateHologramByHologramId = /* GraphQL */ `
  subscription OnUpdateHologramByHologramId($id: ID) {
    onUpdateHologramByHologramID(id: $id) {
      id
      baseType
      intro
      title
      graph
      createdByID
      contributorID
      accessPolicy
      weightID
      action
      userPresent
      updatedAt
      createdAt
      owner
      like {
        items {
          id
          baseType
          hologramID
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      weight {
        id
        baseType
        weightType
        hologramNodeID
        userNodeID
        nodeID
        hologramID
        calculation
        value
        version
        accessPolicy
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        userNode {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        hologramNode {
          id
          hologramID
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
      }
      post {
        items {
          id
          baseType
          postType
          hologramID
          title
          content
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      group {
        items {
          id
          baseType
          hologramID
          groupID
          color
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      node {
        items {
          id
          hologramID
          nodeID
          accessPolicy
          weightID
          createdByID
          createdAt
          updatedAt
        }
        nextToken
      }
      community {
        items {
          id
          hologramID
          communityID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      contributor {
        items {
          id
          baseType
          hologramID
          contributorID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      edge {
        items {
          id
          hologramID
          edgeID
          startNodeID
          endNodeID
          accessPolicy
          createdByID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
