<template>
  <div v-if="sidebarOpen !== true" class="flex h-full bg-sd-base03-brblack">
    <button
      @click="toggleSidebar"
      @mouseover="hoverSidebarToggelButton = true"
      @mouseleave="hoverSidebarToggelButton = false"
      class="flex items-center h-10 px-2 rounded-full hover:bg-sd-base2-white hover:bg-opacity-5 focus:outline-none"
    >
      <div v-if="userData" class="my-auto">
        <div v-if="userData.avatar.key == 'avatars/defaultAvatarDark.png'" class="flex items-center">
          <tempAvatar 
            :size="6"
            :username="user.username"
            :bgColor="'bg-sd-base0-brblue'"
            :textColor="'text-sd-base03-brblack '"
            :fontSize="'text-md'"
            class="mr-4"
          />
        </div>
        <div v-else class="flex items-center">
          <amplify-s3-image :key="userData.avatar.key" :img-key="userData.avatar.key" level="protected" class="w-6 h-6 mx-3" />
        </div>
      </div>
      <div class="flex-1 py-0 my-auto font-sans font-semibold text-left text-1xl text-sd-base0-brblue">
        {{ user.username }}
      </div>
      <svg
        v-if="hoverSidebarToggelButton == true"
        xmlns="http://www.w3.org/2000/svg"
        class="w-5 h-5 ml-1 fill-current text-sd-base0-brblue"
        viewBox="0 0 16 16"
      >
        <path d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z" />
      </svg>
    </button>
  </div>
</template>
<script>
import { gaHelpers } from "@/analytics/helper.js";
import { ref, computed } from "vue";
import { useStore } from "vuex";
import tempAvatar from "../user/tempAvatar.vue"
export default {
  name: "SidebarToggler",
  components: {
    tempAvatar
  },
  setup() {
    const store = useStore();
    const hoverSidebarToggelButton = ref(false);
    const user = computed(() => store.getters["auth/user"]);
    const userData = computed(() => store.getters["auth/userData"]);
    const sidebarOpen = computed(() => store.getters["sidebar/sidebarOpen"]);

    const toggleSidebar = () => {
      console.log("init");
      if (sidebarOpen.value) {
        store.dispatch("sidebar/toggleSidebar", { data: false });
      } else {
        store.dispatch("sidebar/toggleSidebar", { data: true });
        gaHelpers.interaction("open_sidebar", "modal");
      }
    };

    return {
      user,
      userData,
      hoverSidebarToggelButton,
      sidebarOpen,
      // Method
      toggleSidebar,
    };
  }
};
</script>

<style>
</style>