
import { API, graphqlOperation } from "aws-amplify";
import _ from "lodash"
import { handle, validateEmailSimple } from "../../../utilities/utilities"

import { createHologramContributor as createHologramContributorMutation } from "../../../graphql/custom/customMutations"
import { deleteHologramContributor as deleteHologramContributorMutation } from "../../../graphql/custom/customMutations"
import { updateHologram as updateHologramMutation } from "../../../graphql/custom/customMutations"

export const contribute = {
  state: {
    contributionMode: false,
    currentHologramContributors: [],
  },
  mutations: {
    mutateContributionMode: (state, payload) => {
      state.contributionMode = payload;
    },
    mutateCurrentHologramContributors: (state, payload) => {
      state.currentHologramContributors = payload;
    },
  },
  actions: {
    // Unit actions
    setContributionMode: (context, contributionMode) => {
      context.commit("mutateContributionMode", contributionMode);
    },
    setCurrentHologramContributors: (context, hologramContributors) => {
      context.commit("mutateCurrentHologramContributors", hologramContributors)
    },
    addItemIntoCurrentHologramContributors: (context, contributor) => {
      let temp = _.cloneDeep(context.state.currentHologramContributors);
      temp.splice(0, 0, contributor);
      context.commit("mutateCurrentHologramContributors", temp)
    },
    removeItemFromCurrentHologramContributors: (context, contributorID) => {
      let temp = _.cloneDeep(context.state.currentHologramContributors);
      const index = temp.findIndex( e => e.id === contributorID);
      temp.splice(index, 1);
      context.commit("mutateCurrentHologramContributors", temp)
    },

    // Request actions

    createHologramContributor: async (_, {hologramID, contributorID, owner}) => {
      const variables = {
        input: {
          baseType: "HologramContributor",
          hologramID: hologramID,
          contributorID: contributorID,
          owner: owner
        }
      }
      try {
        const result = await API.graphql({
          query: createHologramContributorMutation,
          variables: variables,
          authMode: "AWS_IAM"
        })
        return Promise.resolve(result.data.createHologramContributor)
      } catch(error){
        return Promise.reject(error)
      }
    },
    deleteHologramContributor: async (_, {hologramContributorID}) => {
      const variables = {
        input: {
          id: hologramContributorID
        }
      }
      try {
        const result = await API.graphql({
          query: deleteHologramContributorMutation,
          variables: variables,
          authMode: "AWS_IAM"
        })
        return Promise.resolve(result.data.deleteHologramContributor)
      } catch(error){
        return Promise.reject(error)
      }
    },

    // Business actions

    checkOutContributor: async (context, {hologramID, contributorUserID, hologramContributorID}) => {
      let variables; // eslint-disable-line no-unused-vars

      variables = {
        hologramContributorID: hologramContributorID
      };

      const [deleteHologramContributorError, ] = await handle(context.dispatch("deleteHologramContributor", variables));

      if ( deleteHologramContributorError ){
        deleteHologramContributorError.context = "Can't delete HologramContributor"
        return Promise.reject(deleteHologramContributorError)
      }

      context.dispatch("removeItemFromCurrentHologramContributors", hologramContributorID);

      variables = {
        hologramID: hologramID,
        contributorUserID: contributorUserID
      };

      const [checkoutContributorFromHologramError, checkoutContributorFromHologramResult] = await handle(context.dispatch("checkoutContributorFromHologram", variables));

      if ( checkoutContributorFromHologramError ){
        checkoutContributorFromHologramError.context = "Can't delete contributor from hologram"
        return Promise.reject(checkoutContributorFromHologramError)
      }

      let newHologram = _.cloneDeep(context.rootState.hologram.currentHologram);
      newHologram.contributorID = checkoutContributorFromHologramResult.contributorID;
      newHologram.contributor = checkoutContributorFromHologramResult.contributor
      context.dispatch("setCurrentHologram", newHologram);

      return Promise.resolve("Success")
    },
    checkInContributor: async (context, { identityTerm, hologramID, ownerID }) => {
      if (!identityTerm) {
        return;
      }

      const isEmail = validateEmailSimple(identityTerm);
      let getUserError, user;

      if (isEmail) {
        const getUserVariables = {
          email: identityTerm,
        };

        [getUserError, user] = await handle(
          context.dispatch("auth/getUserIDByEmail", getUserVariables, {
            root: true,
          })
        );

        if (getUserError) {
          getUserError.context = "Can't get user by email";
          return Promise.reject(getUserError);
        }
      } else {
        const getUserVariables = {
          username: identityTerm,
        };

        [getUserError, user] = await handle(
          context.dispatch("auth/getUserIDByUsername", getUserVariables, {
            root: true,
          })
        );

        if (getUserError) {
          getUserError.context = "Can't get user by username";
          return Promise.reject(getUserError);
        }
      }

      if (!user) {
        return Promise.reject("UserNotFound");
      }

      const currentContributors = context.state.currentHologramContributors;
      const contributorIndex = currentContributors.findIndex( e => e.contributorID === user.id );
      if ( contributorIndex !== -1 ){
        return Promise.reject("DuplicatedContributor")
      }

      const createHologramContributorVariables = {
        hologramID: hologramID,
        contributorID: user.id,
        owner: ownerID
      }

      const [createHologramContributorError, contributor] = await handle( // eslint-disable-line no-unused-vars
        context.dispatch(
          "createHologramContributor",
          createHologramContributorVariables
        )
      )

      if ( createHologramContributorError ){
        createHologramContributorError.context = "Can't create hologramContributor"
        return Promise.reject(createHologramContributorError)
      }
      
      context.dispatch("addItemIntoCurrentHologramContributors", contributor);

      const updateHologramVariables = {
        contributorID: user.id,
        hologramID: hologramID
      };

      const [updateHologramError, hologram] = await handle(
        context.dispatch(
          "checkContributorIntoHologram",
          updateHologramVariables
        )
      );

      if (updateHologramError) {
        updateHologramError.context = "Can't update Hologram";
        return Promise.reject(updateHologramError);
      }

      let newHologram = _.cloneDeep(context.rootState.hologram.currentHologram);
      newHologram.contributorID = hologram.contributorID;
      newHologram.contributor = hologram.contributor
      context.dispatch("setCurrentHologram", newHologram);

      return Promise.resolve(contributor)
    },
    checkContributorIntoHologram: async (context, {hologramID, contributorID}) => {
      const currentHologram = context.rootState.hologram.currentHologram
      
      if ( currentHologram.id !== hologramID ){
        return Promise.reject("Hologram not match")
      }

      let contributors = currentHologram.contributorID
      contributors.splice(0, 0, contributorID)

      const variables = {
        input: {
          id: hologramID,
          contributorID: contributors
        }
      }
      try {
        const result = await API.graphql(graphqlOperation(updateHologramMutation, variables));
        return Promise.resolve(result.data.updateHologram);
      } catch(error){
        return Promise.reject(error)
      }
    },
    checkoutContributorFromHologram: async(context, {hologramID, contributorUserID}) => {
      const currentHologram = context.rootState.hologram.currentHologram;

      if ( currentHologram.id !== hologramID ){
        return Promise.reject("Hologram not match")
      }

      let contributors = currentHologram.contributorID;
      const index = contributors.indexOf(contributorUserID);
      if ( index === -1 ){
        return Promise.reject("Target contributor not found")
      }

      contributors.splice(index, 1);

      const variables = {
        input: {
          id: hologramID,
          contributorID: contributors
        }
      }
      try {
        const result = await API.graphql(graphqlOperation(updateHologramMutation, variables));
        return Promise.resolve(result.data.updateHologram);
      } catch(error){
        return Promise.reject(error)
      }
    },
  },
  getters: {
    contributionMode: (state) => state.contributionMode,
    currentHologramContributors: (state) => state.currentHologramContributors,
  }
};
