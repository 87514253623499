<template>
  <div
    class="flex h-8 mx-auto rounded-md shadow-2xl w-60 bg-sd-base02-black transition-width"
    v-if="deleteModeOpen || connectionSetUpModeOpen"
  >
    <div
      class="flex flex-row mx-auto"
    >
      <div
        v-if="deleteModeOpen"
        class="my-auto mr-4 font-sans text-sm font-semibold text-sd-yellow"
      >
        Delete mode
      </div>
      <div
        v-if="connectionSetUpModeOpen"
        class="my-auto mr-4 font-sans text-sm font-semibold text-sd-yellow"
      >
        Setup connection mode
      </div>
      <button
        class="px-1 my-auto font-sans text-sm font-normal rounded-md text-sd-base1-brcyan hover:bg-sd-base2-white hover:bg-opacity-5 focus:outline-none"
        @click="closeDeleteMode"
      >
        close
      </button>
    </div>
    
  </div>
</template>
<script>
import { computed } from 'vue'
import { useStore } from "vuex";
import { gaHelpers } from "@/analytics/helper.js"

export default {
  setup() {
    const store = useStore();
    const deleteModeOpen = computed(() => store.getters["hologram/deleteModeOpen"]);
    const connectionSetUpModeOpen = computed(() => store.getters["hologram/connectionSetUpModeOpen"]);

    const closeDeleteMode = () => {
      if (deleteModeOpen.value){
        store.dispatch("hologram/updateDeleteModeOpen", {data:false});
        gaHelpers.interaction("close_delete_mode", "delete_mode");
      }
      if (connectionSetUpModeOpen.value){
        store.dispatch("hologram/cleanUpConnectionSetupMode");
        store.dispatch("hologram/updateRenderHologramNodeColor", {data:true})
        gaHelpers.interaction("close_setup_edge_mode", "setup_edge_mode");
      }
    }
    return {
      deleteModeOpen,
      connectionSetUpModeOpen,
      closeDeleteMode
    }
  }
};
</script>
<style>
</style>