<template>
  <div class="fixed inset-0 h-full">
    <div
      class="flex items-end justify-center h-full min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0"
      id="modal-container"
    >
      <transition
        enter-active-class="duration-300 ease-out"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          @click="toggleModal"
          class="fixed inset-0"
          v-if="modalOpen"
        >
          <div class="absolute inset-0"></div>
        </div>
      </transition>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        v-if="modalOpen"
        >&#8203;</span
      >
      <transition
        name="modal-transition"
        enter-active-class="duration-300 ease-out"
        enter-from-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
        enter-to-class="translate-y-0 opacity-100 sm:scale-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="translate-y-0 opacity-100 sm:scale-100"
        leave-to-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
      >
        <div
          class="fixed flex flex-row overflow-y-auto rounded-sm shadow-xl w-80 bg-sd-base02-black p-1.5"
          role="dialog"
          aria-modal="true"
          v-if="modalOpen"
          v-bind:style="positionStyle"
        >
          <div
            class="flex flex-grow mr-2"
          >
            <input 
              type="text"
              class="w-full bg-sd-base03-brblack font-sans rounded-sm font-normal text-sm text-sd-base0-brblue py-1.5 px-3 focus:outline-none focus:ring-0 focus:border-sd-base1-brcyan"
              v-model="hologramTitle"
              id="hologram-title-input"
              @keyup.enter="updateHologramTitle"
            />
          </div>
          <div
            class="flex px-1 my-auto font-sans text-sm font-normal text-sd-base0-brblue"
          >
            enter
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { computed, ref, onMounted, nextTick, onUnmounted } from 'vue'
import { useStore } from "vuex";
//import { gaHelpers } from "@/analytics/helper.js"
export default {
  name: "hologramTitleEditorModal",
  setup() {
    const store = useStore();
    const currentHologram = computed(() => store.getters["hologram/currentHologram"]);
    const modalOpen = computed(() => store.getters["modal/hologramTitleEditorModalOpen"]);
    const modalPosition = computed(() => store.getters["modal/hologramTitleEditorModalPosition"]);
    const positionStyle = ref({})
    const hologramTitle = ref(null)
    //const modalData = computed(() => store.getters["modal/hologramSharingModalData"]);
    
    const toggleModal = () => {
      if (modalOpen.value){
        store.dispatch("modal/toggleHologramTitleEditorModal", {data:false});
        store.dispatch("modal/updateHologramTitleEditorModalPosition", {data:{}});
      }
    }

    const prepare = () => {
      positionStyle.value = {
        left: modalPosition.value.x + 'px',
        top: modalPosition.value.y + 30 + 'px'
      }
      hologramTitle.value = currentHologram.value.title

      window.addEventListener("keydown", keydownEscHandler)

      nextTick(()=>{
        const input = document.getElementById("hologram-title-input");
        input.focus();
        input.select();
      })
    }

    const clear = () => {
      window.removeEventListener("keydown", keydownEscHandler)
    }

    const keydownEscHandler = (event) => {
      if ( event.code !== "Escape" ) return;
      if ( !modalOpen.value ) return;
      toggleModal()
    };

    const updateHologramTitle = async () => {
      if ( hologramTitle.value === currentHologram.value.title ){
        toggleModal();
        return;
      }

      const data = {
        id: currentHologram.value.id,
        title: hologramTitle.value
      }
      try {
        store.dispatch("hologram/updateHologramTitle", {"data":data})
        toggleModal();
      } catch(err){
        return;
      }
    }

    onMounted(prepare)
    onUnmounted(clear)

    return {
      prepare,
      clear,
      currentHologram,
      hologramTitle,
      modalOpen,
      positionStyle,
      toggleModal,
      updateHologramTitle,
      keydownEscHandler
    }

  }
};
</script>
<style>
  ::selection {
    background-color: rgba(238, 232, 213, 0.2);
    opacity: 0.1;
  }
</style>