<template>
  <div
    v-bind:style="getSidebarStyle()"
    v-bind:class="getSidebarAnimationClass()"
    class="fixed top-0 left-0 flex w-56 h-screen overflow-y-auto bg-sd-base02-black"
  >
    <div class="flex flex-col w-full h-full">
      <button class="flex w-full h-16 group hover:bg-sd-base2-white hover:bg-opacity-5 focus:outline-none">
        <div
          v-if="userData"
          class="my-auto"
        >
          <div
            v-if="userData.avatar.key == 'avatars/defaultAvatarDark.png'"
            class="flex items-center"
          >
            <tempAvatar 
              :size="6"
              :username="user.username"
              :bgColor="'bg-sd-base0-brblue'"
              :textColor="'text-sd-base03-brblack '"
              :fontSize="'text-md'"
              class="mx-5"
            />
          </div>
          <div
            v-else
            class="flex items-center"
          >
            <amplify-s3-image
              :key="userData.avatar.key"
              :img-key="userData.avatar.key"
              level="protected"
              class="w-6 h-6 mx-6"
            />
          </div>
        </div>
        <div
          v-if="user"
          class="flex my-auto mr-4"
        >
          <div
            v-if="checkWhetherUserLoginWithGoogle"
            class="flex my-auto font-sans font-semibold text-left text-sd-base0-brblue"
          >
            {{ user.attributes.name }}
          </div>
          <div
            v-else
            class="flex-1 my-auto font-sans font-semibold text-left text-1xl text-sd-base0-brblue"
          >
            {{ user.username }}
          </div>
        </div>
        <button
          @click="toggleSidebarHandler"
          class="flex items-center justify-end my-auto ml-auto mr-3 rounded-sm h-7 w-7 focus:outline-none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-4 h-4 opacity-0 fill-current text-sd-base0-brblue group-hover:opacity-100"
            viewBox="0 0 16 16"
          >
            <path d="M11.854 3.646a.5.5 0 0 1 0 .708L8.207 8l3.647 3.646a.5.5 0 0 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 0 1 .708 0zM4.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 1 0v-13a.5.5 0 0 0-.5-.5z" />
          </svg>
        </button>
      </button>
      <button
        @click="toggleCreateNewHologramModal"
        class="flex items-center w-full pl-5 h-7 hover:bg-sd-base2-white hover:bg-opacity-5 focus:outline-none"
      >
        <div class="flex w-5 h-7">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="flex w-3 h-3 my-auto fill-current text-sd-base0-brblue"
            viewBox="0 0 16 16"
          >
            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
          </svg>
        </div>
        <span class="ml-2 font-sans text-sm font-semibold text-sd-base0-brblue">Create new hologram</span>
      </button>
      <button
        @click="toggleMainSettingsModal"
        class="flex items-center w-full pl-5 h-7 hover:bg-sd-base2-white hover:bg-opacity-5 focus:outline-none"
      >
        <div class="flex w-5 h-7">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="flex w-3 h-3 my-auto fill-current text-sd-base0-brblue"
            viewBox="0 0 16 16"
          >
            <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 01-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 01.872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 012.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 012.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 01.872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 01-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 01-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 110-5.86 2.929 2.929 0 010 5.858z" />
          </svg>
        </div>
        <span class="ml-2 font-sans text-sm font-semibold text-sd-base0-brblue">Settings</span>
      </button>
      <router-link
        :to="{ name: 'Dock', params: { username: $route.params.username } }"
        tag="button"
        class="flex items-center w-full pl-5 mt-6 group h-7 hover:bg-sd-base2-white hover:bg-opacity-5 focus:outline-none"
      >
        <div class="flex w-5 h-7">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="flex w-3 h-3 my-auto fill-current text-sd-base0-brblue"
            viewBox="0 0 16 16"
          >
            <path d="M0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6v7zM2 3a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 0-1h-11A.5.5 0 0 0 2 3zm2-2a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7A.5.5 0 0 0 4 1z" />
          </svg>
        </div>
        <span class="ml-2 font-sans text-sm font-semibold text-sd-base0-brblue">Dock</span>
      </router-link>
      <router-link
        :to="{
          name: 'UserHolograms',
          params: { username: $route.params.username },
        }"
        tag="button"
        class="flex items-center w-full pl-5 group h-7 hover:bg-sd-base2-white hover:bg-opacity-5 focus:outline-none"
      >
        <div class="flex w-5 h-7">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="flex w-3 h-3 my-auto fill-current text-sd-base0-brblue"
            viewBox="0 0 16 16"
          >
            <path d="M9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3zm-8.322.12C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139z" />
          </svg>
        </div>
        <span class="ml-2 font-sans text-sm font-semibold text-sd-base0-brblue">Holograms</span>
      </router-link>
      <div class="flex-1">&nbsp;</div>
      <footer class="sticky bottom-0 left-0 flex flex-row bg-sd-base02-black">
        <button
          @click="logout"
          class="sticky flex w-full px-5 py-4 bottom-20 group hover:bg-sd-base2-white hover:bg-opacity-5 focus:outline-none"
        >
          <div class="flex-1 my-auto font-sans font-semibold text-left text-1xl text-sd-base0-brblue">Logout</div>
        </button>
      </footer>
    </div>

    <teleport to="#modals">
      <div v-show="mainSettingsModalOpen">
        <mainSettingsModal />
      </div>
    </teleport>
    <teleport to="#modals">
      <div v-show="createNewHologramModalOpen">
        <createNewHologramModal />
      </div>
    </teleport>
  </div>
</template>

<script>
import totuslink_config from "../../totuslink.config";
import { mapGetters } from "vuex";
import { TweenMax } from "gsap";
import mainSettingsModal from "@/components/modals/mainSettingsModal.vue";
import createNewHologramModal from "@/components/modals/createNewHologramModal.vue";
import { gaHelpers } from "@/analytics/helper.js";
import tempAvatar from "../user/tempAvatar.vue"
export default {
  name: "Sidebar",
  components: {
    mainSettingsModal,
    createNewHologramModal,
    tempAvatar,
  },
  data() {
    return {
      hardcodeSidebarOffsetWidth: 224,
    };
  },
  mounted() {
    if (this.$el) {
      TweenMax.set(this.$el, {
        x: -Math.abs(this.$el.offsetWidth),
      });
      const sidebarWidth = this.$el.offsetWidth;
      this.$store.dispatch("sidebar/toggleSidebarWidth", sidebarWidth);
    }
  },
  methods: {
    async logout() {
      await this.$store.dispatch("auth/logout");
      this.removeAllCookies();
      this.$router.push("/");
    },
    toggleSidebarHandler() {
      if (this.sidebarOpen) {
        this.$store.dispatch("sidebar/toggleSidebar", { data: false });
        gaHelpers.interaction("close_sidebar", "modal");
      } else {
        this.$store.dispatch("sidebar/toggleSidebar", { data: true });
      }
    },
    getSidebarAnimationClass() {
      if (this.$route.name == "Hologram") {
        return "transform duration-300";
      }
    },
    getSidebarStyle() {
      if (this.sidebarOpen) {
        if (this.$el) {
          return {
            "margin-left": `${Math.abs(this.$el.offsetWidth)}px`,
          };
        } else {
          return {
            "margin-left": `${Math.abs(this.hardcodeSidebarOffsetWidth)}px`,
          };
        }
      } else {
        return {
          "margin-left": `0px`,
        };
      }
    },
    toggleMainSettingsModal() {
      if (this.mainSettingsModalOpen) {
        this.$store.dispatch("modal/toggleMainSettingsModal", { data: false });
      } else {
        this.$store.dispatch("modal/toggleMainSettingsModal", { data: true });
        gaHelpers.interaction("open_main_setting_modal", "modal");
      }
    },
    toggleCreateNewHologramModal() {
      if (this.createNewHologramModalOpen) {
        this.$store.dispatch("modal/toggleCreateNewHologramModal", {
          data: false,
        });
      } else {
        this.$store.dispatch("modal/toggleCreateNewHologramModal", {
          data: true,
        });
        gaHelpers.interaction("open_create_new_hologram_modal", "modal");
      }
    },
    removeAllCookies: function () {
      this.$cookie.removeCookie("idToken", {
        domain: totuslink_config.cookies_domain,
      });
      this.$cookie.removeCookie("accessToken", {
        domain: totuslink_config.cookies_domain,
      });
      this.$cookie.removeCookie("refreshToken", {
        domain: totuslink_config.cookies_domain,
      });
      this.$cookie.removeCookie("clockDrift", {
        domain: totuslink_config.cookies_domain,
      });
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      userData: "auth/userData",
      sidebarOpen: "sidebar/sidebarOpen",
    }),
    sidebarOpen: function () {
      return this.$store.state.sidebar.sidebarOpen;
    },
    mainSettingsModalOpen: function () {
      return this.$store.state.modal.mainSettingsModalOpen;
    },
    createNewHologramModalOpen: function () {
      return this.$store.state.modal.createNewHologramModalOpen;
    },
    checkWhetherUserLoginWithGoogle: function () {
      if (this.user != null && this.user.attributes.identities) {
        let provider = JSON.parse(this.user.attributes.identities);
        if (provider[0].providerName == "Google") {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
amplify-s3-image {
  --height: 24px;
  --width: 24px;
}
</style>