<template>
  <div 
    class="flex flex-col h-auto sm:h-full w-10/12 sm:w-8/12 md:w-7/12 mx-auto my-24"
    @keydown="onKeydownHandler($event)"  
  >
    <div
      class="w-full mr-auto text-left font-sans font-semibold text-lg text-sd-base2-white"
    >
      {{`# ${question.index}`}}
    </div>
    <div
      class="w-full mt-2 mr-auto text-left font-sans font-semibold text-2xl md:text-3xl text-sd-base2-white"
    >
      {{question.title}}
    </div>
    <div
      class="w-full mt-2 mr-auto text-left font-sans font-normal text-lg md:text-xl text-sd-base1-brcyan"
    >
      {{question.context}}
    </div>
    <div
      class="w-full mt-4 sm:mt-6"
    >
      <input 
        class="w-full px-0 bg-sd-base03-brblack border-t-0 border-l-0 border-r-0 focus:ring-0 focus:border-b-3 focus:border-sd-base1-brcyan focus:ring-sd-base1-brcyan font-sans font-normal text-lg text-sd-base1-brcyan"
        type="text"
        placeholder="Type your answer here..."
        v-model="activeAnswer"
        @input="onInputHandler($event)"
      >
    </div>
    <div
      class="w-full mt-20"
    >
      <button
        class="flex flex-row w-24 h-12 items-center justify-center border border-sd-base00-bryellow rounded-sm focus:outline-none hover:bg-sd-base02-black"
        @click="clickHandler"
        v-show="activeAnswer && !hideOKButton"
      >
        <div
          class="font-sans font-semibold text-lg text-sd-base1-brcyan mr-2"
        >
          OK
        </div>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" class="flex fill-current text-sd-base0-brblue w-6 h-6 m-auto" viewBox="0 0 16 16">
            <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
          </svg>
        </div>
      </button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import _ from 'lodash'
export default {
  name: "shortAnswer",
  props:{
    question: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      activeAnswer: null,
      hideOKButton: false,
    }
  },
  methods: {
    onKeydownHandler($event){
      console.log($event)
    },
    clickHandler(){
      if (this.activeAnswer){
        const updateAnswer = {};
        const temp = _.cloneDeep(this.question);
        temp.answer = this.activeAnswer;
        updateAnswer[`${this.question.id}`] = temp;
        this.$store.dispatch("questionnaire/updateAnswers", {data: updateAnswer});
        this.$store.dispatch("questionnaire/updateAnsweredQuestions", {data: this.question.id, type:"update"})
        const index = this.questionnaire.questionsOrder.findIndex( e => e === this.currentQuestionID);
        console.log(index, this.questionnaire.questionsOrder, this.currentQuestionID)
        if ( index+1 <= this.questionnaire.questionsOrder.length -1 ){
          this.$store.dispatch("questionnaire/updateCurrentQuestionID",{data:this.questionnaire.questionsOrder[`${index+1}`]})
        }
        if (this.answeredQuestions.length === this.questionsSum){
          this.hideOKButton = true;
        }
      } 
    },
    onInputHandler($event){
      if ($event.currentTarget.innerText.length == 0){
        this.$store.dispatch("questionnaire/updateAnsweredQuestions", {type:"delete", data:this.question.id})
      }
    }
  },
  computed: {
    ...mapGetters({
      currentQuestionID: "questionnaire/currentQuestionID",
      questionnaire: "questionnaire/questionnaire",
      questionsSum: "questionnaire/questionsSum",
      answeredQuestions: "questionnaire/answeredQuestions",
    })
  },
};
</script>
<style>
</style>