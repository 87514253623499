//import awsconfig from "@/aws-exports";
import { API, graphqlOperation } from "aws-amplify";
import { listHolograms as listHologramsQuery } from "../../graphql/queries"
import { getHologram as getHologramQuery } from "../../graphql/queries"
import { getPublicHologram as getPublicHologramQuery } from "../../graphql/custom/customQueries"
import { updateHologram as updateHologramMutation } from "../../graphql/custom/customMutations"
import { deleteHologramEdge as deleteHologramEdgeMutation } from "../../graphql/custom/customMutations"
import { createHologram as createHologramMutation } from "../../graphql/mutations"
import { deleteHologram as deleteHologramMutation } from "../../graphql/custom/customMutations"
import { hologramByDateUpdated as hologramByDateUpdatedQuery } from "../../graphql/custom/customQueries"
import { superChargedGetHologram } from "../../graphql/helper/Get"


import _ from "lodash"

import { group } from "./group/group.js";
import { node } from "./node/node"
import { control } from "./control/control.js"
import { contribute } from "./contribute/contribute.js"
import { edge } from "./edge/edge"

export const hologram = {
  namespaced: true,
  modules: {
    group,
    node,
    edge,
    control,
    contribute
  },
  state: { 
    hologramsBelongedToCurrentUser: [],
    currentHologram: null,
  },
  mutations: {
    mutateHologramsBelongedToCurrentUser(state, payload){
      state.hologramsBelongedToCurrentUser = payload;
    },
    mutateCurrentHologram(state, payload){
      state.currentHologram = payload;
    },
  },
  actions: {
    //Unit actions

    setCurrentHologram(context, currentHologram){
      context.commit("mutateCurrentHologram", currentHologram);
    },
    removeItemFromCurrentUserHolograms: (context, hologramID) => {
      let tempData = _.cloneDeep(context.state.hologramsBelongedToCurrentUser);
      const index = tempData.findIndex( e => e.id === hologramID);
      tempData.splice(index, 1);
      context.commit("mutateHologramsBelongedToCurrentUser", tempData);
    },
    updateItemInCurrentUserHolograms: (context, hologram) => {
      const hologramID = hologram.id;
      let temp = _.cloneDeep(context.state.hologramsBelongedToCurrentUser);
      const index = temp.findIndex( e => e.id === hologramID);
      temp[index] = hologram;
      context.commit("mutateHologramsBelongedToCurrentUser", temp);
    },
    AddNewItemToCurrentUserHolograms:(context, hologram) => {
      let temp = _.cloneDeep(context.state.hologramsBelongedToCurrentUser);
      temp.splice(0, 0, hologram);
      context.commit("mutateHologramsBelongedToCurrentUser", temp);
    },

    // Request actions
    updateHologram: async (_, hologram) => {
      const variables = {
        input: hologram
      }
      try {
        const result = await API.graphql(
          graphqlOperation(updateHologramMutation, variables)
        );
        return Promise.resolve(result.data.updateHologram);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    deleteHologram: async (_, hologramID) => {
      const variables = {
        input: {
          id: hologramID
        }
      };
      try {
        const res = await API.graphql(graphqlOperation(deleteHologramMutation, variables));
        return Promise.resolve(res.data.deleteHologram)
      } catch(err){
        return Promise.reject(err)
      }
    },
    updateHologramTitle: async (context, {data}) => {
      try {
        const result = await API.graphql(graphqlOperation(updateHologramMutation, {input:data}))
        context.dispatch("updateItemInCurrentUserHolograms", result.data.updateHologram);
        context.dispatch("setCurrentHologram", result.data.updateHologram);
        return Promise.resolve(result.data.updateHologram)
      } catch(err){
        return Promise.reject(err)
      }
    },
    superChargedGetHologram: async (context, { type, hologramID }) => {
      try {
        const hologram = await superChargedGetHologram(type, hologramID, 100, null, 100, null, 100, null);
        context.commit("mutateCurrentHologram", hologram)
        context.commit("mutateCurrentHologramNodes", hologram.node.items);
        context.commit("mutateCurrentHologramEdges", hologram.edge.items);
        context.commit("mutateCurrentHologramGroups", hologram.group.items);
        context.commit("mutateCurrentHologramContributors", hologram.contributor.items);
        
        let hologramNodeWeights = [];
        for ( const hologramNode of hologram.node.items ){
          hologramNodeWeights.push(hologramNode.weight);
        }
        context.dispatch("weight/setCurrentHologramNodeWeights", hologramNodeWeights, { root:true });

        let hologramNodeGroups = [];
        for ( const hologramNode of hologram.node.items ){
          hologramNodeGroups.push(...hologramNode.group.items);
        }
        context.commit("mutateCurrentHologramNodeGroups", hologramNodeGroups);
        
      } catch(err){
        return Promise.reject(err)
      }
    },
    listHologramsByUpdatedAt: async () => {
      const input = {
        accessPolicy: "public",
        limit: 25,
        sortDirection: 'DESC'
      }
      try {
        const result = await API.graphql({
          query: hologramByDateUpdatedQuery,
          variables: input,
          authMode: "AWS_IAM"
        })
        console.log(result)
        return Promise.resolve(result.data.hologramByDateUpdated.items);
      } catch(err){
        console.error(err)
        return Promise.reject(err);
      }
    },
    getHologramsBelongToCurrentUser: async (context, {data}) => {
      try {
        // temp workaround, because we havent decide whether specific owner filed ourself yet, we use createdID to filter
        const hologramsData = await API.graphql(graphqlOperation(listHologramsQuery, {filter:{createdByID:{eq:`${data}`}}}));
        context.commit("mutateHologramsBelongedToCurrentUser", hologramsData.data.listHolograms.items);
        return Promise.resolve("Success");
      } catch(err){
        console.log(err)
        return Promise.reject(err);
      }
    },
    getPublicAccessHologramData: async (context, {hologramID}) => {
      const variables = {
        id: hologramID
      };
      try {
        const result = await API.graphql({
          query: getPublicHologramQuery,
          variables: variables,
          authMode: "AWS_IAM"
        })
        context.commit("mutateCurrentHologram", result.data.getHologram);
        return Promise.resolve(result.data.getHologram);
      } catch(err){
        return Promise.reject(err);
      }
    },
    getPrivateAccessHologramData: async (context, {hologramID}) => {
      const variables = {
        id: hologramID
      };
      try {
        const result = await API.graphql(graphqlOperation(getHologramQuery, variables));
        context.commit("mutateCurrentHologram", result.data.getHologram);
        return Promise.resolve(result.data.getHologram);
      } catch(err){
        return Promise.reject(err);
      }
    },
    createHologram: async (context, newHologram) =>{
      const varialbes = {
        input: newHologram
      };
      try {
        const result = await API.graphql(graphqlOperation(createHologramMutation, varialbes))
        context.dispatch("AddNewItemToCurrentUserHolograms", result.data.createHologram)
        return Promise.resolve(result.data.createHologram) 
      } catch(err){
        return Promise.reject(err);
      }
    },
    updateHologramIntro: async (context, {hologramID, intro}) => {
      const data = {
        id: hologramID,
        intro: intro
      }
      try {
        const result = await API.graphql(graphqlOperation(updateHologramMutation, {input:data}));
        context.dispatch("updateItemInCurrentUserHolograms", result.data.updateHologram);
        context.dispatch("setCurrentHologram", result.data.updateHologram);
        return Promise.resolve(result.data.updateHologram);
      } catch(err){
        return Promise.reject(err)
      }
    },

    // Business actions
    
    deleteHologramEntity: async (context, {data}) => {
      let currentHologram = context.state.currentHologram;
      let currentHologramNodes = context.rootState.hologram.node.currentHologramNodes;
      let currentHologramNodeWeights = context.rootState.weight.currentHologramNodeWeights;
      const currentHologramNodeGroups = context.rootState.hologram.group.currentHologramNodeGroups;

      let deleteNodeID;
      let influencedNodesID = [];
      let deleteType;

      let action = {
        version: null,
        timeStamp: null,
        type: null,
        add: {
          hologramNode: [],
          hologramNodeWeight: [],
          hologramNodeGroup: [],
          hologramEdge: [],
        },
        remove: {
          hologramNode: [],
          hologramNodeWeight: [],
          hologramNodeGroup: [],
          hologramEdge: []
        },
        update: {
          hologramNode: [],
          hologramNodeWeight: []
        }
      }

      for ( const element of data ){
        if ( element.nodeID ){
          deleteType = "DeleteNode"
          deleteNodeID = element.nodeID;
          const deleteHologramNodeID = element.id;

          // delete hologramNode

          const [deleteHologramNodeError, deleteHologramNodeResult] = await handle(context.dispatch("deleteHologramNode", deleteHologramNodeID));

          if ( deleteHologramNodeError ) {  
            deleteHologramNodeError.context = "Can't delete hologramNode Object"
            return Promise.reject(deleteHologramNodeError) 
          }

          context.dispatch("removeItemFromCurrentHologramNodes", { id:deleteHologramNodeID });  

          action.remove.hologramNode.splice(0, 0, deleteHologramNodeResult.id);
          
          // delete hologramNodeWeight

          const [deleteHologramNodeWeightError, deleteHologramNodeWeightResult] = await handle(deleteHologramNodeWeight(context, currentHologramNodeWeights, deleteHologramNodeID));
          if ( deleteHologramNodeWeightError ){
            return Promise.reject(deleteHologramNodeWeightError)
          }
          action.remove.hologramNodeWeight.splice(0, 0, deleteHologramNodeWeightResult.id);
          
          // delete hologramNodeGroup

          const hologramNodeGroupIndex = currentHologramNodeGroups.findIndex( e => e.hologramNodeID === deleteHologramNodeID );

          if ( hologramNodeGroupIndex !== -1 ){
            const [deleteHologramNodeGroupError, deleteHologramNodeGroupResult] = await handle(deleteHologramNodeGroup(context, currentHologramNodeGroups, deleteHologramNodeID));

            if ( deleteHologramNodeGroupError ){
              deleteHologramNodeGroupError.context = "Can't delete hologramNode's group"
              return Promise.reject(deleteHologramNodeGroupError) 
            }

            action.remove.hologramNodeGroup.splice(0, 0, deleteHologramNodeGroupResult.id);
            context.dispatch("removeItemFromCurrentHologramNodeGroups", deleteHologramNodeGroupResult.id);
          }

          // delete hologramGraphNode

          const [deleteHologramGraphNodeError, deleteHologramGraphNodeResult] = await handle(deleteNodeFromHologramGraph(context, currentHologram, deleteNodeID));

          if ( deleteHologramGraphNodeError ){
            deleteHologramGraphNodeError.context = "Could not delete node from hologram's graph"
            return Promise.reject(deleteHologramGraphNodeError) 
          }

          let newHologram = currentHologram;
          newHologram.graph = deleteHologramGraphNodeResult.graph

          context.dispatch("setCurrentHologram", newHologram);
        } else if (element.edgeID) {
          const [deleteHologramEdgeError, deleteHologramEdgeResult] = await handle(deleteHologramEdge(element.id))

          if ( deleteHologramEdgeError ) {
            deleteHologramEdgeError.context = "Could not delete hologram edge object"
            return Promise.reject(deleteHologramEdgeError) 
          }

          context.dispatch("removeItemFromCurrentHologramEdges", element.id);

          action.remove.hologramEdge.splice(0, 0, deleteHologramEdgeResult.id);

          if (deleteNodeID){
            if (deleteHologramEdgeResult.startNodeID === deleteNodeID){
              influencedNodesID.splice(0, 0, deleteHologramEdgeResult.endNodeID);
            } else {
              influencedNodesID.splice(0, 0, deleteHologramEdgeResult.startNodeID);
            }
          } else {
            influencedNodesID.splice(0, 0, deleteHologramEdgeResult.endNodeID);
            influencedNodesID.splice(0, 0, deleteHologramEdgeResult.startNodeID);
          }

          const [deleteHologramGraphEdgeError, deleteHologramGraphEdgeResult] = await handle(deleteEdgeFromHologramGraph(context, currentHologram, element.edgeID))

          if ( deleteHologramGraphEdgeError ) {
            deleteHologramGraphEdgeError.context = "Could not delete edge from hologram's graph"
            return Promise.reject(deleteHologramGraphEdgeError) 
          }
          
          let newHologram = currentHologram;
          newHologram.graph = deleteHologramGraphEdgeResult.graph

          context.dispatch("setCurrentHologram", newHologram);

        }
      }

      if ( !deleteType ){
        deleteType = "DeleteEdge"
      }


      for ( const nodeID of influencedNodesID ){
        const updateResult = await updateInfluencedNodeWeight(context, nodeID, currentHologramNodes, currentHologramNodeWeights)
        action.update.hologramNodeWeight.splice(0, 0, updateResult);
      }

      currentHologram = context.state.currentHologram;

      let actions = JSON.parse(currentHologram.action)
      const previousAction = actions[0]

      if ( actions.length > 3){
        actions.pop();
      }

      action.version = previousAction.version + 1;
      action.timeStamp = Date.now();
      action.type = deleteType;

      actions.splice(0, 0, action)

      const updateHologramInput = {
        id: currentHologram.id,
        action: JSON.stringify(actions)
      }

      const [ updateHologramError, updateHologramResult ] = await handle(context.dispatch("updateHologram", updateHologramInput));

      if ( updateHologramError ){
        updateHologramError.context = "Can't update hologram's version"
        return Promise.reject(updateHologramError) 
      }

      let newHologram = currentHologram;
      newHologram.action = updateHologramResult.action;

      context.dispatch("setCurrentHologram", newHologram);

      context.dispatch("updateRenderHologramGraph", {data:true});
      return Promise.resolve("Success");
    },
    updateTargetNodeTitleAtHologramGraph: async (context, { nodeID, title }) => {
      let currentHologram = context.state.currentHologram;
      let graph = JSON.parse(currentHologram.graph);
      const index = graph.nodes.findIndex( e => e.id === nodeID );
      if ( index !== -1 ){
        graph.nodes[index].title = title
      }
      const variables = {
        "input": {
          "id": currentHologram.id,
          "graph": JSON.stringify(graph)
        }
      }
      try {
        const result = await API.graphql(graphqlOperation(updateHologramMutation, variables));
        context.commit("mutateCurrentHologram", result.data.updateHologram);
        return Promise.resolve(result.data.updateHologram);
      } catch(err){
        return Promise.reject(err);
      }
    },
    checkoutWholeHologram: async (context) => {
      const currentHologram = context.state.currentHologram;
      const currentHologramEdges = context.rootState.hologram.edge.currentHologramEdges;
      const currentHologramNodes = context.rootState.hologram.node.currentHologramNodes;
      const currentHologramNodeWeights = context.rootState.weight.currentHologramNodeWeights;
      const currentHologramGroups = context.rootState.hologram.group.currentHologramGroups;
      const currentHologramNodeGroups = context.rootState.hologram.group.currentHologramNodeGroups;

      for ( const hologramEdge of currentHologramEdges ){
        const [error, ] = await handle(context.dispatch("deleteHologramEdge", hologramEdge.id));
        if ( error ){
          error.context = "Can't delete hologramEdge";
          return Promise.reject(error)
        }
      }
      context.dispatch("setCurrentHologramEdges", []);

      for ( const hologramNode of currentHologramNodes ){
        const [error, ] = await handle(context.dispatch("deleteHologramNode", hologramNode.id))
        if ( error ){
          error.context = "Can't delete hologramNode";
          return Promise.reject(error)
        }
      }
      context.dispatch("setCurrentHologramNodes", []);

      for ( const weight of currentHologramNodeWeights ){
        const [error, ] = await handle(context.dispatch("weight/deleteWeight", weight.id, {root:true}));
        if ( error ){
          error.context = "Can't delete hologramNode's weight";
          return Promise.reject(error)
        }
      }

      context.dispatch("weight/setCurrentHologramNodeWeights", [], {root:true});

      for ( const hologramGroup of currentHologramGroups ){
        const [error, ] = await handle(context.dispatch("deleteHologramGroup", hologramGroup.id));
        if ( error ){
          error.context = "Can't delete hologramGroup";
          return Promise.reject(error)
        }
      }

      context.dispatch("setCurrentHologramGroups", []);

      for ( const hologramNodeGroup of currentHologramNodeGroups ){
        const [error, ] = await handle(context.dispatch("deleteHologramNodeGroup", hologramNodeGroup.id));
        if ( error ){
          error.context = "Can't delete hologramNodeGroup";
          return Promise.reject(error)
        }
      }

      context.dispatch("setCurrentHologramNodeGroups", []);

      const [deleteHologramError, ] = await handle(context.dispatch("deleteHologram", currentHologram.id));

      if ( deleteHologramError ){
        deleteHologramError.context = "Can't delete hologram";
        return Promise.reject(deleteHologramError)
      }

      context.dispatch("removeItemFromCurrentUserHolograms", currentHologram.id);
      context.dispatch("modal/closeAllModal", null,{root:true});
    },
  },
  getters:{
    hologramsBelongedToCurrentUser: (state) => state.hologramsBelongedToCurrentUser,
    currentHologram: (state) => state.currentHologram,
  }
}
















/**
 * 
 * @param {object} context - vuex context object
 * @param {*} hologramEdgeID 
 * @returns deleted hologramEdge object
 */



const deleteHologramEdge = async function(hologramEdgeID){
  const variables = {
    input: {
      id: hologramEdgeID
    }
  }
  try {
    const result = await API.graphql({
      query: deleteHologramEdgeMutation,
      variables: variables,
      authMode: "AWS_IAM"
    })    
    return Promise.resolve(result.data.deleteHologramEdge);
  } catch(err){
    return Promise.reject(err);
  }
}

/**
 * 
 * @param {object} context - vuex context object
 * @param {object} currentHologram - hologram object
 * @param {*} edgeID 
 * @returns success/failed Promise
 */

const deleteEdgeFromHologramGraph = async function(context, currentHologram, edgeID){
  try {
    let hologramGraph = JSON.parse(currentHologram.graph);
    const index = hologramGraph.links.findIndex( e => e.edgeID === edgeID );
    hologramGraph.links.splice(index, 1);
    hologramGraph = JSON.stringify(hologramGraph);
    currentHologram.graph = hologramGraph;
    const updateHologram = {
      "id": currentHologram.id,
      "graph": hologramGraph
    }
    const result = await API.graphql(graphqlOperation(updateHologramMutation, {input:updateHologram}))
    return Promise.resolve(result.data.updateHologram);
  } catch(err){
    console.log(err);
    return Promise.reject(err);
  }
}


/**
 * 
 * @param {object} context - vuex context object
 * @param {object} currentHologram - hologram object
 * @param {id} nodeID 
 * @returns success/failed Promise
 */

const deleteNodeFromHologramGraph = async function(context, currentHologram, nodeID){
  try {
    let hologramGraph = JSON.parse(currentHologram.graph);
    const index = hologramGraph.nodes.findIndex( e => e.id === nodeID );
    hologramGraph.nodes.splice(index, 1);
    hologramGraph = JSON.stringify(hologramGraph);
    currentHologram.graph = hologramGraph;
    const updateHologram = {
      "id": currentHologram.id,
      "graph": hologramGraph
    }
    const result = await API.graphql(graphqlOperation(updateHologramMutation, {input:updateHologram}))
    return Promise.resolve(result.data.updateHologram);
  } catch(err){
    console.log(err);
    return Promise.reject(err);
  }
}

const deleteHologramNodeGroup = async (context, hologramNodeGroups, targetHologramNodeID) => {
  try {
    const index = hologramNodeGroups.findIndex( e => e.hologramNodeID === targetHologramNodeID );
    if ( index !== -1 ){ 
      const hologramNodeGroup = hologramNodeGroups[index];
      const result = await context.dispatch("deleteHologramNodeGroup", hologramNodeGroup.id)
      return Promise.resolve(result)
    }
  } catch(error){
    return Promise.reject(error) 
  }
}

const deleteHologramNodeWeight = async (context, hologramNodeWeights, targetHologramNodeID) => {
  const index = hologramNodeWeights.findIndex( e => e.hologramNodeID === targetHologramNodeID );

  if ( index === -1 ){
    return Promise.reject("HologramNode's weight not found")
  }

  const hologramNodeWeight = hologramNodeWeights[index];

  const variables = hologramNodeWeight.id;

  const [error, result] = await handle(context.dispatch("weight/deleteWeight", variables, { root:true }));
  
  if ( error ){
    error.context = "Can't delete hologramNode's weight object"
    return Promise.reject(error)
  }

  context.dispatch("weight/removeItemFromCurrentHologramNodeWeights", result.id, { root:true });
  
  return Promise.resolve(result)
}

const updateInfluencedNodeWeight = async (context, nodeID, hologramNodes, hologramNodeWeights) => {
  const targetHologramNodeindex = hologramNodes.findIndex( e => e.nodeID === nodeID )

  if ( targetHologramNodeindex === -1 ) {
    throw new Error("Can't find target hologramNode when try to update influenced node weight")
  }
  
  const targetHologramNode = hologramNodes[targetHologramNodeindex];

  const targetHologramNodeWeightIndex = hologramNodeWeights.findIndex( e => e.hologramNodeID === targetHologramNode.id )

  if ( targetHologramNodeWeightIndex === -1 ) {
    throw new Error("Can't find target hologramNodeWeight when try to update influenced node weight")
  }

  const targetHologramNodeWeight = hologramNodeWeights[targetHologramNodeWeightIndex]

  let weightVersion = JSON.parse(targetHologramNodeWeight.version);

  if ( weightVersion.length > 5 ){
    weightVersion.pop();
  }


  const weightPreviousVersion = weightVersion[0]
  const weightNewVersion = {
    "times": Date.now(),
    "counts": {
      "link": weightPreviousVersion.counts.link - 1,
      "note": weightPreviousVersion.counts.note,
    },
    "weight": {
      "link": 1,
      "note": 0.01,
    }
  }
  const weightNewValue = weightNewVersion.counts.link*weightNewVersion.weight.link
    +weightNewVersion.counts.note*weightNewVersion.weight.note;

  weightVersion.splice(0, 0, weightNewVersion);
  const updateWeightVariables = {
    weight: {
      id: targetHologramNodeWeight.id,
      version: JSON.stringify(weightVersion),
      value: weightNewValue
    }
  };

  const [updateWeightError, updateWeightErrorResult] = await handle(context.dispatch("weight/updateHologramNodeWeight", updateWeightVariables, {root:true})); // eslint-disable-line no-unused-vars
  
  if ( updateWeightError ) {
    updateWeightError.context = "Can't update hologramNodeWeight"
    return Promise.reject(updateWeightError)
  }

  context.dispatch("weight/updateItemInCurrentHologramNodeWeight", updateWeightErrorResult, {root:true});

  return Promise.resolve(updateWeightErrorResult)
}

const handle = (promise) => {
  return promise
    .then(data => ([undefined, data]))
    .catch(error => Promise.resolve([error, undefined]));
}



























