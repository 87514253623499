<template>
  <div class="flex flex-row flex-shrink-0 w-full h-16 pl-6 pr-4">
    <div class="flex flex-row my-auto mr-auto font-sans font-semibold text-left cursor-default text-sd-base0-brblue">
      <div class="my-auto mr-4">
        {{ hologramOwner }}
      </div>
      <div class="my-auto mr-4">
        /
      </div>
      <div class="my-auto">
        {{ hologramTitle }}
      </div>
    </div>
    <div class="flex flex-row ml-auto">
      <router-link
        to="/login"
        class="my-auto mr-6 font-sans font-semibold cursor-pointer text-sd-base00-bryellow hover:text-sd-base3-brwhite"
      >
        Login
      </router-link>
      <router-link
        to="/signup"
        class="my-auto font-sans font-semibold cursor-pointer text-sd-base00-bryellow hover:text-sd-base3-brwhite"
      >
        Sign up
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: "HologramNavbarForNonUser",
  props: {
    hologramTitle: {
      type: String,
      require: true,
    },
    hologramOwner: {
      type: String,
      require: true,
    },
  },
};
</script>
<style>
</style>