<template>
  <div class="flex flex-row flex-shrink-0 w-full h-16 pl-6 pr-4">
    <div class="flex flex-row my-auto mr-auto font-sans font-semibold text-left cursor-default text-sd-base0-brblue">
      <div
        class="my-auto mr-4"
      >
        {{ hologramOwner }}
      </div>
      <div
        class="my-auto mr-4"
      >
        /
      </div>
      <div
        class="my-auto"
      >
        {{ hologramTitle }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HologramNavbarForContributor",
  props: {
    hologramTitle: {
      type: String,
      require: true,
    },
    hologramOwner: {
      type: String,
      require: true,
    },
  },
};
</script>
<style>
</style>