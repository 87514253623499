<template>
  <div class="fixed inset-0 h-full">
    <div
      class="flex items-end justify-center h-full min-h-screen px-4 pt-4 text-center sm:block sm:p-0"
      id="modal-container"
    >
      <transition
        enter-active-class="duration-300 ease-out"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          @click="toggleChangeHologramAccessPolicyModal"
          class="fixed inset-0"
          v-if="modalOpen"
        >
          <div class="absolute inset-0 bg-black opacity-50"></div>
        </div>
      </transition>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        v-show="modalOpen"
      >&#8203;</span>
      <transition
        name="modal-transition"
        enter-active-class="duration-300 ease-out"
        enter-from-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
        enter-to-class="translate-y-0 opacity-100 sm:scale-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="translate-y-0 opacity-100 sm:scale-100"
        leave-to-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
      >
        <div
          class="items-center inline-block overflow-y-auto text-left align-middle transition-all transform rounded-lg shadow-xl cursor-default sm:my-8 sm:align-middle sm:max-w-xl sm:w-full"
          role="dialog"
          aria-modal="true"
          id="settings-container"
          v-show="modalOpen"
        >
          <div class="w-full bg-sd-base02-black">
            <div class="flex-col flex-grow w-full pt-8 sm:flex sm:px-8 sm:items-start">
              <div class="mt-3 sm:mt-0 sm:text-left">
                <h2
                  class="text-xl font-medium leading-6 text-sd-base2-white"
                  id="modal-headline"
                >
                  Change hologram access policy
                </h2>
                <span class="font-sans text-sm font-normal text-sd-base0-brblue">
                  This action will change the hologram <span class="font-semibold text-sd-base1-brcyan">{{currentHologram.title}}</span>'s access policy
                </span>
              </div>
            </div>
            <div class="flex px-8 mt-8 text-sd-base1-brcyan">
              <div class="px-4 py-2 text-sm rounded-md bg-sd-base03-brblack">
                <p>Current access policy: <span class="font-bold">{{currentPolicy}}</span></p>
              </div>
            </div>
            <div class="w-full px-8 pb-8 mt-8 sm:text-left sm:items-start text-sd-base0-brblue">
              <p class="font-medium text-sd-base1-brcyan">This action will change this hologram from <span class="text-sd-base2-white">{{currentPolicy}}</span> to <span class="text-sd-base2-white">{{futurePolicy}}</span>.</p>
              <p
                v-if="currentPolicy == 'private'"
                class="mt-2 text-sm"
              >
              <ul class="pl-4 list-disc">
                <li>
                  The hologram will be visible to everyone who can visit <span class="font-semibold text-sd-base1-brcyan">https://hololink.co</span>
                </li>
                <li class="mt-2">
                  This hologram can receive stars and follow by other users.
                </li>
              </ul>
              </p>
              <p
                v-if="currentPolicy == 'public'"
                class="flex flex-col mt-2 text-sm"
              >
              <ul class="pl-4 list-disc">
                <li>
                  <span>You will <span class="font-bold">permanently</span> lose</span> all stars, followers of this hologram.
                </li>
                <li class="mt-1">
                  Your private hologram is limited to 3 at max. Please consider upgrade your plan or access more private slot by contributing (We are still testing this feature).
                </li>
              </ul>
              </p>
            </div>
            <footer class="sticky bottom-0 flex flex-col border-t border-opacity-50 bg-sd-base02-black border-sd-base01-brgreen sm:py-4 sm:px-4">
              <p class="font-sans text-sd-base0-brblue">
                Please type <span class="px-1 font-bold text-sd-base1-brcyan">{{currentHologram.title}}</span> to confirm.
              </p>
              <input
                class="flex-1 py-1.5 px-3 mt-2 bg-sd-base02-black font-sans rounded font-normal text-sm text-sd-base0-brblue border border-sd-base00-bryellow focus:outline-none focus:ring-1 focus:ring-sd-base1-brcyan"
                :placeholder="currentHologram.title"
                v-model="confirmationCode"
                autocomplete="off"
                id="confirmationCode"
              />
              <div class="flex flex-row mt-4">
                <button
                  @click="changeHologramAccessPolicyHandler"
                  class="px-4 py-2 font-sans text-sm font-medium border rounded-md border-sd-base01-brgreen focus:outline-none"
                  :disabled="confirmationCode != currentHologram.title"
                  v-bind:class="getButtonAdditionalClass()"
                >
                  Change access policy
                </button>
                <button
                  @click="toggleChangeHologramAccessPolicyModal"
                  class="px-4 py-2 ml-4 font-sans text-sm font-medium border rounded-md border-sd-base01-brgreen text-sd-base0-brblue hover:bg-sd-base2-white hover:bg-opacity-5 focus:outline-none"
                >
                  Cancel
                </button>
                <div
                  v-if="changeHologramAccessPolicyProcedure"
                  class="flex items-center ml-4 mr-2"
                >
                  <svg
                    class="w-5 h-5 animate-spin text-sd-base0-brblue"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </div>
                <div
                  v-if="changeHologramAccessPolicyProcedure"
                  class="flex items-center font-sans text-sm font-normal text-sd-base00-bryellow"
                >
                  This might take several minutes
                </div>
              </div>
            </footer>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { API, graphqlOperation } from "aws-amplify";
import { updateHologram as updateHologramMutation } from "../../graphql/mutations";
import { updateHologramNode as updateHologramNodeMutation } from "../../graphql/mutations";
import { updateHologramEdge as updateHologramEdgeMutation } from "../../graphql/mutations";
import { updateWeight as updateWeightMutation } from "../../graphql/mutations";
import { updateGroup as updateGroupMutation } from "../../graphql/mutations";
import { gaHelpers } from "@/analytics/helper.js";
import { ref, onMounted, computed, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
export default {
  name: "changeHologramAccessPolicyModal",
  setup() {
    const store = useStore();
    const currentPolicy = ref(null);
    const futurePolicy = ref(null);
    const currentHologram = computed(() => store.getters["hologram/currentHologram"]);
    const modalOpen = computed(() => store.getters["modal/changeHologramAccessPolicyModalOpen"]);

    const toggleChangeHologramAccessPolicyModal = () => {
      if (modalOpen.value) {
        store.dispatch("modal/toggleChangeHologramAccessPolicyModal", { data: false });
      }
    };

    const keydownEscHandler = (event) => {
      if (event.code !== "Escape") {
        return;
      }
      if (!modalOpen.value) {
        return;
      }
      toggleChangeHologramAccessPolicyModal();
    };

    const prepare = () => {
      currentPolicy.value = currentHologram.value.accessPolicy;
      if (currentPolicy.value === "private") {
        futurePolicy.value = "public";
      } else {
        futurePolicy.value = "private";
      }
      window.addEventListener("keydown", keydownEscHandler);
    };
    onMounted(prepare);

    const clear = () => {
      window.removeEventListener("keydown", keydownEscHandler);
    };

    onBeforeUnmount(clear);

    const confirmationCode = ref(null);
    const getButtonAdditionalClass = () => {
      if (confirmationCode.value === currentHologram.value.title) {
        return "text-sd-base2-white hover:bg-sd-base2-white hover:bg-opacity-5";
      } else {
        return "text-sd-base01-brgreen cursor-default";
      }
    };

    const currentHologramNodes = computed(() => store.getters["hologram/currentHologramNodes"]);
    const currentHologramGroups = computed(() => store.getters["hologram/currentHologramGroups"]);
    const currentHologramEdges = computed(() => store.getters["hologram/currentHologramEdges"]);
    const currentHologramNodeWeights = computed(() => store.getters["weight/currentHologramNodeWeights"]);

    const changeHologramAccessPolicyProcedure = ref(false);
    const changeHologramAccessPolicyHandler = async () => {
      changeHologramAccessPolicyProcedure.value = true;
      const updateHologramVariables = {
        input: {
          id: currentHologram.value.id,
          accessPolicy: futurePolicy.value,
        },
      };
      try {
        const hologramResult = await API.graphql(graphqlOperation(updateHologramMutation, updateHologramVariables));
        store.dispatch("hologram/setCurrentHologram", hologramResult.data.updateHologram);
      } catch (err) {
        console.log(err);
      }

      // Change HologramNode access policy
      for (const hologramNode of currentHologramNodes.value) {
        const updateHologramNodesVariables = {
          input: {
            id: hologramNode.id,
            accessPolicy: futurePolicy.value,
          },
        };
        try {
          const updateResult = await API.graphql(graphqlOperation(updateHologramNodeMutation, updateHologramNodesVariables));
          store.dispatch("hologram/updateItemInCurrentHologramNodes", updateResult.data.updateHologramNode);
        } catch (err) {
          console.log(err);
        }
      }

      // change Hologram groups access policy
      for (const hologramGroup of currentHologramGroups.value) {
        const updateHologramGroupsVariables = {
          input: {
            id: hologramGroup.id,
            accessPolicy: futurePolicy.value,
          },
        };
        try {
          const updateResult = await API.graphql(graphqlOperation(updateGroupMutation, updateHologramGroupsVariables));
          store.dispatch("hologram/updateCurrentHologramGroups", { data: updateResult.data.updateGroup });
        } catch (err) {
          console.log(err);
        }
      }

      // Change hologram-edge access policy
      for (const hologramEdge of currentHologramEdges.value) {
        const updateHologramEdgeVariables = {
          input: {
            id: hologramEdge.id,
            accessPolicy: futurePolicy.value,
          },
        };
        try {
          const updateResult = await API.graphql(graphqlOperation(updateHologramEdgeMutation, updateHologramEdgeVariables));
          store.dispatch("hologram/updateItemInCurrentHologramEdges", updateResult.data.updateHologramEdge);
        } catch (err) {
          console.error("Something went wrong when update hologramEdges accessPolicy", err);
        }
      }

      // Change Hologram node weight access policy
      for (const weight of currentHologramNodeWeights.value) {
        const updateHologramNodeWeight = {
          input: {
            id: weight.id,
            accessPolicy: futurePolicy.value,
          },
        };
        try {
          const updateResult = await API.graphql(graphqlOperation(updateWeightMutation, updateHologramNodeWeight));
          store.dispatch("weight/updateItemInCurrentHologramNodeWeight", updateResult.data.updateWeight);
        } catch (err) {
          console.error("Something went wrong when update hologramNodesWeight accessPolicy", err);
        }
      }
      gaHelpers.engagement("edit_hologram_access_policy", "context");
      changeHologramAccessPolicyProcedure.value = false;
      toggleChangeHologramAccessPolicyModal();
    };

    return {
      // Local
      confirmationCode,
      changeHologramAccessPolicyProcedure,
      modalOpen,
      currentPolicy,
      futurePolicy,

      // Store
      currentHologram,

      // Method
      getButtonAdditionalClass,
      changeHologramAccessPolicyHandler,
      toggleChangeHologramAccessPolicyModal,
    };
  },
};
</script>
<style>
</style>