<template>
  <div class="flex flex-col w-full mt-8">
    <div class="flex flex-row link-block-placeholder">
      <div 
        class="flex flex-row h-10 my-auto mr-4 rounded-md w-28"
      >
        <iconArrowUpRight
          :size="4"
          class="my-auto mr-4 text-sd-base0-brblue"
        />
        <div class="my-auto mr-auto font-sans text-sm font-medium cursor-default text-sd-base1-brcyan">
          Connection
        </div>
      </div>
      <button
        class="flex flex-grow h-10 px-4 py-1 my-auto rounded-md focus:outline-none hover:bg-opacity-10 hover:bg-sd-base2-white"
        @click="toggleLinkBlock"
        :class="getStyle()"
      >
        <div class="flex my-auto mr-auto">
          <div
            v-if="edges.length === 0"
            class="font-sans text-sm font-normal text-sd-base00-bryellow"
          >
            This node doesn't have connection
          </div>
          <div
            v-else
            class="font-sans text-sm font-normal text-sd-base00-bryellow"
          >
            <div
              v-if="edges.length === 1"
            >
              This node has a connection
            </div>
            <div
              v-else
            >
              This node have {{ edges.length }} connections
            </div>
          </div>
        </div>
        <div class="flex my-auto ml-auto">
          <div v-if="edges.length !== 0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="flex w-4 h-4 m-auto fill-current text-sd-base00-bryellow"
              viewBox="0 0 16 16"
            >
              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
            </svg>
          </div>
        </div>
      </button>
    </div>
    <div
      v-if="edgesBlockOpen"
      class="flex flex-row mt-4"
    >
      <div class="flex flex-col w-full py-2 pr-2 rounded-md shadow-md bg-sd-base2-white bg-opacity-5">
        <div>
          <div
            v-for="edge in edges"
            v-bind:key="edge.id"
            class="flex flex-row py-1"
          >
            <div class="flex items-center justify-center w-6 mb-auto mr-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="flex w-6 h-6 my-auto fill-current text-sd-base0-brblue"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
              </svg>
            </div>
            <div
              class="my-auto font-sans text-sm font-semibold text-left text-sd-base1-brcyan"
            >
              {{ getEdgeNodeTitle(edge) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue"
import iconArrowUpRight from "../icon/iconArrowUpRight.vue";
export default {
  name: "NodeEdgeBlock",
  props: {
    edges: {
      type: Array,
      require: true
    },
    targetHologramNode: {
      type: Object,
      require: true
    },
    hologramNodes: {
      type: Array,
      require: true
    }
  }, 
  components: {
    iconArrowUpRight,
  },
  setup(props) {
    const edgesBlockOpen = ref(false)

    const toggleLinkBlock = () => {
      if ( props.edges.length > 0 ){
        edgesBlockOpen.value = !edgesBlockOpen.value;
      }
    }

    const getEdgeNodeTitle = (edge) => {
      let targetHologramNodeIndex
      if ( edge.startNodeID === props.targetHologramNode.nodeID ){
        targetHologramNodeIndex = props.hologramNodes.findIndex( e => e.nodeID === edge.endNodeID )
      } else {
        targetHologramNodeIndex = props.hologramNodes.findIndex( e => e.nodeID === edge.startNodeID )
      }
      const targetHologramNode = props.hologramNodes[targetHologramNodeIndex]
      if ( targetHologramNodeIndex === -1 ){
        return ""
      }
      return targetHologramNode.node.title
    }

    const getStyle = () => {
      if ( edgesBlockOpen.value ){
        return "bg-sd-base2-white bg-opacity-5 shadow-md"
      }
    }

    return {
      // Local
      edgesBlockOpen,

      // Method
      toggleLinkBlock,
      getEdgeNodeTitle,
      getStyle
    }
  }
};
</script>
<style>
</style>