<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    v-bind:class="iconStyle"
    viewBox="0 0 16 16"
  >
    <path d="M6 1H1v14h5V1zm9 0h-5v5h5V1zm0 9v5h-5v-5h5zM0 1a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1zm9 0a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1V1zm1 8a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1h-5z"/>
  </svg>
</template>
<script>
import { computed } from "vue";
export default {
  name: "iconGrid1x2",
  props: {
    size: {
      type: Number,
      require: true,
    },
  },
  setup(props) {
    const iconStyle = computed(() => {
      return `fill-current w-${props.size} h-${props.size}`;
    });
    return {
      iconStyle,
    };
  },
};
</script>
<style>
</style>