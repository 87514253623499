import { API, graphqlOperation } from "aws-amplify";
import { updateUserNode as updateUserNodeMutation} from "../../../graphql/mutations"
//import { createHologramNode, createUserNode, createDomain } from "../../../graphql/helper/Create"
// import { getUserNode, getHologramNode, getNode } from "../../../graphql/helper/Get"
import { getUrlObject } from "../../../utilities/utilities"
import { handle } from "../../../utilities/utilities"
// import { updateHologram } from "../../../graphql/helper/update.js";
import { updateHologramNode as updateHologramNodeMutation } from "../../../graphql/mutations"
import { deleteHologramNode as deleteHologramNodeMutation } from "../../../graphql/custom/customMutations"
import { createHologramNode as createHologramNodeMutation } from "../../../graphql/custom/customMutations"

import { createNode as createNodeMutation } from "../../../graphql/mutations"
import { deleteNode as deleteNodeMutation } from "../../../graphql/custom/customMutations"

import { domainByHostName as domainByHostNameQuery } from "../../../graphql/custom/customQueries"
import { nodeByUrl as nodeByUrlQuery } from "../../../graphql/custom/customQueries"
import { createDomain as createDomainMutation } from "../../../graphql/custom/customMutations";

import { createUserNode as createUserNodeMutation } from "../../../graphql/custom/customMutations";
import { deleteUserNode as deleteUserNodeMutation } from "../../../graphql/custom/customMutations";

import { userNodeByUser as userNodeByUserQuery } from "../../../graphql/custom/customQueries" 

import { v4 as uuidv4 } from 'uuid';
import _ from "lodash"

export const node = {
  state: {
    currentHologramNodes:[],
    userNodes:[],
  },
  mutations: {
    mutateCurrentHologramNodes(state, payload){
      state.currentHologramNodes = payload;
    },
    mutateUserNodes(state, payload){
      state.userNodes = payload;
    },
  },
  actions: {
    // Request action
    getNode: async (_, url) => {
      const variables = {
        url: url
      };
      let result;
      try {
        const res = await API.graphql({
          query: nodeByUrlQuery,
          variables: variables,
          authMode: "AWS_IAM"
        })
        if ( res.data.nodeByUrl.items.length > 0 ){
          result = {
            status: "Found",
            result: res.data.nodeByUrl.items[0]
          };
        } else {
          result = {
            status: "NotFound",
          }
        }
        return Promise.resolve(result)
      } catch(error){
        return Promise.reject(error)
      }
    },
    createNode: async (_, { id, title, url, domainID }) => {
      const variables = {
        input: {
          id: id,
          baseType: "Node",
          domainID: domainID,
          title: title,
          url: url,
        }
      }
      try {
        const result = await API.graphql({
          query: createNodeMutation,
          variables: variables,
          authMode: "AWS_IAM"
        })
        return Promise.resolve(result.data.createNode)
      } catch(err){
        return Promise.reject(err)
      }
    },
    deleteNode: async (_, nodeID) => {
      const variables = {
        input: {
          id: nodeID
        }
      };
      try {
        const result = await API.graphql({
          query: deleteNodeMutation,
          variables: variables,
          authMode: "AWS_IAM"
        })
        return Promise.resolve(result.data.deleteNode)
      } catch(err){
        return Promise.reject(err)
      }
    },
    getUserNodes: async (context, {userID, nextToken, limit}) => { 

      const variables = {
        userID: userID,
        nextToken: nextToken,
        limit: limit
      }

      try {
        let result = await API.graphql({
          query: userNodeByUserQuery,
          variables: variables,
          authMode: "AWS_IAM"
        });

        console.log(result, limit, nextToken)

        if ( result.data.userNodeByUser.nextToken ){
          const recursiveResult = await context.dispatch("getUserNodes", {userID:userID, nextToken:result.data.userNodeByUser.nextToken, limit:limit});

          console.log(recursiveResult)
          result.data.userNodeByUser.items.push(...recursiveResult);
        }

        return Promise.resolve(result.data.userNodeByUser.items)
      } catch(err){
        return Promise.reject(err)
      }
    },
    createUserNode: async (_, { id, userID, nodeID, weightID }) => {
      const variables = {
        input: {
          id: id,
          userID: userID,
          nodeID: nodeID,
          weightID: weightID,
          owner: userID
        }
      }
      try {
        const res = await API.graphql({
          query: createUserNodeMutation,
          variables: variables,
          authMode: "AWS_IAM"
        })
        return Promise.resolve(res.data.createUserNode)
      } catch(err){
        return Promise.reject(err)
      }
    },
    deleteUserNode: async (_, userNodeID) => {
      const variables = {
        input: {
          id: userNodeID
        }
      };
      try {
        const res = await API.graphql(graphqlOperation(deleteUserNodeMutation, variables));
        return Promise.resolve(res.data.deleteUserNode)
      } catch(err){
        return Promise.reject(err)
      }
    },
    updateUserNodeNote: async (context, {userNodeID, note}) => {
      const variables = {
        input: {
          id: userNodeID,
          note: note
        }
      }
      try {
        const result = await API.graphql(graphqlOperation(updateUserNodeMutation, variables));
        context.dispatch("updateItemInUserNodes", result.data.updateUserNode);
        return Promise.resolve(result.data.updateUserNode);
      } catch(err) {
        return Promise.reject(err);
      }
    },
    getDomain: async (_, hostName) => {
      const variables = {
        hostName: hostName
      }

      let result;

      try {
        const res = await API.graphql({
          query: domainByHostNameQuery,
          variables: variables,
          authMode: "AWS_IAM"
        })

        if ( res.data.domainByHostName.items.length > 0 ){
          result = {
            status: "Found",
            result: res.data.domainByHostName.items[0]
          }
        } else {
          result = {
            status: "NotFound"
          }
        }

        return Promise.resolve(result)
      } catch(err){
        return Promise.reject(err)
      }
    },
    createDomain: async (_, { id, hostName }) => {
      const variables = {
        input: {
          id: id,
          baseType: "Domain",
          hostName: hostName,
        }
      };
      console.log(variables)
      try {
        const result = await API.graphql({
          query: createDomainMutation,
          variables: variables,
          authMode: "AWS_IAM"
        })
        return Promise.resolve(result.data.createDomain)
      } catch(err){
        return Promise.reject(err)
      }
    },
    createHologramNode: async (_, { id, nodeID, hologramID, accessPolicy, weightID, userID }) => {
      const variables = {
        input: {
          id: id,
          hologramID: hologramID,
          nodeID: nodeID,
          accessPolicy: accessPolicy,
          weightID: weightID,
          createdByID: userID,
          owner: userID
        }
      };
      try {
        const result = await API.graphql({
          query: createHologramNodeMutation,
          variables: variables,
          authMode: "AWS_IAM"
        })
        return Promise.resolve(result.data.createHologramNode)
      } catch(err){
        return Promise.reject(err)
      }
    },
    deleteHologramNode: async (_, hologramNodeID) => {
      const variables = {
        input: {
          id: hologramNodeID
        }
      }
      try {
        const result = await API.graphql({
          query: deleteHologramNodeMutation,
          variables: variables,
          authMode: "AWS_IAM"
        })
        return Promise.resolve(result.data.deleteHologramNode);
      } catch(err){
        return Promise.reject(err);
      }
    },
    
    /*
    getHologramNode: async (_, {nodeID, hoogramID}) => {

    },
    */

    // Business action


    // Unit action

    updateItemInCurrentHologramNodes: (context, hologramNode) => {
      const hologramNodeID = hologramNode.id;
      let tempData = _.cloneDeep(context.state.currentHologramNodes);
      let targetIndex = tempData.findIndex( e => e.id == hologramNodeID);
      tempData.splice(targetIndex, 1, hologramNode);
      context.commit("mutateCurrentHologramNodes", tempData);
    },
    addItemToCurrentHologramNodes: (context, hologramNode) => {
      let temp = _.cloneDeep(context.state.currentHologramNodes);
      temp.splice(0, 0, hologramNode)
      context.commit("mutateCurrentHologramNodes", temp);
    },
    removeItemFromCurrentHologramNodes: (context, { id }) => {
      const hologramNodeID = id;
      let tempData = _.cloneDeep(context.state.currentHologramNodes);
      let targetIndex = tempData.findIndex( e => e.id == hologramNodeID);
      tempData.splice(targetIndex, 1)
      context.commit("mutateCurrentHologramNodes", tempData);
    },
    setCurrentHologramNodes: (context, hologramNodes) => {
      context.commit("mutateCurrentHologramNodes", hologramNodes);
    },
    setUserNodes: (context, {data}) => {
      context.commit("mutateUserNodes", data);
    },
    updateItemInUserNodes: async (context, userNode) => {
      let temp = _.cloneDeep(context.state.userNodes);
      const index = temp.findIndex( e => e.id === userNode.id );
      temp[index] = userNode;
      context.commit("mutateUserNodes", temp);
    },
    addNewItemToUserNodes: async (context, userNode) => {
      let temp = _.cloneDeep(context.state.userNodes);
      temp.splice(0, 0, userNode);
      context.commit("mutateUserNodes", temp);
    },

    advancedBuildNode: async (context, {targetNode, currentHologram, currentHologramNodes, userID, userNodes}) => {
      //const currentHologramNodes = context.state.currentHologramNodes;
      
      let domain;
      let domainIsNew = false;

      const hostName = getUrlObject(targetNode.url).hostname;
      const [checkDomainError, checkDomain] = await handle(context.dispatch("getDomain", hostName));

      if ( checkDomainError ){
        checkDomainError.context = "Can't check whether domain exist in DB"
        return Promise.reject(checkDomainError)
      }

      if ( checkDomain.status === "Found" ){
        domain = checkDomain.result;
      } else {
        domainIsNew = true;
        domain = {
          id: uuidv4(),
          baseType: "Domain",
          hostName: hostName
        }
      }

      console.log(domain)

      let node;
      let nodeIsNew = false;

      const [checkNodeError, checkNode] = await handle(context.dispatch("getNode", targetNode.url));

      if ( checkNodeError ){
        checkNodeError.context = "Can't check whether node exist in DB"
        return Promise.reject(checkNodeError)
      }

      if ( checkNode.status === "Found" ){
        node = checkNode.result;
      } else {
        nodeIsNew = true;
        node = {
          id: uuidv4(),
          baseType: "Node",
          url: targetNode.url,
          title: targetNode.title,
          domainID: domain.id
        }
      }

      
      const checkHologramNode = findHologramNode(currentHologramNodes, node.id);

      if ( checkHologramNode.status === "Found" ){
        return Promise.reject("Duplicated node")
      }

      let hologramNode = {
        id: uuidv4(),
        baseType: "HologramNode",
        hologramID: currentHologram.id,
        nodeID: node.id,
        node: node,
        weightID: null,
        owner: userID, 
        accessPolicy: currentHologram.accessPolicy
      }

      console.log(hologramNode)

      const newVersion = [
        {
          "times": Date.now(),
          "counts": {
            "link": 0,
            "note": 0,
          },
          "weight": {
            "link": 1,
            "note": 0.01,
          }
        }
      ];

      const newCalculation = {
        "weight": {
          "link": 1,
          "note": 0.01,
        }
      };
      
      let hologramNodeWeight = {
        id: uuidv4(),
        baseType: "Weight",
        weightType: "HologramNode",
        accessPolicy: currentHologram.accessPolicy,
        nodeID: node.id,
        node: node,
        hologramNodeID: hologramNode.id,
        hologramNode: hologramNode,
        value: 0,
        calculation: JSON.stringify(newCalculation),
        version: JSON.stringify(newVersion),
        owner: userID
      }

      hologramNode.weightID = hologramNodeWeight.id;

      context.dispatch("addItemToCurrentHologramNodes", hologramNode);
      context.dispatch("weight/addItemIntoCurrentHologramNodeWeight", hologramNodeWeight, {root:true})

      let userNode, userNodeWeight;
      let userNodeIsNew = false;

      const checkUserNode = findUserNode(userNodes, node.id);

      if ( checkUserNode.status === "Found" ){
        userNode = checkUserNode.result;
      } else {
        userNodeIsNew = true;
        userNode = {
          id: uuidv4(),
          userID: userID,
          nodeID: node.id,
          node: node,
          weightID: null,
          owner: userID
        }
        userNodeWeight = {
          id: uuidv4(),
          baseType: "Weight",
          weightType: "UserNode",
          accessPolicy: "private",
          value: 0,
          nodeID: node.id,
          node: node,
          calculation: JSON.stringify(newCalculation),
          version: JSON.stringify(newVersion),
          owner: userID
        }
        userNode.weightID = userNodeWeight.id
        context.dispatch("addNewItemToUserNodes", userNode)
      } 

      const constructHologramInput = {
        node: node,
        currentHologram: currentHologram,
        hologramNode: hologramNode,
        hologramNodeWeight: hologramNodeWeight
      }

      const newHologram = constructHologramGraphAndAction(constructHologramInput);
      context.dispatch("setCurrentHologram", newHologram);
      context.commit("mutateRenderHologramGraph", true);

      const uploadInput = {
        context: context,
        domainIsNew: domainIsNew,
        nodeIsNew: nodeIsNew,
        userNodeIsNew: userNodeIsNew,
        domain: domain,
        node: node,
        userNode: userNode,
        userNodeWeight: userNodeWeight,
        hologramNode: hologramNode,
        hologramNodeWeight: hologramNodeWeight,
        hologram: newHologram
      }

      console.log(uploadInput)

      try {
        uploadNodeBuildingRelatedData(uploadInput)
      } catch(err){
        console.error("Something went wrong when upload node related data", err)
      }

    },
    /*
    buildNode: async (context, {targetNode, currentHologramNodes, currentHologram, userID}) => {
      const hostName = getUrlObject(targetNode.url).hostname;
      //const domain = await getOrCreateDomain(hostName, userID);

      let action = {
        version: null,
        timeStamp: null,
        type: null,
        add: {
          hologramNode: [],
          hologramNodeWeight: [],
          hologramNodeGroup: [],
          hologramEdge: [],
        },
        remove: {
          hologramNode: [],
          hologramNodeWeight: [],
          hologramNodeGroup: [],
          hologramEdge: []
        },
        update: {
          hologramNode: [],
          hologramNodeWeight: []
        }
      }

      let variables;

      let domain;
      const [checkDomainError, checkDomain] = await handle(getDomain(hostName));

      if ( checkDomainError ){
        checkDomainError.context = "Can't check whether domain exist in DB"
        return Promise.reject(checkDomainError)
      }

      if ( checkDomain.status === "Found" ){
        domain = checkDomain.result;
      } else {

        const [createDomainError, createDomainResult] = await handle(createDomain(hostName, userID));
        
        if ( createDomainError ){
          createDomainError.context = "Can't create domain"
          return Promise.reject(createDomainError)
        }

        domain = createDomainResult;
      }

      let node;

      const [checkNodeError, checkNode] = await handle(getNode(targetNode.url));

      if ( checkNodeError ){
        checkNodeError.context = "Can't check whether node exist in DB"
        return Promise.reject(checkNodeError)
      }

      if ( checkNode.status === "Found" ){
        node = checkNode.result;
      } else {

        const createNodeVariables = {
          title: targetNode.title,
          url: targetNode.url,
          domainID: domain.id
        }

        const [createNodeError, newNode] = await handle(context.dispatch("createNode", createNodeVariables));

        if ( createNodeError ){
          createNodeError.context = "Can't create node"
          return Promise.reject(createNodeError)
        }
        
        node = newNode;

      }
      
      
      const [checkHologramNodeError, checkHologramNode]  = await handle(getHologramNode(currentHologramNodes, currentHologram.id, node.id));

      if ( checkHologramNodeError ){
        checkHologramNodeError.context = "Can't check whether hologramNode exist in DB"
        return Promise.reject(checkHologramNodeError)
      }

      if (checkHologramNode.status === "found"){
        return "duplicated"
      }

      variables = {
        "type": "hologramNode", 
        "accessPolicy": currentHologram.accessPolicy, 
        "hologramID": currentHologram.id, 
        "nodeID": node.id, 
        "owner": userID 
      }
      const [createHologramNodeWeightError ,hologramNodeWeight] = await handle(context.dispatch("weight/createInitWeight", variables, {root:true}));

      if ( createHologramNodeWeightError ){
        createHologramNodeWeightError.context = "Can't create hologramNode's weight"
        return Promise.reject(createHologramNodeWeightError)
      }

      const [createHologramNodeError, hologramNode] = await handle(createHologramNode(userID, currentHologram.id, node.id, currentHologram.accessPolicy, hologramNodeWeight.id, userID));
      
      if ( createHologramNodeError ){
        createHologramNodeError.context = "Can't create hologramNode"
        return Promise.reject(createHologramNodeError)
      }

      context.dispatch("addItemToCurrentHologramNodes", hologramNode);

      action.add.hologramNode.splice(0, 0, hologramNode);

      const updateWeightVariables = {
        "weight": {
          "id": hologramNodeWeight.id,
          "hologramNodeID": hologramNode.id
        }
      }
      
      console.log(hologramNode)

      const [updateWeightError, updateWeightResult] = await handle(context.dispatch("weight/updateHologramNodeWeight", updateWeightVariables, {root:true}));

      if ( updateWeightError ){
        updateWeightError.context = "Can't update hologramNode's weight";
        return Promise.reject(updateWeightError)
      }

      context.dispatch("weight/updateItemInCurrentHologramNodeWeight", updateWeightResult, {root:true});

      

      console.log(updateWeightResult)

      action.add.hologramNodeWeight.splice(0, 0, updateWeightResult);

      let userNode; // eslint-disable-line no-unused-vars

      const [checkUserNodeError, checkUserNode] = await handle(getUserNode(userID, node.id));

      if ( checkUserNodeError ){
        checkUserNodeError.context = "Can't check whether userNode exist in DB";
        return Promise.reject(checkUserNodeError)
      }

      if ( checkUserNode.status === "Found" ){
        userNode = checkUserNode.result;
      } else {
        variables = {
          "type": "userNode", 
          "accessPolicy": "private", 
          "nodeID": node.id, 
          "owner": userID
        }
        const [createUserNodeWeightError, newUserNodeWeight] = await handle(context.dispatch("weight/createInitWeight", variables, {root:true}))

        if ( createUserNodeWeightError ){
          createUserNodeWeightError.context = "Can't create userNode's weight";
          return Promise.reject(createUserNodeWeightError)
        }

        const [createUserNodeError, newUserNode] = await handle(createUserNode(userID, node.id, newUserNodeWeight.id, userID));

        if ( createUserNodeError ){
          createUserNodeError.context = "Can't create userNode"
          return Promise.reject(createUserNodeError)
        }

        userNode = newUserNode;

        context.dispatch("addNewItemToUserNodes", newUserNode);
      }

      const d3Node = {
        "id": node.id,
        "title": node.title,
        "url": node.url, 
      };

      let hologramGraph;

      if (currentHologram.graph){
        hologramGraph = JSON.parse(currentHologram.graph);
      } else { 
        hologramGraph = {
          "nodes":[],
          "links":[]
        };
      }

      hologramGraph.nodes.splice(0, 0, d3Node);

      let actions = JSON.parse(currentHologram.action)
      let previousAction = actions[0];

      if ( actions.length > 3 ){
        actions.pop();
      }

      action.type = "BuildNode";
      action.timeStamp = Date.now();
      action.version = previousAction.version + 1;

      console.log(action)

      actions.splice(0, 0, action)

      const updateHologramInput = {
        id: currentHologram.id,
        graph: JSON.stringify(hologramGraph),
        action: JSON.stringify(actions),
      }

      const [updateHologramError, updateHologramResult] = await handle(updateHologram(updateHologramInput));
            
      if ( updateHologramError ){
        updateHologramError.context = "Can't update hologram graph when building node"
        return Promise.reject(updateHologramError)
      }

      // To not overwrite original contributor and createdBy field
      let newHologram = currentHologram;
      newHologram.graph = updateHologramResult.graph
      newHologram.action = updateHologramResult.action
      
      context.dispatch("setCurrentHologram", newHologram);
      context.commit("mutateRenderHologramGraph", true);
      return Promise.resolve("Success")
    },
    */
    updateHologramNodeTitle: async (context, {currentHologram, nodeID, hologramNodeID, title}) => {
      let action = {
        version: null,
        timeStamp: null,
        type: null,
        add: {
          hologramNode: [],
          hologramNodeWeight: [],
          hologramNodeGroup: [],
          hologramEdge: [],
        },
        remove: {
          hologramNode: [],
          hologramNodeWeight: [],
          hologramNodeGroup: [],
          hologramEdge: []
        },
        update: {
          hologramNode: [],
          hologramNodeWeight: []
        }
      }

      const [updateHologramNodeError, updateHologramNodeResult] = await handle(updateHologramNodeTitle(hologramNodeID, title));

      if ( updateHologramNodeError ){
        updateHologramNodeError.context = "Can't update hologramNode's title";
        return Promise.reject(updateHologramNodeError)
      }

      action.update.hologramNode.splice(0, 0, updateHologramNodeResult);

      const variables = getUpdateHologramGraphAndActionVariables(currentHologram, nodeID, title, action);

      const [updateHologramError, updateHologramResult] = await handle(context.dispatch("updateHologram", variables));

      if ( updateHologramError ){
        updateHologramError.context = "Can't update hologram";
        return Promise.reject(updateHologramError)
      }

      let newHologram = currentHologram;
      newHologram.action = updateHologramResult.action;
      newHologram.graph = updateHologramResult.graph
      context.dispatch("setCurrentHologram", newHologram);
    },

    // Request action

    
  },
  getters: {
    userNodes: (state) => state.userNodes,
    currentHologramNodes: (state) => state.currentHologramNodes
  }
}

const getUpdateHologramGraphAndActionVariables = (currentHologram, nodeID, title, action) => {
  let actions = JSON.parse(currentHologram.action);

  if ( actions.length > 3 ){
    actions.pop()
  }

  const previousAction = actions[0]

  action.timeStamp = Date.now();
  action.type = "UpdateNodeTitle";
  action.version = previousAction.version + 1;

  actions.splice(0, 0, action)

  const graph = JSON.parse(currentHologram.graph);
  const index = graph.nodes.findIndex( e => e.id === nodeID );

  if ( index === -1 ){
    return Promise.reject("Can't find target node in hologram's graph")
  }

  graph.nodes[index].title = title;

  const variables = {
    id: currentHologram.id,
    action: JSON.stringify(actions),
    graph: JSON.stringify()
  }

  return variables
}

const updateHologramNodeTitle = async (hologramNodeID, title) => {
  const variables = {
    input: {
      id: hologramNodeID,
      title: title
    }
  };
  try {
    const result = await API.graphql(graphqlOperation(updateHologramNodeMutation, variables))
    return Promise.resolve(result.data.updateHologramNode)
  } catch(err){
    return Promise.reject(err)
  }
};

const findHologramNode = (hologramNodes, nodeID) => {
  let result;
  const index = hologramNodes.findIndex( e => e.nodeID === nodeID )

  if ( index !== -1 ){
    result = {
      status: "Found",
      result: hologramNodes[index]
    }
  } else {
    result = {
      status: "NotFound"
    }
  }

  return result
};

const findUserNode = (userNodes, nodeID) => {
  let result;
  const index = userNodes.findIndex( e => e.nodeID === nodeID );

  if ( index !== -1 ){
    result = {
      status: "Found",
      result: userNodes[index]
    }
  } else {
    result = {
      status: "NotFound"
    }
  }

  return result
};

const constructHologramGraphAndAction = ({node, currentHologram, hologramNode, hologramNodeWeight}) => {
  let action = {
    version: null,
    timeStamp: null,
    type: null,
    add: {
      hologramNode: [],
      hologramEdge: [],
      hologramGroup: [],
      hologramNodeGroup: [],
      hologramNodeWeight: [],
    },
    remove: {
      hologramNode: [],
      hologramEdge: [],
      hologramGroup: [],
      hologramNodeGroup: [],
      hologramNodeWeight: [],
    },
    update: {
      group: [],
      hologramNode: [],
      hologramEdge: [],
      hologramGroup: [],
      hologramNodeGroup: [],
      hologramNodeWeight: []
    }
  };

  const d3Node = {
    "id": node.id,
    "title": node.title,
    "url": node.url, 
  };

  let hologramGraph;

  if (currentHologram.graph){
    hologramGraph = JSON.parse(currentHologram.graph);
  } else { 
    hologramGraph = {
      "nodes":[],
      "links":[]
    };
  }

  hologramGraph.nodes.splice(0, 0, d3Node);

  let actions = JSON.parse(currentHologram.action)
  

  if ( !actions ){
    action.version = 1;
    actions = []
  } else {
    let previousAction = actions[0];
    action.version = previousAction.version + 1;
    if ( actions.length > 3 ){
      actions.pop();
    }
  }

  

  

  action.type = "BuildNode";
  action.timeStamp = Date.now();
  

  action.add.hologramNode.splice(0, 0, hologramNode);
  action.add.hologramNodeWeight.splice(0, 0, hologramNodeWeight)

  actions.splice(0, 0, action)

  let newHologram = currentHologram;
  newHologram.graph = JSON.stringify(hologramGraph);
  newHologram.action = JSON.stringify(actions)

  return newHologram;
};

const uploadNodeBuildingRelatedData = async ({context, domainIsNew, nodeIsNew, userNodeIsNew, domain, node, userNode, userNodeWeight, hologramNode, hologramNodeWeight, hologram}) => {

  let uploadedData = []

  if ( domainIsNew ){
    const createDomainInput = {
      id: domain.id,
      hostName: domain.hostName
    };

    const [createDomainError, createDomainResult ] = await handle(context.dispatch("createDomain", createDomainInput));

    if ( createDomainError ){
      createDomainError.context = "Can't create domain";
      return Promise.reject(createDomainError)
    }

    uploadedData.splice(0, 0, createDomainResult);
  }

  if ( nodeIsNew ){
    const createNodeInput = {
      id: node.id, 
      title: node.title, 
      url: node.url, 
      domainID: node.domainID
    };

    const [ createNodeError, createNodeResult ] = await handle(context.dispatch("createNode", createNodeInput));

    if ( createNodeError ){
      createNodeError.context = "Can't create node";
      cleanUpNodeRelatedBadData(context, uploadedData);
      return Promise.reject(createNodeError)
    }

    uploadedData.splice(0, 0, createNodeResult);
  }

  let createUserNodeError, createUserNodeResult, createUserNodeWeightError, createUserNodeWeightResult;

  if ( userNodeIsNew ){
    const createUserNodeInput = {
      id: userNode.id,
      userID: userNode.owner,
      nodeID: userNode.nodeID,
      weightID: userNode.weightID
    };

    [createUserNodeError, createUserNodeResult] = await handle(context.dispatch("createUserNode", createUserNodeInput))

    if ( createUserNodeError ){
      createUserNodeError.context = "Can't create userNode";
      cleanUpNodeRelatedBadData(context, uploadedData);
      return Promise.reject(createUserNodeError)
    }

    uploadedData.splice(0, 0, createUserNodeResult);

    const createUserNodeWeightInput = {
      id: userNodeWeight.id, 
      userNodeID: userNodeWeight.userNodeID, 
      nodeID: userNodeWeight.nodeID, 
      version: userNodeWeight.version, 
      value: userNodeWeight.value, 
      accessPolicy: userNodeWeight.accessPolicy, 
      calculation: userNodeWeight.calculation, 
      userID: userNodeWeight.owner
    };

    [ createUserNodeWeightError, createUserNodeWeightResult ] = await handle(context.dispatch("weight/createUserNodeWeight", createUserNodeWeightInput, {root:true}));

    if ( createUserNodeWeightError ){
      createUserNodeWeightError.context = "Can't create userNode's weight";
      cleanUpNodeRelatedBadData(context, uploadedData);
      return Promise.reject(createUserNodeWeightError)
    }

    uploadedData.splice(0, 0, createUserNodeWeightResult);

  }

  const createHologramNodeInput = {
    id: hologramNode.id,
    hologramID: hologramNode.hologramID,
    nodeID: hologramNode.nodeID,
    accessPolicy: hologramNode.accessPolicy,
    weightID: hologramNode.weightID,
    userID: hologramNode.owner
  };

  const [ createHologramNodeError, createHologramNodeResult ] = await handle(context.dispatch("createHologramNode", createHologramNodeInput));

  if ( createHologramNodeError ){
    createHologramNodeError.context = "Can't create hologramNode";
    cleanUpNodeRelatedBadData(context, uploadedData);
    return Promise.reject(createHologramNodeError)
  }

  uploadedData.splice(0, 0, createHologramNodeResult);

  const createHologramNodeWeightInput = {
    id: hologramNodeWeight.id, 
    hologramNodeID: hologramNodeWeight.hologramNodeID, 
    nodeID: hologramNodeWeight.nodeID, 
    version: hologramNodeWeight.version, 
    value: hologramNodeWeight.value, 
    accessPolicy: hologramNodeWeight.accessPolicy, 
    calculation: hologramNodeWeight.calculation, 
    userID: hologramNodeWeight.owner
  }

  const [ createHologramNodeWeightError, createHologramNodeWeightResult ] = await handle(context.dispatch("weight/createHologramNodeWeight", createHologramNodeWeightInput, {root:true}));

  if ( createHologramNodeWeightError ){
    createHologramNodeWeightError.context = "Can't create hologramNode's weight";
    cleanUpNodeRelatedBadData(context, uploadedData);
    return Promise.reject(createHologramNodeWeightError)
  }

  uploadedData.splice(0, 0, createHologramNodeWeightResult);

  const updateHologramInput = {
    id: hologram.id,
    action: hologram.action,
    graph: hologram.graph
  }

  const [updateHologramError, updateHologramResult] = await handle(context.dispatch("updateHologram", updateHologramInput));

  if ( updateHologramError ){
    updateHologramError.context = "Can't update hologram"
    cleanUpNodeRelatedBadData(context, uploadedData);
    return Promise.reject(updateHologramError)
  }

  let newHologram = _.cloneDeep(context.rootState.hologram.currentHologram);
  newHologram.action = updateHologramResult.action;
  newHologram.graph = updateHologramResult.graph;
  newHologram.node = updateHologramResult.node;

  context.dispatch("setCurrentHologram", newHologram);
  if ( userNodeIsNew ){
    context.dispatch("updateItemInUserNodes", createUserNodeResult);
    context.dispatch("weight/updateItemInUserNodeweights", createUserNodeWeightResult, {root:true});
  }
  context.dispatch("updateItemInCurrentHologramNodes", createHologramNodeResult)
  context.dispatch("weight/updateItemInCurrentHologramNodeWeight", createHologramNodeWeightResult, {root:true});

  return Promise.resolve("Success")
};

const cleanUpNodeRelatedBadData = (context, badData) => { // eslint-disable-line no-unused-vars
  for ( const data of badData ){
    switch (data.baseType) {
      case "Node": {
        context.dispatch("deleteNode", data.id);
        break
      }
      case "UserNode": {
        context.dispatch("deleteUserNode", data.id);
        break
      }
      case "HologramNode": {
        context.dispatch("deleteHologramNode", data.id);
        break
      }
      case "Weight": {
        context.dispatch("weight/deleteWeight", data.id, {root:true});
        break
      }
    }

    if ( data.hologramID && data.nodeID ){
      context.dispatch("deleteHologramNode", data.id);
      continue;
    }

    if ( data.userID && data.nodeID ){
      context.dispatch("deleteUserNode", data.id);
      continue;
    }

  }
};


