/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const queryCorsContent = /* GraphQL */ `
  query QueryCorsContent($url: String) {
    queryCorsContent(url: $url)
  }
`;
export const buildNode = /* GraphQL */ `
  query BuildNode($url: String, $title: String, $hologramID: String) {
    buildNode(url: $url, title: $title, hologramID: $hologramID)
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      isAdmin
      baseType
      username
      avatar {
        region
        bucket
        key
      }
      email
      emailVerified
      firstName
      lastName
      createdAt
      updatedAt
      onBoardingIsFinished
      onBoardingQuestionnaireIsFinished
      owner
      hologram {
        items {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        nextToken
      }
      likeHologram {
        items {
          id
          baseType
          hologramID
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      createdImage {
        items {
          id
          baseType
          createdByID
          contentType
          height
          width
          size
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      questionnaire {
        items {
          id
          userID
          questionnaireID
          isFinished
          answer
          createdAt
          updatedAt
        }
        nextToken
      }
      ownNode {
        items {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      ownEdge {
        items {
          id
          userID
          edgeID
          note
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      contributedHologram {
        items {
          id
          baseType
          hologramID
          contributorID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const userByUsername = /* GraphQL */ `
  query UserByUsername(
    $username: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByUsername(
      username: $username
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getHologram = /* GraphQL */ `
  query GetHologram($id: ID!) {
    getHologram(id: $id) {
      id
      baseType
      intro
      title
      graph
      createdByID
      contributorID
      accessPolicy
      weightID
      updatedAt
      createdAt
      owner
      action
      userPresent
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      block {
        items {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      like {
        items {
          id
          baseType
          hologramID
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      weight {
        id
        baseType
        weightType
        hologramNodeID
        userNodeID
        nodeID
        hologramID
        calculation
        value
        version
        accessPolicy
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        userNode {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        hologramNode {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
      }
      post {
        items {
          id
          baseType
          postType
          hologramID
          title
          content
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      group {
        items {
          id
          baseType
          hologramID
          groupID
          color
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      node {
        items {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      community {
        items {
          id
          hologramID
          communityID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      contributor {
        items {
          id
          baseType
          hologramID
          contributorID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      edge {
        items {
          id
          hologramID
          edgeID
          startNodeID
          endNodeID
          accessPolicy
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listHolograms = /* GraphQL */ `
  query ListHolograms(
    $filter: ModelHologramFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHolograms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const hologramByDateUpdated = /* GraphQL */ `
  query HologramByDateUpdated(
    $accessPolicy: AccessPolicy
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHologramFilterInput
    $limit: Int
    $nextToken: String
  ) {
    hologramByDateUpdated(
      accessPolicy: $accessPolicy
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getBlock = /* GraphQL */ `
  query GetBlock($id: ID!) {
    getBlock(id: $id) {
      id
      baseType
      belongTo
      blockType
      content
      html
      url
      title
      level
      nodify
      accessPolicy
      parentBlockID
      hologramID
      postID
      commentID
      nodeID
      edgeID
      groupID
      ownerUserNodeID
      ownerUserEdgeID
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      parentBlock {
        id
        baseType
        belongTo
        blockType
        content
        html
        url
        title
        level
        nodify
        accessPolicy
        parentBlockID
        hologramID
        postID
        commentID
        nodeID
        edgeID
        groupID
        ownerUserNodeID
        ownerUserEdgeID
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        parentBlock {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        edge {
          baseType
          id
          startNodeID
          endNodeID
          createdAt
          updatedAt
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        group {
          baseType
          id
          title
          createdByID
          createdAt
          updatedAt
        }
        post {
          id
          baseType
          postType
          hologramID
          title
          content
          owner
          createdAt
          updatedAt
        }
        comment {
          id
          baseType
          postID
          content
          parentCommentID
          owner
          createdAt
          updatedAt
        }
        ownerUserNode {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        ownerUserEdge {
          id
          userID
          edgeID
          note
          owner
          createdAt
          updatedAt
        }
      }
      edge {
        baseType
        id
        startNodeID
        endNodeID
        createdAt
        updatedAt
        block {
          nextToken
        }
        startNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        endNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      group {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          nextToken
        }
        hologramNode {
          nextToken
        }
        node {
          nextToken
        }
        edge {
          nextToken
        }
      }
      post {
        id
        baseType
        postType
        hologramID
        title
        content
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        block {
          nextToken
        }
        comment {
          nextToken
        }
      }
      comment {
        id
        baseType
        postID
        content
        parentCommentID
        owner
        createdAt
        updatedAt
        block {
          nextToken
        }
        post {
          id
          baseType
          postType
          hologramID
          title
          content
          owner
          createdAt
          updatedAt
        }
        parentComment {
          id
          baseType
          postID
          content
          parentCommentID
          owner
          createdAt
          updatedAt
        }
        childComment {
          nextToken
        }
      }
      ownerUserNode {
        id
        userID
        nodeID
        title
        note
        weightID
        version
        owner
        createdAt
        updatedAt
        user {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
      }
      ownerUserEdge {
        id
        userID
        edgeID
        note
        owner
        createdAt
        updatedAt
        user {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        edge {
          baseType
          id
          startNodeID
          endNodeID
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listBlocks = /* GraphQL */ `
  query ListBlocks(
    $filter: ModelBlockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlocks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        baseType
        belongTo
        blockType
        content
        html
        url
        title
        level
        nodify
        accessPolicy
        parentBlockID
        hologramID
        postID
        commentID
        nodeID
        edgeID
        groupID
        ownerUserNodeID
        ownerUserEdgeID
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        parentBlock {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        edge {
          baseType
          id
          startNodeID
          endNodeID
          createdAt
          updatedAt
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        group {
          baseType
          id
          title
          createdByID
          createdAt
          updatedAt
        }
        post {
          id
          baseType
          postType
          hologramID
          title
          content
          owner
          createdAt
          updatedAt
        }
        comment {
          id
          baseType
          postID
          content
          parentCommentID
          owner
          createdAt
          updatedAt
        }
        ownerUserNode {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        ownerUserEdge {
          id
          userID
          edgeID
          note
          owner
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getHighlight = /* GraphQL */ `
  query GetHighlight($id: ID!) {
    getHighlight(id: $id) {
      id
      baseType
      content
      nodeID
      owner
      createdAt
      updatedAt
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
    }
  }
`;
export const listHighlights = /* GraphQL */ `
  query ListHighlights(
    $filter: ModelHighlightFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHighlights(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        baseType
        content
        nodeID
        owner
        createdAt
        updatedAt
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getHologramLike = /* GraphQL */ `
  query GetHologramLike($id: ID!) {
    getHologramLike(id: $id) {
      id
      baseType
      hologramID
      createdByID
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
    }
  }
`;
export const listHologramLikes = /* GraphQL */ `
  query ListHologramLikes(
    $filter: ModelHologramLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHologramLikes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        baseType
        hologramID
        createdByID
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
      }
      nextToken
    }
  }
`;
export const getDomain = /* GraphQL */ `
  query GetDomain($id: ID!) {
    getDomain(id: $id) {
      baseType
      hostName
      id
      updatedAt
      createdAt
      node {
        items {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listDomains = /* GraphQL */ `
  query ListDomains(
    $filter: ModelDomainFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDomains(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        baseType
        hostName
        id
        updatedAt
        createdAt
        node {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const domainByHostName = /* GraphQL */ `
  query DomainByHostName(
    $hostName: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDomainFilterInput
    $limit: Int
    $nextToken: String
  ) {
    domainByHostName(
      hostName: $hostName
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        baseType
        hostName
        id
        updatedAt
        createdAt
        node {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getEdge = /* GraphQL */ `
  query GetEdge($id: ID!) {
    getEdge(id: $id) {
      baseType
      id
      startNodeID
      endNodeID
      createdAt
      updatedAt
      block {
        items {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      startNode {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      endNode {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      user {
        items {
          id
          userID
          edgeID
          note
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      hologram {
        items {
          id
          hologramID
          edgeID
          startNodeID
          endNodeID
          accessPolicy
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      group {
        items {
          id
          groupID
          edgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listEdges = /* GraphQL */ `
  query ListEdges(
    $filter: ModelEdgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEdges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        baseType
        id
        startNodeID
        endNodeID
        createdAt
        updatedAt
        block {
          nextToken
        }
        startNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        endNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const byStartNode = /* GraphQL */ `
  query ByStartNode(
    $startNodeID: ID
    $endNodeID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEdgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byStartNode(
      startNodeID: $startNodeID
      endNodeID: $endNodeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        baseType
        id
        startNodeID
        endNodeID
        createdAt
        updatedAt
        block {
          nextToken
        }
        startNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        endNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const byEndNode = /* GraphQL */ `
  query ByEndNode(
    $endNodeID: ID
    $startNodeID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEdgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byEndNode(
      endNodeID: $endNodeID
      startNodeID: $startNodeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        baseType
        id
        startNodeID
        endNodeID
        createdAt
        updatedAt
        block {
          nextToken
        }
        startNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        endNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getNode = /* GraphQL */ `
  query GetNode($id: ID!) {
    getNode(id: $id) {
      baseType
      id
      url
      title
      wordCounts
      favicon
      domainID
      weightID
      createdAt
      updatedAt
      block {
        items {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      highlight {
        items {
          id
          baseType
          content
          nodeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      domain {
        baseType
        hostName
        id
        updatedAt
        createdAt
        node {
          nextToken
        }
      }
      inEdges {
        items {
          baseType
          id
          startNodeID
          endNodeID
          createdAt
          updatedAt
        }
        nextToken
      }
      outEdges {
        items {
          baseType
          id
          startNodeID
          endNodeID
          createdAt
          updatedAt
        }
        nextToken
      }
      weight {
        id
        baseType
        weightType
        hologramNodeID
        userNodeID
        nodeID
        hologramID
        calculation
        value
        version
        accessPolicy
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        userNode {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        hologramNode {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
      }
      user {
        items {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      hologram {
        items {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      group {
        items {
          id
          groupID
          nodeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listNodes = /* GraphQL */ `
  query ListNodes(
    $filter: ModelNodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const nodeByUrl = /* GraphQL */ `
  query NodeByUrl(
    $url: String
    $title: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    nodeByUrl(
      url: $url
      title: $title
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getWeight = /* GraphQL */ `
  query GetWeight($id: ID!) {
    getWeight(id: $id) {
      id
      baseType
      weightType
      hologramNodeID
      userNodeID
      nodeID
      hologramID
      calculation
      value
      version
      accessPolicy
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      userNode {
        id
        userID
        nodeID
        title
        note
        weightID
        version
        owner
        createdAt
        updatedAt
        user {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
      }
      hologramNode {
        id
        hologramID
        title
        nodeID
        accessPolicy
        weightID
        createdByID
        owner
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        group {
          nextToken
        }
      }
    }
  }
`;
export const listWeights = /* GraphQL */ `
  query ListWeights(
    $filter: ModelWeightFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWeights(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        baseType
        weightType
        hologramNodeID
        userNodeID
        nodeID
        hologramID
        calculation
        value
        version
        accessPolicy
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        userNode {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        hologramNode {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getImage = /* GraphQL */ `
  query GetImage($id: ID!) {
    getImage(id: $id) {
      id
      baseType
      createdByID
      fullsize {
        region
        bucket
        key
      }
      thumbnail {
        region
        bucket
        key
      }
      contentType
      height
      width
      size
      owner
      createdAt
      updatedAt
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
    }
  }
`;
export const listImages = /* GraphQL */ `
  query ListImages(
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        baseType
        createdByID
        fullsize {
          region
          bucket
          key
        }
        thumbnail {
          region
          bucket
          key
        }
        contentType
        height
        width
        size
        owner
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
      }
      nextToken
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      baseType
      id
      title
      createdByID
      createdAt
      updatedAt
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      hologram {
        items {
          id
          baseType
          hologramID
          groupID
          color
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      hologramNode {
        items {
          id
          baseType
          owner
          hologramNodeID
          groupID
          createdAt
          updatedAt
        }
        nextToken
      }
      node {
        items {
          id
          groupID
          nodeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      edge {
        items {
          id
          groupID
          edgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          nextToken
        }
        hologramNode {
          nextToken
        }
        node {
          nextToken
        }
        edge {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const groupByTitle = /* GraphQL */ `
  query GroupByTitle(
    $title: String
    $createdByID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupByTitle(
      title: $title
      createdByID: $createdByID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          nextToken
        }
        hologramNode {
          nextToken
        }
        node {
          nextToken
        }
        edge {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getPath = /* GraphQL */ `
  query GetPath($id: ID!) {
    getPath(id: $id) {
      baseType
      id
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listPaths = /* GraphQL */ `
  query ListPaths(
    $filter: ModelPathFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaths(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        baseType
        id
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCommunity = /* GraphQL */ `
  query GetCommunity($id: ID!) {
    getCommunity(id: $id) {
      baseType
      id
      name
      owner
      createdAt
      updatedAt
      hologram {
        items {
          id
          hologramID
          communityID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listCommunitys = /* GraphQL */ `
  query ListCommunitys(
    $filter: ModelCommunityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommunitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        baseType
        id
        name
        owner
        createdAt
        updatedAt
        hologram {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      baseType
      postType
      hologramID
      title
      content
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      block {
        items {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      comment {
        items {
          id
          baseType
          postID
          content
          parentCommentID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        baseType
        postType
        hologramID
        title
        content
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        block {
          nextToken
        }
        comment {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      baseType
      postID
      content
      parentCommentID
      owner
      createdAt
      updatedAt
      block {
        items {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      post {
        id
        baseType
        postType
        hologramID
        title
        content
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        block {
          nextToken
        }
        comment {
          nextToken
        }
      }
      parentComment {
        id
        baseType
        postID
        content
        parentCommentID
        owner
        createdAt
        updatedAt
        block {
          nextToken
        }
        post {
          id
          baseType
          postType
          hologramID
          title
          content
          owner
          createdAt
          updatedAt
        }
        parentComment {
          id
          baseType
          postID
          content
          parentCommentID
          owner
          createdAt
          updatedAt
        }
        childComment {
          nextToken
        }
      }
      childComment {
        items {
          id
          baseType
          postID
          content
          parentCommentID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        baseType
        postID
        content
        parentCommentID
        owner
        createdAt
        updatedAt
        block {
          nextToken
        }
        post {
          id
          baseType
          postType
          hologramID
          title
          content
          owner
          createdAt
          updatedAt
        }
        parentComment {
          id
          baseType
          postID
          content
          parentCommentID
          owner
          createdAt
          updatedAt
        }
        childComment {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      title
      context
      options
      type
      subType
      createdAt
      updatedAt
      questionnaire {
        items {
          id
          questionnaireID
          questionID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        context
        options
        type
        subType
        createdAt
        updatedAt
        questionnaire {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getQuestionnaire = /* GraphQL */ `
  query GetQuestionnaire($id: ID!) {
    getQuestionnaire(id: $id) {
      id
      title
      context
      triggerStage
      questionsOrder
      ending
      createdAt
      updatedAt
      user {
        items {
          id
          userID
          questionnaireID
          isFinished
          answer
          createdAt
          updatedAt
        }
        nextToken
      }
      question {
        items {
          id
          questionnaireID
          questionID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listQuestionnaires = /* GraphQL */ `
  query ListQuestionnaires(
    $filter: ModelQuestionnaireFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionnaires(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        context
        triggerStage
        questionsOrder
        ending
        createdAt
        updatedAt
        user {
          nextToken
        }
        question {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getUserQuestionnaire = /* GraphQL */ `
  query GetUserQuestionnaire($id: ID!) {
    getUserQuestionnaire(id: $id) {
      id
      userID
      questionnaireID
      isFinished
      answer
      createdAt
      updatedAt
      user {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      questionnaire {
        id
        title
        context
        triggerStage
        questionsOrder
        ending
        createdAt
        updatedAt
        user {
          nextToken
        }
        question {
          nextToken
        }
      }
    }
  }
`;
export const listUserQuestionnaires = /* GraphQL */ `
  query ListUserQuestionnaires(
    $filter: ModelUserQuestionnaireFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserQuestionnaires(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        questionnaireID
        isFinished
        answer
        createdAt
        updatedAt
        user {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        questionnaire {
          id
          title
          context
          triggerStage
          questionsOrder
          ending
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getQuestionnaireQuestion = /* GraphQL */ `
  query GetQuestionnaireQuestion($id: ID!) {
    getQuestionnaireQuestion(id: $id) {
      id
      questionnaireID
      questionID
      createdAt
      updatedAt
      question {
        id
        title
        context
        options
        type
        subType
        createdAt
        updatedAt
        questionnaire {
          nextToken
        }
      }
      questionnaire {
        id
        title
        context
        triggerStage
        questionsOrder
        ending
        createdAt
        updatedAt
        user {
          nextToken
        }
        question {
          nextToken
        }
      }
    }
  }
`;
export const listQuestionnaireQuestions = /* GraphQL */ `
  query ListQuestionnaireQuestions(
    $filter: ModelQuestionnaireQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionnaireQuestions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionnaireID
        questionID
        createdAt
        updatedAt
        question {
          id
          title
          context
          options
          type
          subType
          createdAt
          updatedAt
        }
        questionnaire {
          id
          title
          context
          triggerStage
          questionsOrder
          ending
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getIssue = /* GraphQL */ `
  query GetIssue($id: ID!) {
    getIssue(id: $id) {
      id
      baseType
      issueType
      url
      description
      createdByID
      createdAt
      updatedAt
    }
  }
`;
export const listIssues = /* GraphQL */ `
  query ListIssues(
    $filter: ModelIssueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIssues(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        baseType
        issueType
        url
        description
        createdByID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserNode = /* GraphQL */ `
  query GetUserNode($id: ID!) {
    getUserNode(id: $id) {
      id
      userID
      nodeID
      title
      note
      weightID
      version
      owner
      createdAt
      updatedAt
      user {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      weight {
        id
        baseType
        weightType
        hologramNodeID
        userNodeID
        nodeID
        hologramID
        calculation
        value
        version
        accessPolicy
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        userNode {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        hologramNode {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listUserNodes = /* GraphQL */ `
  query ListUserNodes(
    $filter: ModelUserNodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserNodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        nodeID
        title
        note
        weightID
        version
        owner
        createdAt
        updatedAt
        user {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const userNodeByUser = /* GraphQL */ `
  query UserNodeByUser(
    $userID: ID
    $nodeID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserNodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userNodeByUser(
      userID: $userID
      nodeID: $nodeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        nodeID
        title
        note
        weightID
        version
        owner
        createdAt
        updatedAt
        user {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const userNodeByNode = /* GraphQL */ `
  query UserNodeByNode(
    $nodeID: ID
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserNodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userNodeByNode(
      nodeID: $nodeID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        nodeID
        title
        note
        weightID
        version
        owner
        createdAt
        updatedAt
        user {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getUserEdge = /* GraphQL */ `
  query GetUserEdge($id: ID!) {
    getUserEdge(id: $id) {
      id
      userID
      edgeID
      note
      owner
      createdAt
      updatedAt
      user {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      edge {
        baseType
        id
        startNodeID
        endNodeID
        createdAt
        updatedAt
        block {
          nextToken
        }
        startNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        endNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
    }
  }
`;
export const listUserEdges = /* GraphQL */ `
  query ListUserEdges(
    $filter: ModelUserEdgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserEdges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        edgeID
        note
        owner
        createdAt
        updatedAt
        user {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        edge {
          baseType
          id
          startNodeID
          endNodeID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getHologramGroup = /* GraphQL */ `
  query GetHologramGroup($id: ID!) {
    getHologramGroup(id: $id) {
      id
      baseType
      hologramID
      groupID
      color
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      group {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          nextToken
        }
        hologramNode {
          nextToken
        }
        node {
          nextToken
        }
        edge {
          nextToken
        }
      }
    }
  }
`;
export const listHologramGroups = /* GraphQL */ `
  query ListHologramGroups(
    $filter: ModelHologramGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHologramGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        baseType
        hologramID
        groupID
        color
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        group {
          baseType
          id
          title
          createdByID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getHologramNode = /* GraphQL */ `
  query GetHologramNode($id: ID!) {
    getHologramNode(id: $id) {
      id
      hologramID
      title
      nodeID
      accessPolicy
      weightID
      createdByID
      owner
      createdAt
      updatedAt
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      weight {
        id
        baseType
        weightType
        hologramNodeID
        userNodeID
        nodeID
        hologramID
        calculation
        value
        version
        accessPolicy
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        userNode {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        hologramNode {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
      }
      group {
        items {
          id
          baseType
          owner
          hologramNodeID
          groupID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listHologramNodes = /* GraphQL */ `
  query ListHologramNodes(
    $filter: ModelHologramNodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHologramNodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        hologramID
        title
        nodeID
        accessPolicy
        weightID
        createdByID
        owner
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        group {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const hologramNodeByCreatedById = /* GraphQL */ `
  query HologramNodeByCreatedById(
    $hologramID: ID
    $createdByID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHologramNodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    hologramNodeByCreatedByID(
      hologramID: $hologramID
      createdByID: $createdByID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        hologramID
        title
        nodeID
        accessPolicy
        weightID
        createdByID
        owner
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        group {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getHologramNodeGroup = /* GraphQL */ `
  query GetHologramNodeGroup($id: ID!) {
    getHologramNodeGroup(id: $id) {
      id
      baseType
      owner
      hologramNodeID
      groupID
      createdAt
      updatedAt
      group {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          nextToken
        }
        hologramNode {
          nextToken
        }
        node {
          nextToken
        }
        edge {
          nextToken
        }
      }
      hologramNode {
        id
        hologramID
        title
        nodeID
        accessPolicy
        weightID
        createdByID
        owner
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        group {
          nextToken
        }
      }
    }
  }
`;
export const listHologramNodeGroups = /* GraphQL */ `
  query ListHologramNodeGroups(
    $filter: ModelHologramNodeGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHologramNodeGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        baseType
        owner
        hologramNodeID
        groupID
        createdAt
        updatedAt
        group {
          baseType
          id
          title
          createdByID
          createdAt
          updatedAt
        }
        hologramNode {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getHologramCommunity = /* GraphQL */ `
  query GetHologramCommunity($id: ID!) {
    getHologramCommunity(id: $id) {
      id
      hologramID
      communityID
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      community {
        baseType
        id
        name
        owner
        createdAt
        updatedAt
        hologram {
          nextToken
        }
      }
    }
  }
`;
export const listHologramCommunitys = /* GraphQL */ `
  query ListHologramCommunitys(
    $filter: ModelHologramCommunityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHologramCommunitys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        hologramID
        communityID
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        community {
          baseType
          id
          name
          owner
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getGroupNode = /* GraphQL */ `
  query GetGroupNode($id: ID!) {
    getGroupNode(id: $id) {
      id
      groupID
      nodeID
      owner
      createdAt
      updatedAt
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      group {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          nextToken
        }
        hologramNode {
          nextToken
        }
        node {
          nextToken
        }
        edge {
          nextToken
        }
      }
    }
  }
`;
export const listGroupNodes = /* GraphQL */ `
  query ListGroupNodes(
    $filter: ModelGroupNodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupNodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupID
        nodeID
        owner
        createdAt
        updatedAt
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        group {
          baseType
          id
          title
          createdByID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getHologramContributor = /* GraphQL */ `
  query GetHologramContributor($id: ID!) {
    getHologramContributor(id: $id) {
      id
      baseType
      hologramID
      contributorID
      owner
      createdAt
      updatedAt
      contributor {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
    }
  }
`;
export const listHologramContributors = /* GraphQL */ `
  query ListHologramContributors(
    $filter: ModelHologramContributorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHologramContributors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        baseType
        hologramID
        contributorID
        owner
        createdAt
        updatedAt
        contributor {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
      }
      nextToken
    }
  }
`;
export const getHologramEdge = /* GraphQL */ `
  query GetHologramEdge($id: ID!) {
    getHologramEdge(id: $id) {
      id
      hologramID
      edgeID
      startNodeID
      endNodeID
      accessPolicy
      createdByID
      owner
      createdAt
      updatedAt
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      edge {
        baseType
        id
        startNodeID
        endNodeID
        createdAt
        updatedAt
        block {
          nextToken
        }
        startNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        endNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      startNode {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      endNode {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
    }
  }
`;
export const listHologramEdges = /* GraphQL */ `
  query ListHologramEdges(
    $filter: ModelHologramEdgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHologramEdges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        hologramID
        edgeID
        startNodeID
        endNodeID
        accessPolicy
        createdByID
        owner
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        edge {
          baseType
          id
          startNodeID
          endNodeID
          createdAt
          updatedAt
        }
        startNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        endNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getGroupEdge = /* GraphQL */ `
  query GetGroupEdge($id: ID!) {
    getGroupEdge(id: $id) {
      id
      groupID
      edgeID
      owner
      createdAt
      updatedAt
      edge {
        baseType
        id
        startNodeID
        endNodeID
        createdAt
        updatedAt
        block {
          nextToken
        }
        startNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        endNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      group {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          nextToken
        }
        hologramNode {
          nextToken
        }
        node {
          nextToken
        }
        edge {
          nextToken
        }
      }
    }
  }
`;
export const listGroupEdges = /* GraphQL */ `
  query ListGroupEdges(
    $filter: ModelGroupEdgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupEdges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupID
        edgeID
        owner
        createdAt
        updatedAt
        edge {
          baseType
          id
          startNodeID
          endNodeID
          createdAt
          updatedAt
        }
        group {
          baseType
          id
          title
          createdByID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
