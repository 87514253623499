<template>
  <div
    v-if="basicDataIsReady == true"
    class="flex w-screen min-h-screen app"
  >
    <Sidebar v-if="$route.meta.showSidebar" />
    <div class="flex flex-grow min-h-screen bg-sd-base03-brblack">
      <router-view />
    </div>
    <HologramSidebar v-if="$route.meta.showHologramSidebar" />
  </div>
</template>

<script>
import "v-tooltip/dist/v-tooltip.css";
import { mapActions, mapGetters } from "vuex";
import Sidebar from "@/components/sidebar/Sidebar.vue";
import HologramSidebar from "./components/sidebar/HologramSidebar.vue";
export default {
  data() {
    return {
      basicDataIsReady: false,
    };
  },
  async mounted() {
    try {
      await this.authAction();
    } catch (err) {
      console.log(err);
    }

    this.basicDataIsReady = true;
  },
  components: {
    Sidebar,
    HologramSidebar,
  },
  methods: {
    ...mapActions("auth", ["authAction"]),
  },
  computed: {
    ...mapGetters({
      currentHologramDataReady: "hologram/currentHologramDataReady",
    }),
  },
  watch: {
    $route: function (to, from) {
      if (to.fullPath !== from.fullPath) {
        this.$store.dispatch("hologram/updateCurrentHologramDataReady", { data: false });
      }
      if (to.name !== "Hologram") {
        this.$store.dispatch("sidebar/toggleHologramSidebar", { data: false });
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.v-popper--theme-info-tooltip .v-popper__inner {
  background: #839496;
  color: #002b36;
  padding: 3px 8px;
  border-radius: 6px;
  font-size: 12px;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 500;
}
.v-popper__arrow {
  border-color: #839496;
}
</style>
