export const control = {
  state: {
    connectionSetUpModeOpen: false,
    connectionStartNodeID: null,
    connectionEndNodeID: null,
    transactingStatus: false,
    editingCurrentHologramPermission: true,
    currentHologramDataReady: false,
    renderHologramGraph: false,
    renderHologramGraphDataOnly: false,
    renderHologramDisplay: false,
    searchNodeToolboxOpen: false,
    displayCluster: false,
    displayColorMode: false,
    searchToolboxInput: null,
    renderHologramNodeColor: false,
    deleteModeOpen: false,
    hologramNodeDetailTargetID: null,
  },
  mutations: {
    mutateConnectionSetUpModeOpen(state, payload){
      state.connectionSetUpModeOpen = payload;
    },
    mutateConnectionStartNodeID(state, payload){
      state.connectionStartNodeID = payload;
    },
    mutateConnectionEndNodeID(state, payload){
      state.connectionEndNodeID = payload;
    },
    mutateTransactingStatus(state, payload){
      state.transactingStatus = payload;
    },
    mutateEditingCurrentHologramPermission(state, payload){
      state.editingCurrentHologramPermission = payload;
    },
    mutateCurrentHologramDataReady(state, payload){
      state.currentHologramDataReady = payload;
    },
    mutateRenderHologramGraph(state, payload){
      state.renderHologramGraph = payload;
    },
    mutateRenderHologramGraphDataOnly: (state, payload) => {
      state.renderHologramGraphDataOnly = payload;
    },
    mutateRenderHologramDisplay(state, payload){
      state.renderHologramDisplay = payload;
    },
    mutateSearchNodeToolboxOpen(state, payload){
      state.searchNodeToolboxOpen = payload;
    },
    mutateDisplayCluster(state, payload){
      state.displayCluster = payload;
    },
    mutateDisplayColorMode: (state, payload) => {
      state.displayColorMode = payload;
    },
    mutateSearchToolboxInput(state, payload){
      state.searchToolboxInput = payload;
    },
    mutateRenderHologramNodeColor(state, payload){
      state.renderHologramNodeColor = payload;
    },
    mutateDeleteModeOpen(state, payload){
      state.deleteModeOpen = payload;
    },
    mutateHologramNodeDetailTargetID: (state, payload) => {
      state.hologramNodeDetailTargetID = payload;
    },
  },
  actions: {
    toggleConnectionSetupMode: (context, {data}) => {
      context.commit("mutateConnectionSetUpModeOpen", data);
    },
    cleanUpConnectionSetupMode: (context) => {
      context.commit("mutateConnectionSetUpModeOpen", false);
      context.commit("mutateConnectionStartNodeID", null);
      context.commit("mutateConnectionEndNodeID", null);
    },
    setConnectionStartNode: (context, {data}) => {
      context.commit("mutateConnectionStartNodeID", data);
    },
    setConnectionEndNode: (context, {data}) => {
      context.commit("mutateConnectionEndNodeID", data);
    },
    updateTransactingStatus(context, {data}){
      context.commit("mutateTransactingStatus", data);
    },
    updateEditingCurrentHologramPermission(context, {data}){
      context.commit("mutateEditingCurrentHologramPermission", data);
    },
    updateCurrentHologramDataReady(context, {data}){
      context.commit("mutateCurrentHologramDataReady", data);
    },
    updateRenderHologramGraph(context, {data}){
      context.commit("mutateRenderHologramGraph", data);
    },
    updateRenderHologramGraphDataOnly: (context, {data}) => {
      context.commit("mutateRenderHologramGraphDataOnly", data);
    },
    updateRenderHologramDisplay(context, {data}){
      context.commit("mutateRenderHologramDisplay", data);
    },
    updateSearchNodeToolboxOpen(context, {data}){
      context.commit("mutateSearchNodeToolboxOpen", data);
    },
    updateDisplayCluster(context, {data}){
      context.commit("mutateDisplayCluster", data);
    },
    toggleDisplayColorMode: (context, {data}) => {
      context.commit("mutateDisplayColorMode", data);
    },
    updateSearchToolboxInput(context, {data}){
      context.commit("mutateSearchToolboxInput", data);
    },
    updateRenderHologramNodeColor(context, {data}){
      context.commit("mutateRenderHologramNodeColor", data);
    },
    updateDeleteModeOpen(context, {data}){
      context.commit("mutateDeleteModeOpen", data)
    },
    setHologramNodeDetailTargetID: (context, {data}) => {
      context.commit("mutateHologramNodeDetailTargetID", data);
    },
  },
  getters: {
    connectionSetUpModeOpen: (state) =>  state.connectionSetUpModeOpen,
    connectionStartNodeID: (state) =>  state.connectionStartNodeID,
    connectionEndNodeID: (state) =>  state.connectionEndNodeID,
    transactingStatus: (state) => state.transactingStatus,
    editingCurrentHologramPermission: (state) => state.editingCurrentHologramPermission,
    currentHologramDataReady: (state) => state.currentHologramDataReady,
    renderHologramGraph: (state) => state.renderHologramGraph,
    renderHologramGraphDataOnly: (state) => state.renderHologramGraphDataOnly,
    renderHologramDisplay: (state) => state.renderHologramDisplay,
    searchNodeToolboxOpen: (state) => state.searchNodeToolboxOpen,
    displayCluster: (state) => state.displayCluster,
    displayColorMode: (state) => state.displayColorMode,
    searchToolboxInput: (state) => state.searchToolboxInput,
    renderHologramNodeColor: (state) => state.renderHologramNodeColor,
    deleteModeOpen: (state) => state.deleteModeOpen,
    hologramNodeDetailTargetID: (state) => state.hologramNodeDetailTargetID,
  }
};
