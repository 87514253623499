<template>
  <div class="flex flex-col flex-shrink-0 w-full h-screen bg-sd-base03-brblack">
    <div
      class="flex flex-col w-10/12 mx-auto my-16 sm:m-auto sm:w-8/12 md:w-7/12"
    >
      <div
        class="w-full mr-auto font-sans text-4xl font-bold text-left sm:text-5xl text-sd-base2-white"
      >
        Welcome
      </div>
      <p
        class="w-full mt-3 font-sans text-lg font-normal text-left sm:mt-6 sm:text-xl text-sd-base1-brcyan"
      >
        Before we embark the journey into Hololink, we would like to invite you take 
        this questionnaire. It not only empower the user experience we can 
        provide to you but also enhance the power of Hololink itself.
      </p>
      <div
        class="w-full mt-6 font-sans text-2xl font-bold text-left sm:mt-10 sm:text-3xl text-sd-base1-brcyan"
      >
        Note:
      </div>
      <div
        class="pl-5"
      >
        <ul class="w-full mt-2 font-sans text-base font-normal text-left list-disc list-outside text-sd-base1-brcyan">
          <li>
            The quesionnaire will roughly take <span class="font-bold text-sd-base2-white"> 5 minutes </span> to answer.
          </li>
          <li class="mt-1">
            We will ask about the preference of choosing content and the way to organise the contents you read.
          </li>
          <li class="mt-1">
            The questionnaire will result in a <span class="font-bold text-sd-base2-white"> hologram </span>.
          </li>
        </ul>
      </div>
      <router-link
        :to="{ name: 'QuestionnaireQuestions' }"
        class="px-4 py-2 mt-10 font-sans font-bold border rounded-sm w-36 border-sd-base1-brcyan text-sd-base2-white hover:bg-sd-base02-black"
      >
        Start
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name:"QuestionnaireIntro",
  mounted() {
    window.document.title = "Totuslink - Questionnaire intro"
  },
};
</script>
<style>
</style>