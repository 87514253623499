
export const deleteHologram = /* GraphQL */ `
  mutation DeleteHologram(
    $input: DeleteHologramInput!
    $condition: ModelHologramConditionInput
  ) {
    deleteHologram(input: $input, condition: $condition) {
      id
      baseType
      intro
      title
      graph
      createdByID
      contributorID
      accessPolicy
      weightID
      updatedAt
      createdAt
      owner
      action
      userPresent
    }
  }
`;



export const deleteWeight = /* GraphQL */ `
  mutation DeleteWeight(
    $input: DeleteWeightInput!
    $condition: ModelWeightConditionInput
  ) {
    deleteWeight(input: $input, condition: $condition) {
      id
      baseType
      weightType
      hologramNodeID
      userNodeID
      nodeID
      hologramID
      calculation
      value
      version
      accessPolicy
      owner
      createdAt
      updatedAt
    }
  }
`;



export const createHologramEdge = /* GraphQL */ `
  mutation CreateHologramEdge(
    $input: CreateHologramEdgeInput!
    $condition: ModelHologramEdgeConditionInput
  ) {
    createHologramEdge(input: $input, condition: $condition) {
      id
      hologramID
      edgeID
      startNodeID
      endNodeID
      accessPolicy
      createdByID
      owner
      createdAt
      updatedAt
      edge {
        baseType
        id
        startNodeID
        endNodeID
        createdAt
        updatedAt
      }
      startNode {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
      }
      endNode {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
      }
    }
  }
`;

export const deleteHologramEdge = /* GraphQL */ `
  mutation DeleteHologramEdge(
    $input: DeleteHologramEdgeInput!
    $condition: ModelHologramEdgeConditionInput
  ) {
    deleteHologramEdge(input: $input, condition: $condition) {
      id
      hologramID
      edgeID
      startNodeID
      endNodeID
      accessPolicy
      createdByID
      owner
      createdAt
      updatedAt
    }
  }
`;

export const deleteHologramGroup = /* GraphQL */ `
  mutation DeleteHologramGroup(
    $input: DeleteHologramGroupInput!
    $condition: ModelHologramGroupConditionInput
  ) {
    deleteHologramGroup(input: $input, condition: $condition) {
      id
      baseType
      hologramID
      groupID
      color
      owner
      createdAt
      updatedAt
    }
  }
`;

export const deleteHologramNode = /* GraphQL */ `
  mutation DeleteHologramNode(
    $input: DeleteHologramNodeInput!
    $condition: ModelHologramNodeConditionInput
  ) {
    deleteHologramNode(input: $input, condition: $condition) {
      id
      hologramID
      nodeID
      accessPolicy
      weightID
      createdByID
      owner
      createdAt
      updatedAt
    }
  }
`;

export const createHologramNodeGroup = /* GraphQL */ `
  mutation CreateHologramNodeGroup(
    $input: CreateHologramNodeGroupInput!
    $condition: ModelHologramNodeGroupConditionInput
  ) {
    createHologramNodeGroup(input: $input, condition: $condition) {
      id
      baseType
      owner
      hologramNodeID
      groupID
      createdAt
      updatedAt
      group {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
      }
      hologramNode {
        id
        hologramID
        title
        nodeID
        accessPolicy
        weightID
        createdByID
        owner
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateHologramNodeGroup = /* GraphQL */ `
  mutation UpdateHologramNodeGroup(
    $input: UpdateHologramNodeGroupInput!
    $condition: ModelHologramNodeGroupConditionInput
  ) {
    updateHologramNodeGroup(input: $input, condition: $condition) {
      id
      baseType
      owner
      hologramNodeID
      groupID
      createdAt
      updatedAt
      group {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
      }
      hologramNode {
        id
        hologramID
        title
        nodeID
        accessPolicy
        weightID
        createdByID
        owner
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteHologramNodeGroup = /* GraphQL */ `
  mutation DeleteHologramNodeGroup(
    $input: DeleteHologramNodeGroupInput!
    $condition: ModelHologramNodeGroupConditionInput
  ) {
    deleteHologramNodeGroup(input: $input, condition: $condition) {
      id
      baseType
      owner
      hologramNodeID
      groupID
      createdAt
      updatedAt
    }
  }
`;

export const createWeight = /* GraphQL */ `
  mutation CreateWeight(
    $input: CreateWeightInput!
    $condition: ModelWeightConditionInput
  ) {
    createWeight(input: $input, condition: $condition) {
      id
      baseType
      weightType
      hologramNodeID
      userNodeID
      nodeID
      hologramID
      calculation
      value
      version
      accessPolicy
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
      }
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
      }
      userNode {
        id
        userID
        nodeID
        title
        note
        weightID
        version
        owner
        createdAt
        updatedAt
      }
      hologramNode {
        id
        hologramID
        nodeID
        accessPolicy
        weightID
        createdByID
        owner
        createdAt
        updatedAt
      }
    }
  }
`;

export const updateWeight = /* GraphQL */ `
  mutation UpdateWeight(
    $input: UpdateWeightInput!
    $condition: ModelWeightConditionInput
  ) {
    updateWeight(input: $input, condition: $condition) {
      id
      baseType
      weightType
      hologramNodeID
      userNodeID
      nodeID
      hologramID
      calculation
      value
      version
      accessPolicy
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
      }
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
      }
      userNode {
        id
        userID
        nodeID
        title
        note
        weightID
        version
        owner
        createdAt
        updatedAt
      }
      hologramNode {
        id
        hologramID
        nodeID
        accessPolicy
        weightID
        createdByID
        owner
        createdAt
        updatedAt
      }
    }
  }
`;

export const createHologramNode = /* GraphQL */ `
  mutation CreateHologramNode(
    $input: CreateHologramNodeInput!
    $condition: ModelHologramNodeConditionInput
  ) {
    createHologramNode(input: $input, condition: $condition) {
      id
      hologramID
      nodeID
      accessPolicy
      weightID
      createdByID
      owner
      createdAt
      updatedAt
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
      }
      weight {
        id
        baseType
        weightType
        hologramNodeID
        userNodeID
        nodeID
        hologramID
        calculation
        value
        version
        accessPolicy
        owner
        createdAt
        updatedAt
      }
      group {
        items {
          id
          baseType
          owner
          hologramNodeID
          groupID
          createdAt
          updatedAt
        }
      }
    }
  }
`;


export const createHologramContributor = /* GraphQL */ `
  mutation CreateHologramContributor(
    $input: CreateHologramContributorInput!
    $condition: ModelHologramContributorConditionInput
  ) {
    createHologramContributor(input: $input, condition: $condition) {
      id
      hologramID
      contributorID
      owner
      createdAt
      updatedAt
      contributor {
        baseType
        username
        email
      }
    }
  }
`;

export const deleteHologramContributor = /* GraphQL */ `
  mutation DeleteHologramContributor(
    $input: DeleteHologramContributorInput!
    $condition: ModelHologramContributorConditionInput
  ) {
    deleteHologramContributor(input: $input, condition: $condition) {
      id
      baseType
      hologramID
      contributorID
      owner
      createdAt
      updatedAt
    }
  }
`;

export const updateHologram = /* GraphQL */ `
  mutation UpdateHologram(
    $input: UpdateHologramInput!
    $condition: ModelHologramConditionInput
  ) {
    updateHologram(input: $input, condition: $condition) {
      id
      baseType
      intro
      title
      graph
      createdByID
      contributorID
      accessPolicy
      weightID
      updatedAt
      createdAt
      action
      userPresent
      owner
      like {
        items {
          id
          baseType
          hologramID
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      weight {
        id
        baseType
        weightType
        hologramNodeID
        userNodeID
        nodeID
        hologramID
        calculation
        value
        version
        accessPolicy
        owner
        createdAt
        updatedAt
      }
      post {
        items {
          id
          baseType
          postType
          hologramID
          title
          content
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      group {
        items {
          id
          baseType
          hologramID
          groupID
          color
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      node {
        items {
          id
          hologramID
          nodeID
          accessPolicy
          weightID
          createdByID
          createdAt
          updatedAt
        }
        nextToken
      }
      community {
        items {
          id
          hologramID
          communityID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      contributor {
        items {
          id
          baseType
          hologramID
          contributorID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      edge {
        items {
          id
          hologramID
          edgeID
          startNodeID
          endNodeID
          accessPolicy
          createdByID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;

export const createUserEdge = /* GraphQL */ `
  mutation CreateUserEdge(
    $input: CreateUserEdgeInput!
    $condition: ModelUserEdgeConditionInput
  ) {
    createUserEdge(input: $input, condition: $condition) {
      id
      userID
      edgeID
      note
      owner
      createdAt
      updatedAt
      user {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
      }
      edge {
        baseType
        id
        startNodeID
        endNodeID
        createdAt
        updatedAt
        startNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        endNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const deleteUserEdge = /* GraphQL */ `
  mutation DeleteUserEdge(
    $input: DeleteUserEdgeInput!
    $condition: ModelUserEdgeConditionInput
  ) {
    deleteUserEdge(input: $input, condition: $condition) {
      id
      userID
      edgeID
      note
      owner
      createdAt
      updatedAt
    }
  }
`;

export const createEdge = /* GraphQL */ `
  mutation CreateEdge(
    $input: CreateEdgeInput!
    $condition: ModelEdgeConditionInput
  ) {
    createEdge(input: $input, condition: $condition) {
      baseType
      id
      startNodeID
      endNodeID
      createdAt
      updatedAt
    }
  }
`;

export const deleteEdge = /* GraphQL */ `
  mutation DeleteEdge(
    $input: DeleteEdgeInput!
    $condition: ModelEdgeConditionInput
  ) {
    deleteEdge(input: $input, condition: $condition) {
      baseType
      id
      startNodeID
      endNodeID
      createdAt
      updatedAt
    }
  }
`;

export const createDomain = /* GraphQL */ `
  mutation CreateDomain(
    $input: CreateDomainInput!
    $condition: ModelDomainConditionInput
  ) {
    createDomain(input: $input, condition: $condition) {
      baseType
      hostName
      id
      updatedAt
      createdAt
    }
  }
`;

export const createUserNode = /* GraphQL */ `
  mutation CreateUserNode(
    $input: CreateUserNodeInput!
    $condition: ModelUserNodeConditionInput
  ) {
    createUserNode(input: $input, condition: $condition) {
      id
      userID
      nodeID
      title
      note
      weightID
      version
      owner
      createdAt
      updatedAt
      user {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
      }
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
      }
      weight {
        id
        baseType
        weightType
        hologramNodeID
        userNodeID
        nodeID
        hologramID
        calculation
        value
        version
        accessPolicy
        owner
        createdAt
        updatedAt
      }
    }
  }
`;

export const deleteNode = /* GraphQL */ `
  mutation DeleteNode(
    $input: DeleteNodeInput!
    $condition: ModelNodeConditionInput
  ) {
    deleteNode(input: $input, condition: $condition) {
      baseType
      id
      url
      title
      wordCounts
      favicon
      domainID
      weightID
      createdAt
      updatedAt
    }
  }
`;

export const deleteUserNode = /* GraphQL */ `
  mutation DeleteUserNode(
    $input: DeleteUserNodeInput!
    $condition: ModelUserNodeConditionInput
  ) {
    deleteUserNode(input: $input, condition: $condition) {
      id
      userID
      nodeID
      title
      note
      weightID
      version
      owner
      createdAt
      updatedAt
    }
  }
`;

export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      baseType
      id
      title
      createdByID
      createdAt
      updatedAt
    }
  }
`;

export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      baseType
      id
      title
      createdByID
      createdAt
      updatedAt
    }
  }
`;

export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      baseType
      id
      title
      createdByID
      createdAt
      updatedAt
    }
  }
`;

export const groupByTitle = /* GraphQL */ `
  query GroupByTitle(
    $title: String
    $createdByID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupByTitle(
      title: $title
      createdByID: $createdByID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;