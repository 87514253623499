<template>
  <div class="w-full h-screen overflow-y-hidden flex flex-col">
    <div
      v-for="question in questions"
      v-bind:key="question.id"
      :id="question.id"
    >
      <div
        class="w-full h-screen flex bg-sd-base03-brblack"
        v-if="question.type === 'shortAnswer'"
      >
        <shortAnswer :question="question" />
      </div>
      <div
        class="w-full h-screen flex bg-sd-base03-brblack"
        v-if="question.type === 'singleSelection'"
      >
        <singleSelectionBase :question="question" />
      </div>
      <div
        class="w-full h-screen flex bg-sd-base03-brblack"
        v-if="question.type === 'multiSelection'"
      >
        <multiSelectionBase :question="question" />
      </div>
      <div
        class="w-full h-screen flex bg-sd-base03-brblack"
        v-if="question.type === 'linearScale'"
      >
        <linearScaleBase :question="question" />
      </div>
    </div>
  </div>
</template>
<script>
import shortAnswer from "@/components/questionnaire/shortAnswer.vue"
import singleSelectionBase from "@/components/questionnaire/singleSelectionBase.vue"
import multiSelectionBase from "@/components/questionnaire/multiSelectionBase.vue"
import linearScaleBase from "@/components/questionnaire/linearScaleBase.vue"

import { mapGetters } from 'vuex';
export default {
  name:"questions",
  data() {
    return {
      
    }
  },
  components:{
    shortAnswer,
    singleSelectionBase,
    multiSelectionBase,
    linearScaleBase,
  },
  methods: {
  
  },
  computed: {
    ...mapGetters({
      questions: "questionnaire/questions",
    })
  },
};
</script>
<style>
</style>