<template>
  <div class="flex flex-col w-full h-full base bg-sd-base03-brblack">
    <div class="flex">
      <LandingNav />
    </div>
    <div class="flex flex-col mx-auto mt-32 w-112">
      <div
        class="mr-auto font-sans font-extrabold cursor-default text-8xl text-sd-base2-white"
      >
        404
      </div>
      <div
        class="mt-4 mr-auto font-sans text-lg font-normal text-left cursor-default text-sd-base1-brcyan"
      >
        We are sorry that this is not the page you are looking for. If you had
        already logged in our services, we suggest you find the content you want
        from
        <button
          @click="pushToDashboard"
          class="font-sans text-lg focus:outline-none"
          v-bind:class="getPushToDashboardStyle()"
          :disabled="!user"
        >
          Dashboard
        </button>
        or
        <button
          @click="pushToExplore"
          class="font-sans text-lg focus:outline-none"
          v-bind:class="getPushToExploreStyle()"
          disabled
        >
          Explore
        </button>
        Holograms.
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import LandingNav from "@/components/LandingNav.vue";
export default {
  name: "NotFound404",
  components: {
    LandingNav,
  },
  mounted() {
    window.document.title = "Totuslink - 404"
  },
  methods: {
    pushToDashboard() {
      const globalUserNameForUrl = this.user.attributes.email.split('@')[0]
      this.$router.push({name:"Dashboard", params:{username:globalUserNameForUrl}})
    },
    getPushToDashboardStyle() {
      if (this.user) {
        return "font-bold hover:text-sd-base2-white"
      } else {
        return "font-normal cursor-default"
      }
    },
    pushToExplore(){

    },
    getPushToExploreStyle(){
      // Wait until we open explore features
      if (this.user) {
        return "font-normal cursor-default"
        //return "font-bold hover:text-sd-base2-white"
      } else {
        return "font-normal cursor-default"
      }
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
};
</script>
<style>
</style>