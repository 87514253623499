<template>
  <div>
    <div
      v-if="subType === 'InternalToken'"
    >
      <div
        class="flex flex-row mt-4 mr-4 w-108 h-36 p-4 cursor-pointer rounded-sm  border-sd-base0-brblue hover:bg-sd-base02-black"
        @click="updateActiveAnswer"
        v-bind:class="getAdditionStyle()"
      >
        <div
          class="flex w-8 h-8 m-auto rounded-full border border-sd-base0-brblue"
          v-if="modelValue !== option.id"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg" 
            class="flex fill-current text-sd-base0-brblue w-5 h-5 m-auto" 
            viewBox="0 0 16 16"
          >
            <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
          </svg>
        </div>
        <div
          v-else
          class="flex w-8 h-8 m-auto rounded-full bg-sd-base0-brblue"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg" 
            class="flex fill-current text-sd-base02-black w-5 h-5 m-auto" 
            viewBox="0 0 16 16"
          >
            <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
          </svg>
        </div>
        <div
          class="flex flex-col flex-shrink ml-4 w-80"
        >
          <div
            class="w-full text-left font-sans font-semibold text-md text-sd-base2-white"
          >
            {{option.title}}
          </div>
          <div
            class="w-full text-left font-sans font-normal text-sm text-sd-base1-brcyan"
          >
            {{truncateAbstract}}
          </div>
          <div
            class="mt-1.5 h-6 grid grid-flow-row grid-cols-2 grid-rows-1"
          >
            <div
              class="flex flex-row my-auto"
            >
              <div class="my-auto">
                <svg xmlns="http://www.w3.org/2000/svg" class="flex fill-current text-sd-base0-brblue w-4 h-4 m-auto"  viewBox="0 0 16 16">
                  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                  <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                </svg>
              </div>
              <div
                class="ml-2 font-sans text-sm text-sd-base1-brcyan"
              >
                {{option.author}}
              </div>
            </div>
            <div 
              class="flex flex-row my-auto"
            >
              <div class="my-auto">
                <svg class="flex fill-current text-sd-base0-brblue w-4 h-4 m-auto" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M7.21.8C7.69.295 8 0 8 0c.109.363.234.708.371 1.038.812 1.946 2.073 3.35 3.197 4.6C12.878 7.096 14 8.345 14 10a6 6 0 0 1-12 0C2 6.668 5.58 2.517 7.21.8zm.413 1.021A31.25 31.25 0 0 0 5.794 3.99c-.726.95-1.436 2.008-1.96 3.07C3.304 8.133 3 9.138 3 10c0 0 2.5 1.5 5 .5s5-.5 5-.5c0-1.201-.796-2.157-2.181-3.7l-.03-.032C9.75 5.11 8.5 3.72 7.623 1.82z"/>
                  <path fill-rule="evenodd" d="M4.553 7.776c.82-1.641 1.717-2.753 2.093-3.13l.708.708c-.29.29-1.128 1.311-1.907 2.87l-.894-.448z"/>
                </svg>
              </div>
              <div
                class="ml-2 font-sans text-sm text-sd-base1-brcyan"
              >
                {{option.fuel}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="subType === 'Redflag'"
    >

    </div>
  </div>
</template>
<script>
export default {
  name: "singleSelection",
  props:{
    option: {
      required: true,
      type: Object,
    },
    subType: {
      required: true,
      type: String,
    },
    modelValue: String,
  },
  emits: ['update:modelValue'],
  methods: {
    updateActiveAnswer(){
      this.$emit('update:modelValue', this.option.id)
    },
    getAdditionStyle(){
      if (this.modelValue === this.option.id){
        return 'bg-sd-base02-black border'
      } else {
        return 'border'
      }
    },
    truncateString({string, length}) {
      // If the length of str is less than or equal to num
      // just return str--don't truncate it.
      if (string.length <= length) {
        return string
      }
      // Return str truncated with '...' concatenated to the end of str.
      return string.slice(0, length) + '...'
    }
  },
  computed: {
    truncateAbstract: function(){
      return this.truncateString({string:this.option.abstract, length:100})
    }
  },
};
</script>
<style>
</style>