<template>
  <div class="fixed inset-0 h-full">
    <div
      class="flex items-end justify-center h-full min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0"
      id="modal-container"
    >
      <transition
        enter-active-class="duration-300 ease-out"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div 
          @click="toggleNodeContextMenuHandler" 
          class="fixed inset-0"
          v-if="nodeContextMenuOpen"
        >
          <div class="absolute inset-0 opacity-50"></div>
        </div>
      </transition>
      <transition
        name="modal-transition"
        enter-active-class="duration-300 ease-out"
        enter-from-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
        enter-to-class="translate-y-0 opacity-100 sm:scale-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="translate-y-0 opacity-100 sm:scale-100"
        leave-to-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
      >
        <div
          class="fixed overflow-y-auto text-left align-bottom transition-all transform rounded-sm shadow-xl w-52"
          role="dialog"
          aria-modal="true"
          id="node-context-menu-container"
          v-show="nodeContextMenuOpen"
          v-bind:style="positionStyle"
        >
          <div class="w-full py-2 border rounded-sm bg-sd-base02-black border-sd-base00-bryellow">
            <a
              class="flex flex-row w-full px-3 py-1 cursor-pointer hover:bg-sd-base2-white hover:bg-opacity-5 focus:outline-none"
              :href="nodeContextMenuData.url"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="flex w-4 h-4 my-auto mr-2 fill-current text-sd-base0-brblue"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"
                />
              </svg>
              <div
                class="flex text-sm font-normal text-left text-sd-base1-brcyan"
              >
                Open link
              </div>
            </a>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { gaHelpers } from "@/analytics/helper.js"
export default {  
  name:"nodeContextMenu",
  data() {
    return {
      positionStyle:{},
      modalWidth: null
    }
  },
  mounted() {
    this.positionStyle = {
      left: this.triggeredPosition.x + 'px',
      top: this.triggeredPosition.y + 30 + 'px'
    };
    window.addEventListener("keydown", this.keydownEscHandler)
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.keydownEscHandler)
  },
  methods: {
    keydownEscHandler: function(event){
      if ( event.code !== "Escape" ) return;
      if ( !this.nodeContextMenuOpen ) return;
      this.toggleNodeContextMenuHandler()
    },
    toggleNodeContextMenuHandler(){
      if (this.nodeContextMenuOpen){
        this.$store.dispatch("modal/toggleNodeContextMenu", {data:false})
        this.$store.dispatch("modal/updateNodeContextMenuPosition", {type:"reset"})
        gaHelpers.interaction("close_node_context_menu", "node_context_menu");
      }
    },
    toggleDeleteNodeModal(){
      if (this.connectionSetUpModeOpen){
        this.$store.dispatch("hologram/cleanUpConnectionSetupMode");
        this.$store.dispatch("hologram/updateRenderHologramNodeColor", {data:true})
        gaHelpers.interaction("close_setup_edge_mode_by_open_delete_mode_at_node_context_menu", "setup_edge_mode");
      }
      
      if (!this.deleteNodeModalOpen){
        this.$store.dispatch("modal/toggleDeleteNodeModal", {data:true});
        this.$store.dispatch("modal/updateDeleteNodeID", {type:"update", data:this.nodeContextMenuData.id});
        this.$store.dispatch("modal/toggleNodeContextMenu", {data:false})
        this.$store.dispatch("modal/updateNodeContextMenuPosition", {type:"reset"})
        gaHelpers.interaction("open_delete_node_modal", "delete_node_modal");
      }
    }
  },
  computed:{
    ...mapGetters({
      nodeContextMenuOpen: "modal/nodeContextMenuOpen",
      triggeredPosition: "modal/nodeContextMenuPosition",
      nodeContextMenuData: "modal/nodeContextMenuData",
      connectionSetUpModeOpen: "hologram/connectionSetUpModeOpen",
      deleteNodeModalOpen: "modal/deleteNodeModalOpen",
    }),
  }
};
</script>

<style>
</style>