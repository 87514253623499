<template>
  <div
    class="flex h-12 mx-auto duration-500 transform rounded-md shadow-2xl bg-sd-base02-black transition-width"
    v-bind:class="getPanelWidth()"
    v-click-away="onClickAway"
  >
    <div
      class="flex flex-row m-auto"
    >
      <button
        class="flex w-8 h-8 mr-2 border border-opacity-0 rounded-md focus:outline-none border-sd-base01-brgreen hover:border-opacity-100"
        @click="setDeleteModeHandler"
      >
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          class="flex w-4 h-4 m-auto fill-current"
          viewBox="0 0 16 16"
          :class="getDeleteModeIconStyle()"
        >
          <path d="M3.5 3.5c-.614-.884-.074-1.962.858-2.5L8 7.226 11.642 1c.932.538 1.472 1.616.858 2.5L8.81 8.61l1.556 2.661a2.5 2.5 0 1 1-.794.637L8 9.73l-1.572 2.177a2.5 2.5 0 1 1-.794-.637L7.19 8.61 3.5 3.5zm2.5 10a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0zm7 0a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0z"/>
        </svg>
      </button>
      <button
        class="flex w-8 h-8 mr-2 border border-opacity-0 rounded-md focus:outline-none border-sd-base01-brgreen hover:border-opacity-100"
        @click="setConnectionModeHandler"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="flex w-4 h-4 m-auto fill-current"
          viewBox="0 0 16 16"
          :class="getConnectionModeIconStyle()"
        >
          <path
            fill-rule="evenodd"
            d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"
          />
        </svg>
      </button>
      <div
        class="mr-2"
      >
        <input 
          class="h-8 px-3 m-auto font-sans duration-500 transform border rounded-full text-sd-base2-white text-md border-sd-base01-brgreen focus:ring-0 focus:outline-none bg-sd-base03-brblack transition-width"
          @click="inputClickHandler"
          v-bind:class="getInputWidth()"
          v-model="searchInput"
          @keyup.up="selectPreviousNode($event)"
          @keyup.down="selectNextNode($event)"
          @keyup.enter="buildNodeHandler"
          @keyup.esc="closeAllHandler"
          id="hologram-search-toolbox-input"
          autocomplete="off"
        >
      </div>
      <div
        class="flex flex-row"
      >
        <button
          class="flex w-8 h-8 mr-2 border border-opacity-0 rounded-md focus:outline-none border-sd-base01-brgreen hover:border-opacity-100"
          v-bind:class="getToggleClusterButtonStyle()"
          @click="toggleClusterMode()"
          v-tooltip="{
            content: 'group',
            theme: 'info-tooltip',
          }"
        >
          <iconIntersect :size="4" class="m-auto" />
        </button>
        <button
          class="flex w-8 h-8 mr-2 border border-opacity-0 rounded-md focus:outline-none border-sd-base01-brgreen hover:border-opacity-100"
          v-bind:class="getToggleColorButtonStyle()"
          @click="toggleDisplayColorMode()"
          v-tooltip="{
            content: 'Display color',
            theme: 'info-tooltip',
          }"
        >
          <iconPaletteFill :size="4" class="m-auto" />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import "@/assets/vTooltip.css"
import iconIntersect from "../icon/iconIntersect"
import iconPaletteFill from "../icon/iconPaletteFill"
import { directive } from "vue3-click-away";
import { mapGetters } from 'vuex';
import { gaHelpers } from "../../analytics/helper.js"
export default {
  name: "hologramControlPanel",
  directives: {
    ClickAway: directive
  },
  components: {
    iconIntersect,
    iconPaletteFill
  },
  data() {
    return {
      searchInput: null,
    }
  },
  methods: {
    toggleDisplayColorMode: function(){
      if ( this.displayColorMode ){
        this.$store.dispatch("hologram/toggleDisplayColorMode", {data:false});
        gaHelpers.interaction("close_color_display_mode", "group");
        return;
      }
      this.$store.dispatch("hologram/toggleDisplayColorMode", {data:true});
      gaHelpers.interaction("open_color_display_mode", "group");
    },
    toggleClusterMode: function(){
      if ( this.displayCluster ){
        this.$store.dispatch("hologram/updateDisplayCluster", {data:false});
        gaHelpers.interaction("close_group_mode", "group");
        return;
      }
      this.$store.dispatch("hologram/updateDisplayCluster", {data:true});
      gaHelpers.interaction("open_group_mode", "group");
    },
    setConnectionModeHandler: function(){
      if (this.deleteModeOpen){
        this.$store.dispatch("hologram/updateDeleteModeOpen", {data:false});
        gaHelpers.interaction("close_delete_mode_by_open_setup_edge_mode", "delete_mode");
      }
      
      if (this.connectionSetUpModeOpen){
        this.$store.dispatch("hologram/cleanUpConnectionSetupMode");
        this.$store.dispatch("hologram/updateRenderHologramNodeColor", {data:true})
        gaHelpers.interaction("close_setup_edge_mode", "setup_edge_mode");
      } else {
        this.$store.dispatch("hologram/toggleConnectionSetupMode", {data:true});
        this.$store.dispatch("hologram/updateRenderHologramNodeColor", {data:true})
        gaHelpers.interaction("open_setup_edge_mode", "setup_edge_mode");
      }
    },
    setDeleteModeHandler: function(){
      if (this.connectionSetUpModeOpen){
        this.$store.dispatch("hologram/cleanUpConnectionSetupMode");
        this.$store.dispatch("hologram/updateRenderHologramNodeColor", {data:true})
        gaHelpers.interaction("close_setup_edge_mode_by_open_delete_mode", "setup_edge_mode");
      }

      if (this.deleteModeOpen){
        this.$store.dispatch("hologram/updateDeleteModeOpen", {data:false});
        gaHelpers.interaction("close_delete_mode", "delete_mode");
      } else {
        this.$store.dispatch("hologram/updateDeleteModeOpen", {data:true});
        gaHelpers.interaction("open_delete_mode", "delete_mode");
      }
    },
    getToggleClusterButtonStyle: function(){
      if (this.displayCluster) return "text-sd-yellow"
      return "text-sd-base01-brgreen"
    },
    getToggleColorButtonStyle: function(){
      if ( this.displayColorMode ) return "text-sd-yellow"
      return "text-sd-base01-brgreen"
    },
    getInputWidth: function(){
      if (this.hologramControlPanelInputOpen) return "w-80"
      return "w-8"
    },
    getPanelWidth: function(){
      if (this.hologramControlPanelInputOpen) return "w-124"
      return "w-60"
    },  
    getConnectionModeIconStyle: function(){
      if (this.connectionSetUpModeOpen) return "text-sd-yellow"
      return "text-sd-base01-brgreen"
    },
    getDeleteModeIconStyle: function(){
      if (this.deleteModeOpen) return "text-sd-yellow"
      return "text-sd-base01-brgreen"
    },
    inputClickHandler: function(){
      this.$store.dispatch("modal/updateHologramControlPanelInputOpen", {data:true})
      gaHelpers.interaction("open_searchbar", "search");
    },
    onClickAway: function(event){
      if (!event.target.offsetParent){
        this.searchInput = "";
        this.$store.dispatch("modal/updateHologramControlPanelInputOpen", {data:false})
      } else if (event.target.offsetParent && event.target.offsetParent.getAttribute('id') === null) {
        this.searchInput = "";
        this.$store.dispatch("modal/updateHologramControlPanelInputOpen", {data:false})
      } else if (!event.target){
        this.searchInput = "";
        this.$store.dispatch("modal/updateHologramControlPanelInputOpen", {data:false})
      }
    },
    selectPreviousNode: function($event){ // eslint-disable-line no-unused-vars
      //$event.preventDefault();
      const index = this.searchToolboxFilteredNode.findIndex( e => e.result.id === this.searchToolboxSelectedNodeID );
      $event.target.focus()

      if ( index - 1 < 0 ){
        return;
      }

      if ( this.searchToolboxFilteredNode[`${index-1}`].status === "exist" ){
        return
      }
      this.$store.dispatch("modal/updateSearchToolboxSelectedNodeID", {data:this.searchToolboxFilteredNode[`${index-1}`].result.id})
    },
    selectNextNode: function($event){ // eslint-disable-line no-unused-vars
      //$event.preventDefault();
      const index = this.searchToolboxFilteredNode.findIndex( e => e.result.id === this.searchToolboxSelectedNodeID );
      $event.target.focus()

      console.log(index, this.searchToolboxFilteredNode.length)

      if ( index + 2 > this.searchToolboxFilteredNode.length ){
        return;
      }

      if ( this.searchToolboxFilteredNode[`${index+1}`].status === "exist" ){
        return
      }

      this.$store.dispatch("modal/updateSearchToolboxSelectedNodeID", {data:this.searchToolboxFilteredNode[`${index+1}`].result.id})
    },
    buildNodeHandler: async function(){

      const index = this.searchToolboxFilteredNode.findIndex( e => e.result.id === this.searchToolboxSelectedNodeID );
      const node = this.searchToolboxFilteredNode[`${index}`].result;
    
      this.$store.dispatch("hologram/updateTransactingStatus", {data:true});
      this.$store.dispatch("hologram/updateSearchNodeToolboxOpen", {data:false});

      const buildNodeVariables = {
        currentHologramNodes: this.currentHologramNodes,
        currentHologram: this.currentHologram,
        userID: this.userData.id,
        targetNode: node,
        userNodes: this.userNodes
      };
      const result = await this.$store.dispatch("hologram/advancedBuildNode", buildNodeVariables);

      this.$store.dispatch("hologram/updateTransactingStatus", {data:false});
      this.searchInput = "";
      if (result === "duplicated"){
        const newNotification = {
          "location": "nodeCreation",
          "type": "duplicated"
        }
        this.$store.dispatch("notification/updateHologramOperationNotification", {type:"update", data:newNotification})
      }
      gaHelpers.engagement("add_node", "core");
    },
    closeAllHandler: function(){
      this.searchInput = "";
      this.$store.dispatch("modal/updateHologramControlPanelInputOpen", {data:false})
    },
  },
  computed: {
    ...mapGetters({
      userData: "auth/userData",
      userNodes: "hologram/userNodes",
      currentHologram: "hologram/currentHologram",
      currentHologramNodes: "hologram/currentHologramNodes",
      hologramControlPanelInputOpen: "modal/hologramControlPanelInputOpen",
      connectionSetUpModeOpen: "hologram/connectionSetUpModeOpen",
      transactingStatus: "hologram/transactingStatus",
      displayCluster: "hologram/displayCluster",
      displayColorMode: "hologram/displayColorMode",
      deleteModeOpen: "hologram/deleteModeOpen",
      searchToolboxSelectedNodeID: "modal/searchToolboxSelectedNodeID",
      searchToolboxFilteredNode: "modal/searchToolboxFilteredNode",
    })
  },
  watch: {
    searchInput: function(newValue){
      if (newValue === ""){
        this.$store.dispatch("hologram/updateSearchToolboxInput", {data:newValue});
        return;
      }
      this.$store.dispatch("hologram/updateSearchToolboxInput", {data:newValue});
      this.$store.dispatch("hologram/updateSearchNodeToolboxOpen", {data:true});
      this.$store.dispatch("modal/updateHologramControlPanelInputOpen", {data:true});
    }
  },
};
</script>
<style>


</style>