<template>
  <div
    class="flex flex-col w-full h-screen bg-sd-base03-brblack"
    @keydown="onKeydownHandler($event)"
  >
    <div
      class="flex flex-col m-auto"
    >
      <div
        class="w-full mt-2 mr-auto font-sans text-3xl font-medium text-center text-sd-base1-brcyan"
      >
        {{ questionnaire.ending.title }}
      </div>
      <div
        class="w-full mt-2 mr-auto font-sans text-lg font-normal text-center text-sd-base1-brcyan"
      >
        {{ questionnaire.ending.context }}
      </div>
      <div class="flex w-full mx-auto mt-20">
        <button
          class="flex flex-row items-center justify-center w-24 h-12 mx-auto border rounded-sm border-sd-base00-bryellow focus:outline-none hover:bg-sd-base02-black"
          @click="clickHandler"
        >
          <div class="font-sans text-lg font-semibold text-sd-base1-brcyan">
            Let's go
          </div>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "questionnaireEnding",
  props: {
    question: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      answer: null,
    };
  },
  methods: {
    onKeydownHandler($event) {
      console.log($event);
    },
    clickHandler:  function(){
      this.$router.push({ name: 'UserHolograms', params:{ username: this.user.username}});
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      currentQuestionID: "questionnaire/currentQuestionID",
      questionnaire: "questionnaire/questionnaire",
    }),
  },
};
</script>
<style>
</style>