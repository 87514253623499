<template>
  <div
    class="w-full"
    v-if="targetUserNode.id"
  >
    <markdownEditor
      v-if="dataIsReady"
      class="w-full"
      :targetNote="targetNote"
      :targetUserNodeID="targetUserNode.id"
      @update-note="catchUpdateNoteEvent"
    />
  </div>
</template>
<script>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import markdownEditor from "../markdownEditor.vue";
import { countWords } from "../../utilities/utilities";
import { gaHelpers } from "@/analytics/helper.js";
export default {
  name: "NodeNoteBlock",
  components: { markdownEditor },
  props: {
    hologramNode: {
      type: Object,
      require: true,
    },
    hologramNodeWeight: {
      type: Object,
      require: true,
    },
    targetUserNode: {
      type: Object,
      require: true,
    },
  },
  setup(props) {
    const store = useStore();
    const targetNote = ref("");
    const dataIsReady = ref(false);
    const currentNodeUpdatedNote = ref(null);

    const getNote = () => {
      targetNote.value = props.targetUserNode.note;
    };

    const prepare = () => {
      getNote();
      dataIsReady.value = true;
    };

    onMounted(prepare);

    const updateNoteEvent = ref({});
    const catchUpdateNoteEvent = (message) => {
      currentNodeUpdatedNote.value = message;
      clearTimeout(updateNoteEvent.value[`${props.targetUserNode.id}`]);
      delete updateNoteEvent.value[`${props.targetUserNode.id}`];
      updateNoteEvent.value[`${props.targetUserNode.id}`] = setTimeout(() => {
        updateNoteToDB(props.targetUserNode.id, props.hologramNodeWeight, message);
      }, 3000);
    };

    const updateNoteToDB = async (userNodeID, hologramNodeWeight, note) => {
      store.dispatch("hologram/updateTransactingStatus", { data: true });
      const version = JSON.parse(hologramNodeWeight.version);
      const counts = countWords(note);
      const newVersion = {
        times: Date.now(),
        counts: {
          link: version[0].counts.link,
          note: counts,
          //crossHologram: version[0].counts.crossHologram,
          //outerLink: version[0].counts.outerLink,
        },
        weight: {
          link: 1,
          note: 0.001,
          crossHologram: 0.5,
          outerLink: 0.5,
        },
      };

      const newValue = newVersion.counts.link * newVersion.weight.link + newVersion.counts.note * newVersion.weight.note;
      //newVersion.counts.crossHologram * newVersion.weight.crossHologram +
      //newVersion.counts.outerLink * newVersion.weight.outerLink;

      version.splice(0, 0, newVersion);


      const updateWeightVariables = {
        weight: {
          id: hologramNodeWeight.id,
          version: JSON.stringify(version),
          value: newValue,
        },
      };
      try {
        const result = await store.dispatch("weight/updateHologramNodeWeight", updateWeightVariables);
        this.$store.dispatch("weight/updateItemInCurrentHologramNodeWeight", result);
      } catch (err) {
        console.log("Something went wrong when update hologramNode weight", err);
      }
      try {
        await store.dispatch("hologram/updateUserNodeNote", { userNodeID: userNodeID, note: note });
        gaHelpers.engagement("edit_note", "context");
      } catch (err) {
        console.log("Something went wrong when update userNode Note", err);
      }
      store.dispatch("hologram/updateRenderHologramGraphDataOnly", { data: true });
      store.dispatch("hologram/updateTransactingStatus", { data: false });
    };

    const updateNoteToDBNow = () => {
      if (!currentNodeUpdatedNote.value) {
        return;
      }

      clearTimeout(updateNoteEvent.value[`${props.targetUserNode.id}`]);
      delete updateNoteEvent.value[`${props.targetUserNode.id}`];
      updateNoteToDB(props.targetUserNode.id, props.hologramNodeWeight, currentNodeUpdatedNote.value);
    };

    onBeforeUnmount(updateNoteToDBNow);

    return {
      // Local
      targetNote,
      dataIsReady,

      // Event
      catchUpdateNoteEvent,
      updateNoteEvent,
    };
  },
};
</script>
<style>
</style>