import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css';
import { Amplify } from "aws-amplify";
import aws_exports from './aws-exports';
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueGtag from "vue-gtag-next";
import { VueCookieNext } from 'vue-cookie-next'
import VTooltipPlugin from 'v-tooltip'

const app = createApp(App);

app.use(VueGtag, {
  property: {
    id: "G-ZFGJ0L072G"
  },
  params: {
    send_page_view: false,
  }
});

// Prevents toasts of the same type from appearing simultaneously, discarding duplicates
const filterBeforeCreate = (toast, toasts) => {
  if (toasts.filter(t => t.type === toast.type).length !== 0) {
    // Returning false discards the toast
    return false;
  }
  // You can modify the toast if you want
  return toast;
}

import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';

app.use(store).use(router)
app.use(VueCookieNext)
app.use(Toast,{
  filterBeforeCreate, 
  position: POSITION.BOTTOM_RIGHT,
  transition: "Vue-Toastification__fade",
  hideProgressBar: true,
})


const VtooltipConfig = {
  themes: {
    "info-tooltip": {
      disposeTimeout: 10000,
      $extend: 'tooltip',
      $resetCss: true,
    }
  }
}


app.use(VTooltipPlugin, VtooltipConfig)
app.config.isCustomElement = tag => tag.startsWith('amplify-');
app.mount('#app')
//Amplify.Logger.LOG_LEVEL = "DEBUG";

Amplify.configure(aws_exports);

/*
Amplify.configure({
  Auth: {
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: 'localhost',
      // OPTIONAL - Cookie path
      path: '/totuslink',
      // OPTIONAL - Cookie expiration in days
      expires: 1,
      // OPTIONAL - Cookie secure flag
      secure: false
    },
  }
})
*/

applyPolyfills().then(() => {
  defineCustomElements(window);
});