<template>
  <div
    class="w-full h-10"
  >
    <button
      class="flex w-full h-full px-4 focus:outline-none"
      @click="toggleOptionsModal($event)"
    >
      <div
        v-if="selectedOption && selectedOption.group"
        class="flex flex-row h-5 px-2 my-auto mr-2 rounded-sm"
        :class="selectedGroupColor"
      >
        <div
          class="my-auto font-sans text-sm"
        >
          {{ selectedGroupTitle }}
        </div>
      </div>
    </button>
    <teleport to="#modals">
      <div 
        v-if="singleSelectionOptionsOpen"
      >
        <singleSelectOptions
          :open="singleSelectionOptionsOpen"
          @toggle-single-selection-options="catchToggleSingleSelectionOptionsEvent"
          :data="singleSelectionOptionsData"
          :position="singleSelectonOptionPosition"
          :confirmGroupDeletionModalOpen="confirmGroupDeletionModalOpen"
          @change-color="changeColorHandler"
          @delete-option="deleteOptionHandler"
          @update-single-selection-options-action="catchSingleSelectionOptionsAction"
          @change-option-name="changeOptionNameHandler"
        />
      </div>
    </teleport>
  </div>
</template>
<script>
import _ from 'lodash';
import singleSelectOptions from "../input/singleSelectOptions.vue"
import { ref, watch, onMounted, computed } from "vue"
import { colors } from "../../assets/colors"
import { gaHelpers } from "../../analytics/helper"

export default {
  name: "singleSelect",
  components: {
    singleSelectOptions,
  },
  props: {
    options: {
      type: Object,
      required: true
    },
    selectedOption: {
      type: Object,
      required: false
    },
    confirmGroupDeletionModalOpen: {
      type: Boolean,
      required: true
    }
  },
  emits:["updateSelectedOption", "deleteOption", "createOption", "changeColor", "changeOptionName", "updateSingleSelectionOptionsStatus" ],
  setup(props, context) {
    const singleSelectionOptionsOpen = ref(false)
    const singleSelectionOptionsData = ref({})
    const singleSelectonOptionPosition = ref({})
    const optionOpen = ref(false);
    const selectedGroupColor = ref(null)
    const toggleOptionsModal = ($event) => {
      if ( !singleSelectionOptionsOpen.value ) {
        const optionsData = {
          selectedOption: props.selectedOption,
          options: props.options
        }
        const position = getElementPosition($event.currentTarget);

        singleSelectionOptionsOpen.value = true;
        singleSelectionOptionsData.value = optionsData;
        singleSelectonOptionPosition.value = position;
        context.emit("updateSingleSelectionOptionsStatus", true);
        gaHelpers.interaction("open_group_options_panel", "group")
      }
    };

    const selectedGroupTitle = computed(() => {
      return props.selectedOption.group.title
    })

    const prepare = () => {
      selectedGroupColor.value = getSelectedGroupColor();
    };

    onMounted(prepare);

    const getSelectedGroupColor = () => {
      const colorIndex = colors.findIndex( e => e.name === props.selectedOption.color );
      if ( colorIndex !== -1 ){
        const color = colors[`${colorIndex}`];
        return `${color.bgCode} ${color.textCode}`
      } else {
        return "bg-sd-base1-brcyan text-sd-base02-black"
      }
    }

    const changeColorHandler = (message) => {
      context.emit("changeColor", message)
    }

    const deleteOptionHandler = (message) => {
      context.emit("deleteOption", message)
    }

    const changeOptionNameHandler = (message) => {
      context.emit("changeOptionName", message)
    }

    const catchToggleSingleSelectionOptionsEvent = () => {
      if ( singleSelectionOptionsOpen.value ){
        singleSelectionOptionsOpen.value = false;
        singleSelectionOptionsData.value = {};
        singleSelectonOptionPosition.value = {};
        context.emit("updateSingleSelectionOptionsStatus", false);
        gaHelpers.interaction("close_group_options_panel", "group")
      }
    }

    const catchSingleSelectionOptionsAction = (message) => {
      switch (message.action) {
        case "select": {
          context.emit("updateSelectedOption", message.option)
          break;
        }
        case "remove": {
          context.emit("updateSelectedOption", {});
          break;
        }
        case "create": {
          context.emit("createOption", message.option );
          break
        }
      }
    };
    
    
    watch(() => _.cloneDeep(props.selectedOption), (newValue) => {
      const optionsData = {
        selectedOption: newValue,
        options: props.options
      }
      singleSelectionOptionsData.value = optionsData;
      selectedGroupColor.value = getSelectedGroupColor();
    })

    return {
      selectedGroupTitle,
      optionOpen,
      prepare,
      toggleOptionsModal,
      singleSelectionOptionsOpen,
      singleSelectionOptionsData,
      singleSelectonOptionPosition,
      catchToggleSingleSelectionOptionsEvent,
      changeColorHandler,
      deleteOptionHandler,
      changeOptionNameHandler,
      catchSingleSelectionOptionsAction,
      selectedGroupColor
    }
  }
};

const getElementPosition = (element) => {
  const getPosition = element.getBoundingClientRect();
  const result = {
    x:getPosition.x,
    y:getPosition.y,
    width: getPosition.width,
    height: getPosition.height,
  };
  return result;
}
</script>
<style>
</style>