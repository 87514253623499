/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($owner: String) {
    onCreateUser(owner: $owner) {
      id
      isAdmin
      baseType
      username
      avatar {
        region
        bucket
        key
      }
      email
      emailVerified
      firstName
      lastName
      createdAt
      updatedAt
      onBoardingIsFinished
      onBoardingQuestionnaireIsFinished
      owner
      hologram {
        items {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        nextToken
      }
      likeHologram {
        items {
          id
          baseType
          hologramID
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      createdImage {
        items {
          id
          baseType
          createdByID
          contentType
          height
          width
          size
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      questionnaire {
        items {
          id
          userID
          questionnaireID
          isFinished
          answer
          createdAt
          updatedAt
        }
        nextToken
      }
      ownNode {
        items {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      ownEdge {
        items {
          id
          userID
          edgeID
          note
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      contributedHologram {
        items {
          id
          baseType
          hologramID
          contributorID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($owner: String) {
    onUpdateUser(owner: $owner) {
      id
      isAdmin
      baseType
      username
      avatar {
        region
        bucket
        key
      }
      email
      emailVerified
      firstName
      lastName
      createdAt
      updatedAt
      onBoardingIsFinished
      onBoardingQuestionnaireIsFinished
      owner
      hologram {
        items {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        nextToken
      }
      likeHologram {
        items {
          id
          baseType
          hologramID
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      createdImage {
        items {
          id
          baseType
          createdByID
          contentType
          height
          width
          size
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      questionnaire {
        items {
          id
          userID
          questionnaireID
          isFinished
          answer
          createdAt
          updatedAt
        }
        nextToken
      }
      ownNode {
        items {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      ownEdge {
        items {
          id
          userID
          edgeID
          note
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      contributedHologram {
        items {
          id
          baseType
          hologramID
          contributorID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($owner: String) {
    onDeleteUser(owner: $owner) {
      id
      isAdmin
      baseType
      username
      avatar {
        region
        bucket
        key
      }
      email
      emailVerified
      firstName
      lastName
      createdAt
      updatedAt
      onBoardingIsFinished
      onBoardingQuestionnaireIsFinished
      owner
      hologram {
        items {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        nextToken
      }
      likeHologram {
        items {
          id
          baseType
          hologramID
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      createdImage {
        items {
          id
          baseType
          createdByID
          contentType
          height
          width
          size
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      questionnaire {
        items {
          id
          userID
          questionnaireID
          isFinished
          answer
          createdAt
          updatedAt
        }
        nextToken
      }
      ownNode {
        items {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      ownEdge {
        items {
          id
          userID
          edgeID
          note
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      contributedHologram {
        items {
          id
          baseType
          hologramID
          contributorID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateHologram = /* GraphQL */ `
  subscription OnCreateHologram($owner: String, $contributorID: String) {
    onCreateHologram(owner: $owner, contributorID: $contributorID) {
      id
      baseType
      intro
      title
      graph
      createdByID
      contributorID
      accessPolicy
      weightID
      updatedAt
      createdAt
      owner
      action
      userPresent
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      block {
        items {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      like {
        items {
          id
          baseType
          hologramID
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      weight {
        id
        baseType
        weightType
        hologramNodeID
        userNodeID
        nodeID
        hologramID
        calculation
        value
        version
        accessPolicy
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        userNode {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        hologramNode {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
      }
      post {
        items {
          id
          baseType
          postType
          hologramID
          title
          content
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      group {
        items {
          id
          baseType
          hologramID
          groupID
          color
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      node {
        items {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      community {
        items {
          id
          hologramID
          communityID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      contributor {
        items {
          id
          baseType
          hologramID
          contributorID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      edge {
        items {
          id
          hologramID
          edgeID
          startNodeID
          endNodeID
          accessPolicy
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateHologram = /* GraphQL */ `
  subscription OnUpdateHologram($owner: String, $contributorID: String) {
    onUpdateHologram(owner: $owner, contributorID: $contributorID) {
      id
      baseType
      intro
      title
      graph
      createdByID
      contributorID
      accessPolicy
      weightID
      updatedAt
      createdAt
      owner
      action
      userPresent
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      block {
        items {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      like {
        items {
          id
          baseType
          hologramID
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      weight {
        id
        baseType
        weightType
        hologramNodeID
        userNodeID
        nodeID
        hologramID
        calculation
        value
        version
        accessPolicy
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        userNode {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        hologramNode {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
      }
      post {
        items {
          id
          baseType
          postType
          hologramID
          title
          content
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      group {
        items {
          id
          baseType
          hologramID
          groupID
          color
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      node {
        items {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      community {
        items {
          id
          hologramID
          communityID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      contributor {
        items {
          id
          baseType
          hologramID
          contributorID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      edge {
        items {
          id
          hologramID
          edgeID
          startNodeID
          endNodeID
          accessPolicy
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteHologram = /* GraphQL */ `
  subscription OnDeleteHologram($owner: String, $contributorID: String) {
    onDeleteHologram(owner: $owner, contributorID: $contributorID) {
      id
      baseType
      intro
      title
      graph
      createdByID
      contributorID
      accessPolicy
      weightID
      updatedAt
      createdAt
      owner
      action
      userPresent
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      block {
        items {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      like {
        items {
          id
          baseType
          hologramID
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      weight {
        id
        baseType
        weightType
        hologramNodeID
        userNodeID
        nodeID
        hologramID
        calculation
        value
        version
        accessPolicy
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        userNode {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        hologramNode {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
      }
      post {
        items {
          id
          baseType
          postType
          hologramID
          title
          content
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      group {
        items {
          id
          baseType
          hologramID
          groupID
          color
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      node {
        items {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      community {
        items {
          id
          hologramID
          communityID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      contributor {
        items {
          id
          baseType
          hologramID
          contributorID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      edge {
        items {
          id
          hologramID
          edgeID
          startNodeID
          endNodeID
          accessPolicy
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateBlock = /* GraphQL */ `
  subscription OnCreateBlock($owner: String) {
    onCreateBlock(owner: $owner) {
      id
      baseType
      belongTo
      blockType
      content
      html
      url
      title
      level
      nodify
      accessPolicy
      parentBlockID
      hologramID
      postID
      commentID
      nodeID
      edgeID
      groupID
      ownerUserNodeID
      ownerUserEdgeID
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      parentBlock {
        id
        baseType
        belongTo
        blockType
        content
        html
        url
        title
        level
        nodify
        accessPolicy
        parentBlockID
        hologramID
        postID
        commentID
        nodeID
        edgeID
        groupID
        ownerUserNodeID
        ownerUserEdgeID
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        parentBlock {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        edge {
          baseType
          id
          startNodeID
          endNodeID
          createdAt
          updatedAt
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        group {
          baseType
          id
          title
          createdByID
          createdAt
          updatedAt
        }
        post {
          id
          baseType
          postType
          hologramID
          title
          content
          owner
          createdAt
          updatedAt
        }
        comment {
          id
          baseType
          postID
          content
          parentCommentID
          owner
          createdAt
          updatedAt
        }
        ownerUserNode {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        ownerUserEdge {
          id
          userID
          edgeID
          note
          owner
          createdAt
          updatedAt
        }
      }
      edge {
        baseType
        id
        startNodeID
        endNodeID
        createdAt
        updatedAt
        block {
          nextToken
        }
        startNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        endNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      group {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          nextToken
        }
        hologramNode {
          nextToken
        }
        node {
          nextToken
        }
        edge {
          nextToken
        }
      }
      post {
        id
        baseType
        postType
        hologramID
        title
        content
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        block {
          nextToken
        }
        comment {
          nextToken
        }
      }
      comment {
        id
        baseType
        postID
        content
        parentCommentID
        owner
        createdAt
        updatedAt
        block {
          nextToken
        }
        post {
          id
          baseType
          postType
          hologramID
          title
          content
          owner
          createdAt
          updatedAt
        }
        parentComment {
          id
          baseType
          postID
          content
          parentCommentID
          owner
          createdAt
          updatedAt
        }
        childComment {
          nextToken
        }
      }
      ownerUserNode {
        id
        userID
        nodeID
        title
        note
        weightID
        version
        owner
        createdAt
        updatedAt
        user {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
      }
      ownerUserEdge {
        id
        userID
        edgeID
        note
        owner
        createdAt
        updatedAt
        user {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        edge {
          baseType
          id
          startNodeID
          endNodeID
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onUpdateBlock = /* GraphQL */ `
  subscription OnUpdateBlock($owner: String) {
    onUpdateBlock(owner: $owner) {
      id
      baseType
      belongTo
      blockType
      content
      html
      url
      title
      level
      nodify
      accessPolicy
      parentBlockID
      hologramID
      postID
      commentID
      nodeID
      edgeID
      groupID
      ownerUserNodeID
      ownerUserEdgeID
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      parentBlock {
        id
        baseType
        belongTo
        blockType
        content
        html
        url
        title
        level
        nodify
        accessPolicy
        parentBlockID
        hologramID
        postID
        commentID
        nodeID
        edgeID
        groupID
        ownerUserNodeID
        ownerUserEdgeID
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        parentBlock {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        edge {
          baseType
          id
          startNodeID
          endNodeID
          createdAt
          updatedAt
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        group {
          baseType
          id
          title
          createdByID
          createdAt
          updatedAt
        }
        post {
          id
          baseType
          postType
          hologramID
          title
          content
          owner
          createdAt
          updatedAt
        }
        comment {
          id
          baseType
          postID
          content
          parentCommentID
          owner
          createdAt
          updatedAt
        }
        ownerUserNode {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        ownerUserEdge {
          id
          userID
          edgeID
          note
          owner
          createdAt
          updatedAt
        }
      }
      edge {
        baseType
        id
        startNodeID
        endNodeID
        createdAt
        updatedAt
        block {
          nextToken
        }
        startNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        endNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      group {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          nextToken
        }
        hologramNode {
          nextToken
        }
        node {
          nextToken
        }
        edge {
          nextToken
        }
      }
      post {
        id
        baseType
        postType
        hologramID
        title
        content
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        block {
          nextToken
        }
        comment {
          nextToken
        }
      }
      comment {
        id
        baseType
        postID
        content
        parentCommentID
        owner
        createdAt
        updatedAt
        block {
          nextToken
        }
        post {
          id
          baseType
          postType
          hologramID
          title
          content
          owner
          createdAt
          updatedAt
        }
        parentComment {
          id
          baseType
          postID
          content
          parentCommentID
          owner
          createdAt
          updatedAt
        }
        childComment {
          nextToken
        }
      }
      ownerUserNode {
        id
        userID
        nodeID
        title
        note
        weightID
        version
        owner
        createdAt
        updatedAt
        user {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
      }
      ownerUserEdge {
        id
        userID
        edgeID
        note
        owner
        createdAt
        updatedAt
        user {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        edge {
          baseType
          id
          startNodeID
          endNodeID
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onDeleteBlock = /* GraphQL */ `
  subscription OnDeleteBlock($owner: String) {
    onDeleteBlock(owner: $owner) {
      id
      baseType
      belongTo
      blockType
      content
      html
      url
      title
      level
      nodify
      accessPolicy
      parentBlockID
      hologramID
      postID
      commentID
      nodeID
      edgeID
      groupID
      ownerUserNodeID
      ownerUserEdgeID
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      parentBlock {
        id
        baseType
        belongTo
        blockType
        content
        html
        url
        title
        level
        nodify
        accessPolicy
        parentBlockID
        hologramID
        postID
        commentID
        nodeID
        edgeID
        groupID
        ownerUserNodeID
        ownerUserEdgeID
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        parentBlock {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        edge {
          baseType
          id
          startNodeID
          endNodeID
          createdAt
          updatedAt
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        group {
          baseType
          id
          title
          createdByID
          createdAt
          updatedAt
        }
        post {
          id
          baseType
          postType
          hologramID
          title
          content
          owner
          createdAt
          updatedAt
        }
        comment {
          id
          baseType
          postID
          content
          parentCommentID
          owner
          createdAt
          updatedAt
        }
        ownerUserNode {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        ownerUserEdge {
          id
          userID
          edgeID
          note
          owner
          createdAt
          updatedAt
        }
      }
      edge {
        baseType
        id
        startNodeID
        endNodeID
        createdAt
        updatedAt
        block {
          nextToken
        }
        startNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        endNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      group {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          nextToken
        }
        hologramNode {
          nextToken
        }
        node {
          nextToken
        }
        edge {
          nextToken
        }
      }
      post {
        id
        baseType
        postType
        hologramID
        title
        content
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        block {
          nextToken
        }
        comment {
          nextToken
        }
      }
      comment {
        id
        baseType
        postID
        content
        parentCommentID
        owner
        createdAt
        updatedAt
        block {
          nextToken
        }
        post {
          id
          baseType
          postType
          hologramID
          title
          content
          owner
          createdAt
          updatedAt
        }
        parentComment {
          id
          baseType
          postID
          content
          parentCommentID
          owner
          createdAt
          updatedAt
        }
        childComment {
          nextToken
        }
      }
      ownerUserNode {
        id
        userID
        nodeID
        title
        note
        weightID
        version
        owner
        createdAt
        updatedAt
        user {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
      }
      ownerUserEdge {
        id
        userID
        edgeID
        note
        owner
        createdAt
        updatedAt
        user {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        edge {
          baseType
          id
          startNodeID
          endNodeID
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onCreateHighlight = /* GraphQL */ `
  subscription OnCreateHighlight($owner: String) {
    onCreateHighlight(owner: $owner) {
      id
      baseType
      content
      nodeID
      owner
      createdAt
      updatedAt
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateHighlight = /* GraphQL */ `
  subscription OnUpdateHighlight($owner: String) {
    onUpdateHighlight(owner: $owner) {
      id
      baseType
      content
      nodeID
      owner
      createdAt
      updatedAt
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteHighlight = /* GraphQL */ `
  subscription OnDeleteHighlight($owner: String) {
    onDeleteHighlight(owner: $owner) {
      id
      baseType
      content
      nodeID
      owner
      createdAt
      updatedAt
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
    }
  }
`;
export const onCreateHologramLike = /* GraphQL */ `
  subscription OnCreateHologramLike($owner: String) {
    onCreateHologramLike(owner: $owner) {
      id
      baseType
      hologramID
      createdByID
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateHologramLike = /* GraphQL */ `
  subscription OnUpdateHologramLike($owner: String) {
    onUpdateHologramLike(owner: $owner) {
      id
      baseType
      hologramID
      createdByID
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteHologramLike = /* GraphQL */ `
  subscription OnDeleteHologramLike($owner: String) {
    onDeleteHologramLike(owner: $owner) {
      id
      baseType
      hologramID
      createdByID
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
    }
  }
`;
export const onCreateDomain = /* GraphQL */ `
  subscription OnCreateDomain {
    onCreateDomain {
      baseType
      hostName
      id
      updatedAt
      createdAt
      node {
        items {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateDomain = /* GraphQL */ `
  subscription OnUpdateDomain {
    onUpdateDomain {
      baseType
      hostName
      id
      updatedAt
      createdAt
      node {
        items {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteDomain = /* GraphQL */ `
  subscription OnDeleteDomain {
    onDeleteDomain {
      baseType
      hostName
      id
      updatedAt
      createdAt
      node {
        items {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateEdge = /* GraphQL */ `
  subscription OnCreateEdge {
    onCreateEdge {
      baseType
      id
      startNodeID
      endNodeID
      createdAt
      updatedAt
      block {
        items {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      startNode {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      endNode {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      user {
        items {
          id
          userID
          edgeID
          note
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      hologram {
        items {
          id
          hologramID
          edgeID
          startNodeID
          endNodeID
          accessPolicy
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      group {
        items {
          id
          groupID
          edgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateEdge = /* GraphQL */ `
  subscription OnUpdateEdge {
    onUpdateEdge {
      baseType
      id
      startNodeID
      endNodeID
      createdAt
      updatedAt
      block {
        items {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      startNode {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      endNode {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      user {
        items {
          id
          userID
          edgeID
          note
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      hologram {
        items {
          id
          hologramID
          edgeID
          startNodeID
          endNodeID
          accessPolicy
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      group {
        items {
          id
          groupID
          edgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteEdge = /* GraphQL */ `
  subscription OnDeleteEdge {
    onDeleteEdge {
      baseType
      id
      startNodeID
      endNodeID
      createdAt
      updatedAt
      block {
        items {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      startNode {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      endNode {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      user {
        items {
          id
          userID
          edgeID
          note
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      hologram {
        items {
          id
          hologramID
          edgeID
          startNodeID
          endNodeID
          accessPolicy
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      group {
        items {
          id
          groupID
          edgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateNode = /* GraphQL */ `
  subscription OnCreateNode {
    onCreateNode {
      baseType
      id
      url
      title
      wordCounts
      favicon
      domainID
      weightID
      createdAt
      updatedAt
      block {
        items {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      highlight {
        items {
          id
          baseType
          content
          nodeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      domain {
        baseType
        hostName
        id
        updatedAt
        createdAt
        node {
          nextToken
        }
      }
      inEdges {
        items {
          baseType
          id
          startNodeID
          endNodeID
          createdAt
          updatedAt
        }
        nextToken
      }
      outEdges {
        items {
          baseType
          id
          startNodeID
          endNodeID
          createdAt
          updatedAt
        }
        nextToken
      }
      weight {
        id
        baseType
        weightType
        hologramNodeID
        userNodeID
        nodeID
        hologramID
        calculation
        value
        version
        accessPolicy
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        userNode {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        hologramNode {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
      }
      user {
        items {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      hologram {
        items {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      group {
        items {
          id
          groupID
          nodeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateNode = /* GraphQL */ `
  subscription OnUpdateNode {
    onUpdateNode {
      baseType
      id
      url
      title
      wordCounts
      favicon
      domainID
      weightID
      createdAt
      updatedAt
      block {
        items {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      highlight {
        items {
          id
          baseType
          content
          nodeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      domain {
        baseType
        hostName
        id
        updatedAt
        createdAt
        node {
          nextToken
        }
      }
      inEdges {
        items {
          baseType
          id
          startNodeID
          endNodeID
          createdAt
          updatedAt
        }
        nextToken
      }
      outEdges {
        items {
          baseType
          id
          startNodeID
          endNodeID
          createdAt
          updatedAt
        }
        nextToken
      }
      weight {
        id
        baseType
        weightType
        hologramNodeID
        userNodeID
        nodeID
        hologramID
        calculation
        value
        version
        accessPolicy
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        userNode {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        hologramNode {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
      }
      user {
        items {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      hologram {
        items {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      group {
        items {
          id
          groupID
          nodeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteNode = /* GraphQL */ `
  subscription OnDeleteNode {
    onDeleteNode {
      baseType
      id
      url
      title
      wordCounts
      favicon
      domainID
      weightID
      createdAt
      updatedAt
      block {
        items {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      highlight {
        items {
          id
          baseType
          content
          nodeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      domain {
        baseType
        hostName
        id
        updatedAt
        createdAt
        node {
          nextToken
        }
      }
      inEdges {
        items {
          baseType
          id
          startNodeID
          endNodeID
          createdAt
          updatedAt
        }
        nextToken
      }
      outEdges {
        items {
          baseType
          id
          startNodeID
          endNodeID
          createdAt
          updatedAt
        }
        nextToken
      }
      weight {
        id
        baseType
        weightType
        hologramNodeID
        userNodeID
        nodeID
        hologramID
        calculation
        value
        version
        accessPolicy
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        userNode {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        hologramNode {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
      }
      user {
        items {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      hologram {
        items {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      group {
        items {
          id
          groupID
          nodeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateWeight = /* GraphQL */ `
  subscription OnCreateWeight($owner: String) {
    onCreateWeight(owner: $owner) {
      id
      baseType
      weightType
      hologramNodeID
      userNodeID
      nodeID
      hologramID
      calculation
      value
      version
      accessPolicy
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      userNode {
        id
        userID
        nodeID
        title
        note
        weightID
        version
        owner
        createdAt
        updatedAt
        user {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
      }
      hologramNode {
        id
        hologramID
        title
        nodeID
        accessPolicy
        weightID
        createdByID
        owner
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        group {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateWeight = /* GraphQL */ `
  subscription OnUpdateWeight($owner: String) {
    onUpdateWeight(owner: $owner) {
      id
      baseType
      weightType
      hologramNodeID
      userNodeID
      nodeID
      hologramID
      calculation
      value
      version
      accessPolicy
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      userNode {
        id
        userID
        nodeID
        title
        note
        weightID
        version
        owner
        createdAt
        updatedAt
        user {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
      }
      hologramNode {
        id
        hologramID
        title
        nodeID
        accessPolicy
        weightID
        createdByID
        owner
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        group {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteWeight = /* GraphQL */ `
  subscription OnDeleteWeight($owner: String) {
    onDeleteWeight(owner: $owner) {
      id
      baseType
      weightType
      hologramNodeID
      userNodeID
      nodeID
      hologramID
      calculation
      value
      version
      accessPolicy
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      userNode {
        id
        userID
        nodeID
        title
        note
        weightID
        version
        owner
        createdAt
        updatedAt
        user {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
      }
      hologramNode {
        id
        hologramID
        title
        nodeID
        accessPolicy
        weightID
        createdByID
        owner
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        group {
          nextToken
        }
      }
    }
  }
`;
export const onCreateImage = /* GraphQL */ `
  subscription OnCreateImage($owner: String) {
    onCreateImage(owner: $owner) {
      id
      baseType
      createdByID
      fullsize {
        region
        bucket
        key
      }
      thumbnail {
        region
        bucket
        key
      }
      contentType
      height
      width
      size
      owner
      createdAt
      updatedAt
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateImage = /* GraphQL */ `
  subscription OnUpdateImage($owner: String) {
    onUpdateImage(owner: $owner) {
      id
      baseType
      createdByID
      fullsize {
        region
        bucket
        key
      }
      thumbnail {
        region
        bucket
        key
      }
      contentType
      height
      width
      size
      owner
      createdAt
      updatedAt
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteImage = /* GraphQL */ `
  subscription OnDeleteImage($owner: String) {
    onDeleteImage(owner: $owner) {
      id
      baseType
      createdByID
      fullsize {
        region
        bucket
        key
      }
      thumbnail {
        region
        bucket
        key
      }
      contentType
      height
      width
      size
      owner
      createdAt
      updatedAt
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
    }
  }
`;
export const onCreateGroup = /* GraphQL */ `
  subscription OnCreateGroup {
    onCreateGroup {
      baseType
      id
      title
      createdByID
      createdAt
      updatedAt
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      hologram {
        items {
          id
          baseType
          hologramID
          groupID
          color
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      hologramNode {
        items {
          id
          baseType
          owner
          hologramNodeID
          groupID
          createdAt
          updatedAt
        }
        nextToken
      }
      node {
        items {
          id
          groupID
          nodeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      edge {
        items {
          id
          groupID
          edgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateGroup = /* GraphQL */ `
  subscription OnUpdateGroup {
    onUpdateGroup {
      baseType
      id
      title
      createdByID
      createdAt
      updatedAt
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      hologram {
        items {
          id
          baseType
          hologramID
          groupID
          color
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      hologramNode {
        items {
          id
          baseType
          owner
          hologramNodeID
          groupID
          createdAt
          updatedAt
        }
        nextToken
      }
      node {
        items {
          id
          groupID
          nodeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      edge {
        items {
          id
          groupID
          edgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteGroup = /* GraphQL */ `
  subscription OnDeleteGroup {
    onDeleteGroup {
      baseType
      id
      title
      createdByID
      createdAt
      updatedAt
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      hologram {
        items {
          id
          baseType
          hologramID
          groupID
          color
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      hologramNode {
        items {
          id
          baseType
          owner
          hologramNodeID
          groupID
          createdAt
          updatedAt
        }
        nextToken
      }
      node {
        items {
          id
          groupID
          nodeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      edge {
        items {
          id
          groupID
          edgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreatePath = /* GraphQL */ `
  subscription OnCreatePath($owner: String) {
    onCreatePath(owner: $owner) {
      baseType
      id
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePath = /* GraphQL */ `
  subscription OnUpdatePath($owner: String) {
    onUpdatePath(owner: $owner) {
      baseType
      id
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePath = /* GraphQL */ `
  subscription OnDeletePath($owner: String) {
    onDeletePath(owner: $owner) {
      baseType
      id
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCommunity = /* GraphQL */ `
  subscription OnCreateCommunity($owner: String) {
    onCreateCommunity(owner: $owner) {
      baseType
      id
      name
      owner
      createdAt
      updatedAt
      hologram {
        items {
          id
          hologramID
          communityID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateCommunity = /* GraphQL */ `
  subscription OnUpdateCommunity($owner: String) {
    onUpdateCommunity(owner: $owner) {
      baseType
      id
      name
      owner
      createdAt
      updatedAt
      hologram {
        items {
          id
          hologramID
          communityID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteCommunity = /* GraphQL */ `
  subscription OnDeleteCommunity($owner: String) {
    onDeleteCommunity(owner: $owner) {
      baseType
      id
      name
      owner
      createdAt
      updatedAt
      hologram {
        items {
          id
          hologramID
          communityID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreatePost = /* GraphQL */ `
  subscription OnCreatePost($owner: String) {
    onCreatePost(owner: $owner) {
      id
      baseType
      postType
      hologramID
      title
      content
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      block {
        items {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      comment {
        items {
          id
          baseType
          postID
          content
          parentCommentID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdatePost = /* GraphQL */ `
  subscription OnUpdatePost($owner: String) {
    onUpdatePost(owner: $owner) {
      id
      baseType
      postType
      hologramID
      title
      content
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      block {
        items {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      comment {
        items {
          id
          baseType
          postID
          content
          parentCommentID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeletePost = /* GraphQL */ `
  subscription OnDeletePost($owner: String) {
    onDeletePost(owner: $owner) {
      id
      baseType
      postType
      hologramID
      title
      content
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      block {
        items {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      comment {
        items {
          id
          baseType
          postID
          content
          parentCommentID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateComment = /* GraphQL */ `
  subscription OnCreateComment($owner: String) {
    onCreateComment(owner: $owner) {
      id
      baseType
      postID
      content
      parentCommentID
      owner
      createdAt
      updatedAt
      block {
        items {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      post {
        id
        baseType
        postType
        hologramID
        title
        content
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        block {
          nextToken
        }
        comment {
          nextToken
        }
      }
      parentComment {
        id
        baseType
        postID
        content
        parentCommentID
        owner
        createdAt
        updatedAt
        block {
          nextToken
        }
        post {
          id
          baseType
          postType
          hologramID
          title
          content
          owner
          createdAt
          updatedAt
        }
        parentComment {
          id
          baseType
          postID
          content
          parentCommentID
          owner
          createdAt
          updatedAt
        }
        childComment {
          nextToken
        }
      }
      childComment {
        items {
          id
          baseType
          postID
          content
          parentCommentID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateComment = /* GraphQL */ `
  subscription OnUpdateComment($owner: String) {
    onUpdateComment(owner: $owner) {
      id
      baseType
      postID
      content
      parentCommentID
      owner
      createdAt
      updatedAt
      block {
        items {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      post {
        id
        baseType
        postType
        hologramID
        title
        content
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        block {
          nextToken
        }
        comment {
          nextToken
        }
      }
      parentComment {
        id
        baseType
        postID
        content
        parentCommentID
        owner
        createdAt
        updatedAt
        block {
          nextToken
        }
        post {
          id
          baseType
          postType
          hologramID
          title
          content
          owner
          createdAt
          updatedAt
        }
        parentComment {
          id
          baseType
          postID
          content
          parentCommentID
          owner
          createdAt
          updatedAt
        }
        childComment {
          nextToken
        }
      }
      childComment {
        items {
          id
          baseType
          postID
          content
          parentCommentID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteComment = /* GraphQL */ `
  subscription OnDeleteComment($owner: String) {
    onDeleteComment(owner: $owner) {
      id
      baseType
      postID
      content
      parentCommentID
      owner
      createdAt
      updatedAt
      block {
        items {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      post {
        id
        baseType
        postType
        hologramID
        title
        content
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        block {
          nextToken
        }
        comment {
          nextToken
        }
      }
      parentComment {
        id
        baseType
        postID
        content
        parentCommentID
        owner
        createdAt
        updatedAt
        block {
          nextToken
        }
        post {
          id
          baseType
          postType
          hologramID
          title
          content
          owner
          createdAt
          updatedAt
        }
        parentComment {
          id
          baseType
          postID
          content
          parentCommentID
          owner
          createdAt
          updatedAt
        }
        childComment {
          nextToken
        }
      }
      childComment {
        items {
          id
          baseType
          postID
          content
          parentCommentID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateQuestion = /* GraphQL */ `
  subscription OnCreateQuestion {
    onCreateQuestion {
      id
      title
      context
      options
      type
      subType
      createdAt
      updatedAt
      questionnaire {
        items {
          id
          questionnaireID
          questionID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateQuestion = /* GraphQL */ `
  subscription OnUpdateQuestion {
    onUpdateQuestion {
      id
      title
      context
      options
      type
      subType
      createdAt
      updatedAt
      questionnaire {
        items {
          id
          questionnaireID
          questionID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteQuestion = /* GraphQL */ `
  subscription OnDeleteQuestion {
    onDeleteQuestion {
      id
      title
      context
      options
      type
      subType
      createdAt
      updatedAt
      questionnaire {
        items {
          id
          questionnaireID
          questionID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateQuestionnaire = /* GraphQL */ `
  subscription OnCreateQuestionnaire {
    onCreateQuestionnaire {
      id
      title
      context
      triggerStage
      questionsOrder
      ending
      createdAt
      updatedAt
      user {
        items {
          id
          userID
          questionnaireID
          isFinished
          answer
          createdAt
          updatedAt
        }
        nextToken
      }
      question {
        items {
          id
          questionnaireID
          questionID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateQuestionnaire = /* GraphQL */ `
  subscription OnUpdateQuestionnaire {
    onUpdateQuestionnaire {
      id
      title
      context
      triggerStage
      questionsOrder
      ending
      createdAt
      updatedAt
      user {
        items {
          id
          userID
          questionnaireID
          isFinished
          answer
          createdAt
          updatedAt
        }
        nextToken
      }
      question {
        items {
          id
          questionnaireID
          questionID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteQuestionnaire = /* GraphQL */ `
  subscription OnDeleteQuestionnaire {
    onDeleteQuestionnaire {
      id
      title
      context
      triggerStage
      questionsOrder
      ending
      createdAt
      updatedAt
      user {
        items {
          id
          userID
          questionnaireID
          isFinished
          answer
          createdAt
          updatedAt
        }
        nextToken
      }
      question {
        items {
          id
          questionnaireID
          questionID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateUserQuestionnaire = /* GraphQL */ `
  subscription OnCreateUserQuestionnaire {
    onCreateUserQuestionnaire {
      id
      userID
      questionnaireID
      isFinished
      answer
      createdAt
      updatedAt
      user {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      questionnaire {
        id
        title
        context
        triggerStage
        questionsOrder
        ending
        createdAt
        updatedAt
        user {
          nextToken
        }
        question {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateUserQuestionnaire = /* GraphQL */ `
  subscription OnUpdateUserQuestionnaire {
    onUpdateUserQuestionnaire {
      id
      userID
      questionnaireID
      isFinished
      answer
      createdAt
      updatedAt
      user {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      questionnaire {
        id
        title
        context
        triggerStage
        questionsOrder
        ending
        createdAt
        updatedAt
        user {
          nextToken
        }
        question {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteUserQuestionnaire = /* GraphQL */ `
  subscription OnDeleteUserQuestionnaire {
    onDeleteUserQuestionnaire {
      id
      userID
      questionnaireID
      isFinished
      answer
      createdAt
      updatedAt
      user {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      questionnaire {
        id
        title
        context
        triggerStage
        questionsOrder
        ending
        createdAt
        updatedAt
        user {
          nextToken
        }
        question {
          nextToken
        }
      }
    }
  }
`;
export const onCreateQuestionnaireQuestion = /* GraphQL */ `
  subscription OnCreateQuestionnaireQuestion {
    onCreateQuestionnaireQuestion {
      id
      questionnaireID
      questionID
      createdAt
      updatedAt
      question {
        id
        title
        context
        options
        type
        subType
        createdAt
        updatedAt
        questionnaire {
          nextToken
        }
      }
      questionnaire {
        id
        title
        context
        triggerStage
        questionsOrder
        ending
        createdAt
        updatedAt
        user {
          nextToken
        }
        question {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateQuestionnaireQuestion = /* GraphQL */ `
  subscription OnUpdateQuestionnaireQuestion {
    onUpdateQuestionnaireQuestion {
      id
      questionnaireID
      questionID
      createdAt
      updatedAt
      question {
        id
        title
        context
        options
        type
        subType
        createdAt
        updatedAt
        questionnaire {
          nextToken
        }
      }
      questionnaire {
        id
        title
        context
        triggerStage
        questionsOrder
        ending
        createdAt
        updatedAt
        user {
          nextToken
        }
        question {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteQuestionnaireQuestion = /* GraphQL */ `
  subscription OnDeleteQuestionnaireQuestion {
    onDeleteQuestionnaireQuestion {
      id
      questionnaireID
      questionID
      createdAt
      updatedAt
      question {
        id
        title
        context
        options
        type
        subType
        createdAt
        updatedAt
        questionnaire {
          nextToken
        }
      }
      questionnaire {
        id
        title
        context
        triggerStage
        questionsOrder
        ending
        createdAt
        updatedAt
        user {
          nextToken
        }
        question {
          nextToken
        }
      }
    }
  }
`;
export const onCreateIssue = /* GraphQL */ `
  subscription OnCreateIssue {
    onCreateIssue {
      id
      baseType
      issueType
      url
      description
      createdByID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateIssue = /* GraphQL */ `
  subscription OnUpdateIssue {
    onUpdateIssue {
      id
      baseType
      issueType
      url
      description
      createdByID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteIssue = /* GraphQL */ `
  subscription OnDeleteIssue {
    onDeleteIssue {
      id
      baseType
      issueType
      url
      description
      createdByID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUserNode = /* GraphQL */ `
  subscription OnCreateUserNode($owner: String) {
    onCreateUserNode(owner: $owner) {
      id
      userID
      nodeID
      title
      note
      weightID
      version
      owner
      createdAt
      updatedAt
      user {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      weight {
        id
        baseType
        weightType
        hologramNodeID
        userNodeID
        nodeID
        hologramID
        calculation
        value
        version
        accessPolicy
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        userNode {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        hologramNode {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onUpdateUserNode = /* GraphQL */ `
  subscription OnUpdateUserNode($owner: String) {
    onUpdateUserNode(owner: $owner) {
      id
      userID
      nodeID
      title
      note
      weightID
      version
      owner
      createdAt
      updatedAt
      user {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      weight {
        id
        baseType
        weightType
        hologramNodeID
        userNodeID
        nodeID
        hologramID
        calculation
        value
        version
        accessPolicy
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        userNode {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        hologramNode {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onDeleteUserNode = /* GraphQL */ `
  subscription OnDeleteUserNode($owner: String) {
    onDeleteUserNode(owner: $owner) {
      id
      userID
      nodeID
      title
      note
      weightID
      version
      owner
      createdAt
      updatedAt
      user {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      weight {
        id
        baseType
        weightType
        hologramNodeID
        userNodeID
        nodeID
        hologramID
        calculation
        value
        version
        accessPolicy
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        userNode {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        hologramNode {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onCreateUserEdge = /* GraphQL */ `
  subscription OnCreateUserEdge($owner: String) {
    onCreateUserEdge(owner: $owner) {
      id
      userID
      edgeID
      note
      owner
      createdAt
      updatedAt
      user {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      edge {
        baseType
        id
        startNodeID
        endNodeID
        createdAt
        updatedAt
        block {
          nextToken
        }
        startNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        endNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateUserEdge = /* GraphQL */ `
  subscription OnUpdateUserEdge($owner: String) {
    onUpdateUserEdge(owner: $owner) {
      id
      userID
      edgeID
      note
      owner
      createdAt
      updatedAt
      user {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      edge {
        baseType
        id
        startNodeID
        endNodeID
        createdAt
        updatedAt
        block {
          nextToken
        }
        startNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        endNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteUserEdge = /* GraphQL */ `
  subscription OnDeleteUserEdge($owner: String) {
    onDeleteUserEdge(owner: $owner) {
      id
      userID
      edgeID
      note
      owner
      createdAt
      updatedAt
      user {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      edge {
        baseType
        id
        startNodeID
        endNodeID
        createdAt
        updatedAt
        block {
          nextToken
        }
        startNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        endNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
    }
  }
`;
export const onCreateHologramGroup = /* GraphQL */ `
  subscription OnCreateHologramGroup {
    onCreateHologramGroup {
      id
      baseType
      hologramID
      groupID
      color
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      group {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          nextToken
        }
        hologramNode {
          nextToken
        }
        node {
          nextToken
        }
        edge {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateHologramGroup = /* GraphQL */ `
  subscription OnUpdateHologramGroup {
    onUpdateHologramGroup {
      id
      baseType
      hologramID
      groupID
      color
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      group {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          nextToken
        }
        hologramNode {
          nextToken
        }
        node {
          nextToken
        }
        edge {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteHologramGroup = /* GraphQL */ `
  subscription OnDeleteHologramGroup {
    onDeleteHologramGroup {
      id
      baseType
      hologramID
      groupID
      color
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      group {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          nextToken
        }
        hologramNode {
          nextToken
        }
        node {
          nextToken
        }
        edge {
          nextToken
        }
      }
    }
  }
`;
export const onCreateHologramNode = /* GraphQL */ `
  subscription OnCreateHologramNode {
    onCreateHologramNode {
      id
      hologramID
      title
      nodeID
      accessPolicy
      weightID
      createdByID
      owner
      createdAt
      updatedAt
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      weight {
        id
        baseType
        weightType
        hologramNodeID
        userNodeID
        nodeID
        hologramID
        calculation
        value
        version
        accessPolicy
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        userNode {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        hologramNode {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
      }
      group {
        items {
          id
          baseType
          owner
          hologramNodeID
          groupID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateHologramNode = /* GraphQL */ `
  subscription OnUpdateHologramNode {
    onUpdateHologramNode {
      id
      hologramID
      title
      nodeID
      accessPolicy
      weightID
      createdByID
      owner
      createdAt
      updatedAt
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      weight {
        id
        baseType
        weightType
        hologramNodeID
        userNodeID
        nodeID
        hologramID
        calculation
        value
        version
        accessPolicy
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        userNode {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        hologramNode {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
      }
      group {
        items {
          id
          baseType
          owner
          hologramNodeID
          groupID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteHologramNode = /* GraphQL */ `
  subscription OnDeleteHologramNode {
    onDeleteHologramNode {
      id
      hologramID
      title
      nodeID
      accessPolicy
      weightID
      createdByID
      owner
      createdAt
      updatedAt
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      weight {
        id
        baseType
        weightType
        hologramNodeID
        userNodeID
        nodeID
        hologramID
        calculation
        value
        version
        accessPolicy
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        userNode {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        hologramNode {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
      }
      group {
        items {
          id
          baseType
          owner
          hologramNodeID
          groupID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateHologramNodeGroup = /* GraphQL */ `
  subscription OnCreateHologramNodeGroup {
    onCreateHologramNodeGroup {
      id
      baseType
      owner
      hologramNodeID
      groupID
      createdAt
      updatedAt
      group {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          nextToken
        }
        hologramNode {
          nextToken
        }
        node {
          nextToken
        }
        edge {
          nextToken
        }
      }
      hologramNode {
        id
        hologramID
        title
        nodeID
        accessPolicy
        weightID
        createdByID
        owner
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        group {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateHologramNodeGroup = /* GraphQL */ `
  subscription OnUpdateHologramNodeGroup {
    onUpdateHologramNodeGroup {
      id
      baseType
      owner
      hologramNodeID
      groupID
      createdAt
      updatedAt
      group {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          nextToken
        }
        hologramNode {
          nextToken
        }
        node {
          nextToken
        }
        edge {
          nextToken
        }
      }
      hologramNode {
        id
        hologramID
        title
        nodeID
        accessPolicy
        weightID
        createdByID
        owner
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        group {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteHologramNodeGroup = /* GraphQL */ `
  subscription OnDeleteHologramNodeGroup {
    onDeleteHologramNodeGroup {
      id
      baseType
      owner
      hologramNodeID
      groupID
      createdAt
      updatedAt
      group {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          nextToken
        }
        hologramNode {
          nextToken
        }
        node {
          nextToken
        }
        edge {
          nextToken
        }
      }
      hologramNode {
        id
        hologramID
        title
        nodeID
        accessPolicy
        weightID
        createdByID
        owner
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        group {
          nextToken
        }
      }
    }
  }
`;
export const onCreateHologramCommunity = /* GraphQL */ `
  subscription OnCreateHologramCommunity {
    onCreateHologramCommunity {
      id
      hologramID
      communityID
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      community {
        baseType
        id
        name
        owner
        createdAt
        updatedAt
        hologram {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateHologramCommunity = /* GraphQL */ `
  subscription OnUpdateHologramCommunity {
    onUpdateHologramCommunity {
      id
      hologramID
      communityID
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      community {
        baseType
        id
        name
        owner
        createdAt
        updatedAt
        hologram {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteHologramCommunity = /* GraphQL */ `
  subscription OnDeleteHologramCommunity {
    onDeleteHologramCommunity {
      id
      hologramID
      communityID
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      community {
        baseType
        id
        name
        owner
        createdAt
        updatedAt
        hologram {
          nextToken
        }
      }
    }
  }
`;
export const onCreateGroupNode = /* GraphQL */ `
  subscription OnCreateGroupNode {
    onCreateGroupNode {
      id
      groupID
      nodeID
      owner
      createdAt
      updatedAt
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      group {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          nextToken
        }
        hologramNode {
          nextToken
        }
        node {
          nextToken
        }
        edge {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateGroupNode = /* GraphQL */ `
  subscription OnUpdateGroupNode {
    onUpdateGroupNode {
      id
      groupID
      nodeID
      owner
      createdAt
      updatedAt
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      group {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          nextToken
        }
        hologramNode {
          nextToken
        }
        node {
          nextToken
        }
        edge {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteGroupNode = /* GraphQL */ `
  subscription OnDeleteGroupNode {
    onDeleteGroupNode {
      id
      groupID
      nodeID
      owner
      createdAt
      updatedAt
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      group {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          nextToken
        }
        hologramNode {
          nextToken
        }
        node {
          nextToken
        }
        edge {
          nextToken
        }
      }
    }
  }
`;
export const onCreateHologramContributor = /* GraphQL */ `
  subscription OnCreateHologramContributor {
    onCreateHologramContributor {
      id
      baseType
      hologramID
      contributorID
      owner
      createdAt
      updatedAt
      contributor {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateHologramContributor = /* GraphQL */ `
  subscription OnUpdateHologramContributor {
    onUpdateHologramContributor {
      id
      baseType
      hologramID
      contributorID
      owner
      createdAt
      updatedAt
      contributor {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteHologramContributor = /* GraphQL */ `
  subscription OnDeleteHologramContributor {
    onDeleteHologramContributor {
      id
      baseType
      hologramID
      contributorID
      owner
      createdAt
      updatedAt
      contributor {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
    }
  }
`;
export const onCreateHologramEdge = /* GraphQL */ `
  subscription OnCreateHologramEdge {
    onCreateHologramEdge {
      id
      hologramID
      edgeID
      startNodeID
      endNodeID
      accessPolicy
      createdByID
      owner
      createdAt
      updatedAt
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      edge {
        baseType
        id
        startNodeID
        endNodeID
        createdAt
        updatedAt
        block {
          nextToken
        }
        startNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        endNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      startNode {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      endNode {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateHologramEdge = /* GraphQL */ `
  subscription OnUpdateHologramEdge {
    onUpdateHologramEdge {
      id
      hologramID
      edgeID
      startNodeID
      endNodeID
      accessPolicy
      createdByID
      owner
      createdAt
      updatedAt
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      edge {
        baseType
        id
        startNodeID
        endNodeID
        createdAt
        updatedAt
        block {
          nextToken
        }
        startNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        endNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      startNode {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      endNode {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteHologramEdge = /* GraphQL */ `
  subscription OnDeleteHologramEdge {
    onDeleteHologramEdge {
      id
      hologramID
      edgeID
      startNodeID
      endNodeID
      accessPolicy
      createdByID
      owner
      createdAt
      updatedAt
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      edge {
        baseType
        id
        startNodeID
        endNodeID
        createdAt
        updatedAt
        block {
          nextToken
        }
        startNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        endNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      startNode {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      endNode {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
    }
  }
`;
export const onCreateGroupEdge = /* GraphQL */ `
  subscription OnCreateGroupEdge {
    onCreateGroupEdge {
      id
      groupID
      edgeID
      owner
      createdAt
      updatedAt
      edge {
        baseType
        id
        startNodeID
        endNodeID
        createdAt
        updatedAt
        block {
          nextToken
        }
        startNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        endNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      group {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          nextToken
        }
        hologramNode {
          nextToken
        }
        node {
          nextToken
        }
        edge {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateGroupEdge = /* GraphQL */ `
  subscription OnUpdateGroupEdge {
    onUpdateGroupEdge {
      id
      groupID
      edgeID
      owner
      createdAt
      updatedAt
      edge {
        baseType
        id
        startNodeID
        endNodeID
        createdAt
        updatedAt
        block {
          nextToken
        }
        startNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        endNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      group {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          nextToken
        }
        hologramNode {
          nextToken
        }
        node {
          nextToken
        }
        edge {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteGroupEdge = /* GraphQL */ `
  subscription OnDeleteGroupEdge {
    onDeleteGroupEdge {
      id
      groupID
      edgeID
      owner
      createdAt
      updatedAt
      edge {
        baseType
        id
        startNodeID
        endNodeID
        createdAt
        updatedAt
        block {
          nextToken
        }
        startNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        endNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      group {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          nextToken
        }
        hologramNode {
          nextToken
        }
        node {
          nextToken
        }
        edge {
          nextToken
        }
      }
    }
  }
`;
