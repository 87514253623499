import { createStore } from 'vuex';
import { auth } from './auth/auth.js';
import { sidebar } from './sidebar/sidebar.js';
import { modal } from './modal/modal.js';
import { hologram } from './hologram/hologram.js';
import { questionnaire } from './questionnaire/questionnaire.js';
import { notification } from './notification/notification.js';
import { weight } from './weight/weight.js'

export default createStore({
  modules: {
    auth,
    sidebar,
    modal,
    hologram,
    questionnaire,
    notification,
    weight
  }
})