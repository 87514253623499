<template>
  <div class="flex flex-row flex-shrink-0 w-full h-16 pl-6 pr-4">
    <div
      class="my-auto mr-2"
      v-show="!sidebarOpen"
    >
      <SidebarToggler />
    </div>
    <div
      v-show="!sidebarOpen"
      class="flex items-center mr-2 font-bold text-sd-base00-bryellow"
    >
      <p>/</p>
    </div>
    <div
      class="flex flex-row py-4 my-auto mr-auto"
    >
      <button
        class="flex items-center h-8 px-2 rounded-md hover:bg-sd-base2-white hover:bg-opacity-5 focus:outline-none"
        @click="toggleEditHologramTitleModal($event)"
      >
        <div class="flex-1 my-auto font-sans text-base font-semibold text-left text-sd-base0-brblue">
          {{ hologramTitle }}
        </div>
      </button>
    </div>
    <div class="flex flex-row my-auto ml-auto">
      <div class="flex w-8 h-8 mr-3">
        <loadingSpin
          v-if="transactingStatus"
          :size="5"
          class="m-auto text-sd-base0-brblue"
        />
      </div>
      <button
        class="flex items-center px-2 py-1 mr-2 font-sans text-sm rounded-md text-sd-base1-brcyan hover:bg-sd-base2-white hover:bg-opacity-5 focus:outline-none"
        @click="toggleSharingModal($event)"
      >
        Share
      </button>
      <button
        class="flex items-center w-8 h-8 my-auto mr-2 rounded-md hover:bg-sd-base2-white hover:bg-opacity-5 focus:outline-none"
        @click="toggleHologramSettingsModal($event)"
      >
        <iconThreeDots
          :size="4"
          class="m-auto text-sd-base0-brblue"
        />
      </button>
      <HologramSidebarToggler />
    </div>
  </div>
</template>
<script>
import SidebarToggler from "../sidebar/SidebarToggler.vue";
import HologramSidebarToggler from "../sidebar/HologramSidebarToggler.vue"
import iconThreeDots from "../icon/iconThreeDots.vue";
import { useStore } from "vuex";
import { getElementPosition } from "../../utilities/utilities";
import loadingSpin from "../animation/loadingSpin.vue";
export default {
  name: "HologramNavbarForOwner",
  components: {
    loadingSpin,
    iconThreeDots,
    SidebarToggler,
    HologramSidebarToggler
  },
  props: {
    sidebarOpen: {
      type: Boolean,
      require: true,
    },
    dataIsReady: {
      type: Boolean,
      require: true,
    },
    titleEditorModalOpen: {
      type: Boolean,
      require: true,
    },
    sharingModalOpen: {
      type: Boolean,
      require: true,
    },
    settingModalOpen: {
      type: Boolean,
      require: true,
    },
    hologramTitle: {
      type: String,
      require: true,
    },
    transactingStatus: {
      type: Boolean,
      require: true,
    }
  },
  setup(props) {
    const store = useStore();
    const toggleEditHologramTitleModal = ($event) => {
      if (props.titleEditorModalOpen) {
        store.dispatch("modal/toggleHologramTitleEditorModal", { data: false });
        store.dispatch("modal/updateHologramTitleEditorModalPosition", {
          data: {},
        });
      } else {
        const position = getElementPosition($event.target);
        store.dispatch("modal/toggleHologramTitleEditorModal", { data: true });
        store.dispatch("modal/updateHologramTitleEditorModalPosition", {
          data: position,
        });
      }
    };

    const toggleSharingModal = ($event) => {
      if (props.sharingModalOpen) {
        store.dispatch("modal/toggleHologramSharingModal", { data: false });
        store.dispatch("modal/updateHologramSharingModalData", {
          type: "reset",
        });
      } else {
        store.dispatch("modal/toggleHologramSharingModal", { data: true });
        const position = getElementPosition($event.target);
        store.dispatch("modal/updateHologramSharingModalPosition", { data: position });
      }
    };

    const toggleHologramSettingsModal = ($event) => {
      if (props.settingModalOpen) {
        store.dispatch("modal/toggleHologramSettingsModal", { data: false });
        store.dispatch("modal/updateHologramSettingsModalTriggeredPosition", {
          type: "update",
          data: {},
        });
      } else {
        store.dispatch("modal/toggleHologramSettingsModal", { data: true });
        const position = getElementPosition($event.target);
        store.dispatch("modal/updateHologramSettingsModalTriggeredPosition", {
          type: "update",
          data: position,
        });
      }
    };

    return {
      // Method
      toggleEditHologramTitleModal,
      toggleSharingModal,
      toggleHologramSettingsModal,
    };
  },
};
</script>
<style>
</style>