<template>
  <div
    class="w-full px-10 sm:px-20"
  >
    <div
      class="flex flex-col h-60 sm:w-8/12 sm:mx-auto"
    >
      <div
        class="w-full mx-auto mt-auto font-sans text-3xl font-semibold text-left sm:text-4xl md:text-5xl text-sd-base2-white "
      >
        Welcome to Totuslink dock
      </div>
      <div
       class="w-full mx-auto mt-1 mb-auto font-sans font-normal text-left text-md sm:text-lg md:text-xl md:mt-3 text-sd-base1-brcyan"
      >
        Explore, read and be inspired by public holograms built by totuslink users.
      </div>
    </div>
    <div
      class="w-full mb-20 sm:w-8/12 sm:mx-auto"
    >
      <router-link
        tag="button"
        :to="{ name: 'Hologram', params:{ username: hologram.createdBy.username, hologramID:hologram.id }}"
        v-for="hologram in holograms"
        v-bind:key="hologram.id"
        class="flex flex-col w-full p-5 mt-5 border rounded-md shadow-xl border-sd-base0-brblue bg-sd-base02-black"
      >
        <div
          class="font-sans text-xl font-semibold text-left break-all text-sd-base2-white"
        >
          {{hologram.title}}
        </div>
        <div
          class="font-sans text-sm font-normal text-left text-sd-base1-brcyan"
        >
          {{hologram.intro}}
        </div>
        <div
          class="flex flex-row w-full mt-8"
        >
          <img 
            src="@/assets/avatar1_dark.png" 
            alt="default_avatar"
            class="w-5 h-5 mr-3"
          >
          <span
            class="font-sans text-sm font-semibold text-left text-sd-base1-brcyan"
          >
            {{hologram.createdBy.username}}
          </span>
        </div>
        <div
          class="grid w-full grid-flow-row grid-cols-2 mt-4 -ml-1 sm:w-6/12"
        >
          <div
            class="flex flex-row"
          >
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              class="flex w-6 h-6 my-auto mr-2 fill-current text-sd-base1-brcyan" 
              viewBox="0 0 16 16"
            >
              <path d="M8 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0 1A5 5 0 1 0 8 3a5 5 0 0 0 0 10z"/>
              <path d="M10 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0z"/>
            </svg>
            <span
              class="flex items-center font-sans text-sm font-normal text-left text-sd-base1-brcyan"
            >
              {{hologram.node.items.length}}
            </span>
          </div>
          <div
            class="flex flex-row"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="flex w-3 h-3 my-auto mr-2 fill-current text-sd-base1-brcyan"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"
              />
            </svg>
            <span
              class="flex items-center font-sans text-sm font-normal text-left text-sd-base1-brcyan"
            >
              {{hologram.edge.items.length}}
            </span>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
import { ref, onMounted } from 'vue'
import { useStore } from "vuex";
export default {
  name: "Dock",
  setup() {
    const store = useStore();
    const holograms = ref([]);

    const prepareData = async () => {
      window.document.title = "Totuslink Dock"
      try {
        const listPublicHolograms = await store.dispatch("hologram/listHologramsByUpdatedAt");
        holograms.value = listPublicHolograms;
      } catch(err){
        console.log("Something went wrong when list public hologram by updatedAt", err);
      }
    }

    onMounted(prepareData);

    return {
      holograms,
      prepareData,
    }
  }
};

</script>
<style>

</style>