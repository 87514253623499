<template>
  <div
    class="flex w-full h-full base bg-sd-base03-brblack"
  >
    <div
      class="flex flex-col w-full m-auto mx-4 sm:w-360 sm:mx-auto"
    >
      <div 
        class="font-sans text-xl font-bold lg:text-3xl text-sd-base2-white"
      >
        Reset your password
      </div>
      <div
        class="mt-2 font-sans text-sm font-normal lg:text-base"
      >
        <div
          v-if="!showVerificationForm"
          class="text-sd-base1-brcyan"
        >
          Enter your user account's username and we will send you a password reset code.
        </div>
        <div
          v-else
          class="text-sd-base2-white"
        >
          Please submit new password with the code received via email.
        </div>
      </div>
      <div 
        class="flex flex-row w-full mx-auto mt-12 rounded-md sm:w-360 border-sd-base00-bryellow"
      >
        <input 
          class="flex-1 bg-sd-base02-black font-sans rounded-md font-normal text-sm text-sd-base0-brblue py-1.5 px-3 focus:outline-none focus:ring-1 focus:ring-sd-base1-brcyan" 
          placeholder="Enter your username..."
          type="text"
          v-model="username"
          id="username"
          :disabled="showVerificationForm"
        />
      </div>
      <div
        v-if="!showVerificationForm"
        class="flex flex-col"
      >
        <button
          class="flex flex-row w-full py-1.5 mx-auto mt-4 border rounded sm:w-360 border-sd-base00-bryellow focus:outline-none hover:bg-sd-base02-black"
          @click="submitForgotPassword"
        >
          <div
            class="m-auto font-sans text-sm font-normal text-sd-base0-brblue"
          >
            Send reset code
          </div>
        </button>
        <div
          class="flex mt-4 font-sans text-sm font-medium lg:text-base text-sd-yellow"
          v-if="error"
        >
          <div
            class="mx-auto"
          >
            {{error}}
          </div>
        </div>
      </div>
      <div
        v-else
      >
        <div
          v-if="!successfullyUpdatePassword"
          class="flex flex-col"
        >
          <div class="inline-block w-full my-6 border-b border-sd-base00-bryellow"></div>
          <div 
            class="flex flex-col w-full mx-auto rounded-md sm:w-360 border-sd-base00-bryellow"
          >
            <div
              class="w-full font-sans text-sm font-light leading-4 text-left text-sd-base1-brcyan"
            >
              Verification code
            </div>
            <input 
              class="flex-1 mt-3 bg-sd-base02-black font-sans rounded-md font-normal text-sm text-sd-base0-brblue py-1.5 px-3 focus:outline-none focus:ring-1 focus:ring-sd-base1-brcyan" 
              placeholder="Paste verification code..."
              type="text"
              v-model="verificationCode"
              id="verificationCode"
            />
          </div>
          <div 
            class="flex flex-col w-full mx-auto mt-4 rounded-md sm:w-360 border-sd-base00-bryellow"
          >
            <div
              class="w-full font-sans text-sm font-light leading-4 text-left text-sd-base1-brcyan"
            >
              New password
            </div>
            <input 
              class="flex-1 mt-3 bg-sd-base02-black font-sans rounded-md font-normal text-sm text-sd-base0-brblue py-1.5 px-3 focus:outline-none focus:ring-1 focus:ring-sd-base1-brcyan" 
              placeholder="Enter new password..."
              type="password"
              v-model="password"
              id="password"
            />
          </div>
          <div 
            class="flex flex-col w-full mx-auto mt-4 rounded-md sm:w-360 border-sd-base00-bryellow"
          >
            <div
              class="w-full font-sans text-sm font-light leading-4 text-left text-sd-base1-brcyan"
            >
              Confirm new password
            </div>
            <input 
              class="flex-1 mt-3 bg-sd-base02-black font-sans rounded-md font-normal text-sm text-sd-base0-brblue py-1.5 px-3 focus:outline-none focus:ring-1 focus:ring-sd-base1-brcyan" 
              placeholder="Confirm new password..."
              type="password"
              v-model="confirmPassword"
              id="confirmPassword"
            />
          </div>
          <button
            class="flex flex-row w-full py-1.5 mx-auto mt-6 border rounded sm:w-360 border-sd-base00-bryellow focus:outline-none hover:bg-sd-base02-black"
            @click="updatePassword"
          >
            <div
              v-if=" status === 'waiting' "
              class="m-auto font-sans text-sm font-normal text-sd-base0-brblue"
            >
              Update password
            </div>
            <div
              v-if=" status === 'success' "
              class="m-auto font-sans text-sm font-normal text-sd-base0-brblue"
            >
              Update successfully
            </div>
            <div
              v-if=" status === 'processing' "
              class="flex m-auto font-sans text-sm font-normal text-sd-base0-brblue"
            >
              <div
                class="flex flex-row m-auto"
              >
                <svg class="w-5 h-5 mr-3 -ml-1 animate-spin text-sd-base0-brblue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <div>
                  Processing
                </div>
              </div>
            </div>
          </button>
          <div
            class="flex mt-4 font-sans text-sm font-medium lg:text-base text-sd-yellow"
            v-if="error"
          >
            <div
              class="mx-auto"
            >
              {{error}}
            </div>
          </div>

        </div>
        <div
          v-else
          class="flex flex-col"
        >
          <div
            class="mt-4 font-sans text-base text-sd-base1-brcyan"
          >
            Successfully update password, please login with new password
          </div>
          <router-link
            to="/login"
            class="py-1 mt-4 font-sans text-base border rounded-md border-sd-base01-brgreen text-sd-base1-brcyan hover:bg-sd-base02-black"
          >
            Go to login page
          </router-link>
        </div>
        
      </div>
      
    </div>
  </div>
</template>
<script>
import { ref } from 'vue'
import { Auth } from "aws-amplify";
import { gaHelpers } from "@/analytics/helper.js"

export default {
  name: "ResetPassword",
  setup() {
    const username = ref(null);
    const error = ref(null);
    const showVerificationForm = ref(false);
    const verificationCode = ref(null);
    const password = ref(null);
    const confirmPassword = ref(null);
    const status = ref("waiting");
    const successfullyUpdatePassword = ref(false)

    const submitForgotPassword = async () => {
      if ( !username.value ) {
        error.value = "Please enter a valid username."
        return;
      }

      try {
        await Auth.forgotPassword(username.value);
        gaHelpers.engagement("reset_password_verification", "auth")
        showVerificationForm.value = true;
        error.value = null;
      } catch(err){
        if ( err.code === "LimitExceededException" ){
          error.value = "Attempt limit exceeded, please try after some time.";
          return;
        }
        error.value = "Failed to send password reset code. Please try again";
      }
    }

    const updatePassword = async () => {
      if ( !verificationCode.value ){
        error.value = "Please enter verification code"
        return;
      }

      if ( !password.value ){
        error.value = "Please enter new password"
        return;
      }

      if ( !confirmPassword.value ){
        error.value = "Please confirm new password"
        return;
      }

      if ( password.value.toLowerCase() !== confirmPassword.value.toLowerCase() ){
        error.value = "New password and confirm new password input not the same"
        return;
      }

      error.value = null;

      try {
        status.value = "processing";
        await Auth.forgotPasswordSubmit(username.value, verificationCode.value, password.value);
        gaHelpers.engagement("update_password", "auth")
        status.value = "success";
        verificationCode.value = null;
        password.value = null;
        confirmPassword.value = null;
        successfullyUpdatePassword.value = true;
      } catch(err){
        error.value = "Failed to update password. Please try again later.";
      }
    };

    return {
      username,
      error,
      verificationCode,
      password,
      confirmPassword,
      showVerificationForm,
      submitForgotPassword,
      updatePassword,
      status,
      successfullyUpdatePassword
    }
  }
};
</script>
<style>
</style>