import { API, graphqlOperation } from "aws-amplify";
import { createWeight as createWeightMutation } from "../../graphql/custom/customMutations";
import { updateWeight as updateWeightMutation } from "../../graphql/custom/customMutations";
import { deleteWeight as deleteWeightMutation } from "../../graphql/custom/customMutations";
import _ from "lodash"


export const weight = {
  namespaced: true,
  state: {
    currentHologramNodeWeights: [],
    userNodeWeights: [],
    weightChange: false,
  },
  mutations: {
    mutateCurrentHologramNodeWeight(state, payload){
      state.currentHologramNodeWeights = payload;
    },
    mutateUserNodeWeights(state, payload){
      state.userNodeWeights = payload;
    },
    mutateWeightChange(state, payload){
      state.weightChange = payload;
    },
  },
  actions: {
    // Unit actions
    updateWeightChange(context, {data}){
      context.commit("mutateWeightChange", data)
    },
    setCurrentHologramNodeWeights: (context, hologramNodeWeights) => {
      context.commit("mutateCurrentHologramNodeWeight", hologramNodeWeights);
    },
    removeItemFromCurrentHologramNodeWeights: (context, hologramNodeWeightID) => {
      let temp = _.cloneDeep(context.state.currentHologramNodeWeights);
      const index = temp.findIndex( e => e.id === hologramNodeWeightID);
      temp.splice(index, 1);
      context.commit("mutateCurrentHologramNodeWeight", temp);
    },
    addItemIntoCurrentHologramNodeWeight: (context, hologramNodeWeight) => {
      let temp = _.cloneDeep(context.state.currentHologramNodeWeights);
      temp.splice(0, 0, hologramNodeWeight);
      context.commit("mutateCurrentHologramNodeWeight", temp);
    },
    updateItemInCurrentHologramNodeWeight: (context, hologramNodeWeight) => {
      let temp = _.cloneDeep(context.state.currentHologramNodeWeights);
      const index = temp.findIndex( e => e.id === hologramNodeWeight.id );
      temp.splice(index, 1, hologramNodeWeight);
      context.commit("mutateCurrentHologramNodeWeight", temp);
    },
    addItemIntoUserNodeWeights: (context, weight) => {
      let temp = _.cloneDeep(context.state.userNodeWeights);
      temp.splice(0, 0, weight);
      context.commit("mutateUserNodeWeights", temp);
    },
    updateItemInUserNodeweights: (context, weight) => {
      let temp = _.cloneDeep(context.state.userNodeWeights);
      const index = temp.findIndex( e => e.id === weight.id );
      temp[index] = weight;
      context.commit("mutateUserNodeWeights", temp);
    },


    // Request actions
    createInitWeight: async (context, {type, accessPolicy, hologramID, nodeID, owner}) => {
      const newVersion = [
        {
          "times": Date.now(),
          "counts": {
            "link": 0,
            "note": 0,
          },
          "weight": {
            "link": 1,
            "note": 0.01,
          }
        }
      ];

      const newCalculation = {
        "weight": {
          "link": 1,
          "note": 0.01,
        }
      };

      const newWeight = {
        baseType: "Weight",
        weightType: type,
        calculation: JSON.stringify(newCalculation),
        value: 0,
        version: JSON.stringify(newVersion),
        accessPolicy: accessPolicy,
        hologramID: hologramID,
        nodeID: nodeID,
        owner: owner
      };
      try {
        const result = await API.graphql(graphqlOperation(createWeightMutation, {input:newWeight}));
        if ( type === "hologramNode" ){
          context.dispatch("addItemIntoCurrentHologramNodeWeight", result.data.createWeight);
        } else if ( type === "userNode" ){
          context.dispatch("addItemIntoUserNodeWeights", result.data.createWeight);
        }
        return Promise.resolve(result.data.createWeight);
      } catch(err){
        return Promise.reject(err);
      }
    },
    createHologramNodeWeight: async (_, { id, hologramNodeID, nodeID, version, value, accessPolicy, calculation, userID }) => {
      const variables = {
        input: {
          id: id,
          baseType: "Weight",
          weightType: "HologramNode",
          hologramNodeID: hologramNodeID,
          nodeID: nodeID,
          accessPolicy: accessPolicy,
          version: version,
          value: value,
          calculation: calculation,
          owner: userID
        }
      };
      try {
        let res = await API.graphql({
          query: createWeightMutation,
          variables: variables,
          authMode: "AWS_IAM"
        });
        return Promise.resolve(res.data.createWeight)
      } catch(err){
        return Promise.reject(err)
      }
    },
    updateHologramNodeWeight: async (context, {weight}) => {
      const variables = {
        "input": weight
      }
      try {
        const result = await API.graphql({
          query: updateWeightMutation,
          variables: variables,
          authMode: "AWS_IAM"
        })       
        return Promise.resolve(result.data.updateWeight)
      } catch(err){
        return Promise.reject(err)
      }
    },
    deleteWeight: async (_, weightID) => {

      const variables = {
        "input": {
          "id": weightID
        }
      }

      try {
        const result = await API.graphql({
          query: deleteWeightMutation,
          variables: variables,
          authMode: "AWS_IAM"
        })       
        return Promise.resolve(result.data.deleteWeight);
      } catch(err){
        console.log(err)
        return Promise.reject(err);
      }
    },
    createUserNodeWeight: async (_, { id, userNodeID, nodeID, version, value, accessPolicy, calculation, userID }) => {
      const variables = {
        input: {
          id: id,
          baseType: "Weight",
          weightType: "HologramNode",
          userNodeID: userNodeID,
          nodeID: nodeID,
          accessPolicy: accessPolicy,
          version: version,
          value: value,
          calculation: calculation,
          owner: userID
        }
      };
      try {
        let res = await API.graphql({
          query: createWeightMutation,
          variables: variables,
          authMode: "AWS_IAM"
        });
        return Promise.resolve(res.data.createWeight)
      } catch(err){
        return Promise.reject(err)
      }
    },
    
  },
  getters: {
    currentHologramNodeWeights: (state) => state.currentHologramNodeWeights,
    userNodeWeights: (state) => state.userNodeWeights,
    weightChange: (state) => state.weightChange
  }
}