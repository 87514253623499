import { createRouter, createWebHistory } from "vue-router";
import { trackRouter } from "vue-gtag-next";
import Home from "../views/Home.vue";
import LoginView from "../views/LoginView";
import SignUpView from "../views/SignUpView.vue";
import Dashboard from "../views/Dashboard.vue";
import { Auth } from "aws-amplify";
import UserHolograms from "../views/UserHolograms.vue";
import Hologram from "../views/Hologram.vue";
import AboutUs from "../views/AboutUs.vue";
import Dock from "../views/Dock.vue";
import NotFound404 from "../views/NotFound404.vue";
import ResetPassword from "../views/ResetPassword.vue";
import QuestionnaireBase from "../views/questionnaire/QuestionnaireBase.vue";
import QuestionnaireIntro from "../views/questionnaire/QuestionnaireIntro.vue";
import QuestionnaireQuestions from "../views/questionnaire/QuestionnaireQuestions.vue";
import QuestionnaireEnding from "../views/questionnaire/QuestionnaireEnding.vue";

import Admin from "../views/Admin.vue";
import store from "../store";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      showSidebar: false,
      showHologramSidebar: false,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
    meta: {
      showSidebar: false,
      showHologramSidebar: false,
    },
  },
  {
    path: "/signup",
    name: "SignUp",
    component: SignUpView,
    meta: {
      showSidebar: false,
      showHologramSidebar: false,
    },
  },
  {
    path: "/reset_password",
    name: "ResetPassword",
    component: ResetPassword,
    meta: {
      showSidebar: false,
      showHologramSidebar: false,
    },
  },
  {
    path: "/aboutus",
    name: "AboutUs",
    component: AboutUs,
    meta: {
      showSidebar: false,
      showHologramSidebar: false,
    },
  },
  {
    path: "/questionnaire",
    name: "Questionnaire",
    component: QuestionnaireBase,
    meta: {
      showSidebar: false,
      showHologramSidebar: false,
    },
    children: [
      {
        path: "intro",
        name: "QuestionnaireIntro",
        component: QuestionnaireIntro,
        meta: {
          showQuestionnaireFooter: false,
        },
      },
      {
        path: "questions",
        name: "QuestionnaireQuestions",
        component: QuestionnaireQuestions,
        meta: {
          showQuestionnaireFooter: true,
        },
      },
      {
        path: "ending",
        name: "QuestionnaireEnding",
        component: QuestionnaireEnding,
        meta: {
          showQuestionnaireFooter: false,
        },
      },
    ],
  },
  {
    path: "/:username",
    name: "Dashboard",
    component: Dashboard,
    children: [
      {
        path: "holograms",
        name: "UserHolograms",
        component: UserHolograms,
        meta: {
          showHologramNavbar: false,
          requiresAuth: true,
        },
      },
      {
        path: ":hologramID",
        name: "Hologram",
        component: Hologram,
        meta: {
          showHologramNavbar: true,
          requiresAuth: true,
          showHologramSidebar: true,
        },
      },
      {
        path: "dock",
        name: "Dock",
        component: Dock,
        meta: {
          showEditorToggle: false,
          showHologramOptions: false,
          showHologramTitle: false,
          showHologramSidebar: false,
          requiresAuth: true,
        },
      },
    ],
    meta: {
      requiredAuth: true,
      showSidebar: true,
      showHologramSidebar: false,
    },
  },
  {
    path: "/admin",
    component: Admin,
    name: "Admin",
  },
  {
    path: "/404",
    component: NotFound404,
    name: "404",
    meta: {
      requiredAuth: false,
      showSidebar: false,
      showHologramSidebar: false,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound404,
    meta: {
      requiredAuth: false,
      showSidebar: false,
      showHologramSidebar: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// every router guard can only have one next called at a time, so make sure your logic is correct
// or you will face can't match issue

router.beforeEach(async (to, from, next) => {
  if (to.name === "Hologram") {
    store.dispatch("sidebar/toggleSidebar", { data: false });
    next();
    return;
  }

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isAuthenticated = await Auth.currentUserInfo();
  let globalUserNameForUrl = "";
  if (isAuthenticated) {
    globalUserNameForUrl = isAuthenticated.username;
  } else {
    globalUserNameForUrl = "";
  }

  /*
  if ( to.name === "Login" && isAuthenticated){
    next({name:"UserHolograms", params:{username:globalUserNameForUrl}});
  }
  */

  if (requiresAuth && !isAuthenticated) {
    next("/");
  } else if (requiresAuth && isAuthenticated) {
    if (to.name == "Home") {
      next({
        name: "UserHolograms",
        params: { username: globalUserNameForUrl },
      });
    } else {
      next();
    }
  } else if (!requiresAuth && isAuthenticated) {
    if (to.name == "Home") {
      next({
        name: "UserHolograms",
        params: { username: globalUserNameForUrl },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

trackRouter(router);

export default router;
