
export const getUrlObject = (url) => {
  // https://developer.mozilla.org/en-US/docs/Web/API/URL#properties
  try {
    const result = new URL(url)
    return result
  } catch (err) {
    return 'invalidUrl'
  }
}


export const getNodeTitle = (hologramNode) => {
  if ( hologramNode.title ){
    return hologramNode.title
  }

  return hologramNode.node.title;
}

export const contentEditableOnPasteHandler = (event) => {
  // prevent insertion of newlines and paste html
  event.preventDefault();
  let text = (event.originalEvent || event).clipboardData.getData('text/plain');
  text = text.replaceAll('\r\n', ' ');
  text = text.replaceAll('\n', ' ');
  text = text.replaceAll('\r', ' ');
  window.document.execCommand('insertText', false, text);
}

export const truncateString = (string, length) => {
  if (string.length <= length) {
    return string
  }
  return string.slice(0, length) + '...'
}

export const countWords = (text) => {
  var pattern = /[a-zA-Z0-9_\u00A0-\u02AF\u0392-\u03c9\u0410-\u04F9]+|[\u4E00-\u9FFF\u3400-\u4dbf\uf900-\ufaff\u3040-\u309f\uac00-\ud7af]+/g;
  var m = text.match(pattern);
  var count = 0;
  if (m === null) return count;
  for (var i = 0; i < m.length; i++) {
    if (m[i].charCodeAt(0) >= 0x4e00) {
      count += m[i].length;
    } else {
      count += 1;
    }
  }
  return count;
};

export const getElementPosition = (element) => {
  const getPosition = element.getBoundingClientRect();
  const result = {
    x:getPosition.x,
    y:getPosition.y,
    width: getPosition.width,
    height: getPosition.height,
  };
  return result;
}

export const validateEmailSimple = (string) => {
  let re = /\S+@\S+\.\S+/;
  return re.test(string);
}

export const handle = (promise) => {
  return promise
    .then(data => ([undefined, data]))
    .catch(error => Promise.resolve([error, undefined]));
}