/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createNodeBuildingNecessaryItems = /* GraphQL */ `
  mutation CreateNodeBuildingNecessaryItems(
    $domain: CreateDomainInput
    $node: CreateNodeInput
    $hologramNodeWeight: CreateWeightInput
    $userNodeWeight: CreateWeightInput
    $hologramNode: CreateHologramNodeInput
    $userNode: CreateUserNodeInput
  ) {
    CreateNodeBuildingNecessaryItems(
      domain: $domain
      node: $node
      hologramNodeWeight: $hologramNodeWeight
      userNodeWeight: $userNodeWeight
      hologramNode: $hologramNode
      userNode: $userNode
    ) {
      domain {
        baseType
        hostName
        id
        updatedAt
        createdAt
        node {
          nextToken
        }
      }
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      weight {
        id
        baseType
        weightType
        hologramNodeID
        userNodeID
        nodeID
        hologramID
        calculation
        value
        version
        accessPolicy
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        userNode {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        hologramNode {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
      }
      hologramNode {
        id
        hologramID
        title
        nodeID
        accessPolicy
        weightID
        createdByID
        owner
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        group {
          nextToken
        }
      }
      userNode {
        id
        userID
        nodeID
        title
        note
        weightID
        version
        owner
        createdAt
        updatedAt
        user {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      isAdmin
      baseType
      username
      avatar {
        region
        bucket
        key
      }
      email
      emailVerified
      firstName
      lastName
      createdAt
      updatedAt
      onBoardingIsFinished
      onBoardingQuestionnaireIsFinished
      owner
      hologram {
        items {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        nextToken
      }
      likeHologram {
        items {
          id
          baseType
          hologramID
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      createdImage {
        items {
          id
          baseType
          createdByID
          contentType
          height
          width
          size
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      questionnaire {
        items {
          id
          userID
          questionnaireID
          isFinished
          answer
          createdAt
          updatedAt
        }
        nextToken
      }
      ownNode {
        items {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      ownEdge {
        items {
          id
          userID
          edgeID
          note
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      contributedHologram {
        items {
          id
          baseType
          hologramID
          contributorID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      isAdmin
      baseType
      username
      avatar {
        region
        bucket
        key
      }
      email
      emailVerified
      firstName
      lastName
      createdAt
      updatedAt
      onBoardingIsFinished
      onBoardingQuestionnaireIsFinished
      owner
      hologram {
        items {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        nextToken
      }
      likeHologram {
        items {
          id
          baseType
          hologramID
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      createdImage {
        items {
          id
          baseType
          createdByID
          contentType
          height
          width
          size
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      questionnaire {
        items {
          id
          userID
          questionnaireID
          isFinished
          answer
          createdAt
          updatedAt
        }
        nextToken
      }
      ownNode {
        items {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      ownEdge {
        items {
          id
          userID
          edgeID
          note
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      contributedHologram {
        items {
          id
          baseType
          hologramID
          contributorID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      isAdmin
      baseType
      username
      avatar {
        region
        bucket
        key
      }
      email
      emailVerified
      firstName
      lastName
      createdAt
      updatedAt
      onBoardingIsFinished
      onBoardingQuestionnaireIsFinished
      owner
      hologram {
        items {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        nextToken
      }
      likeHologram {
        items {
          id
          baseType
          hologramID
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      createdImage {
        items {
          id
          baseType
          createdByID
          contentType
          height
          width
          size
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      questionnaire {
        items {
          id
          userID
          questionnaireID
          isFinished
          answer
          createdAt
          updatedAt
        }
        nextToken
      }
      ownNode {
        items {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      ownEdge {
        items {
          id
          userID
          edgeID
          note
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      contributedHologram {
        items {
          id
          baseType
          hologramID
          contributorID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createHologram = /* GraphQL */ `
  mutation CreateHologram(
    $input: CreateHologramInput!
    $condition: ModelHologramConditionInput
  ) {
    createHologram(input: $input, condition: $condition) {
      id
      baseType
      intro
      title
      graph
      createdByID
      contributorID
      accessPolicy
      weightID
      updatedAt
      createdAt
      owner
      action
      userPresent
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      block {
        items {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      like {
        items {
          id
          baseType
          hologramID
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      weight {
        id
        baseType
        weightType
        hologramNodeID
        userNodeID
        nodeID
        hologramID
        calculation
        value
        version
        accessPolicy
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        userNode {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        hologramNode {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
      }
      post {
        items {
          id
          baseType
          postType
          hologramID
          title
          content
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      group {
        items {
          id
          baseType
          hologramID
          groupID
          color
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      node {
        items {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      community {
        items {
          id
          hologramID
          communityID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      contributor {
        items {
          id
          baseType
          hologramID
          contributorID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      edge {
        items {
          id
          hologramID
          edgeID
          startNodeID
          endNodeID
          accessPolicy
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateHologram = /* GraphQL */ `
  mutation UpdateHologram(
    $input: UpdateHologramInput!
    $condition: ModelHologramConditionInput
  ) {
    updateHologram(input: $input, condition: $condition) {
      id
      baseType
      intro
      title
      graph
      createdByID
      contributorID
      accessPolicy
      weightID
      updatedAt
      createdAt
      owner
      action
      userPresent
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      block {
        items {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      like {
        items {
          id
          baseType
          hologramID
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      weight {
        id
        baseType
        weightType
        hologramNodeID
        userNodeID
        nodeID
        hologramID
        calculation
        value
        version
        accessPolicy
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        userNode {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        hologramNode {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
      }
      post {
        items {
          id
          baseType
          postType
          hologramID
          title
          content
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      group {
        items {
          id
          baseType
          hologramID
          groupID
          color
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      node {
        items {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      community {
        items {
          id
          hologramID
          communityID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      contributor {
        items {
          id
          baseType
          hologramID
          contributorID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      edge {
        items {
          id
          hologramID
          edgeID
          startNodeID
          endNodeID
          accessPolicy
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteHologram = /* GraphQL */ `
  mutation DeleteHologram(
    $input: DeleteHologramInput!
    $condition: ModelHologramConditionInput
  ) {
    deleteHologram(input: $input, condition: $condition) {
      id
      baseType
      intro
      title
      graph
      createdByID
      contributorID
      accessPolicy
      weightID
      updatedAt
      createdAt
      owner
      action
      userPresent
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      block {
        items {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      like {
        items {
          id
          baseType
          hologramID
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      weight {
        id
        baseType
        weightType
        hologramNodeID
        userNodeID
        nodeID
        hologramID
        calculation
        value
        version
        accessPolicy
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        userNode {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        hologramNode {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
      }
      post {
        items {
          id
          baseType
          postType
          hologramID
          title
          content
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      group {
        items {
          id
          baseType
          hologramID
          groupID
          color
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      node {
        items {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      community {
        items {
          id
          hologramID
          communityID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      contributor {
        items {
          id
          baseType
          hologramID
          contributorID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      edge {
        items {
          id
          hologramID
          edgeID
          startNodeID
          endNodeID
          accessPolicy
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createBlock = /* GraphQL */ `
  mutation CreateBlock(
    $input: CreateBlockInput!
    $condition: ModelBlockConditionInput
  ) {
    createBlock(input: $input, condition: $condition) {
      id
      baseType
      belongTo
      blockType
      content
      html
      url
      title
      level
      nodify
      accessPolicy
      parentBlockID
      hologramID
      postID
      commentID
      nodeID
      edgeID
      groupID
      ownerUserNodeID
      ownerUserEdgeID
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      parentBlock {
        id
        baseType
        belongTo
        blockType
        content
        html
        url
        title
        level
        nodify
        accessPolicy
        parentBlockID
        hologramID
        postID
        commentID
        nodeID
        edgeID
        groupID
        ownerUserNodeID
        ownerUserEdgeID
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        parentBlock {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        edge {
          baseType
          id
          startNodeID
          endNodeID
          createdAt
          updatedAt
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        group {
          baseType
          id
          title
          createdByID
          createdAt
          updatedAt
        }
        post {
          id
          baseType
          postType
          hologramID
          title
          content
          owner
          createdAt
          updatedAt
        }
        comment {
          id
          baseType
          postID
          content
          parentCommentID
          owner
          createdAt
          updatedAt
        }
        ownerUserNode {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        ownerUserEdge {
          id
          userID
          edgeID
          note
          owner
          createdAt
          updatedAt
        }
      }
      edge {
        baseType
        id
        startNodeID
        endNodeID
        createdAt
        updatedAt
        block {
          nextToken
        }
        startNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        endNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      group {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          nextToken
        }
        hologramNode {
          nextToken
        }
        node {
          nextToken
        }
        edge {
          nextToken
        }
      }
      post {
        id
        baseType
        postType
        hologramID
        title
        content
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        block {
          nextToken
        }
        comment {
          nextToken
        }
      }
      comment {
        id
        baseType
        postID
        content
        parentCommentID
        owner
        createdAt
        updatedAt
        block {
          nextToken
        }
        post {
          id
          baseType
          postType
          hologramID
          title
          content
          owner
          createdAt
          updatedAt
        }
        parentComment {
          id
          baseType
          postID
          content
          parentCommentID
          owner
          createdAt
          updatedAt
        }
        childComment {
          nextToken
        }
      }
      ownerUserNode {
        id
        userID
        nodeID
        title
        note
        weightID
        version
        owner
        createdAt
        updatedAt
        user {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
      }
      ownerUserEdge {
        id
        userID
        edgeID
        note
        owner
        createdAt
        updatedAt
        user {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        edge {
          baseType
          id
          startNodeID
          endNodeID
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateBlock = /* GraphQL */ `
  mutation UpdateBlock(
    $input: UpdateBlockInput!
    $condition: ModelBlockConditionInput
  ) {
    updateBlock(input: $input, condition: $condition) {
      id
      baseType
      belongTo
      blockType
      content
      html
      url
      title
      level
      nodify
      accessPolicy
      parentBlockID
      hologramID
      postID
      commentID
      nodeID
      edgeID
      groupID
      ownerUserNodeID
      ownerUserEdgeID
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      parentBlock {
        id
        baseType
        belongTo
        blockType
        content
        html
        url
        title
        level
        nodify
        accessPolicy
        parentBlockID
        hologramID
        postID
        commentID
        nodeID
        edgeID
        groupID
        ownerUserNodeID
        ownerUserEdgeID
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        parentBlock {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        edge {
          baseType
          id
          startNodeID
          endNodeID
          createdAt
          updatedAt
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        group {
          baseType
          id
          title
          createdByID
          createdAt
          updatedAt
        }
        post {
          id
          baseType
          postType
          hologramID
          title
          content
          owner
          createdAt
          updatedAt
        }
        comment {
          id
          baseType
          postID
          content
          parentCommentID
          owner
          createdAt
          updatedAt
        }
        ownerUserNode {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        ownerUserEdge {
          id
          userID
          edgeID
          note
          owner
          createdAt
          updatedAt
        }
      }
      edge {
        baseType
        id
        startNodeID
        endNodeID
        createdAt
        updatedAt
        block {
          nextToken
        }
        startNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        endNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      group {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          nextToken
        }
        hologramNode {
          nextToken
        }
        node {
          nextToken
        }
        edge {
          nextToken
        }
      }
      post {
        id
        baseType
        postType
        hologramID
        title
        content
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        block {
          nextToken
        }
        comment {
          nextToken
        }
      }
      comment {
        id
        baseType
        postID
        content
        parentCommentID
        owner
        createdAt
        updatedAt
        block {
          nextToken
        }
        post {
          id
          baseType
          postType
          hologramID
          title
          content
          owner
          createdAt
          updatedAt
        }
        parentComment {
          id
          baseType
          postID
          content
          parentCommentID
          owner
          createdAt
          updatedAt
        }
        childComment {
          nextToken
        }
      }
      ownerUserNode {
        id
        userID
        nodeID
        title
        note
        weightID
        version
        owner
        createdAt
        updatedAt
        user {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
      }
      ownerUserEdge {
        id
        userID
        edgeID
        note
        owner
        createdAt
        updatedAt
        user {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        edge {
          baseType
          id
          startNodeID
          endNodeID
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteBlock = /* GraphQL */ `
  mutation DeleteBlock(
    $input: DeleteBlockInput!
    $condition: ModelBlockConditionInput
  ) {
    deleteBlock(input: $input, condition: $condition) {
      id
      baseType
      belongTo
      blockType
      content
      html
      url
      title
      level
      nodify
      accessPolicy
      parentBlockID
      hologramID
      postID
      commentID
      nodeID
      edgeID
      groupID
      ownerUserNodeID
      ownerUserEdgeID
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      parentBlock {
        id
        baseType
        belongTo
        blockType
        content
        html
        url
        title
        level
        nodify
        accessPolicy
        parentBlockID
        hologramID
        postID
        commentID
        nodeID
        edgeID
        groupID
        ownerUserNodeID
        ownerUserEdgeID
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        parentBlock {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        edge {
          baseType
          id
          startNodeID
          endNodeID
          createdAt
          updatedAt
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        group {
          baseType
          id
          title
          createdByID
          createdAt
          updatedAt
        }
        post {
          id
          baseType
          postType
          hologramID
          title
          content
          owner
          createdAt
          updatedAt
        }
        comment {
          id
          baseType
          postID
          content
          parentCommentID
          owner
          createdAt
          updatedAt
        }
        ownerUserNode {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        ownerUserEdge {
          id
          userID
          edgeID
          note
          owner
          createdAt
          updatedAt
        }
      }
      edge {
        baseType
        id
        startNodeID
        endNodeID
        createdAt
        updatedAt
        block {
          nextToken
        }
        startNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        endNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      group {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          nextToken
        }
        hologramNode {
          nextToken
        }
        node {
          nextToken
        }
        edge {
          nextToken
        }
      }
      post {
        id
        baseType
        postType
        hologramID
        title
        content
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        block {
          nextToken
        }
        comment {
          nextToken
        }
      }
      comment {
        id
        baseType
        postID
        content
        parentCommentID
        owner
        createdAt
        updatedAt
        block {
          nextToken
        }
        post {
          id
          baseType
          postType
          hologramID
          title
          content
          owner
          createdAt
          updatedAt
        }
        parentComment {
          id
          baseType
          postID
          content
          parentCommentID
          owner
          createdAt
          updatedAt
        }
        childComment {
          nextToken
        }
      }
      ownerUserNode {
        id
        userID
        nodeID
        title
        note
        weightID
        version
        owner
        createdAt
        updatedAt
        user {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
      }
      ownerUserEdge {
        id
        userID
        edgeID
        note
        owner
        createdAt
        updatedAt
        user {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        edge {
          baseType
          id
          startNodeID
          endNodeID
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createHighlight = /* GraphQL */ `
  mutation CreateHighlight(
    $input: CreateHighlightInput!
    $condition: ModelHighlightConditionInput
  ) {
    createHighlight(input: $input, condition: $condition) {
      id
      baseType
      content
      nodeID
      owner
      createdAt
      updatedAt
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
    }
  }
`;
export const updateHighlight = /* GraphQL */ `
  mutation UpdateHighlight(
    $input: UpdateHighlightInput!
    $condition: ModelHighlightConditionInput
  ) {
    updateHighlight(input: $input, condition: $condition) {
      id
      baseType
      content
      nodeID
      owner
      createdAt
      updatedAt
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
    }
  }
`;
export const deleteHighlight = /* GraphQL */ `
  mutation DeleteHighlight(
    $input: DeleteHighlightInput!
    $condition: ModelHighlightConditionInput
  ) {
    deleteHighlight(input: $input, condition: $condition) {
      id
      baseType
      content
      nodeID
      owner
      createdAt
      updatedAt
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
    }
  }
`;
export const createHologramLike = /* GraphQL */ `
  mutation CreateHologramLike(
    $input: CreateHologramLikeInput!
    $condition: ModelHologramLikeConditionInput
  ) {
    createHologramLike(input: $input, condition: $condition) {
      id
      baseType
      hologramID
      createdByID
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
    }
  }
`;
export const updateHologramLike = /* GraphQL */ `
  mutation UpdateHologramLike(
    $input: UpdateHologramLikeInput!
    $condition: ModelHologramLikeConditionInput
  ) {
    updateHologramLike(input: $input, condition: $condition) {
      id
      baseType
      hologramID
      createdByID
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
    }
  }
`;
export const deleteHologramLike = /* GraphQL */ `
  mutation DeleteHologramLike(
    $input: DeleteHologramLikeInput!
    $condition: ModelHologramLikeConditionInput
  ) {
    deleteHologramLike(input: $input, condition: $condition) {
      id
      baseType
      hologramID
      createdByID
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
    }
  }
`;
export const createDomain = /* GraphQL */ `
  mutation CreateDomain(
    $input: CreateDomainInput!
    $condition: ModelDomainConditionInput
  ) {
    createDomain(input: $input, condition: $condition) {
      baseType
      hostName
      id
      updatedAt
      createdAt
      node {
        items {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateDomain = /* GraphQL */ `
  mutation UpdateDomain(
    $input: UpdateDomainInput!
    $condition: ModelDomainConditionInput
  ) {
    updateDomain(input: $input, condition: $condition) {
      baseType
      hostName
      id
      updatedAt
      createdAt
      node {
        items {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteDomain = /* GraphQL */ `
  mutation DeleteDomain(
    $input: DeleteDomainInput!
    $condition: ModelDomainConditionInput
  ) {
    deleteDomain(input: $input, condition: $condition) {
      baseType
      hostName
      id
      updatedAt
      createdAt
      node {
        items {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createEdge = /* GraphQL */ `
  mutation CreateEdge(
    $input: CreateEdgeInput!
    $condition: ModelEdgeConditionInput
  ) {
    createEdge(input: $input, condition: $condition) {
      baseType
      id
      startNodeID
      endNodeID
      createdAt
      updatedAt
      block {
        items {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      startNode {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      endNode {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      user {
        items {
          id
          userID
          edgeID
          note
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      hologram {
        items {
          id
          hologramID
          edgeID
          startNodeID
          endNodeID
          accessPolicy
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      group {
        items {
          id
          groupID
          edgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateEdge = /* GraphQL */ `
  mutation UpdateEdge(
    $input: UpdateEdgeInput!
    $condition: ModelEdgeConditionInput
  ) {
    updateEdge(input: $input, condition: $condition) {
      baseType
      id
      startNodeID
      endNodeID
      createdAt
      updatedAt
      block {
        items {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      startNode {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      endNode {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      user {
        items {
          id
          userID
          edgeID
          note
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      hologram {
        items {
          id
          hologramID
          edgeID
          startNodeID
          endNodeID
          accessPolicy
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      group {
        items {
          id
          groupID
          edgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteEdge = /* GraphQL */ `
  mutation DeleteEdge(
    $input: DeleteEdgeInput!
    $condition: ModelEdgeConditionInput
  ) {
    deleteEdge(input: $input, condition: $condition) {
      baseType
      id
      startNodeID
      endNodeID
      createdAt
      updatedAt
      block {
        items {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      startNode {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      endNode {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      user {
        items {
          id
          userID
          edgeID
          note
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      hologram {
        items {
          id
          hologramID
          edgeID
          startNodeID
          endNodeID
          accessPolicy
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      group {
        items {
          id
          groupID
          edgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createNode = /* GraphQL */ `
  mutation CreateNode(
    $input: CreateNodeInput!
    $condition: ModelNodeConditionInput
  ) {
    createNode(input: $input, condition: $condition) {
      baseType
      id
      url
      title
      wordCounts
      favicon
      domainID
      weightID
      createdAt
      updatedAt
      block {
        items {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      highlight {
        items {
          id
          baseType
          content
          nodeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      domain {
        baseType
        hostName
        id
        updatedAt
        createdAt
        node {
          nextToken
        }
      }
      inEdges {
        items {
          baseType
          id
          startNodeID
          endNodeID
          createdAt
          updatedAt
        }
        nextToken
      }
      outEdges {
        items {
          baseType
          id
          startNodeID
          endNodeID
          createdAt
          updatedAt
        }
        nextToken
      }
      weight {
        id
        baseType
        weightType
        hologramNodeID
        userNodeID
        nodeID
        hologramID
        calculation
        value
        version
        accessPolicy
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        userNode {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        hologramNode {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
      }
      user {
        items {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      hologram {
        items {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      group {
        items {
          id
          groupID
          nodeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateNode = /* GraphQL */ `
  mutation UpdateNode(
    $input: UpdateNodeInput!
    $condition: ModelNodeConditionInput
  ) {
    updateNode(input: $input, condition: $condition) {
      baseType
      id
      url
      title
      wordCounts
      favicon
      domainID
      weightID
      createdAt
      updatedAt
      block {
        items {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      highlight {
        items {
          id
          baseType
          content
          nodeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      domain {
        baseType
        hostName
        id
        updatedAt
        createdAt
        node {
          nextToken
        }
      }
      inEdges {
        items {
          baseType
          id
          startNodeID
          endNodeID
          createdAt
          updatedAt
        }
        nextToken
      }
      outEdges {
        items {
          baseType
          id
          startNodeID
          endNodeID
          createdAt
          updatedAt
        }
        nextToken
      }
      weight {
        id
        baseType
        weightType
        hologramNodeID
        userNodeID
        nodeID
        hologramID
        calculation
        value
        version
        accessPolicy
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        userNode {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        hologramNode {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
      }
      user {
        items {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      hologram {
        items {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      group {
        items {
          id
          groupID
          nodeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteNode = /* GraphQL */ `
  mutation DeleteNode(
    $input: DeleteNodeInput!
    $condition: ModelNodeConditionInput
  ) {
    deleteNode(input: $input, condition: $condition) {
      baseType
      id
      url
      title
      wordCounts
      favicon
      domainID
      weightID
      createdAt
      updatedAt
      block {
        items {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      highlight {
        items {
          id
          baseType
          content
          nodeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      domain {
        baseType
        hostName
        id
        updatedAt
        createdAt
        node {
          nextToken
        }
      }
      inEdges {
        items {
          baseType
          id
          startNodeID
          endNodeID
          createdAt
          updatedAt
        }
        nextToken
      }
      outEdges {
        items {
          baseType
          id
          startNodeID
          endNodeID
          createdAt
          updatedAt
        }
        nextToken
      }
      weight {
        id
        baseType
        weightType
        hologramNodeID
        userNodeID
        nodeID
        hologramID
        calculation
        value
        version
        accessPolicy
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        userNode {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        hologramNode {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
      }
      user {
        items {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      hologram {
        items {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      group {
        items {
          id
          groupID
          nodeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createWeight = /* GraphQL */ `
  mutation CreateWeight(
    $input: CreateWeightInput!
    $condition: ModelWeightConditionInput
  ) {
    createWeight(input: $input, condition: $condition) {
      id
      baseType
      weightType
      hologramNodeID
      userNodeID
      nodeID
      hologramID
      calculation
      value
      version
      accessPolicy
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      userNode {
        id
        userID
        nodeID
        title
        note
        weightID
        version
        owner
        createdAt
        updatedAt
        user {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
      }
      hologramNode {
        id
        hologramID
        title
        nodeID
        accessPolicy
        weightID
        createdByID
        owner
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        group {
          nextToken
        }
      }
    }
  }
`;
export const updateWeight = /* GraphQL */ `
  mutation UpdateWeight(
    $input: UpdateWeightInput!
    $condition: ModelWeightConditionInput
  ) {
    updateWeight(input: $input, condition: $condition) {
      id
      baseType
      weightType
      hologramNodeID
      userNodeID
      nodeID
      hologramID
      calculation
      value
      version
      accessPolicy
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      userNode {
        id
        userID
        nodeID
        title
        note
        weightID
        version
        owner
        createdAt
        updatedAt
        user {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
      }
      hologramNode {
        id
        hologramID
        title
        nodeID
        accessPolicy
        weightID
        createdByID
        owner
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        group {
          nextToken
        }
      }
    }
  }
`;
export const deleteWeight = /* GraphQL */ `
  mutation DeleteWeight(
    $input: DeleteWeightInput!
    $condition: ModelWeightConditionInput
  ) {
    deleteWeight(input: $input, condition: $condition) {
      id
      baseType
      weightType
      hologramNodeID
      userNodeID
      nodeID
      hologramID
      calculation
      value
      version
      accessPolicy
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      userNode {
        id
        userID
        nodeID
        title
        note
        weightID
        version
        owner
        createdAt
        updatedAt
        user {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
      }
      hologramNode {
        id
        hologramID
        title
        nodeID
        accessPolicy
        weightID
        createdByID
        owner
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        group {
          nextToken
        }
      }
    }
  }
`;
export const createImage = /* GraphQL */ `
  mutation CreateImage(
    $input: CreateImageInput!
    $condition: ModelImageConditionInput
  ) {
    createImage(input: $input, condition: $condition) {
      id
      baseType
      createdByID
      fullsize {
        region
        bucket
        key
      }
      thumbnail {
        region
        bucket
        key
      }
      contentType
      height
      width
      size
      owner
      createdAt
      updatedAt
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
    }
  }
`;
export const updateImage = /* GraphQL */ `
  mutation UpdateImage(
    $input: UpdateImageInput!
    $condition: ModelImageConditionInput
  ) {
    updateImage(input: $input, condition: $condition) {
      id
      baseType
      createdByID
      fullsize {
        region
        bucket
        key
      }
      thumbnail {
        region
        bucket
        key
      }
      contentType
      height
      width
      size
      owner
      createdAt
      updatedAt
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
    }
  }
`;
export const deleteImage = /* GraphQL */ `
  mutation DeleteImage(
    $input: DeleteImageInput!
    $condition: ModelImageConditionInput
  ) {
    deleteImage(input: $input, condition: $condition) {
      id
      baseType
      createdByID
      fullsize {
        region
        bucket
        key
      }
      thumbnail {
        region
        bucket
        key
      }
      contentType
      height
      width
      size
      owner
      createdAt
      updatedAt
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      baseType
      id
      title
      createdByID
      createdAt
      updatedAt
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      hologram {
        items {
          id
          baseType
          hologramID
          groupID
          color
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      hologramNode {
        items {
          id
          baseType
          owner
          hologramNodeID
          groupID
          createdAt
          updatedAt
        }
        nextToken
      }
      node {
        items {
          id
          groupID
          nodeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      edge {
        items {
          id
          groupID
          edgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      baseType
      id
      title
      createdByID
      createdAt
      updatedAt
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      hologram {
        items {
          id
          baseType
          hologramID
          groupID
          color
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      hologramNode {
        items {
          id
          baseType
          owner
          hologramNodeID
          groupID
          createdAt
          updatedAt
        }
        nextToken
      }
      node {
        items {
          id
          groupID
          nodeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      edge {
        items {
          id
          groupID
          edgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      baseType
      id
      title
      createdByID
      createdAt
      updatedAt
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      hologram {
        items {
          id
          baseType
          hologramID
          groupID
          color
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      hologramNode {
        items {
          id
          baseType
          owner
          hologramNodeID
          groupID
          createdAt
          updatedAt
        }
        nextToken
      }
      node {
        items {
          id
          groupID
          nodeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      edge {
        items {
          id
          groupID
          edgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createPath = /* GraphQL */ `
  mutation CreatePath(
    $input: CreatePathInput!
    $condition: ModelPathConditionInput
  ) {
    createPath(input: $input, condition: $condition) {
      baseType
      id
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updatePath = /* GraphQL */ `
  mutation UpdatePath(
    $input: UpdatePathInput!
    $condition: ModelPathConditionInput
  ) {
    updatePath(input: $input, condition: $condition) {
      baseType
      id
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deletePath = /* GraphQL */ `
  mutation DeletePath(
    $input: DeletePathInput!
    $condition: ModelPathConditionInput
  ) {
    deletePath(input: $input, condition: $condition) {
      baseType
      id
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createCommunity = /* GraphQL */ `
  mutation CreateCommunity(
    $input: CreateCommunityInput!
    $condition: ModelCommunityConditionInput
  ) {
    createCommunity(input: $input, condition: $condition) {
      baseType
      id
      name
      owner
      createdAt
      updatedAt
      hologram {
        items {
          id
          hologramID
          communityID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateCommunity = /* GraphQL */ `
  mutation UpdateCommunity(
    $input: UpdateCommunityInput!
    $condition: ModelCommunityConditionInput
  ) {
    updateCommunity(input: $input, condition: $condition) {
      baseType
      id
      name
      owner
      createdAt
      updatedAt
      hologram {
        items {
          id
          hologramID
          communityID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteCommunity = /* GraphQL */ `
  mutation DeleteCommunity(
    $input: DeleteCommunityInput!
    $condition: ModelCommunityConditionInput
  ) {
    deleteCommunity(input: $input, condition: $condition) {
      baseType
      id
      name
      owner
      createdAt
      updatedAt
      hologram {
        items {
          id
          hologramID
          communityID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      baseType
      postType
      hologramID
      title
      content
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      block {
        items {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      comment {
        items {
          id
          baseType
          postID
          content
          parentCommentID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      baseType
      postType
      hologramID
      title
      content
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      block {
        items {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      comment {
        items {
          id
          baseType
          postID
          content
          parentCommentID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      baseType
      postType
      hologramID
      title
      content
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      block {
        items {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      comment {
        items {
          id
          baseType
          postID
          content
          parentCommentID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      baseType
      postID
      content
      parentCommentID
      owner
      createdAt
      updatedAt
      block {
        items {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      post {
        id
        baseType
        postType
        hologramID
        title
        content
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        block {
          nextToken
        }
        comment {
          nextToken
        }
      }
      parentComment {
        id
        baseType
        postID
        content
        parentCommentID
        owner
        createdAt
        updatedAt
        block {
          nextToken
        }
        post {
          id
          baseType
          postType
          hologramID
          title
          content
          owner
          createdAt
          updatedAt
        }
        parentComment {
          id
          baseType
          postID
          content
          parentCommentID
          owner
          createdAt
          updatedAt
        }
        childComment {
          nextToken
        }
      }
      childComment {
        items {
          id
          baseType
          postID
          content
          parentCommentID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      baseType
      postID
      content
      parentCommentID
      owner
      createdAt
      updatedAt
      block {
        items {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      post {
        id
        baseType
        postType
        hologramID
        title
        content
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        block {
          nextToken
        }
        comment {
          nextToken
        }
      }
      parentComment {
        id
        baseType
        postID
        content
        parentCommentID
        owner
        createdAt
        updatedAt
        block {
          nextToken
        }
        post {
          id
          baseType
          postType
          hologramID
          title
          content
          owner
          createdAt
          updatedAt
        }
        parentComment {
          id
          baseType
          postID
          content
          parentCommentID
          owner
          createdAt
          updatedAt
        }
        childComment {
          nextToken
        }
      }
      childComment {
        items {
          id
          baseType
          postID
          content
          parentCommentID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      baseType
      postID
      content
      parentCommentID
      owner
      createdAt
      updatedAt
      block {
        items {
          id
          baseType
          belongTo
          blockType
          content
          html
          url
          title
          level
          nodify
          accessPolicy
          parentBlockID
          hologramID
          postID
          commentID
          nodeID
          edgeID
          groupID
          ownerUserNodeID
          ownerUserEdgeID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      post {
        id
        baseType
        postType
        hologramID
        title
        content
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        block {
          nextToken
        }
        comment {
          nextToken
        }
      }
      parentComment {
        id
        baseType
        postID
        content
        parentCommentID
        owner
        createdAt
        updatedAt
        block {
          nextToken
        }
        post {
          id
          baseType
          postType
          hologramID
          title
          content
          owner
          createdAt
          updatedAt
        }
        parentComment {
          id
          baseType
          postID
          content
          parentCommentID
          owner
          createdAt
          updatedAt
        }
        childComment {
          nextToken
        }
      }
      childComment {
        items {
          id
          baseType
          postID
          content
          parentCommentID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      title
      context
      options
      type
      subType
      createdAt
      updatedAt
      questionnaire {
        items {
          id
          questionnaireID
          questionID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
      title
      context
      options
      type
      subType
      createdAt
      updatedAt
      questionnaire {
        items {
          id
          questionnaireID
          questionID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion(
    $input: DeleteQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    deleteQuestion(input: $input, condition: $condition) {
      id
      title
      context
      options
      type
      subType
      createdAt
      updatedAt
      questionnaire {
        items {
          id
          questionnaireID
          questionID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createQuestionnaire = /* GraphQL */ `
  mutation CreateQuestionnaire(
    $input: CreateQuestionnaireInput!
    $condition: ModelQuestionnaireConditionInput
  ) {
    createQuestionnaire(input: $input, condition: $condition) {
      id
      title
      context
      triggerStage
      questionsOrder
      ending
      createdAt
      updatedAt
      user {
        items {
          id
          userID
          questionnaireID
          isFinished
          answer
          createdAt
          updatedAt
        }
        nextToken
      }
      question {
        items {
          id
          questionnaireID
          questionID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateQuestionnaire = /* GraphQL */ `
  mutation UpdateQuestionnaire(
    $input: UpdateQuestionnaireInput!
    $condition: ModelQuestionnaireConditionInput
  ) {
    updateQuestionnaire(input: $input, condition: $condition) {
      id
      title
      context
      triggerStage
      questionsOrder
      ending
      createdAt
      updatedAt
      user {
        items {
          id
          userID
          questionnaireID
          isFinished
          answer
          createdAt
          updatedAt
        }
        nextToken
      }
      question {
        items {
          id
          questionnaireID
          questionID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteQuestionnaire = /* GraphQL */ `
  mutation DeleteQuestionnaire(
    $input: DeleteQuestionnaireInput!
    $condition: ModelQuestionnaireConditionInput
  ) {
    deleteQuestionnaire(input: $input, condition: $condition) {
      id
      title
      context
      triggerStage
      questionsOrder
      ending
      createdAt
      updatedAt
      user {
        items {
          id
          userID
          questionnaireID
          isFinished
          answer
          createdAt
          updatedAt
        }
        nextToken
      }
      question {
        items {
          id
          questionnaireID
          questionID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createUserQuestionnaire = /* GraphQL */ `
  mutation CreateUserQuestionnaire(
    $input: CreateUserQuestionnaireInput!
    $condition: ModelUserQuestionnaireConditionInput
  ) {
    createUserQuestionnaire(input: $input, condition: $condition) {
      id
      userID
      questionnaireID
      isFinished
      answer
      createdAt
      updatedAt
      user {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      questionnaire {
        id
        title
        context
        triggerStage
        questionsOrder
        ending
        createdAt
        updatedAt
        user {
          nextToken
        }
        question {
          nextToken
        }
      }
    }
  }
`;
export const updateUserQuestionnaire = /* GraphQL */ `
  mutation UpdateUserQuestionnaire(
    $input: UpdateUserQuestionnaireInput!
    $condition: ModelUserQuestionnaireConditionInput
  ) {
    updateUserQuestionnaire(input: $input, condition: $condition) {
      id
      userID
      questionnaireID
      isFinished
      answer
      createdAt
      updatedAt
      user {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      questionnaire {
        id
        title
        context
        triggerStage
        questionsOrder
        ending
        createdAt
        updatedAt
        user {
          nextToken
        }
        question {
          nextToken
        }
      }
    }
  }
`;
export const deleteUserQuestionnaire = /* GraphQL */ `
  mutation DeleteUserQuestionnaire(
    $input: DeleteUserQuestionnaireInput!
    $condition: ModelUserQuestionnaireConditionInput
  ) {
    deleteUserQuestionnaire(input: $input, condition: $condition) {
      id
      userID
      questionnaireID
      isFinished
      answer
      createdAt
      updatedAt
      user {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      questionnaire {
        id
        title
        context
        triggerStage
        questionsOrder
        ending
        createdAt
        updatedAt
        user {
          nextToken
        }
        question {
          nextToken
        }
      }
    }
  }
`;
export const createQuestionnaireQuestion = /* GraphQL */ `
  mutation CreateQuestionnaireQuestion(
    $input: CreateQuestionnaireQuestionInput!
    $condition: ModelQuestionnaireQuestionConditionInput
  ) {
    createQuestionnaireQuestion(input: $input, condition: $condition) {
      id
      questionnaireID
      questionID
      createdAt
      updatedAt
      question {
        id
        title
        context
        options
        type
        subType
        createdAt
        updatedAt
        questionnaire {
          nextToken
        }
      }
      questionnaire {
        id
        title
        context
        triggerStage
        questionsOrder
        ending
        createdAt
        updatedAt
        user {
          nextToken
        }
        question {
          nextToken
        }
      }
    }
  }
`;
export const updateQuestionnaireQuestion = /* GraphQL */ `
  mutation UpdateQuestionnaireQuestion(
    $input: UpdateQuestionnaireQuestionInput!
    $condition: ModelQuestionnaireQuestionConditionInput
  ) {
    updateQuestionnaireQuestion(input: $input, condition: $condition) {
      id
      questionnaireID
      questionID
      createdAt
      updatedAt
      question {
        id
        title
        context
        options
        type
        subType
        createdAt
        updatedAt
        questionnaire {
          nextToken
        }
      }
      questionnaire {
        id
        title
        context
        triggerStage
        questionsOrder
        ending
        createdAt
        updatedAt
        user {
          nextToken
        }
        question {
          nextToken
        }
      }
    }
  }
`;
export const deleteQuestionnaireQuestion = /* GraphQL */ `
  mutation DeleteQuestionnaireQuestion(
    $input: DeleteQuestionnaireQuestionInput!
    $condition: ModelQuestionnaireQuestionConditionInput
  ) {
    deleteQuestionnaireQuestion(input: $input, condition: $condition) {
      id
      questionnaireID
      questionID
      createdAt
      updatedAt
      question {
        id
        title
        context
        options
        type
        subType
        createdAt
        updatedAt
        questionnaire {
          nextToken
        }
      }
      questionnaire {
        id
        title
        context
        triggerStage
        questionsOrder
        ending
        createdAt
        updatedAt
        user {
          nextToken
        }
        question {
          nextToken
        }
      }
    }
  }
`;
export const createIssue = /* GraphQL */ `
  mutation CreateIssue(
    $input: CreateIssueInput!
    $condition: ModelIssueConditionInput
  ) {
    createIssue(input: $input, condition: $condition) {
      id
      baseType
      issueType
      url
      description
      createdByID
      createdAt
      updatedAt
    }
  }
`;
export const updateIssue = /* GraphQL */ `
  mutation UpdateIssue(
    $input: UpdateIssueInput!
    $condition: ModelIssueConditionInput
  ) {
    updateIssue(input: $input, condition: $condition) {
      id
      baseType
      issueType
      url
      description
      createdByID
      createdAt
      updatedAt
    }
  }
`;
export const deleteIssue = /* GraphQL */ `
  mutation DeleteIssue(
    $input: DeleteIssueInput!
    $condition: ModelIssueConditionInput
  ) {
    deleteIssue(input: $input, condition: $condition) {
      id
      baseType
      issueType
      url
      description
      createdByID
      createdAt
      updatedAt
    }
  }
`;
export const createUserNode = /* GraphQL */ `
  mutation CreateUserNode(
    $input: CreateUserNodeInput!
    $condition: ModelUserNodeConditionInput
  ) {
    createUserNode(input: $input, condition: $condition) {
      id
      userID
      nodeID
      title
      note
      weightID
      version
      owner
      createdAt
      updatedAt
      user {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      weight {
        id
        baseType
        weightType
        hologramNodeID
        userNodeID
        nodeID
        hologramID
        calculation
        value
        version
        accessPolicy
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        userNode {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        hologramNode {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateUserNode = /* GraphQL */ `
  mutation UpdateUserNode(
    $input: UpdateUserNodeInput!
    $condition: ModelUserNodeConditionInput
  ) {
    updateUserNode(input: $input, condition: $condition) {
      id
      userID
      nodeID
      title
      note
      weightID
      version
      owner
      createdAt
      updatedAt
      user {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      weight {
        id
        baseType
        weightType
        hologramNodeID
        userNodeID
        nodeID
        hologramID
        calculation
        value
        version
        accessPolicy
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        userNode {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        hologramNode {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteUserNode = /* GraphQL */ `
  mutation DeleteUserNode(
    $input: DeleteUserNodeInput!
    $condition: ModelUserNodeConditionInput
  ) {
    deleteUserNode(input: $input, condition: $condition) {
      id
      userID
      nodeID
      title
      note
      weightID
      version
      owner
      createdAt
      updatedAt
      user {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      weight {
        id
        baseType
        weightType
        hologramNodeID
        userNodeID
        nodeID
        hologramID
        calculation
        value
        version
        accessPolicy
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        userNode {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        hologramNode {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createUserEdge = /* GraphQL */ `
  mutation CreateUserEdge(
    $input: CreateUserEdgeInput!
    $condition: ModelUserEdgeConditionInput
  ) {
    createUserEdge(input: $input, condition: $condition) {
      id
      userID
      edgeID
      note
      owner
      createdAt
      updatedAt
      user {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      edge {
        baseType
        id
        startNodeID
        endNodeID
        createdAt
        updatedAt
        block {
          nextToken
        }
        startNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        endNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
    }
  }
`;
export const updateUserEdge = /* GraphQL */ `
  mutation UpdateUserEdge(
    $input: UpdateUserEdgeInput!
    $condition: ModelUserEdgeConditionInput
  ) {
    updateUserEdge(input: $input, condition: $condition) {
      id
      userID
      edgeID
      note
      owner
      createdAt
      updatedAt
      user {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      edge {
        baseType
        id
        startNodeID
        endNodeID
        createdAt
        updatedAt
        block {
          nextToken
        }
        startNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        endNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
    }
  }
`;
export const deleteUserEdge = /* GraphQL */ `
  mutation DeleteUserEdge(
    $input: DeleteUserEdgeInput!
    $condition: ModelUserEdgeConditionInput
  ) {
    deleteUserEdge(input: $input, condition: $condition) {
      id
      userID
      edgeID
      note
      owner
      createdAt
      updatedAt
      user {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      edge {
        baseType
        id
        startNodeID
        endNodeID
        createdAt
        updatedAt
        block {
          nextToken
        }
        startNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        endNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
    }
  }
`;
export const createHologramGroup = /* GraphQL */ `
  mutation CreateHologramGroup(
    $input: CreateHologramGroupInput!
    $condition: ModelHologramGroupConditionInput
  ) {
    createHologramGroup(input: $input, condition: $condition) {
      id
      baseType
      hologramID
      groupID
      color
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      group {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          nextToken
        }
        hologramNode {
          nextToken
        }
        node {
          nextToken
        }
        edge {
          nextToken
        }
      }
    }
  }
`;
export const updateHologramGroup = /* GraphQL */ `
  mutation UpdateHologramGroup(
    $input: UpdateHologramGroupInput!
    $condition: ModelHologramGroupConditionInput
  ) {
    updateHologramGroup(input: $input, condition: $condition) {
      id
      baseType
      hologramID
      groupID
      color
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      group {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          nextToken
        }
        hologramNode {
          nextToken
        }
        node {
          nextToken
        }
        edge {
          nextToken
        }
      }
    }
  }
`;
export const deleteHologramGroup = /* GraphQL */ `
  mutation DeleteHologramGroup(
    $input: DeleteHologramGroupInput!
    $condition: ModelHologramGroupConditionInput
  ) {
    deleteHologramGroup(input: $input, condition: $condition) {
      id
      baseType
      hologramID
      groupID
      color
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      group {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          nextToken
        }
        hologramNode {
          nextToken
        }
        node {
          nextToken
        }
        edge {
          nextToken
        }
      }
    }
  }
`;
export const createHologramNode = /* GraphQL */ `
  mutation CreateHologramNode(
    $input: CreateHologramNodeInput!
    $condition: ModelHologramNodeConditionInput
  ) {
    createHologramNode(input: $input, condition: $condition) {
      id
      hologramID
      title
      nodeID
      accessPolicy
      weightID
      createdByID
      owner
      createdAt
      updatedAt
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      weight {
        id
        baseType
        weightType
        hologramNodeID
        userNodeID
        nodeID
        hologramID
        calculation
        value
        version
        accessPolicy
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        userNode {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        hologramNode {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
      }
      group {
        items {
          id
          baseType
          owner
          hologramNodeID
          groupID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateHologramNode = /* GraphQL */ `
  mutation UpdateHologramNode(
    $input: UpdateHologramNodeInput!
    $condition: ModelHologramNodeConditionInput
  ) {
    updateHologramNode(input: $input, condition: $condition) {
      id
      hologramID
      title
      nodeID
      accessPolicy
      weightID
      createdByID
      owner
      createdAt
      updatedAt
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      weight {
        id
        baseType
        weightType
        hologramNodeID
        userNodeID
        nodeID
        hologramID
        calculation
        value
        version
        accessPolicy
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        userNode {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        hologramNode {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
      }
      group {
        items {
          id
          baseType
          owner
          hologramNodeID
          groupID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteHologramNode = /* GraphQL */ `
  mutation DeleteHologramNode(
    $input: DeleteHologramNodeInput!
    $condition: ModelHologramNodeConditionInput
  ) {
    deleteHologramNode(input: $input, condition: $condition) {
      id
      hologramID
      title
      nodeID
      accessPolicy
      weightID
      createdByID
      owner
      createdAt
      updatedAt
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      weight {
        id
        baseType
        weightType
        hologramNodeID
        userNodeID
        nodeID
        hologramID
        calculation
        value
        version
        accessPolicy
        owner
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        userNode {
          id
          userID
          nodeID
          title
          note
          weightID
          version
          owner
          createdAt
          updatedAt
        }
        hologramNode {
          id
          hologramID
          title
          nodeID
          accessPolicy
          weightID
          createdByID
          owner
          createdAt
          updatedAt
        }
      }
      group {
        items {
          id
          baseType
          owner
          hologramNodeID
          groupID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createHologramNodeGroup = /* GraphQL */ `
  mutation CreateHologramNodeGroup(
    $input: CreateHologramNodeGroupInput!
    $condition: ModelHologramNodeGroupConditionInput
  ) {
    createHologramNodeGroup(input: $input, condition: $condition) {
      id
      baseType
      owner
      hologramNodeID
      groupID
      createdAt
      updatedAt
      group {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          nextToken
        }
        hologramNode {
          nextToken
        }
        node {
          nextToken
        }
        edge {
          nextToken
        }
      }
      hologramNode {
        id
        hologramID
        title
        nodeID
        accessPolicy
        weightID
        createdByID
        owner
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        group {
          nextToken
        }
      }
    }
  }
`;
export const updateHologramNodeGroup = /* GraphQL */ `
  mutation UpdateHologramNodeGroup(
    $input: UpdateHologramNodeGroupInput!
    $condition: ModelHologramNodeGroupConditionInput
  ) {
    updateHologramNodeGroup(input: $input, condition: $condition) {
      id
      baseType
      owner
      hologramNodeID
      groupID
      createdAt
      updatedAt
      group {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          nextToken
        }
        hologramNode {
          nextToken
        }
        node {
          nextToken
        }
        edge {
          nextToken
        }
      }
      hologramNode {
        id
        hologramID
        title
        nodeID
        accessPolicy
        weightID
        createdByID
        owner
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        group {
          nextToken
        }
      }
    }
  }
`;
export const deleteHologramNodeGroup = /* GraphQL */ `
  mutation DeleteHologramNodeGroup(
    $input: DeleteHologramNodeGroupInput!
    $condition: ModelHologramNodeGroupConditionInput
  ) {
    deleteHologramNodeGroup(input: $input, condition: $condition) {
      id
      baseType
      owner
      hologramNodeID
      groupID
      createdAt
      updatedAt
      group {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          nextToken
        }
        hologramNode {
          nextToken
        }
        node {
          nextToken
        }
        edge {
          nextToken
        }
      }
      hologramNode {
        id
        hologramID
        title
        nodeID
        accessPolicy
        weightID
        createdByID
        owner
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          weightID
          updatedAt
          createdAt
          owner
          action
          userPresent
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        group {
          nextToken
        }
      }
    }
  }
`;
export const createHologramCommunity = /* GraphQL */ `
  mutation CreateHologramCommunity(
    $input: CreateHologramCommunityInput!
    $condition: ModelHologramCommunityConditionInput
  ) {
    createHologramCommunity(input: $input, condition: $condition) {
      id
      hologramID
      communityID
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      community {
        baseType
        id
        name
        owner
        createdAt
        updatedAt
        hologram {
          nextToken
        }
      }
    }
  }
`;
export const updateHologramCommunity = /* GraphQL */ `
  mutation UpdateHologramCommunity(
    $input: UpdateHologramCommunityInput!
    $condition: ModelHologramCommunityConditionInput
  ) {
    updateHologramCommunity(input: $input, condition: $condition) {
      id
      hologramID
      communityID
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      community {
        baseType
        id
        name
        owner
        createdAt
        updatedAt
        hologram {
          nextToken
        }
      }
    }
  }
`;
export const deleteHologramCommunity = /* GraphQL */ `
  mutation DeleteHologramCommunity(
    $input: DeleteHologramCommunityInput!
    $condition: ModelHologramCommunityConditionInput
  ) {
    deleteHologramCommunity(input: $input, condition: $condition) {
      id
      hologramID
      communityID
      owner
      createdAt
      updatedAt
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      community {
        baseType
        id
        name
        owner
        createdAt
        updatedAt
        hologram {
          nextToken
        }
      }
    }
  }
`;
export const createGroupNode = /* GraphQL */ `
  mutation CreateGroupNode(
    $input: CreateGroupNodeInput!
    $condition: ModelGroupNodeConditionInput
  ) {
    createGroupNode(input: $input, condition: $condition) {
      id
      groupID
      nodeID
      owner
      createdAt
      updatedAt
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      group {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          nextToken
        }
        hologramNode {
          nextToken
        }
        node {
          nextToken
        }
        edge {
          nextToken
        }
      }
    }
  }
`;
export const updateGroupNode = /* GraphQL */ `
  mutation UpdateGroupNode(
    $input: UpdateGroupNodeInput!
    $condition: ModelGroupNodeConditionInput
  ) {
    updateGroupNode(input: $input, condition: $condition) {
      id
      groupID
      nodeID
      owner
      createdAt
      updatedAt
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      group {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          nextToken
        }
        hologramNode {
          nextToken
        }
        node {
          nextToken
        }
        edge {
          nextToken
        }
      }
    }
  }
`;
export const deleteGroupNode = /* GraphQL */ `
  mutation DeleteGroupNode(
    $input: DeleteGroupNodeInput!
    $condition: ModelGroupNodeConditionInput
  ) {
    deleteGroupNode(input: $input, condition: $condition) {
      id
      groupID
      nodeID
      owner
      createdAt
      updatedAt
      node {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      group {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          nextToken
        }
        hologramNode {
          nextToken
        }
        node {
          nextToken
        }
        edge {
          nextToken
        }
      }
    }
  }
`;
export const createHologramContributor = /* GraphQL */ `
  mutation CreateHologramContributor(
    $input: CreateHologramContributorInput!
    $condition: ModelHologramContributorConditionInput
  ) {
    createHologramContributor(input: $input, condition: $condition) {
      id
      baseType
      hologramID
      contributorID
      owner
      createdAt
      updatedAt
      contributor {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
    }
  }
`;
export const updateHologramContributor = /* GraphQL */ `
  mutation UpdateHologramContributor(
    $input: UpdateHologramContributorInput!
    $condition: ModelHologramContributorConditionInput
  ) {
    updateHologramContributor(input: $input, condition: $condition) {
      id
      baseType
      hologramID
      contributorID
      owner
      createdAt
      updatedAt
      contributor {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
    }
  }
`;
export const deleteHologramContributor = /* GraphQL */ `
  mutation DeleteHologramContributor(
    $input: DeleteHologramContributorInput!
    $condition: ModelHologramContributorConditionInput
  ) {
    deleteHologramContributor(input: $input, condition: $condition) {
      id
      baseType
      hologramID
      contributorID
      owner
      createdAt
      updatedAt
      contributor {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
    }
  }
`;
export const createHologramEdge = /* GraphQL */ `
  mutation CreateHologramEdge(
    $input: CreateHologramEdgeInput!
    $condition: ModelHologramEdgeConditionInput
  ) {
    createHologramEdge(input: $input, condition: $condition) {
      id
      hologramID
      edgeID
      startNodeID
      endNodeID
      accessPolicy
      createdByID
      owner
      createdAt
      updatedAt
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      edge {
        baseType
        id
        startNodeID
        endNodeID
        createdAt
        updatedAt
        block {
          nextToken
        }
        startNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        endNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      startNode {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      endNode {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
    }
  }
`;
export const updateHologramEdge = /* GraphQL */ `
  mutation UpdateHologramEdge(
    $input: UpdateHologramEdgeInput!
    $condition: ModelHologramEdgeConditionInput
  ) {
    updateHologramEdge(input: $input, condition: $condition) {
      id
      hologramID
      edgeID
      startNodeID
      endNodeID
      accessPolicy
      createdByID
      owner
      createdAt
      updatedAt
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      edge {
        baseType
        id
        startNodeID
        endNodeID
        createdAt
        updatedAt
        block {
          nextToken
        }
        startNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        endNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      startNode {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      endNode {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
    }
  }
`;
export const deleteHologramEdge = /* GraphQL */ `
  mutation DeleteHologramEdge(
    $input: DeleteHologramEdgeInput!
    $condition: ModelHologramEdgeConditionInput
  ) {
    deleteHologramEdge(input: $input, condition: $condition) {
      id
      hologramID
      edgeID
      startNodeID
      endNodeID
      accessPolicy
      createdByID
      owner
      createdAt
      updatedAt
      createdBy {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        emailVerified
        firstName
        lastName
        createdAt
        updatedAt
        onBoardingIsFinished
        onBoardingQuestionnaireIsFinished
        owner
        hologram {
          nextToken
        }
        likeHologram {
          nextToken
        }
        createdImage {
          nextToken
        }
        questionnaire {
          nextToken
        }
        ownNode {
          nextToken
        }
        ownEdge {
          nextToken
        }
        contributedHologram {
          nextToken
        }
      }
      hologram {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        action
        userPresent
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        block {
          nextToken
        }
        like {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        post {
          nextToken
        }
        group {
          nextToken
        }
        node {
          nextToken
        }
        community {
          nextToken
        }
        contributor {
          nextToken
        }
        edge {
          nextToken
        }
      }
      edge {
        baseType
        id
        startNodeID
        endNodeID
        createdAt
        updatedAt
        block {
          nextToken
        }
        startNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        endNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      startNode {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      endNode {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
        block {
          nextToken
        }
        highlight {
          nextToken
        }
        domain {
          baseType
          hostName
          id
          updatedAt
          createdAt
        }
        inEdges {
          nextToken
        }
        outEdges {
          nextToken
        }
        weight {
          id
          baseType
          weightType
          hologramNodeID
          userNodeID
          nodeID
          hologramID
          calculation
          value
          version
          accessPolicy
          owner
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
    }
  }
`;
export const createGroupEdge = /* GraphQL */ `
  mutation CreateGroupEdge(
    $input: CreateGroupEdgeInput!
    $condition: ModelGroupEdgeConditionInput
  ) {
    createGroupEdge(input: $input, condition: $condition) {
      id
      groupID
      edgeID
      owner
      createdAt
      updatedAt
      edge {
        baseType
        id
        startNodeID
        endNodeID
        createdAt
        updatedAt
        block {
          nextToken
        }
        startNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        endNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      group {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          nextToken
        }
        hologramNode {
          nextToken
        }
        node {
          nextToken
        }
        edge {
          nextToken
        }
      }
    }
  }
`;
export const updateGroupEdge = /* GraphQL */ `
  mutation UpdateGroupEdge(
    $input: UpdateGroupEdgeInput!
    $condition: ModelGroupEdgeConditionInput
  ) {
    updateGroupEdge(input: $input, condition: $condition) {
      id
      groupID
      edgeID
      owner
      createdAt
      updatedAt
      edge {
        baseType
        id
        startNodeID
        endNodeID
        createdAt
        updatedAt
        block {
          nextToken
        }
        startNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        endNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      group {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          nextToken
        }
        hologramNode {
          nextToken
        }
        node {
          nextToken
        }
        edge {
          nextToken
        }
      }
    }
  }
`;
export const deleteGroupEdge = /* GraphQL */ `
  mutation DeleteGroupEdge(
    $input: DeleteGroupEdgeInput!
    $condition: ModelGroupEdgeConditionInput
  ) {
    deleteGroupEdge(input: $input, condition: $condition) {
      id
      groupID
      edgeID
      owner
      createdAt
      updatedAt
      edge {
        baseType
        id
        startNodeID
        endNodeID
        createdAt
        updatedAt
        block {
          nextToken
        }
        startNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        endNode {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        hologram {
          nextToken
        }
        group {
          nextToken
        }
      }
      group {
        baseType
        id
        title
        createdByID
        createdAt
        updatedAt
        createdBy {
          id
          isAdmin
          baseType
          username
          email
          emailVerified
          firstName
          lastName
          createdAt
          updatedAt
          onBoardingIsFinished
          onBoardingQuestionnaireIsFinished
          owner
        }
        hologram {
          nextToken
        }
        hologramNode {
          nextToken
        }
        node {
          nextToken
        }
        edge {
          nextToken
        }
      }
    }
  }
`;
