<template>
  <div
    v-bind:style="containerStyle"
    v-bind:class="getContainerAnimationClass()"
    class="flex flex-col w-full min-h-screen dashboard-base bg-sd-base03-brblack"
  >
    <DashboardNavbar v-if="$route.name !== 'Hologram'" />
    <div class="flex w-full h-full">
      <router-view />
    </div>
    <div class="fixed bottom-8 right-8">
      <helpButton />
    </div>
    <teleport to="#modals">
      <div v-if="hologramSettingsModalOpen">
        <hologramSettingsModal />
      </div>
    </teleport>
    <teleport to="#modals">
      <div v-if="confirmHologramDeletionModalOpen">
        <confirmHologramDeletionModal />
      </div>
    </teleport>
    <teleport to="#modals">
      <div v-if="changeHologramAccessPolicyModalOpen">
        <changeHologramAccessPolicyModal />
      </div>
    </teleport>

    <!--
    <teleport to="#modals">
      <div v-if="hologramIntroModalOpen">
        <hologramIntroModal />
      </div>
    </teleport>
    -->
  </div>
</template>

<script>

import DashboardNavbar from "../components/hologram/DashboardNavbar.vue"
import hologramSettingsModal from "@/components/modals/hologramSettingsModal.vue";
import confirmHologramDeletionModal from "@/components/modals/confirmHologramDeletionModal.vue";
import changeHologramAccessPolicyModal from "@/components/modals/changeHologramAccessPolicyModal.vue";
//import hologramIntroModal from "@/components/modals/hologramIntroModal.vue";
import helpButton from "../components/accessibility/helpButton";
import { getElementPosition } from "../utilities/utilities";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { ref, computed, watch, onMounted } from "vue";
export default {
  name: "Dashboard",
  components: {
    hologramSettingsModal,
    confirmHologramDeletionModal,
    changeHologramAccessPolicyModal,
    //hologramIntroModal,
    helpButton,
    DashboardNavbar,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const hologramTitleIsReady = ref(false);
    const hologramSidebarOpen = computed(() => store.getters["sidebar/hologramSidebarOpen"]);
    const hologramSidebarWidth = computed(() => store.getters["sidebar/hologramSidebarWidth"]);
    const hologramTitleEditorModalOpen = computed(() => store.getters["modal/hologramTitleEditorModalOpen"]);
    const hologramSettingsModalOpen = computed(() => store.getters["modal/hologramSettingsModalOpen"]);
    const hologramSharingModalOpen = computed(() => store.getters["modal/hologramSharingModalOpen"]);
    const user = computed(() => store.getters["auth/user"]);
    const userData = computed(() => store.getters["auth/userData"]);
    const currentHologramOwner = computed(() => {
      return route.params.username;
    });
    const currentHologram = computed(() => store.getters["hologram/currentHologram"]);
    const sidebarOpen = computed(() => store.getters["sidebar/sidebarOpen"]);
    const sidebarWidth = computed(() => store.getters["sidebar/sidebarWidth"]);
    const confirmHologramDeletionModalOpen = computed(() => store.getters["modal/confirmHologramDeletionModalOpen"]);
    const changeHologramAccessPolicyModalOpen = computed(() => store.getters["modal/changeHologramAccessPolicyModalOpen"]);
    const hologramIntroModalOpen = computed(() => store.getters["modal/hologramIntroModalOpen"]);
    const currentHologramDataReady = computed(() => store.getters["hologram/currentHologramDataReady"]);
    const editingCurrentHologramPermission = computed(() => store.getters["hologram/editingCurrentHologramPermission"]);
    const transactingStatus = computed(() => store.getters["hologram/transactingStatus"]);
    const containerStyle = ref(null);
    

    const prepare = () => {
    
      if (sidebarOpen.value) {
        if (hologramSidebarOpen.value) {
          containerStyle.value = {
            "margin-left": `${sidebarWidth.value}px`,
            "margin-right": `${hologramSidebarWidth.value}px`,
          };
        } else {
          containerStyle.value = {
            "margin-left": `${sidebarWidth.value}px`,
            "margin-right": `0px`,
          };
        }
      } else {
        if (hologramSidebarOpen.value) {
          containerStyle.value = {
            "margin-left": `0px`,
            "margin-right": `${hologramSidebarWidth.value}px`,
          };
        } else {
          containerStyle.value = {
            "margin-left": `0px`,
            "margin-right": `0px`,
          };
        }
      }
    };

    onMounted(prepare);

    const currentUserOnBoard = computed(() => {
      if (user.value) {
        if (route.params.username === user.value.username) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });

    const openEditHologramTitleModal = ($event) => {
      if (hologramTitleEditorModalOpen.value) {
        return;
      }
      const position = getElementPosition($event.target);
      store.dispatch("modal/toggleHologramTitleEditorModal", { data: true });
      store.dispatch("modal/updateHologramTitleEditorModalPosition", {
        data: position,
      });
    };

    const toggleSharingModal = ($event) => {
      if (hologramSharingModalOpen.value) {
        store.dispatch("modal/toggleHologramSharingModal", { data: false });
        store.dispatch("modal/updateHologramSharingModalData", {
          type: "reset",
        });
      } else {
        store.dispatch("modal/toggleHologramSharingModal", { data: true });
        const position = getElementPosition($event.target);
        store.dispatch("modal/updateHologramSharingModalPosition", { data: position });
      }
    };

    const toggleHologramSettingsModal = ($event) => {
      if (hologramSettingsModalOpen.value) {
        store.dispatch("modal/toggleHologramSettingsModal", { data: false });
        store.dispatch("modal/updateHologramSettingsModalTriggeredPosition", {
          type: "update",
          data: {},
        });
      } else {
        store.dispatch("modal/toggleHologramSettingsModal", { data: true });
        const position = getElementPosition($event.target);
        store.dispatch("modal/updateHologramSettingsModalTriggeredPosition", {
          type: "update",
          data: position,
        });
      }
    };

    const getContainerAnimationClass = () => {
      if (route.name == "Hologram") {
        return "transform duration-300";
      }
    };

    watch([sidebarOpen, hologramSidebarOpen], (newValue) => {
      // eslint-disable-line no-unused-vars
      const sidebarOpen = newValue[0];
      const hologramSidebarOpen = newValue[1];
      if (sidebarOpen) {
        if (hologramSidebarOpen) {
          containerStyle.value = {
            "margin-left": `${sidebarWidth.value}px`,
            "margin-right": `${hologramSidebarWidth.value}px`,
          };
        } else {
          containerStyle.value = {
            "margin-left": `${sidebarWidth.value}px`,
            "margin-right": `0px`,
          };
        }
      } else {
        if (hologramSidebarOpen) {
          containerStyle.value = {
            "margin-left": `0px`,
            "margin-right": `${hologramSidebarWidth.value}px`,
          };
        } else {
          containerStyle.value = {
            "margin-left": `0px`,
            "margin-right": `0px`,
          };
        }
      }
    });

    watch(
      () => route.fullPath,
      (newValue, oldValue) => {
        if (newValue === oldValue) return;
        store.dispatch("hologram/setCurrentHologram", null);
        store.dispatch("hologram/setCurrentHologramNodes", []);
        store.dispatch("hologram/resetCurrentHologramEdges");
        store.dispatch("modal/toggleMainSettingsModal", { data: false });
        store.dispatch("modal/toggleCreateNewHologramModal", { data: false });
        store.dispatch("modal/toggleDeleteNodeModal", { data: false });
        store.dispatch("modal/toggleHologramEntityDetailModal", { data: false });
      }
    );

    return {
      // Variable
      user,
      userData,
      currentHologram,
      sidebarOpen,
      sidebarWidth,
      hologramTitleIsReady,
      hologramSidebarOpen,
      hologramSidebarWidth,
      currentHologramOwner,
      currentUserOnBoard,
      hologramTitleEditorModalOpen,
      hologramSettingsModalOpen,
      hologramSharingModalOpen,
      confirmHologramDeletionModalOpen,
      changeHologramAccessPolicyModalOpen,
      hologramIntroModalOpen,
      currentHologramDataReady,
      editingCurrentHologramPermission,
      transactingStatus,
      // Method
      openEditHologramTitleModal,
      getContainerAnimationClass,
      toggleSharingModal,
      toggleHologramSettingsModal,
      containerStyle,
    };
  },
};

/*
onBeforeRouteUpdate((to, from, next) => {
      if (transactingStatus.value) {
        const answer = confirm("You have made changes. Do you want to leave?");
        if (answer) {
          next();
        } else {
          next(false);
        }
      }
      next();
    });
*/
</script>

<style>
</style>

