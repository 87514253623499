<template>
  <div class="fixed inset-0 h-full">
    <div
      class="flex items-end justify-center h-full min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0"
      id="modal-container"
    >
      <transition
        enter-active-class="duration-300 ease-out"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div 
          @click="toggleHologramSettingsModal" 
          class="fixed inset-0"
          v-if="hologramSettingsModalOpen"
        >
          <div  class="absolute inset-0 opacity-50"></div>
        </div>
      </transition>
      <transition
        name="modal-transition"
        enter-active-class="duration-300 ease-out"
        enter-from-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
        enter-to-class="translate-y-0 opacity-100 sm:scale-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="translate-y-0 opacity-100 sm:scale-100"
        leave-to-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
      >
        <div
          class="fixed overflow-y-auto text-left align-bottom transition-all transform rounded-sm shadow-xl w-60"
          role="dialog"
          aria-modal="true"
          id="settings-container"
          v-show="hologramSettingsModalOpen"
          v-bind:style="positionStyle"
        >
          <div class="flex flex-col w-full border rounded-sm bg-sd-base02-black border-sd-base01-brgreen">
            <button
              class="flex flex-col px-4 py-2 hover:bg-sd-base2-white hover:bg-opacity-5 focus:outline-none"
              @click="toggleConfirmHologramDeletionModal"
            >
              <div
                class="flex text-sm font-normal text-left text-sd-base1-brcyan"
              >
                Delete hologram
              </div>
            </button>
            <button
              class="flex flex-col px-4 py-2 hover:bg-sd-base2-white hover:bg-opacity-5 focus:outline-none"
              @click="toggleChangeHologramAccessPolicyModal"
            >
              <div
                class="flex text-sm font-normal text-left text-sd-base1-brcyan"
              >
                Change hologram access policy
              </div>
            </button>
            <!--
            <button
              class="flex flex-col px-4 py-2 hover:bg-sd-base2-white hover:bg-opacity-5 focus:outline-none"
              @click="toggleHologramIntroModal"
            >
              <div
                class="flex text-sm font-normal text-left text-sd-base1-brcyan"
              >
                Edit hologram intro
              </div>
            </button>
            -->
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
//import { API, graphqlOperation } from 'aws-amplify'

export default {
  name:"hologramSettingsModal",
  data() {
    return {
      positionStyle:{}
    }
  },
  mounted() {
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    this.positionStyle = {
      right: screenWidth - this.hologramSettingsModalTriggeredPosition.x - this.hologramSettingsModalTriggeredPosition.width + 'px',
      top: this.hologramSettingsModalTriggeredPosition.y + 48 + 'px'
    }
    window.addEventListener("keydown", this.keydownEscHandler);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.keydownEscHandler);
  },
  methods: {
    keydownEscHandler: function(event){
      if ( event.code !== "Escape" ) return;
      if ( !this.hologramSettingsModalOpen ) return;
      this.toggleHologramSettingsModal()
    },
    toggleConfirmHologramDeletionModal(){
      if (!this.confirmHologramDeletionModalOpen){
        this.$store.dispatch("modal/toggleHologramSettingsModal", {data:false});
        this.$store.dispatch("modal/toggleConfirmHologramDeletionModal", {data:true});
      }
    },
    toggleHologramSettingsModal(){
      if (this.hologramSettingsModalOpen){
        this.$store.dispatch("modal/toggleHologramSettingsModal", {data:false})
        this.$store.dispatch("modal/updateHologramSettingsModalTriggeredPosition", {data:{}, type:"update"});
      }
    },
    toggleChangeHologramAccessPolicyModal(){
      if (!this.changeHologramAccessPolicyModalOpen){
        this.$store.dispatch("modal/toggleHologramSettingsModal", {data:false});
        this.$store.dispatch("modal/toggleChangeHologramAccessPolicyModal", {data:true})
      }
    },
    toggleHologramIntroModal(){
      if (!this.hologramIntroModalOpen){
        this.$store.dispatch("modal/toggleHologramIntroModalOpen", {data:true})
      }
    },
    positionHologramSettingsModal(){
      const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

      return {
        right: screenWidth - this.hologramSettingsModalTriggeredPosition.x - 32 + 'px',
        top: this.hologramSettingsModalTriggeredPosition.y + 'px'
      }
    }
  },
  computed:{
    ...mapGetters({
      hologramSettingsModalTriggeredPosition: "modal/hologramSettingsModalTriggeredPosition",
      hologramSettingsModalOpen: "modal/hologramSettingsModalOpen",
      confirmHologramDeletionModalOpen: "modal/confirmHologramDeletionModalOpen",
      changeHologramAccessPolicyModalOpen: "modal/changeHologramAccessPolicyModalOpen",
      hologramIntroModalOpen: "modal/hologramIntroModalOpen",
    })
  }
};
</script>

<style>
</style>