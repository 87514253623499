/* eslint-disable */

export const getPublicHologram = /* GraphQL */ `
  query getPublicHologram($id: ID!) {
    getHologram(id: $id) {
      id
      baseType
      intro
      title
      graph
      createdByID
      accessPolicy
      layeredBlockData
      createdAt
      updatedAt
    }
  }
`;


export const getHologramAccessPolicy = /* GraphQL */ `
  query getHologramAccessPolicy($id: ID!) {
    getHologram(id: $id) {
      id
      accessPolicy
      title
      createdByID
    }
  }
`;
// Remove unnecessary connection field: Group, Post, Comment, OwnerUserNode, OwnerUserEdge
export const listPublicBlocks = /* GraphQL */ `
  query listPublicBlocks(
    $filter: ModelBlockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlocks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        baseType
        belongTo
        blockType
        content
        html
        url
        title
        level
        nodify
        accessPolicy
        parentBlockID
        hologramID
        postID
        commentID
        nodeID
        edgeID
        groupID
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          accessPolicy
          layeredBlockData
          createdAt
          updatedAt
          owner
        }
        owner
        edge {
          baseType
          id
          startNodeID
          endNodeID
          createdAt
          updatedAt
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;

export const listPublicGroups = /* GraphQL */ `
  query listPublicGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        baseType
        id
        title
        hologramID
        createdAt
        updatedAt
        owner
        node {
          items {
            groupID
            nodeID
          }
        }
        edge {
          items {
            edgeID
          }
        }
      }
      nextToken
    }
  }
`;

export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        baseType
        id
        title
        userID
        hologramID
        accessPolicy
        createdAt
        updatedAt
        owner
        node {
          items {
            nodeID
          }
        }
        edge {
          items {
            edgeID
          }
        }
      }
      nextToken
    }
  }
`;

export const hologramByDateUpdated = /* GraphQL */ `
  query HologramByDateUpdated(
    $accessPolicy: AccessPolicy
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHologramFilterInput
    $limit: Int
    $nextToken: String
  ) {
    hologramByDateUpdated(
      accessPolicy: $accessPolicy
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        baseType
        intro
        title
        graph
        createdByID
        contributorID
        accessPolicy
        weightID
        updatedAt
        createdAt
        owner
        node {
          items {
            nodeID
          }
        }
        edge {
          items {
            edgeID
          }
        },
        createdBy {
          baseType
          username
        }
      }
      nextToken
    }
  }
`;

export const listPublicWeights = /* GraphQL */ `
  query ListWeights(
    $filter: ModelWeightFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWeights(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        baseType
        weightType
        hologramNodeID
        nodeID
        hologramID
        calculation
        value
        version
        accessPolicy
        createdAt
        updatedAt
        hologram {
          id
          baseType
          intro
          title
          graph
          createdByID
          contributorID
          accessPolicy
          layeredBlockData
          weightID
          updatedAt
          createdAt
          version
          owner
        }
        node {
          baseType
          id
          url
          title
          wordCounts
          favicon
          domainID
          weightID
          createdAt
          updatedAt
        }
        hologramNode {
          id
          hologramID
          nodeID
          accessPolicy
          weightID
          createdByID
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
    }
  }
`;


export const superChargedGetHologram = /* GraphQL */ `
  query GetHologram(
    $id: ID!
    $groupLimit: Int
    $groupNextToken: String
    $hologramNodeLimit: Int
    $hologramNodeNextToken: String
    $hologramEdgeLimit: Int
    $hologramEdgeNextToken: String
    $hologramContributorLimit: Int
    $hologramContributorNextToken: String
  ) {
    getHologram(id: $id) {
      id
      baseType
      intro
      title
      graph
      createdByID
      accessPolicy
      weightID
      updatedAt
      createdAt
      owner
      contributorID
      action
      userPresent
      createdBy {
        baseType
        username
      }
      group(limit: $groupLimit, nextToken: $groupNextToken) {
        items {
          baseType
          id
          hologramID
          groupID
          owner
          color
          group {
            id
            baseType
            title
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      node (limit: $hologramNodeLimit, nextToken: $hologramNodeNextToken) {
        items {
          id
          hologramID
          nodeID
          node {
            id
            url
            title
          }
          accessPolicy
          weightID
          createdByID
          weight {
            id
            baseType
            weightType
            calculation
            value
            version
            nodeID
            accessPolicy
            owner
            hologramNodeID
          }
          group {
            items {
              id
              baseType
              owner
              hologramNodeID
              groupID
            }
          }
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      contributor (limit: $hologramContributorLimit, nextToken: $hologramContributorNextToken) {
        items {
          id
          hologramID
          contributorID
          contributor {
            baseType
            username
            email
            avatar {
              region
              bucket
              key
            }
          }
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      edge (limit: $hologramEdgeLimit, nextToken: $hologramEdgeNextToken) {
        items {
          id
          hologramID
          edgeID
          edge {
            baseType
            id
            startNodeID
            endNodeID
            startNode {
              baseType
              id
              url
              title
            }
            endNode {
              baseType
              id
              url
              title
            }
          }
          accessPolicy
          createdByID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;

export const userByUsernameForCollaboration = /* GraphQL */ `
  query UserByUsername(
    $username: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByUsername(
      username: $username
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;

export const userByEmailForCollaboration = /* GraphQL */ `
  query UserByEmail(
    $email: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isAdmin
        baseType
        username
        avatar {
          region
          bucket
          key
        }
        email
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;

export const nodeByUrl = /* GraphQL */ `
  query NodeByUrl(
    $url: String
    $title: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    nodeByUrl(
      url: $url
      title: $title
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        baseType
        id
        url
        title
        wordCounts
        favicon
        domainID
        weightID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const userNodeByUser = /* GraphQL */ `
  query UserNodeByUser(
    $userID: ID
    $nodeID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserNodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userNodeByUser(
      userID: $userID
      nodeID: $nodeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        nodeID
        title
        note
        weightID
        version
        createdAt
        updatedAt
        node {
          id
          baseType
          title
          url
        }
      }
      nextToken
    }
  }
`;

export const domainByHostName = /* GraphQL */ `
  query DomainByHostName(
    $hostName: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDomainFilterInput
    $limit: Int
    $nextToken: String
  ) {
    domainByHostName(
      hostName: $hostName
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        baseType
        hostName
        id
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;