<template>
  <div 
    v-if="targetUserNode"
    class="fixed inset-0 h-full"
  >
    <div
      class="flex items-end justify-center h-full min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0"
      id="modal-container"
    >
      <transition
        enter-active-class="duration-300 ease-out"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          @click="closeModal"
          class="fixed inset-0"
          v-if="modalOpen"
        >
          <div class="absolute inset-0 bg-black opacity-50"></div>
        </div>
      </transition>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        v-if="modalOpen"
        >&#8203;</span
      >
      <transition
        name="modal-transition"
        enter-active-class="duration-300 ease-out"
        enter-from-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
        enter-to-class="translate-y-0 opacity-100 sm:scale-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="translate-y-0 opacity-100 sm:scale-100"
        leave-to-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
      >
        <div
          class="inline-block overflow-y-auto text-left align-bottom transition-all transform rounded-lg shadow-xl hologram-node-entity-modal h-5/6 sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full bg-sd-base03-brblack"
          role="dialog"
          aria-modal="true"
          id="new-hologram-container"
          v-if="modalOpen"
        >
          <div
            class="flex flex-col w-full h-full p-6 bg-sd-base03-brblack"
          >
            <div
              class="flex h-8 hologram-entity-navbar"
            > 
              <div
                class="my-auto ml-auto"
                v-if="transactingStatus"
              >
                <svg class="w-5 h-5 animate-spin text-sd-base0-brblue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
              <div
                v-else
                class="w-5 h-5"
              >

              </div>
            </div>
            <div
              class="flex flex-col w-full px-8 mt-12 hologram-entity-content"
            >
              <div
                contenteditable="true"
                class="mt-4 text-3xl font-semibold bg-sd-base03-brblack hologram-entity-title text-sd-base2-white focus:outline-none"
                v-html="nodeTitle"
                @input="titleInputHandler"
                @keydown.enter="titleEnterHandler"
                @paste="contentEditableOnPasteHandler"
              >
              </div>
              <div
                v-if="displayFullUrl"
                class="flex flex-row pt-2 text-sm font-medium hologram-entity-url text-sd-base00-bryellow "
              >
                <a
                  class="flex items-center mr-2" 
                  :href="targetUserNode.node.url"
                  target="_blank" rel="noopener noreferrer"
                >
                  {{ targetUserNode.node.url }}
                </a>
              </div>
              <div
                v-else
                class="flex flex-row pt-2 text-sm font-medium hologram-entity-url text-sd-base00-bryellow "
              >
                <a
                  class="flex mb-auto mr-2" 
                  :href="targetUserNode.node.url"
                  target="_blank" rel="noopener noreferrer"
                >
                  {{truncateUrl}}
                </a>
                <button
                  class="flex w-8 h-4 px-1 my-auto rounded-full bg-sd-base02-black opacity-90 hover:bg-sd-base01-brgreen hover:opacity-80 focus:outline-none"
                  @click="expandUrl"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="flex w-4 h-4 m-auto fill-current text-sd-base0-brblue" viewBox="0 0 16 16">
                    <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
                  </svg>
                </button>
              </div>
              <div
                class="flex flex-col w-full mt-8"
              >
                <div
                  class="flex flex-row link-block-placeholder"
                >
                  <div
                    class="flex flex-row w-32 h-10 px-2 py-1 -ml-1.5 my-auto rounded-md hover:bg-sd-base02-black"
                  > 
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex w-4 h-4 my-auto mr-4 fill-current text-sd-base0-brblue" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                    </svg>
                    <div
                      class="my-auto font-sans font-medium cursor-pointer text-sd-base1-brcyan"
                    >
                      Link
                    </div>
                  </div>
                  <button
                    class="flex flex-grow h-10 px-4 py-1 my-auto rounded-md hover:bg-sd-base02-black focus:outline-none"
                    @click="toggleLinkBlock"
                  >
                    <div
                      class="flex my-auto mr-auto"
                    >
                      <div
                        v-if="totuslink.length === 0"
                        class="font-sans text-sm font-normal text-sd-base00-bryellow"
                      >
                        This node doesn't have link
                      </div>
                      <div
                        v-else
                        class="font-sans text-sm font-normal text-sd-base00-bryellow"
                      >
                        This node has {{ totuslink.length }} links
                      </div>
                    </div>
                    <div
                      class="flex my-auto ml-auto"
                    >
                      <div
                        v-if="totuslink.length !== 0"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" class="flex w-4 h-4 m-auto fill-current text-sd-base00-bryellow" viewBox="0 0 16 16">
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg>
                      </div>
                    </div>
                  </button>
                </div>
                <div
                  v-if="linksBlockOpen"
                  class="flex flex-row mt-4 link-block"
                >
                  <div
                    class="flex flex-col w-full py-3 rounded-md bg-sd-base02-black"
                  >
                    <div>
                      <div
                        v-for="link in totuslink"
                        v-bind:key="link.id"
                        class="flex flex-row p-1"
                      >
                        <div
                          class="flex items-center justify-center w-6 my-auto ml-2 mr-2"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="flex w-6 h-6 my-auto fill-current text-sd-base0-brblue"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                          </svg>
                        </div>
                        <div
                          v-if="link.startNodeID !== targetUserNode.nodeID"
                          class="my-auto font-sans text-sm font-normal text-sd-base1-brcyan"
                        >
                          {{ getLinkNodeTitle("full" ,link.edge.startNodeID) }}
                        </div>
                        <div
                          v-else-if="link.endNodeID !== targetUserNode.nodeID"
                          class="my-auto font-sans text-sm font-normal text-sd-base1-brcyan"
                        >
                          {{ getLinkNodeTitle("full" ,link.edge.endNodeID) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="flex flex-col w-full mt-2"
              >
                <div
                  class="flex flex-row group-block-placeholder"
                >
                  <div
                    class="flex flex-row w-32 h-10 px-2 py-1 -ml-1.5 my-auto rounded-md hover:bg-sd-base02-black"
                  > 
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex w-4 h-4 my-auto mr-4 fill-current text-sd-base0-brblue" viewBox="0 0 16 16">
                      <path d="M6 1H1v14h5V1zm9 0h-5v5h5V1zm0 9v5h-5v-5h5zM0 1a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1zm9 0a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1V1zm1 8a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1h-5z"/>
                    </svg>
                    <div
                      class="my-auto font-sans font-medium cursor-pointer text-sd-base1-brcyan"
                    >
                      Group
                    </div>
                  </div>
                  <div
                    class="flex flex-grow"
                  >
                    <singleSelect 
                      v-if="dataIsReady"
                      @update-single-selection-options-status="catchUpdateSingleSelectionOptionsStatusEvent"
                      :options="currentHologramGroups"
                      :selectedOption="selectedGroup"
                      :confirmGroupDeletionModalOpen="confirmGroupDeletionModalOpen"
                      @update-selected-option="catchUpdateEvent"
                      @create-option="catchCreateEvent"
                      @change-color="catchChangeColorEvent"
                      @delete-option="catchDeleteEvent"
                      @change-option-name="catchChangeNameEvent"
                    />
                  </div>
                </div>
              </div>
              <div
                class="w-full mt-10 hologram-entity-editor"
                v-if="targetUserNode"
              >
                <mdEditor 
                  class="w-full"
                  :userNodeID="targetUserNode.id"
                />
              </div>
              <div
                v-else
                class="mt-4"
              >
                <svg class="w-5 h-5 animate-spin text-sd-base0-brblue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <teleport to="#modals">
      <div
        v-if="confirmGroupDeletionModalOpen"
      >
        <confirmGroupDeletionModal 
          :open="confirmGroupDeletionModalOpen"
          :hologramGroupID="deleteHologramGroupID"
          @toggle-confirm-group-deletion-modal="catchToggleConfirmGroupDeletionModalEvent"
          @deletion-complete="catchGroupDeletionCompleteEvent"
        />
      </div>
    </teleport>
  </div>
</template>

<script>
import mdEditor from "@/components/mdEditor.vue";
import confirmGroupDeletionModal from "./confirmGroupDeletionModal"
import singleSelect from "../input/singleSelect.vue"
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { gaHelpers } from "../../analytics/helper"
import { useStore } from "vuex";
import { getNodeTitle, contentEditableOnPasteHandler } from "../../utilities/utilities"
export default {
  name: "hologramEntityDetailModal",
  components:{
    mdEditor,
    singleSelect,
    confirmGroupDeletionModal
  },
  setup() {
    const store = useStore();
    const truncateUrl = ref(null);
    const displayFullUrl = ref(null);
    const linksBlockOpen = ref(false);
    const backlinksBlockOpen = ref(false);
    const highlightBlockOpen = ref(false);
    const links = ref([]);
    const backlinks = ref([]);
    const totuslink = ref([]);
    const userNodes = computed(() => store.getters["hologram/userNodes"]);
    const mkEditorChangesUnsaved = computed(() => store.getters["modal/mkEditorChangesUnsaved"]);
    const modalOpen = computed(() => store.getters["modal/hologramEntityDetailModalOpen"]);
    const targetUserNode = computed(() => store.getters["modal/hologramEntityDetailModalData"]);
    const hologramNode = ref(null);
    const hologramNodeGroup = ref(null);
    const userData = computed(() => store.getters["auth/userData"]);
    const currentHologram = computed(() => store.getters["hologram/currentHologram"]);
    const currentHologramNodes = computed(() => store.getters["hologram/currentHologramNodes"]);
    const currentHologramEdges = computed(() => store.getters["hologram/currentHologramEdges"]);
    const currentHologramGroups = computed(() => store.getters["hologram/currentHologramGroups"]);
    const currentHologramNodeGroups = computed(() => store.getters["hologram/currentHologramNodeGroups"]);
    const transactingStatus = computed(() => store.getters["hologram/transactingStatus"]);
    const selectedGroup = ref({});
    const dataIsReady = ref(false);
    const confirmGroupDeletionModalOpen = ref(false);
    const deleteHologramGroupID = ref(null);
    const singleSelectionOptionsStatus = ref(false);
    const nodeTitle = ref(null);
    const updateEvent = ref(null);
    
    const prepareData = () => {

      

      if (targetUserNode.value.node.url.length > 64){
        displayFullUrl.value = false;
        truncateUrl.value = truncateString(targetUserNode.value.node.url, 64)
      } else {
        displayFullUrl.value = true;
      }
      totuslink.value = prepareLinkData(targetUserNode.value, currentHologramEdges.value);

      const hologramNodeIndex = currentHologramNodes.value.findIndex( e => e.nodeID === targetUserNode.value.nodeID );
      hologramNode.value = currentHologramNodes.value[`${hologramNodeIndex}`]

      nodeTitle.value = getNodeTitle(hologramNode.value)

      const hologramNodeGroupIndex = currentHologramNodeGroups.value.findIndex( e => e.hologramNodeID === hologramNode.value.id )
      if ( hologramNodeGroupIndex !== -1 ){
        hologramNodeGroup.value = currentHologramNodeGroups.value[`${hologramNodeGroupIndex}`]
        const groupIndex = currentHologramGroups.value.findIndex( e => e.groupID === hologramNodeGroup.value.groupID );
        if ( groupIndex !== -1 ){
          selectedGroup.value = currentHologramGroups.value[`${groupIndex}`];
        }
      }
      dataIsReady.value = true
      window.addEventListener("keydown", keydownEscHandler)
    };

    onMounted(prepareData);
    
    const prepareLinkData = (targetUserNode, currentHologramEdges) => {
      const totuslink = currentHologramEdges.reduce(
        function (array, element) {
          if (element.edge.startNodeID === targetUserNode.nodeID) {
            array.push(element);
          } else if (element.edge.endNodeID === targetUserNode.nodeID){
            array.push(element);
          }
          return array;
        },[]
      );
      return totuslink
    };
    const truncateString = (string, length) => {
      if (string.length <= length) {
        return string
      }
      return string.slice(0, length) + '...'
    };
    const expandUrl = () => {
      displayFullUrl.value = true;
    };
    const closeModal = () => {
      if (modalOpen.value){
        store.dispatch("modal/toggleHologramEntityDetailModal", {data:false});
        store.dispatch("modal/updateHologramEntityDetailModalData", {type:"reset"});
        store.dispatch("hologram/updateBlocksBelongToOpenNode", {type:"reset"})
      }
    };
    const getLinkNodeTitle = (type, nodeID) => {
      const userNodeIndex = userNodes.value.findIndex( e => e.nodeID === nodeID );
      const userNode = userNodes.value[userNodeIndex];
      const hologramNodeIndex = currentHologramNodes.value.findIndex( e => e.nodeID === nodeID );
      const hologramNode = currentHologramNodes.value[hologramNodeIndex];

      const nodeTitle = getNodeTitle(userNode, hologramNode);

      if ( type === "truncate" ){
        return truncateString(nodeTitle, 36)
      }

      if ( type === "full" ){
        return nodeTitle
      }
    }

    const catchChangeNameEvent = async (message) => {
      const variables = {
        "groupID": message.option.groupID,
        "newTitle": message.changedName
      }
      try {
        await store.dispatch("hologram/updateGroupTitle", variables);
        selectedGroup.value.group.title = message.changedName;
        gaHelpers.engagement("change_group_name", "group")
      } catch(err){
        console.error(err)
      }
    }

    const toggleLinkBlock = () => {
      if ( totuslink.value.length > 0 ){
        linksBlockOpen.value = !linksBlockOpen.value;
      }
    }

    const catchUpdateEvent = async (message) => {
      if ( selectedGroup.value.id === message.id ){
        return;
      }
      

      const newGroupID = message.groupID
      selectedGroup.value = message
      // User un-select option -> delete hologramNodeGroup

      if ( Object.keys(message).length === 0 ){
        try {
          await store.dispatch("hologram/deleteHologramNodeGroup", hologramNodeGroup.value.id);
          store.dispatch("hologram/removeItemFromCurrentHologramNodeGroups", hologramNodeGroup.value.id);
          hologramNodeGroup.value = null;
        } catch(err){
          console.error(err)
        }

        const toGraphVariables = {
          "nodeID": hologramNode.value.nodeID,
          "groupID": null
        }
        
        try {
          await store.dispatch("hologram/updateHologramNodeGroupToGraph", toGraphVariables)
        } catch(err){
          console.error(err)
        }
        store.dispatch("hologram/updateRenderHologramNodeColor", {data:true})
        gaHelpers.engagement("remove_group", "group")
        return;
      }

      if ( !hologramNodeGroup.value ){
        // User select group of the node for the first time

        const createHologramNodeGroupVariables = {
          "hologramNodeID": hologramNode.value.id, 
          "groupID": newGroupID,
          "owner":userData.value.id
        }

        try {
          hologramNodeGroup.value = await store.dispatch("hologram/createHologramNodeGroup", createHologramNodeGroupVariables);
        } catch(err){
          console.error(err)
        }
      } else {

        // User select other groups

        const updateHologramNodeGroupVariables = {
          "hologramNodeGroupID": hologramNodeGroup.value.id,
          "newGroupID": newGroupID
        }
        try {
          hologramNodeGroup.value = await store.dispatch("hologram/updateHologramNodeGroup", updateHologramNodeGroupVariables);
        } catch(err){
          console.error(err)
        }
      }

      const toGraphVariables = {
        "nodeID": hologramNode.value.nodeID,
        "groupID": newGroupID
      }
      
      try {
        await store.dispatch("hologram/updateHologramNodeGroupToGraph", toGraphVariables)
      } catch(err){
        console.error(err)
      }
      gaHelpers.engagement("add_group", "group")
      store.dispatch("hologram/updateRenderHologramNodeColor", {data:true})
    }

    const catchCreateEvent = async (message) => {
      let group, hologramGroup;
      try {
        const createGroupVariables = {
          "title": message,  
          "createdByID": userData.value.id,
          "owner": userData.value.id
        }

        group = await store.dispatch("hologram/createGroup", createGroupVariables);
      } catch(err){
        console.error(err)
        return;
      }

      try {
        const createHologramGroupVariables = {
          "hologramID": currentHologram.value.id,
          "groupID": group.id,
          "owner": userData.value.id
        }
        hologramGroup = await store.dispatch("hologram/createHologramGroup", createHologramGroupVariables);
        selectedGroup.value = hologramGroup
      } catch(err){
        console.error(err)
        return;
      }

      try {
        if ( !hologramNodeGroup.value ){
          const createHologramNodeGroupVariables = {
            "hologramNodeID": hologramNode.value.id, 
            "groupID": group.id, 
            "owner":userData.value.id
          }
          hologramNodeGroup.value = await store.dispatch("hologram/createHologramNodeGroup", createHologramNodeGroupVariables);
        } else {
          const updateHologramNodeGroupVariables = {
            "hologramNodeGroupID": hologramNodeGroup.value.id,
            "newGroupID": group.id
          }
          hologramNodeGroup.value = await store.dispatch("hologram/updateHologramNodeGroup", updateHologramNodeGroupVariables);
        }
      } catch(err){
        console.error(err)
        return;
      }

      try {
        const toGraphVariables = {
          "nodeID": hologramNode.value.nodeID,
          "groupID": group.id
        }

        await store.dispatch("hologram/updateHologramNodeGroupToGraph", toGraphVariables)
        
      } catch(err){
        console.error(err)
      }
      gaHelpers.engagement("create_group", "group")
      store.dispatch("hologram/updateRenderHologramNodeColor", {data:true})
    }

    const catchChangeColorEvent = async (message) => {
      const variables = {
        "hologramGroupID": message.option.id,
        "newColor": message.color.name
      }

      try {
        await store.dispatch("hologram/updateHologramGroupColor", variables);
        if ( selectedGroup.value.id === message.option.id ){
          selectedGroup.value.color = message.color.name;
        }
        store.dispatch("hologram/updateRenderHologramNodeColor", {data:true})
        gaHelpers.engagement("change_group_color", "group")
      } catch(err){
        console.error(err)
      }
      
    }

    const catchDeleteEvent = async (message) => {
      toggleConfirmGroupDeletionModal();
      deleteHologramGroupID.value = message.option.id;
      gaHelpers.interaction("open_confirm_group_deletion_modal", "group")
    };
    
    const catchGroupDeletionCompleteEvent = () => {
      if ( hologramNodeGroup.value ){
        hologramNodeGroup.value = null;
        selectedGroup.value = {};
      }
    }

    const catchToggleConfirmGroupDeletionModalEvent = () => {
      if ( confirmGroupDeletionModalOpen.value ){
        toggleConfirmGroupDeletionModal();
      }
    }

    const keydownEscHandler = (event) => {
      if ( event.code !== "Escape" ) return;
      if ( !modalOpen.value ) return;
      if ( singleSelectionOptionsStatus.value ) return;
      closeModal()
    };

    const catchUpdateSingleSelectionOptionsStatusEvent = (message) => {
      singleSelectionOptionsStatus.value = message;
    }

    const toggleConfirmGroupDeletionModal = () => {
      confirmGroupDeletionModalOpen.value = !confirmGroupDeletionModalOpen.value;
    }

    const titleInputHandler = (event) => {
      clearTimeout(updateEvent.value);
      const text = event.currentTarget.innerText;
      updateEvent.value = setTimeout(() => updateTitle(text), 3000);
      //nodeTitle.value = event.currentTarget.innerText;
    }

    const updateTitle = async (title) => {
      store.dispatch("hologram/updateTransactingStatus", {data:true})
      console.log(transactingStatus.value)
      const updateTitlevariables = {
        userNodeID: targetUserNode.value.id,
        title: title
      }
      const updateHologramVariables = {
        nodeID: targetUserNode.value.nodeID,
        title: title
      }
      try {
        await store.dispatch("hologram/updateUserNodeTitle", updateTitlevariables);
        await store.dispatch("hologram/updateTargetNodeTitleAtHologramGraph", updateHologramVariables);
        store.dispatch("hologram/updateRenderHologramGraphDataOnly", {data:true});
      } catch(err){
        console.error(err)
      }
      store.dispatch("hologram/updateTransactingStatus", {data:false})
    }

    const titleEnterHandler = async (event) => {
      event.preventDefault();
      const text = event.currentTarget.innerText;
      clearTimeout(updateEvent.value);
      await updateTitle(text);
    }

    const clear = () => {
      window.removeEventListener("keydown", keydownEscHandler)
    }


    onUnmounted(clear)

    
    return {
      dataIsReady,
      selectedGroup,
      truncateUrl,
      displayFullUrl,
      linksBlockOpen,
      backlinksBlockOpen,
      highlightBlockOpen,
      transactingStatus,
      links,
      backlinks,
      totuslink,
      mkEditorChangesUnsaved,
      modalOpen,
      targetUserNode,
      currentHologramEdges,
      prepareData,
      prepareLinkData,
      truncateString,
      expandUrl,
      closeModal,
      getLinkNodeTitle,
      toggleLinkBlock,
      catchUpdateEvent,
      catchCreateEvent,
      catchDeleteEvent,
      catchChangeNameEvent,
      catchChangeColorEvent,
      currentHologramGroups,
      currentHologramNodes,
      hologramNode,
      confirmGroupDeletionModalOpen,
      catchToggleConfirmGroupDeletionModalEvent,
      catchGroupDeletionCompleteEvent,
      catchUpdateSingleSelectionOptionsStatusEvent,
      deleteHologramGroupID,
      nodeTitle,
      titleInputHandler,
      titleEnterHandler,
      contentEditableOnPasteHandler
    }
  },
};




</script>

<style>
</style>