<template>
  <div 
    class="fixed inset-0 h-full"
  >
    <div
      class="flex items-end justify-center h-full min-h-screen px-4 pt-4 text-center sm:block sm:p-0"
      id="modal-container"
    >
      <!--
        Background overlay, show/hide based on modal state.

        Entering: "ease-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
        Leaving: "ease-in duration-200"
            From: "opacity-100"
            To: "opacity-0"
        -->
      <transition
        enter-active-class="duration-300 ease-out"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div 
          @click="toggleDeleteNodeModal('byClickAway')" 
          class="fixed inset-0"
          v-if="this.deleteNodeModalOpen"
        >
          <div class="absolute inset-0 bg-black opacity-50"></div>
        </div>
      </transition>
      

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        v-show="this.deleteNodeModalOpen"
        >&#8203;</span
      >
      <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
            From: "opacity-100 translate-y-0 sm:scale-100"
            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        -->
      <transition
        name="modal-transition"
        enter-active-class="duration-300 ease-out"
        enter-from-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
        enter-to-class="translate-y-0 opacity-100 sm:scale-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="translate-y-0 opacity-100 sm:scale-100"
        leave-to-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
      >
        <div
          class="items-center inline-block overflow-y-auto text-left align-middle transition-all transform rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-xl sm:w-full"
          role="dialog"
          aria-modal="true"
          id="settings-container"
          v-show="this.deleteNodeModalOpen"
        >
          <div class="w-full bg-sd-base02-black">
            <div
              class="flex-col flex-grow w-full px-4 sm:flex sm:p-8 sm:items-start"
            >
              <div class="mt-3 sm:mt-0 sm:text-left">
                <h2
                  class="text-xl font-medium leading-6 text-sd-base1-brcyan"
                  id="modal-headline"
                >
                  Delete Node
                </h2>
                <span
                  class="font-sans text-sm font-normal text-sd-base00-bryellow"
                >
                  This will delete the node at <span class="font-semibold text-sd-base1-brcyan">{{currentHologram.title}}</span>
                </span>
              </div>
            </div>
            <div class="w-full px-8 pb-8 mt-3 sm:mt-0 sm:text-left sm:items-start text-sd-base1-brcyan">
              <p class="text-sd-base2-white">This will delete:</p>
              <div
                class="mt-2"
                v-for=" t in deleteTargets"
                v-bind:key="t.id"
              >
                <div
                  v-if="t.nodeID"
                  class="flex flex-row"  
                >
                  <div
                    class="mr-2 py-0.5 px-2 rounded bg-sd-base03-brblack"
                  >
                    Node
                  </div>
                  <div
                    class="flex items-center text-sm font-normal text-left"
                  >
                    {{ t.node.title }}
                  </div>
                </div>
                <div
                  v-else-if="t.edgeID"
                  class="flex flex-row"
                >
                  <div
                    class="flex mr-2 py-0.5 px-2 rounded bg-sd-base03-brblack"
                  >
                    Connection
                  </div>
                  <div 
                    class="flex items-center"
                  >
                    <button
                      class="flex mr-2 text-sm has-tooltip focus:outline-none"
                    >
                      {{ getBlockData("startNode", t.edge.startNodeID) }}
                      <span
                        class="p-3 mt-10 mr-2 -ml-2 text-sm font-medium text-left rounded shadow-lg tooltip w-36 bg-sd-base1-brcyan text-sd-base03-brblack"
                      >
                        {{ getBlockData("startNodeFull", t.edge.startNodeID) }}
                      </span>
                    </button>
                  </div>
                  <div
                    class="flex items-center mr-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="flex w-3 h-3 my-auto fill-current text-sd-base0-brblue"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"
                      />
                    </svg>
                  </div>
                  <div 
                    class="flex items-center"
                  >
                    <button
                      class="flex mr-2 text-sm has-tooltip focus:outline-none"
                    >
                      {{ getBlockData("endNode", t.edge.endNodeID) }}
                      <span
                        class="p-3 mt-10 mr-2 -ml-2 text-sm font-medium text-left rounded shadow-lg tooltip w-36 bg-sd-base1-brcyan text-sd-base03-brblack"
                      >
                        {{ getBlockData("endNodeFull", t.edge.endNodeID) }}
                      </span>
                    </button>
                  </div>
                </div>
                
              </div>
              <p class="mt-8 text-sd-base2-white">This won't delete:</p>
              <ul class="pl-4 mt-2 text-sm list-disc">
                <li>
                  The data you store under this node, such as quote and note.
                </li>
                <li class="mt-2">
                  The whole node entity in your account, if you want to delete the node globally you have to delete it in your account setting(under development).
                </li>
              </ul>
            </div>
            <footer
              class="sticky bottom-0 flex flex-row border-t border-opacity-50 bg-sd-base02-black border-sd-base01-brgreen sm:py-4 sm:px-4"
            >
              <button
                @click="deleteNodeHandler"
                class="px-4 py-2 font-sans text-sm font-medium border rounded-md border-sd-base01-brgreen text-sd-base2-white hover:bg-sd-base2-white hover:bg-opacity-5 focus:outline-none"
              >
                Delete
              </button>
              <button
                @click="toggleDeleteNodeModal('byButton')"
                class="px-4 py-2 ml-4 font-sans text-sm font-medium border rounded-md border-sd-base01-brgreen text-sd-base01-brgreen hover:bg-sd-base2-white hover:bg-opacity-5 focus:outline-none"
              >
                Cancel
              </button>
              <div
                  class="flex items-center ml-4"
                  v-if="transactingStatus"
                >
                  <svg class="w-5 h-5 animate-spin text-sd-base0-brblue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </div>
            </footer>

          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { gaHelpers } from "@/analytics/helper.js"
export default {
  name:"deleteNodeModal",
  data() {
    return {
      currentHologramTitle: "",
      deleteTargets:[],
      targetHologramEdge: [],
      targetDeletionBlocktype: null,
      deleteBlockProcedure: false,
      startNodeTruncateContent: {},
      startNodeFullContent: {},
      endNodeTruncateContent: {},
      endNodeFullContent: {},
    }
  },
  mounted() {
    this.deleteTargets = listDeleteTargets(this.currentHologramNodes, this.currentHologramEdges, this.deleteNodeID);
    const {startNodeTruncateContent, startNodeFullContent, endNodeTruncateContent, endNodeFullContent } = makeStaticData(this.deleteTargets, this.currentHologramNodes);
    this.startNodeTruncateContent = startNodeTruncateContent;
    this.startNodeFullContent = startNodeFullContent;
    this.endNodeTruncateContent = endNodeTruncateContent;
    this.endNodeFullContent = endNodeFullContent;
    window.addEventListener("keydown", this.keydownEscHandler)
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.keydownEscHandler)
  },
  methods: {
    keydownEscHandler: function(event){
      if ( event.code !== "Escape" ) return;
      if ( !this.deleteNodeModalOpen ) return;
      this.toggleDeleteNodeModal()
    },
    getBlockData(type, nodeID) {
      if (nodeID) {   
        switch (type) {
          case "startNode":{
            return this.startNodeTruncateContent[`${nodeID}`];
          }
          case "startNodeFull":{
            return this.startNodeFullContent[`${nodeID}`];
          }
          case "endNode":{
            return this.endNodeTruncateContent[`${nodeID}`];
          }
          case "endNodeFull":{
            return this.endNodeFullContent[`${nodeID}`];
          }
        }
      }
    },
    toggleDeleteNodeModal(type){
      if (this.deleteNodeModalOpen){
        this.$store.dispatch('modal/toggleDeleteNodeModal', {data:false});
        this.$store.dispatch("modal/updateTargetDeleteBlockParent", {type:"reset"})
        this.$store.dispatch('modal/updateDeleteNodeID', {type:"reset"});  
      } 

      if ( type === "byClickAway"){
        gaHelpers.interaction("close_delete_node_modal_by_click_away", "delete_node_modal")
      } else {
        gaHelpers.interaction("close_delete_node_modal_by_button", "delete_node_modal")
      }
    },
    async deleteNodeHandler(){
      this.$store.dispatch("hologram/updateTransactingStatus", {data:true});
      let newDeleteNodeNotification;
      try {
        await this.$store.dispatch("hologram/deleteHologramEntity", {data:this.deleteTargets});
        newDeleteNodeNotification = {
          info:"Success",
          blockContent: this.deleteNodeID[0].content
        }
      } catch(err){
        console.error(err)
        newDeleteNodeNotification = {
          info:"Failed",
        }
      }
      
      this.$store.dispatch('modal/updateDeleteNodeID', {type:"reset"});
      this.$store.dispatch('modal/updateDeleteNodeNotification', newDeleteNodeNotification)
      this.$store.dispatch("hologram/updateTransactingStatus", {data:false});
      this.toggleDeleteNodeModal();
    },
  },
  computed:{
    ...mapGetters({
      deleteNodeModalOpen: "modal/deleteNodeModalOpen",
      userNodes: "hologram/userNodes",
      currentHologram: "hologram/currentHologram",
      currentHologramNodes: "hologram/currentHologramNodes",
      currentHologramEdges: "hologram/currentHologramEdges",
      deleteNodeID: "modal/deleteNodeID",
      currentHologramBlocks: "hologram/currentHologramBlocks",
      targetDeleteBlockParent: "modal/targetDeleteBlockParent",
      transactingStatus: "hologram/transactingStatus",
    }),
  }
};

const listDeleteTargets = function(currentHologramNodes, currentHologramEdges, deleteNodeID){
  let deleteTargets = [];
  const index = currentHologramNodes.findIndex( e => e.nodeID === deleteNodeID );
  const targetNode = currentHologramNodes[`${index}`];
  deleteTargets.splice(deleteTargets.length, 0, targetNode);
  const targetEdge = currentHologramEdges.reduce(
    function (array, element) {
      if (element.edge.startNodeID === deleteNodeID) {
        array.push(element);
      } else if (element.edge.endNodeID === deleteNodeID){
        array.push(element);
      }
      return array;
    },[]
  );
  targetEdge.forEach(element => {
    deleteTargets.splice(deleteTargets.length, 0, element);
  });
  return deleteTargets
}

const makeStaticData = function(deleteTargets, currentHologramNodes){
  let staticData = {};
  let startNodeTruncateContent = {};
  let startNodeFullContent = {};
  let endNodeTruncateContent = {};
  let endNodeFullContent = {};
  
  deleteTargets.forEach( t => {
    if (t.edgeID){
      const startHologramNodeIndex = currentHologramNodes.findIndex( e => e.nodeID === t.edge.startNodeID);
      const startNode = currentHologramNodes[`${startHologramNodeIndex}`].node;
      startNodeTruncateContent[`${t.edge.startNodeID}`] = truncateString(startNode.title, 20);
      startNodeFullContent[`${t.edge.startNodeID}`] = startNode.title;

      const endHologramNodeIndex = currentHologramNodes.findIndex( e => e.nodeID === t.edge.endNodeID);
      const endNode = currentHologramNodes[`${endHologramNodeIndex}`].node;
      endNodeTruncateContent[`${t.edge.endNodeID}`] = truncateString(endNode.title, 20);
      endNodeFullContent[`${t.edge.endNodeID}`] = endNode.title;
    }
  });

  staticData.startNodeTruncateContent = startNodeTruncateContent;
  staticData.startNodeFullContent = startNodeFullContent;
  staticData.endNodeTruncateContent = endNodeTruncateContent;
  staticData.endNodeFullContent = endNodeFullContent;
  return staticData
}

const truncateString = function(string, length) {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (string.length <= length) {
    return string
  }
  // Return str truncated with '...' concatenated to the end of str.
  return string.slice(0, length) + '...'
}

</script>


<style>
</style>