<template>
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    v-bind:class="iconStyle"
    viewBox="0 0 16 16"
  >
    <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
  </svg>
</template>
<script>
import { computed } from "vue"
export default {
  name:"iconThreeDots",
  props: {
    size: {
      type: Number,
      require: true,
    }
  },
  setup(props) {
    const iconStyle = computed(() => {
      return `fill-current w-${props.size} h-${props.size}`
    })
    return {
      iconStyle
    }
  }
};
</script>
<style>
</style>