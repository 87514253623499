
export const modal = {
  namespaced: true,
  state: { 
    mainSettingsModalOpen: false,
    createNewHologramModalOpen: false,
    deleteNodeModalOpen: false,
    targetDeleteBlockParent: [],
    deleteNodeID:[],
    deleteNodeNotification: {},
    deleteEdgeModalOpen: false,
    deleteEdgeID: null,
    editorAddBlockToolboxModalOpen:false,
    hologramEntityDetailModalOpen: false,
    hologramEntityDetailModalData: {},
    hologramSettingsModalOpen: false,
    hologramSettingsModalTriggeredPosition: {},
    confirmHologramDeletionModalOpen: false,
    hologramNodeTooltipModalOpen: false,
    hologramNodeTooltipModalData: {},
    hologramNodeTooltipModalTriggeredPosition: {},
    changeHologramAccessPolicyModalOpen: false,
    hologramIntroModalOpen: false,
    nodeContextMenuOpen: false,
    nodeContextMenuPosition: {},
    nodeContextMenuData: {},
    hologramControlPanelInputOpen: false,
    mkEditorChangesUnsaved: false,
    hologramSharingModalOpen: false,
    hologramSharingModalData: {},
    hologramSharingModalPosition: {},
    searchToolboxSelectedNodeID: null,
    searchToolboxFilteredNode: [],
    hologramTitleEditorModalOpen: false,
    hologramTitleEditorModalPosition: {},
  },
  mutations:{
    mutateHologramSharingModalPosition: (state, payload) => {
      state.hologramSharingModalPosition = payload;
    },
    mutateHologramTitleEditorModalPosition: (state, payload) => {
      state.hologramTitleEditorModalPosition = payload;
    },
    mutateHologramTitleEditorModalOpen: (state, payload) => {
      state.hologramTitleEditorModalOpen = payload;
    },
    mutateSearchToolboxFilteredNode: (state, payload) => {
      state.searchToolboxFilteredNode = payload;
    },
    mutateSearchToolboxSelectedNodeID: (state, payload) => {
      state.searchToolboxSelectedNodeID = payload;
    },
    mutateDeleteEdgeID(state, payload){
      state.deleteEdgeID = payload;
    },
    mutateDeleteEdgeModalOpen(state, payload){
      state.deleteEdgeModalOpen = payload;
    },
    mutateHologramSharingModalData(state, payload){
      state.hologramSharingModalData = payload;
    },
    mutateHologramSharingModalOpen(state, payload){
      state.hologramSharingModalOpen = payload;
    },
    mutateMkEditorChangesUnsaved(state, payload){
      state.mkEditorChangesUnsaved = payload;
    },
    mutateHologramControlPanelInputOpen(state, payload){
      state.hologramControlPanelInputOpen = payload;
    },
    mutateNodeContextMenuData(state, payload){
      state.nodeContextMenuData = payload;
    },
    mutateNodeContextMenuOpen(state, payload){
      state.nodeContextMenuOpen = payload;
    },
    mutateNodeContextMenuPosition(state, payload){
      state.nodeContextMenuPosition = payload;
    },
    mutateMainSettingsModalOpen(state, payload){
      state.mainSettingsModalOpen = payload
    },
    mutateDeleteNodeModalOpen(state, payload){
      state.deleteNodeModalOpen = payload
    },
    mutateCreateNewHologramModalOpen(state, payload){
      state.createNewHologramModalOpen = payload;
    },
    mutateDeleteNodeID(state, payload){
      state.deleteNodeID = payload;
    },
    setDeleteNodeNotification(state, payload){
      state.deleteNodeNotification = payload;
    },
    mutateEditorAddBlockToolboxModalOpen(state, payload){
      state.editorAddBlockToolboxModalOpen = payload;
    },
    mutateTargetDeleteBlockParent(state, payload){
      state.targetDeleteBlockParent = payload;
    },
    mutateHologramEntityDetailModalOpen(state, payload){
      state.hologramEntityDetailModalOpen = payload;
    },
    mutateHologramEntityDetailModalData(state, payload){
      state.hologramEntityDetailModalData = payload;
    },
    mutateHologramSettingsModalOpen(state, payload){
      state.hologramSettingsModalOpen = payload;
    },
    mutateHologramSettingsModalTriggeredPosition(state, payload){
      state.hologramSettingsModalTriggeredPosition = payload;
    },
    mutateConfirmHologramDeletionModalOpen(state, payload){
      state.confirmHologramDeletionModalOpen = payload;
    },
    mutateHologramNodeTooltipModalOpen(state, payload){
      state.hologramNodeTooltipModalOpen = payload;
    },
    mutateHologramNodeTooltipModalData(state, payload){
      state.hologramNodeTooltipModalData = payload;
    },
    mutateHologramNodeTooltipModalTriggeredPosition(state, payload){
      state.hologramNodeTooltipModalTriggeredPosition = payload;
    },
    mutateChangeHologramAccessPolicyModalOpen(state, payload){
      state.changeHologramAccessPolicyModalOpen = payload;
    },
    mutateHologramIntroModalOpen(state, payload){
      state.hologramIntroModalOpen = payload;
    }
  },
  actions:{
    closeAllModal(context){
      context.commit("mutateMainSettingsModalOpen", false);
      context.commit("mutateDeleteNodeModalOpen", false);
      context.commit("mutateCreateNewHologramModalOpen", false);
      context.commit("mutateEditorAddBlockToolboxModalOpen", false);
      context.commit("mutateHologramEntityDetailModalOpen", false);
      context.commit("mutateHologramSettingsModalOpen", false);
      context.commit("mutateConfirmHologramDeletionModalOpen", false);
      context.commit("mutateHologramNodeTooltipModalOpen", false);
      context.commit("mutateChangeHologramAccessPolicyModalOpen", false);
      context.commit("mutateHologramIntroModalOpen", false);
    },
    toggleHologramTitleEditorModal: (context, {data}) => {
      context.commit("mutateHologramTitleEditorModalOpen", data);
    },
    toggleNodeContextMenu(context, {data}){
      context.commit("mutateNodeContextMenuOpen", data);
    },
    toggleMainSettingsModal (context, {data}) {
      context.commit("mutateMainSettingsModalOpen", data);
    },
    toggleCreateNewHologramModal(context, {data}) {
      context.commit("mutateCreateNewHologramModalOpen", data);
    },
    toggleDeleteNodeModal(context, {data}) {
      context.commit("mutateDeleteNodeModalOpen", data);
    },
    toggleHologramEntityDetailModal(context, {data}){
      context.commit("mutateHologramEntityDetailModalOpen", data);
    },
    toggleHologramSettingsModal(context, {data}){
      context.commit("mutateHologramSettingsModalOpen", data);
    },
    toggleConfirmHologramDeletionModal(context, {data}){
      context.commit("mutateConfirmHologramDeletionModalOpen", data);
    },
    toggleHologramNodeTooltipModal(context, {data}){
      context.commit("mutateHologramNodeTooltipModalOpen", data);
    },
    toggleChangeHologramAccessPolicyModal(context, {data}){
      context.commit("mutateChangeHologramAccessPolicyModalOpen", data);
    },
    toggleHologramIntroModalOpen(context, {data}){
      context.commit("mutateHologramIntroModalOpen", data);
    },
    toggleHologramSharingModal(context, {data}){
      context.commit("mutateHologramSharingModalOpen", data);
    },
    updateHologramSharingModalPosition: (context, {data}) => {
      context.commit("mutateHologramSharingModalPosition", data);
    },
    updateHologramTitleEditorModalPosition: (context, {data}) => {
      context.commit("mutateHologramTitleEditorModalPosition", data);
    },
    updateSearchToolboxFilteredNode: (context, {data}) => {
      context.commit("mutateSearchToolboxFilteredNode", data);
    },
    updateSearchToolboxSelectedNodeID: (context, {data}) => {
      context.commit("mutateSearchToolboxSelectedNodeID", data);
    },
    updateDeleteEdgeModalOpen(context, {data}){
      context.commit("mutateDeleteEdgeModalOpen", data);
    },
    updateDeleteEdgeID(context, {data}){
      context.commit("mutateDeleteEdgeID", data);
    },
    updateHologramSharingModalData(context, {type, data}){
      switch (type) {
        case "update":{
          context.commit("mutateHologramSharingModalData", data);
          break
        }
        case "reset":{
          context.commit("mutateHologramSharingModalData", {});
          break
        }
      }
    },
    updateHologramControlPanelInputOpen(context, {data}){
      context.commit("mutateHologramControlPanelInputOpen", data);
    },
    updateMkEditorChangesUnsaved(context, {data}){
      context.commit("mutateMkEditorChangesUnsaved", data);
    },
    updateNodeContextMenuData(context, {data, type}){
      switch (type) {
        case "reset":{
          context.commit("mutateNodeContextMenuData", {});
          break
        }
        case "update":{
          context.commit("mutateNodeContextMenuData",data);
          break
        }
      }
    },
    updateNodeContextMenuPosition(context, {data, type}){
      switch (type) {
        case "reset":{
          context.commit("mutateNodeContextMenuPosition", {});
          break
        }
        case "update":{
          context.commit("mutateNodeContextMenuPosition",data);
          break
        }
      }
    },
    updateHologramNodeTooltipModalData(context, {type, data}){
      switch (type) {
        case "reset":{
          context.commit("mutateHologramNodeTooltipModalData", {});
          break
        }
        case "update":{
          context.commit("mutateHologramNodeTooltipModalData",data);
          break
        }
      }
    },
    updateHologramNodeTooltipModalTriggeredPosition(context, {type, data}){
      switch (type) {
        case "reset":{
          context.commit("mutateHologramNodeTooltipModalTriggeredPosition", {});
          break
        }
        case "update":{
          context.commit("mutateHologramNodeTooltipModalTriggeredPosition",data);
          break
        }
      }
    },
    updateHologramSettingsModalTriggeredPosition(context, {type,data}){
      switch (type) {
        case "reset":{
          context.commit("mutateHologramSettingsModalTriggeredPosition", {})
          break
        }
        case "update":{
          context.commit("mutateHologramSettingsModalTriggeredPosition", data)
          break
        }
      }
    },
    updateHologramEntityDetailModalData(context, {type, data}){
      switch (type) {
        case "reset":{
          context.commit("mutateHologramEntityDetailModalData", {});
          break;
        }
        case "update":{
          context.commit("mutateHologramEntityDetailModalData", data);
          break;
        }
      }
    },
    updateDeleteNodeID(context, {data, type}){
      switch (type) {
        case "update":{
          context.commit("mutateDeleteNodeID", data);
          break;
        }
        case "reset":{
          context.commit("mutateDeleteNodeID", []);
          break;
        }
      }
    },
    updateDeleteNodeNotification(context, deleteNodeNotification){
      context.commit("setDeleteNodeNotification", deleteNodeNotification);
    },
    updateEditorAddBlockToolboxModalOpen(context, {data}){
      context.commit("mutateEditorAddBlockToolboxModalOpen", data)
    },
    updateTargetDeleteBlockParent(context, {data, type}){
      switch (type) {
        case "update":{
          let temp = context.state.targetDeleteBlockParent;
          temp.splice(0,1, data);
          context.commit("mutateTargetDeleteBlockParent", temp);
          break;
        }
        case "reset":{
          context.commit("mutateTargetDeleteBlockParent", []);
          break;
        }
      }
    },
  },
  getters:{
    mainSettingsModalOpen: state => state.mainSettingsModalOpen,
    createNewHologramModalOpen: state => state.createNewHologramModalOpen,
    deleteNodeModalOpen: state => state.deleteNodeModalOpen,
    deleteNodeID: state => state.deleteNodeID,
    deleteNodeNotification: (state) => state.deleteNodeNotification,
    editorAddBlockToolboxModalOpen: (state) => state.editorAddBlockToolboxModalOpen,
    targetDeleteBlockParent: (state) => state.targetDeleteBlockParent,
    hologramEntityDetailModalOpen: (state) => state.hologramEntityDetailModalOpen,
    hologramEntityDetailModalData: (state) => state.hologramEntityDetailModalData,
    hologramSettingsModalOpen: (state) => state.hologramSettingsModalOpen,
    hologramSettingsModalTriggeredPosition: (state) => state.hologramSettingsModalTriggeredPosition,
    confirmHologramDeletionModalOpen: (state) => state.confirmHologramDeletionModalOpen,
    hologramNodeTooltipModalOpen: (state) => state.hologramNodeTooltipModalOpen,
    hologramNodeTooltipModalData: (state) => state.hologramNodeTooltipModalData,
    hologramNodeTooltipModalTriggeredPosition: (state) => state.hologramNodeTooltipModalTriggeredPosition,
    changeHologramAccessPolicyModalOpen: (state) => state.changeHologramAccessPolicyModalOpen,
    hologramIntroModalOpen: (state) => state.hologramIntroModalOpen,
    nodeContextMenuOpen: (state) => state.nodeContextMenuOpen,
    nodeContextMenuData: (state) => state.nodeContextMenuData,
    nodeContextMenuPosition: (state) => state.nodeContextMenuPosition,
    hologramControlPanelInputOpen: (state) => state.hologramControlPanelInputOpen,
    mkEditorChangesUnsaved: (state) => state.mkEditorChangesUnsaved,
    hologramSharingModalOpen: (state) => state.hologramSharingModalOpen,
    hologramSharingModalData: (state) => state.hologramSharingModalData,
    hologramSharingModalPosition: (state) => state.hologramSharingModalPosition,
    deleteEdgeModalOpen: (state) => state.deleteEdgeModalOpen,
    deleteEdgeID: (state) => state.deleteEdgeID,
    searchToolboxSelectedNodeID: (state) => state.searchToolboxSelectedNodeID,
    searchToolboxFilteredNode: (state) => state.searchToolboxFilteredNode,
    hologramTitleEditorModalOpen: (state) => state.hologramTitleEditorModalOpen,
    hologramTitleEditorModalPosition: (state) => state.hologramTitleEditorModalPosition,
  }
}