<template>
  <div
    contenteditable="true"
    class="mt-4 font-sans text-2xl font-semibold text-left bg-sd-base02-black hologram-entity-title text-sd-base2-white focus:outline-none"
    v-html="nodeTitle"
    @input="titleInputHandler"
    @keydown.enter="titleEnterHandler"
    @paste="contentEditableOnPasteHandler"
  >
  </div>
</template>
<script>
import { ref } from "vue";
import { contentEditableOnPasteHandler } from "../../utilities/utilities";
export default {
  name: "NodeTitleBlock",
  props: {
    nodeTitle: {
      type: String,
      require: true,
    },
  },
  emits: ["updateTitle"],
  setup(props, context) {
    const updateEvent = ref(null);
    const titleInputHandler = ($event) => {
      clearTimeout(updateEvent.value);
      const text = $event.currentTarget.innerText;
      updateEvent.value = setTimeout(() => updateTitle(text), 3000);
    };

    const updateTitle = (text) => {
      context.emit("updateTitle", text);
    };

    const titleEnterHandler = ($event) => {
      $event.preventDefault();
      const text = $event.currentTarget.innerText;
      clearTimeout(updateEvent.value);
      updateTitle(text);
    };

    return {
      // Local
      // Store
      // Method
      contentEditableOnPasteHandler,
      titleInputHandler,
      titleEnterHandler,
    };
  },
};
</script>
<style>
</style>