<template>
  <div v-if="modalData">
    <transition
      name="modal-transition"
      enter-active-class="duration-300 ease-out"
      enter-from-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
      enter-to-class="translate-y-0 opacity-100 sm:scale-100"
      leave-active-class="duration-200 ease-in"
      leave-from-class="translate-y-0 opacity-100 sm:scale-100"
      leave-to-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
    >
      <div
        class="fixed overflow-y-auto text-left align-bottom transition-all transform rounded-lg shadow-2xl w-360 top-20 left-40 tran"
        role="dialog"
        aria-modal="true"
        id="settings-container"
        v-show="modalOpen"
        v-bind:style="positionStyle"
      >
        <div class="flex flex-col w-full p-4 border rounded-lg bg-sd-base02-black border-sd-base00-bryellow">
          <div
            v-if="targetHologramGroups.length !== 0"
            class="flex flex-row mb-4"
          >
            <div
              v-for=" hologramGroup in targetHologramGroups"
              v-bind:key="hologramGroup.id"
              v-bind:class="getGroupStyle(hologramGroup.color)"
              class="px-2 font-sans text-sm font-semibold rounded"
            >
              {{ hologramGroup.group.title }}
            </div>
          </div>
          <div class="flex flex-row">
            <p class="mr-2 font-sans text-sm font-extralight text-sd-base0-brblue">
              {{hostName}}
            </p>
            <div class="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="flex w-2 h-2 m-auto transform rotate-180 fill-current text-sd-base0-brblue"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.022 1.566a1.13 1.13 0 0 1 1.96 0l6.857 11.667c.457.778-.092 1.767-.98 1.767H1.144c-.889 0-1.437-.99-.98-1.767L7.022 1.566z"
                />
              </svg>
            </div>
          </div>
          <div class="flex mt-1.5 font-sans font-semibold text-lg text-sd-base2-white">
            {{nodeTitle}}
          </div>
          <div class="flex flex-row mt-6">
            <div class="flex flex-row px-2 mr-2 bg-sd-base03-brblack">
              <div class="my-auto mr-1 font-sans text-sm font-semibold text-sd-base1-brcyan">
                Link
              </div>

            </div>
            <div class="mr-8 font-sans text-sm font-semibold text-sd-base1-brcyan">
              {{linkCounts}}
            </div>
            <div class="flex flex-row px-2 mr-2 bg-sd-base03-brblack">
              <div class="my-auto mr-1 font-sans text-sm font-semibold text-sd-base1-brcyan">
                Note
              </div>
            </div>
            <div class="font-sans text-sm font-semibold text-sd-base1-brcyan">
              {{noteCounts}}<span class="ml-2 text-xs font-normal">char</span>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, computed, onMounted } from "vue";
import { colors } from "../../assets/colors";
import { getNodeTitle } from "../../utilities/utilities";
export default {
  name: "hologramNodeTooltipModal",
  setup() {
    const store = useStore();
    const positionStyle = ref({});
    const triggeredPosition = computed(() => store.getters["modal/hologramNodeTooltipModalTriggeredPosition"]);
    const modalData = computed(() => store.getters["modal/hologramNodeTooltipModalData"]);
    const modalOpen = computed(() => store.getters["modal/hologramNodeTooltipModalOpen"]);

    const modalWidth = 360;
    const gapBetweenNodeAndModal = 20;
    const prepare = () => {
      positionStyle.value = {
        left: triggeredPosition.value.x + triggeredPosition.value.width / 2 - modalWidth / 2 + "px",
        top: triggeredPosition.value.y + triggeredPosition.value.height + gapBetweenNodeAndModal + "px",
      };
      getCounts();
      getNodeData();
      getGroup();
    };
    onMounted(prepare);

    const noteCounts = ref(null);
    const linkCounts = ref(null);
    const currentHologramNodeWeights = computed(() => store.getters["weight/currentHologramNodeWeights"]);

    const getCounts = () => {
      const index = currentHologramNodeWeights.value.findIndex((e) => e.nodeID === modalData.value);
      const weight = currentHologramNodeWeights.value[index];
      const version = JSON.parse(weight.version);
      noteCounts.value = version[0].counts.note;
      linkCounts.value = version[0].counts.link;
    };

    const targetUserNode = ref(null);
    const userNodes = computed(() => store.getters["hologram/userNodes"]);
    const currentHologramNodes = computed(() => store.getters["hologram/currentHologramNodes"]);
    const hologramNode = ref(null);
    const hostName = ref(null);
    const nodeTitle = ref(null);

    const getNodeData = () => {
      if (userNodes.value.length !== 0) {
        const userNodeIndex = userNodes.value.findIndex((e) => e.nodeID === modalData.value);
        targetUserNode.value = userNodes.value[userNodeIndex];
      }
      const hologramNodeIndex = currentHologramNodes.value.findIndex((e) => e.nodeID === modalData.value);
      hologramNode.value = currentHologramNodes.value[hologramNodeIndex];
      hostName.value = new URL(hologramNode.value.node.url).hostname;
      nodeTitle.value = getNodeTitle(hologramNode.value);
    };

    const hologramNodeGroups = ref([]);
    const targetHologramGroups = ref([]);
    const currentHologramNodeGroups = computed(() => store.getters["hologram/currentHologramNodeGroups"]);
    const currentHologramGroups = computed(() => store.getters["hologram/currentHologramGroups"]);

    const getGroup = () => {
      hologramNodeGroups.value = currentHologramNodeGroups.value.reduce((array, element) => {
        if (element.hologramNodeID === hologramNode.value.id) {
          array.push(element);
        }
        return array;
      }, []);

      for (const hologramNodeGroup of hologramNodeGroups.value) {
        const groupIndex = currentHologramGroups.value.findIndex((e) => e.groupID === hologramNodeGroup.groupID);
        if ( groupIndex !== -1 ){
          const group = currentHologramGroups.value[groupIndex];
          targetHologramGroups.value.push(group);
        }
      }
    };

    const getGroupStyle = (colorName) => {
      const colorIndex = colors.findIndex((e) => e.name === colorName);
      if (colorIndex !== -1) {
        const color = colors[colorIndex];
        return `${color.bgCode} ${color.textCode}`;
      } else {
        return "bg-sd-base1-brcyan text-sd-base02-black";
      }
    };

    return {
      // Local
      positionStyle,
      targetHologramGroups,
      hostName,
      nodeTitle,
      linkCounts,
      noteCounts,

      // Store
      modalData,
      modalOpen,

      // Method
      getGroupStyle,
    };
  },
};
</script>

<style>
</style>