<template>
  <div class="fixed inset-0 h-full">
    <div
      class="flex items-end justify-center h-full min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0"
      id="modal-container"
    >
      <transition
        enter-active-class="duration-300 ease-out"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          @click="toggleModal"
          class="fixed inset-0"
          v-if="modalOpen"
        >
          <div class="absolute inset-0"></div>
        </div>
      </transition>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        v-if="modalOpen"
      >&#8203;</span>
      <transition
        name="modal-transition"
        enter-active-class="duration-300 ease-out"
        enter-from-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
        enter-to-class="translate-y-0 opacity-100 sm:scale-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="translate-y-0 opacity-100 sm:scale-100"
        leave-to-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
      >
        <div
          class="fixed overflow-y-auto text-left align-bottom transition-all transform rounded-sm shadow-xl w-120 top-16 right-6 focus:outline-none"
          role="dialog"
          aria-modal="true"
          id="hologram-sharing-modal"
          v-if="modalOpen"
          v-bind:style="positionStyle"
        >
          <!--
          <div class="flex flex-col w-full h-56 py-8 overflow-y-scroll border rounded-md bg-sd-base02-black border-sd-base01-brgreen">
            <div class="flex flex-row px-8">
              <div class="mr-auto font-sans font-semibold text-md text-sd-base1-brcyan">
                Contributor
              </div>
            </div>
            <div 
              v-if="hologramOwnerOnboard"
              class="flex flex-row w-full px-8 mt-4" 
            >
              <input
                class="flex flex-grow mr-4 bg-sd-base02-black font-sans rounded-md font-normal text-sm text-sd-base0-brblue py-1.5 px-3 focus:outline-none focus:ring-1 focus:ring-sd-base1-brcyan"
                placeholder="Add people with username or email"
                type="text"
                v-model="contributorIdentityTerm"
                id="contributorIdentityTerm"
                autocomplete="off"
              />
              <button
                class="px-4 py-2 font-sans text-sm font-medium border rounded-md border-sd-base01-brgreen focus:outline-none"
                :disabled="!contributorIdentityTerm"
                v-bind:class="getButtonAdditionalClass()"
                @click="inviteCollaborator"
              >
                Invite
              </button>
            </div>
            <div
              v-if="errorMessage"
              class="px-8 mt-2 font-sans text-base font-semibold text-sd-orange"
            >
              {{ errorMessage }}
            </div>
            <ContributorList 
              class="mt-2" 
              :contributors="currentHologramContributors"
              :hologramOwnerID="currentHologram.owner"
              :contributorsChanged="contributorsChanged"
              @checkout-contributor="openCheckoutContributorModal"
              @update-complete="catchContributorListUpdateCompleteEvent"
            />
          </div>
          -->
          <div class="flex flex-col w-full px-8 py-8 mt-4 border rounded-md bg-sd-base02-black border-sd-base01-brgreen">
            <div class="flex flex-row w-full text-sd-base1-brcyan">
              <div class="px-4 py-2 mr-auto text-sm rounded-md cursor-default bg-sd-base03-brblack">
                <p>Current access policy: <span class="font-bold">{{accessPolicy}}</span></p>
              </div>
              <button
                class="px-3 ml-auto font-sans text-sm font-normal rounded-md bg-sd-base03-brblack hover:bg-sd-base2-white hover:bg-opacity-5"
                @click="toggleAccessPolicyModal"
              >
                Edit
              </button>
            </div>
            <div v-if="accessPolicy === 'public'">
              <div class="mt-4 font-sans text-base font-semibold text-sd-base0-brblue">
                Link
              </div>
              <div class="flex flex-row mt-1 border rounded-md border-sd-base01-brgreen">
                <input
                  disabled
                  class="flex flex-grow px-2 py-0.5 overflow-x-scroll text-sm bg-sd-base03-brblack text-sd-base1-brcyan rounded-tl-md rounded-bl-md"
                  v-model="hologramUrl"
                />
                <button
                  class="inline-block px-3 py-0.5 align-middle focus:outline-none text-sd-base1-brcyan hover:bg-sd-base2-white hover:bg-opacity-5"
                  @click="copy"
                >
                  copy link
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!--
    <teleport to="#modals">
      <div v-if="checkoutContributorModalOpen">
        <CheckoutContributorModal 
          :open="checkoutContributorModalOpen"
          :targetHologramContributor="targetHologramContributor"
          :hologramName="currentHologram.title"
          @close-modal="closeCheckoutContributorModal"
          @confirm-checkout="checkoutContributor"
        />
      </div>
    </teleport>
    -->
  </div> 
</template>

<script>
import { computed, onMounted, onUnmounted, ref } from "vue"; // eslint-disable-line no-unused-vars
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import useClipboard from "vue-clipboard3";
import { gaHelpers } from "@/analytics/helper.js";
//import ContributorList from "../list/ContributorList.vue";
//import CheckoutContributorModal from "./CheckoutContributorModal.vue"
export default {
  name: "hologramSharingModal",
  components: {
    //ContributorList,
    //CheckoutContributorModal,
  },
  props: {
    hologramOwnerOnboard: {
      type: Boolean,
      require: true
    }
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const { toClipboard } = useClipboard();
    const currentHologram = computed(() => store.getters["hologram/currentHologram"]);
    const currentHologramContributors = computed(() => store.getters["hologram/currentHologramContributors"]);
    const transactingStatus = computed(() => store.getters["hologram/transactingStatus"]);
    const accessPolicy = computed(() => currentHologram.value.accessPolicy);
    const modalData = computed(() => store.getters["modal/hologramSharingModalData"]);
    const modalPosition = computed(() => store.getters["modal/hologramSharingModalPosition"]);
    const hologramUrl = computed(() => `https://totuslink.com${route.fullPath}`);
    
    const modalOpen = computed(() => store.getters["modal/hologramSharingModalOpen"]);
    const toggleModal = () => {
      if (modalOpen.value) {
        store.dispatch("modal/toggleHologramSharingModal", { data: false });
        store.dispatch("modal/updateHologramSharingModalData", { type: "reset" });
      }
    };
    
    const positionStyle = ref({});
    const prepare = () => {
      const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      positionStyle.value = {
        right: screenWidth - modalPosition.value.x - modalPosition.value.width + "px",
        top: modalPosition.value.y + 48 + "px",
      };
      window.addEventListener("keydown", keydownEscHandler);
    };

    onMounted(prepare);

    const keydownEscHandler = (event) => {
      if (event.code !== "Escape") return;
      toggleModal();
    };

    const contributorIdentityTerm = ref(null);
    const copy = async () => {
      try {
        await toClipboard(hologramUrl.value);
        gaHelpers.interaction("share_url_copied", "share");
      } catch (e) {
        console.error(e);
      }
    };

    const errorMessage = ref(null)
    const userData = computed(() => store.getters["auth/userData"]);
    const inviteCollaborator = async () => {
      if ( !contributorIdentityTerm.value ){ return }

      errorMessage.value = null;

      const variables = {
        identityTerm: contributorIdentityTerm.value, 
        hologramID: currentHologram.value.id,
        ownerID: userData.value.id
      };

      try {
        store.dispatch("hologram/updateTransactingStatus", {data:true})
        await store.dispatch("hologram/checkInContributor", variables);
        contributorsChanged.value = true;
        contributorIdentityTerm.value = null;
        store.dispatch("hologram/updateTransactingStatus", {data:false})
      } catch(error){
        if ( error === "UserNotFound"){
          errorMessage.value = "User not found"
          store.dispatch("hologram/updateTransactingStatus", {data:false})
          return
        }
        if ( error === "DuplicatedContributor"){
          errorMessage.value = "User has already contributed to this hologram"
          store.dispatch("hologram/updateTransactingStatus", {data:false})
          return
        }
        console.error("Something went wrong when try to check in contributor", error)
      }
    };

    const toggleAccessPolicyModal = () => {
      store.dispatch("modal/toggleChangeHologramAccessPolicyModal", { data: true });
      toggleModal();
    };

    const getButtonAdditionalClass = () => {
      if (contributorIdentityTerm.value) {
        return "text-sd-base2-white hover:bg-sd-base2-white hover:bg-opacity-5";
      } else {
        return "text-sd-base01-brgreen cursor-default";
      }
    };

    const checkoutContributorModalOpen = ref(false);
    const targetHologramContributor = ref(null);
    const openCheckoutContributorModal = (message) => {
      targetHologramContributor.value = message
      toggleCheckoutContributorModal();
    }

    const toggleCheckoutContributorModal = () => {
      if ( checkoutContributorModalOpen.value ){
        checkoutContributorModalOpen.value = false;
      } else {
        checkoutContributorModalOpen.value = true;
      }
    }

    const closeCheckoutContributorModal = () => {
      targetHologramContributor.value = null;
      toggleCheckoutContributorModal()
    }

    const checkoutContributor = async () => {

      errorMessage.value = null;

      const variables = {
        hologramID: currentHologram.value.id, 
        contributorUserID: targetHologramContributor.value.contributorID, 
        hologramContributorID: targetHologramContributor.value.id
      }
      
      try {
        store.dispatch("hologram/updateTransactingStatus", {data:true})
        await store.dispatch("hologram/checkOutContributor", variables);
        contributorsChanged.value = true;
        store.dispatch("hologram/updateTransactingStatus", {data:false})
      } catch(error){
        console.error("Something went wrong when checkout contributor", error)
      }
    }

    const contributorsChanged = ref(false);
    const catchContributorListUpdateCompleteEvent = (message) => {
      if ( !message ){ return }
      contributorsChanged.value = false;
    }

    const close = () => {
      window.removeEventListener("keydown", keydownEscHandler);
    };

    onUnmounted(close);

    return {
      // Local
      contributorIdentityTerm,
      accessPolicy,
      hologramUrl,
      positionStyle,
      checkoutContributorModalOpen,
      targetHologramContributor,
      contributorsChanged,
      errorMessage,

      // Store
      modalOpen,
      modalData,
      transactingStatus,
      currentHologramContributors,
      currentHologram,

      // Method
      keydownEscHandler,
      toggleModal,
      copy,
      toggleAccessPolicyModal,
      getButtonAdditionalClass,
      inviteCollaborator,

      // Event 
      openCheckoutContributorModal,
      closeCheckoutContributorModal,
      checkoutContributor,
      catchContributorListUpdateCompleteEvent
    };
  },
};
</script>
<style></style>