<template>
  <svg 
    v-bind:class="animationStyle"
    class="m-auto animate-spin" 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 24 24"
  >
    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
  </svg>
</template>
<script>
import { computed } from "vue"
export default {
  name: "loadingSpin",
  props: {
    size: {
      type: Number,
      require: true,
    }
  },
  setup(props) {
    const animationStyle = computed(() => {
      return `w-${props.size} h-${props.size}`
    })
    return {
      animationStyle
    }
  }
};
</script>
<style>
</style>