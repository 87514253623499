<template>
  <div 
    class="flex flex-col w-2/3 mx-auto mt-20"
    @keydown="onKeydownHandler($event)"  
  >
    <div
      class="w-full mr-auto text-left font-sans font-semibold text-lg text-sd-base2-white"
    >
      {{`# ${question.index}`}}
    </div>
    <div
      class="w-full mt-2 mr-auto text-left font-sans font-medium text-3xl text-sd-base1-brcyan"
    >
      {{question.title}}
    </div>
    <div
      class="w-full mt-2 mr-auto text-left font-sans font-normal text-lg text-sd-base1-brcyan"
    >
      {{question.context}}
    </div>
    <div
      class="w-full mt-4"
    >
      <div
        v-for="(chunk,index) in optionChunks"
        v-bind:key="`chunk-${index}`"
        class="flex flex-row w-full"
      >
        <div
          class="single-selection-container flex flex-row"
          v-for="option in chunk"
          v-bind:key="option.id"
        >
          <singleSelection :option="option" :subType="question.subType" v-model="activeAnswer" />
        </div>
      </div>
    </div>
    <div
      class="w-full mt-10"
    >
      <button
        class="flex flex-row w-24 h-12 items-center justify-center border border-sd-base00-bryellow rounded-sm focus:outline-none hover:bg-sd-base02-black"
        @click="clickHandler"
        v-show="!hideOKButton"
      >
        <div
          class="font-sans font-semibold text-lg text-sd-base1-brcyan mr-2"
        >
          OK
        </div>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" class="flex fill-current text-sd-base0-brblue w-6 h-6 m-auto" viewBox="0 0 16 16">
            <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
          </svg>
        </div>
      </button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import _ from 'lodash'
import singleSelection from "@/components/questionnaire/singleSelection.vue"
export default {
  name: "singleSelectionBase",
  props:{
    question: {
      required: true,
      type: Object,
    },
  },
  components:{
    singleSelection
  },
  data() {
    return {
      activeAnswer: null,
      hideOKButton: false,
    }
  },
  methods: {
    clickHandler(){
      const updateAnswer = {};
      const temp = _.cloneDeep(this.question);
      const optionIndex = this.question.options.findIndex( e => e.id === this.activeAnswer );
      temp.answer = this.question.options[`${optionIndex}`];
      updateAnswer[`${this.question.id}`] = temp;
      this.$store.dispatch("questionnaire/updateAnswers", {data: updateAnswer});
      this.$store.dispatch("questionnaire/updateAnsweredQuestions", {data: this.question.id, type:"update"})
      const index = this.questionnaire.questionsOrder.findIndex( e => e === this.currentQuestionID);
      console.log(index, this.questionnaire.questionsOrder, this.currentQuestionID)
      if ( index+1 <= this.questionnaire.questionsOrder.length -1 ){
        this.$store.dispatch("questionnaire/updateCurrentQuestionID",{data:this.questionnaire.questionsOrder[`${index+1}`]})
      }
      console.log(this.answeredQuestions.length, this.questionsSum)
      if (this.answeredQuestions.length === this.questionsSum){
        this.hideOKButton = true;
      }
    }
  },
  computed: {
    optionChunks: function(){
      return _.chunk(Object.values(this.question.options), 2);
    },
    ...mapGetters({
      currentQuestionID: "questionnaire/currentQuestionID",
      questionnaire: "questionnaire/questionnaire",
      questionsSum: "questionnaire/questionsSum",
      answeredQuestions: "questionnaire/answeredQuestions",
    })
  },
};
</script>
<style>
</style>