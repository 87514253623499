<template>
  <div class="flex w-full h-full base bg-sd-base03-brblack">
    <div class="flex flex-col w-full m-auto mx-4 sm:w-360 sm:mx-auto">
      <div class="mb-12 font-sans text-5xl font-bold title lg:text-5xl text-sd-base2-white">Sign up</div>
      <form @submit.prevent>
        <div v-if="!confirmPassword" class="font-sans text-sm font-light leading-4 text-left text-sd-base1-brcyan">Username</div>
        <div v-if="!confirmPassword" class="flex flex-row mx-auto mt-3 rounded-md sm:w-360 border-sd-base00-bryellow">
          <input 
            class="flex-1 bg-sd-base02-black font-sans rounded-md font-normal text-sm text-sd-base0-brblue py-1.5 px-3 focus:outline-none focus:ring-1 focus:ring-sd-base1-brcyan" 
            placeholder="Enter your username..."
            type="text"
            v-model="username"
            id="username"
            autocomplete="off"
          />
        </div>
        <div class="mt-6 font-sans text-sm font-light leading-4 text-left text-sd-base1-brcyan">Email</div>
        <div class="flex flex-row mx-auto mt-3 rounded-md sm:w-360 border-sd-base00-bryellow">
          <input 
            class="flex-1 bg-sd-base02-black font-sans rounded-md font-normal text-sm text-sd-base0-brblue py-1.5 px-3 focus:outline-none focus:ring-1 focus:ring-sd-base1-brcyan" 
            placeholder="Enter your email address..."
            type="text"
            v-model="email"
            id="email"
            :disabled="confirmPassword"
            autocomplete="off"
          />
        </div>
        <div v-if="!confirmPassword" class="mt-6 font-sans text-sm font-light leading-4 text-left text-sd-base1-brcyan">Password</div>
        <div v-if="!confirmPassword" class="flex flex-row mx-auto mt-3 rounded-md sm:w-360 border-sd-base00-bryellow">
          <input 
            class="flex-1 bg-sd-base02-black font-sans rounded-md font-normal text-sm text-sd-base0-brblue py-1.5 px-3 focus:outline-none focus:ring-1 focus:ring-sd-base1-brcyan" 
            placeholder="Enter your password..."
            type="password"
            v-model="password"
            autocomplete="off"
          />
        </div>
        <div v-if="error" class="mt-4 font-sans text-sm font-bold text-sd-yellow">{{ error.message }}</div>
        <button v-if="!confirmPassword" @click="signUp" class="flex flex-row w-full mx-auto mt-8 border rounded sm:w-360 border-sd-base00-bryellow focus:outline-none focus:ring-1 focus:ring-sd-base1-brcyan hover:bg-sd-base02-black">
          <div class="flex-1 font-sans font-medium text-sm text-sd-base0-brblue py-1.5 px-3">
            <p>Sign up</p>
          </div>
        </button>
      </form>
      <div v-if="confirmPassword">
        <div class="mt-5 font-sans text-sm font-normal text-center text-sd-base0-brblue">
          <p>We just send you a signup verification code.</p>
          <p>Please check your inbox</p>
          <div class="mt-5 font-sans text-sm font-light leading-4 text-left text-sd-base1-brcyan">Verification code</div>
          <div class="flex flex-row mx-auto mt-3 border rounded-md sm:w-360 border-sd-base00-bryellow">
            <input 
              class="flex-1 bg-sd-base02-black font-sans rounded-md font-normal text-sm text-sd-base0-brblue py-1.5 px-3 focus:outline-none focus:ring-1 focus:ring-sd-base1-brcyan" 
              placeholder="Paste verification code..."
              type="type"
              v-model="code"
            />
          </div>
        </div>
        <button 
          v-if="confirmPassword" 
          @click="confirmSignUp" 
          class="flex flex-row w-full mx-auto mt-6 border rounded sm:w-360 border-sd-base00-bryellow focus:outline-none focus:ring-1 focus:ring-sd-base1-brcyan hover:bg-sd-base02-black"
          :disabled="confirmationProcessing"
        >
          <div v-if="!confirmationProcessing" class="flex-1 font-sans font-medium text-sm text-sd-base0-brblue py-1.5 px-3">
            <p>Confirm signup</p>
          </div>
          <div v-else class="flex mx-auto py-1.5 px-3 items-center">
            <svg class="w-5 h-5 mr-3 -ml-1 animate-spin text-sd-base0-brblue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <span class="font-sans text-sm font-medium text-sd-base0-brblue">Processing</span>
          </div>
        </button>
      </div>
      <!--
      <div v-if="!confirmPassword" class="mt-6 font-sans text-xs font-normal text-sd-base00-bryellow">
        <p>By clicking “Continue with Google/Email" above, you acknowledge that you have read and understood, and agree to Hololink’s <a href="" class="underline">Term of Uses</a> and <a href="" class="underline">Privacy Policy</a></p>
      </div>
      -->
    </div>
  </div>
</template>

<script>
import { gaHelpers } from "@/analytics/helper.js"
export default {
  name: "SignUpView",
  data: () => ({
    password: "",
    username: "",
    email:"",
    error: "",
    confirmPassword: false,
    code: "",
    confirmationProcessing: false,
  }),
  mounted() {
    window.document.title = "Totuslink - Sign up"
  },
  methods: {
    async federationSignIn(){
      await this.$store.dispatch("auth/federationSignIn")
    },
    async signUp() {
      this.error = "";
      if (!this.email || !this.password) {
        return;
      }
      try {
        const { username, password, email } = this;
        await this.$store.dispatch("auth/signUp", {
          username, 
          email,
          password
        });
        this.confirmPassword = true;
        gaHelpers.engagement("sign_up", "auth")
      } catch (error) {
        console.log(error, 'try')
        this.error = error;
        if (error.message == "PreSignUp failed with error A user with the same email address exists."){
          this.error.message = "Email already in use"
        }
      }
    },
    async confirmSignUp() {
      if (!this.username || !this.code) {
        return;
      }
      this.confirmationProcessing = true;
      console.log(this.confirmationProcessing)
      try {
        const { username, password, code, email } = this;
        await this.$store.dispatch("auth/confirmSignUp", {
          username,
          email,
          code,
        });
        gaHelpers.engagement("confirm_sign_up", "auth")
        await this.$store.dispatch("auth/login", {
          username,
          password,
        });
        this.$router.push({name:"Dock", params: {username: username}});
      } catch (error) {
        console.log(error);
        this.confirmationProcessing = false;
        this.error = error;
      }
    }, 
  },
};
</script>

<style>
</style>