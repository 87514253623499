<template>
  <div 
    class="fixed inset-0 h-full"
  >
    <div
      class="flex items-end justify-center h-full min-h-screen px-4 pt-4 text-center sm:block sm:p-0"
      id="modal-container"
    >
      <transition
        enter-active-class="duration-300 ease-out"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div 
          @click="toggleDeleteEdgeModal('byClickAway')" 
          class="fixed inset-0"
          v-if="deleteEdgeModalOpen"
        >
          <div class="absolute inset-0 bg-black opacity-50"></div>
        </div>
      </transition>
      

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        v-show="deleteEdgeModalOpen"
        >&#8203;</span
      >
      <transition
        name="modal-transition"
        enter-active-class="duration-300 ease-out"
        enter-from-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
        enter-to-class="translate-y-0 opacity-100 sm:scale-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="translate-y-0 opacity-100 sm:scale-100"
        leave-to-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
      >
        <div
          class="items-center inline-block overflow-y-auto text-left align-middle transition-all transform rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-xl sm:w-full"
          role="dialog"
          aria-modal="true"
          id="settings-container"
          v-show="deleteEdgeModalOpen"
        >
          <div class="w-full bg-sd-base02-black">
            <div
              class="flex-col flex-grow w-full px-4 sm:flex sm:p-8 sm:items-start"
            >
              <div class="mt-3 sm:mt-0 sm:text-left">
                <h2
                  class="text-xl font-medium leading-6 text-sd-base1-brcyan"
                  id="modal-headline"
                >
                  Delete connection
                </h2>
                <span
                  class="font-sans text-sm font-normal text-sd-base00-bryellow"
                >
                  This will delete the connection at <span class="font-semibold text-sd-base1-brcyan">{{currentHologram.title}}</span>
                </span>
              </div>
            </div>
            <div class="w-full px-8 pb-8 mt-3 sm:mt-0 sm:text-left sm:items-start text-sd-base1-brcyan">
              <p class="text-sd-base2-white">This will delete connection:</p>
              <div
                class="mt-2"
              >
                <div
                  class="flex flex-row"
                >
                  <div 
                    class="flex items-center"
                  >
                    <button
                      class="flex mr-2 text-sm has-tooltip focus:outline-none"
                    >
                      {{ startNodeTruncateContent }}
                      <span
                        class="p-3 mt-10 mr-2 text-sm font-medium text-left rounded shadow-lg tooltip w-52 bg-sd-base1-brcyan text-sd-base03-brblack"
                      >
                        {{ startNodeFullContent }}
                      </span>
                    </button>
                  </div>
                  <div
                    class="flex items-center mr-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="flex w-3 h-3 my-auto fill-current text-sd-base0-brblue"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"
                      />
                    </svg>
                  </div>
                  <div 
                    class="flex items-center"
                  >
                    <button
                      class="flex mr-2 text-sm has-tooltip focus:outline-none"
                    >
                      {{ endNodeTruncateContent }}
                      <span
                        class="p-3 mt-10 mr-2 -ml-2 text-sm font-medium text-left rounded shadow-lg tooltip w-52 bg-sd-base1-brcyan text-sd-base03-brblack"
                      >
                        {{ endNodeFullContent }}
                      </span>
                    </button>
                  </div>
                </div>
                
              </div>
              <p class="mt-8 text-sd-base2-white">This won't delete:</p>
              <ul class="pl-4 mt-2 text-sm list-disc">
                <li>
                  The whole connection entity in your account, if you want to delete the connection globally you have to delete it in your account setting(under development).
                </li>
              </ul>
            </div>
            <footer
              class="sticky bottom-0 flex flex-row border-t border-opacity-50 bg-sd-base02-black border-sd-base01-brgreen sm:py-4 sm:px-4"
            >
              <button
                @click="deleteEdge"
                class="px-4 py-2 font-sans text-sm font-medium border rounded-md border-sd-base01-brgreen text-sd-base2-white hover:bg-sd-base2-white hover:bg-opacity-5 focus:outline-none"
              >
                Delete
              </button>
              <button
                @click="toggleDeleteEdgeModal('byButton')"
                class="px-4 py-2 ml-4 font-sans text-sm font-medium border rounded-md border-sd-base01-brgreen text-sd-base01-brgreen hover:bg-sd-base2-white hover:bg-opacity-5 focus:outline-none"
              >
                Cancel
              </button>
              <div
                  class="flex items-center ml-4"
                  v-if="transactingStatus"
                >
                  <svg class="w-5 h-5 animate-spin text-sd-base0-brblue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </div>
            </footer>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { useStore } from "vuex";
import { gaHelpers } from "@/analytics/helper.js"
export default {
  setup() {
    const store = useStore();
    const targetEdge = ref({});
    const deleteEdgeModalOpen = computed(() => store.getters["modal/deleteEdgeModalOpen"]);
    const targetEdgeID = computed(() => store.getters["modal/deleteEdgeID"]);
    const currentHologram = computed(() => store.getters["hologram/currentHologram"]);
    const currentHologramEdges = computed(() => store.getters["hologram/currentHologramEdges"]);
    const currentHologramNodes = computed(() => store.getters["hologram/currentHologramNodes"]);
    const transactingStatus = computed(() => store.getters["hologram/transactingStatus"]);

    const startNodeTruncateContent = ref(null);
    const startNodeFullContent = ref(null);
    const endNodeTruncateContent = ref(null);
    const endNodeFullContent = ref(null);
    
    const prepare = async () => {
      const index = currentHologramEdges.value.findIndex( e => e.edgeID === targetEdgeID.value );
      targetEdge.value = currentHologramEdges.value[`${index}`];
      
      const startHologramNodeIndex = currentHologramNodes.value.findIndex( e => e.nodeID === targetEdge.value.edge.startNodeID);
      const startNode = currentHologramNodes.value[`${startHologramNodeIndex}`].node;
      startNodeTruncateContent.value = truncateString(startNode.title, 25);
      startNodeFullContent.value = startNode.title;

      const endHologramNodeIndex = currentHologramNodes.value.findIndex( e => e.nodeID === targetEdge.value.edge.endNodeID);
      const endNode = currentHologramNodes.value[`${endHologramNodeIndex}`].node;
      endNodeTruncateContent.value = truncateString(endNode.title, 25);
      endNodeFullContent.value = endNode.title;
      window.addEventListener("keydown", keydownEscHandler);
    };

    onMounted(prepare)

    const keydownEscHandler = (event) => {
      if ( event.code !== "Escape" ) return;
      if ( !deleteEdgeModalOpen.value ) return;
      toggleDeleteEdgeModal()
    };

    const deleteEdge = async () => {
      try {
        store.dispatch("hologram/updateTransactingStatus", {data:true});
        let deleteTargets = [];
        deleteTargets.splice(0, 0, targetEdge.value)
        await store.dispatch("hologram/deleteHologramEntity", {data:deleteTargets});
        gaHelpers.engagement("delete_edge", "core")
        const newNotification = {
          "location": "edgeDeletion",
        }
        store.dispatch("notification/updateHologramOperationNotification", {type:"update", data:newNotification})
        store.dispatch("hologram/updateTransactingStatus", {data:false});
        toggleDeleteEdgeModal();
      } catch(err){
        console.error("Something went wrong when delete Edge", err);
      }
    }

    const toggleDeleteEdgeModal = (type=null) => {
      if ( deleteEdgeModalOpen.value ){
        store.dispatch("modal/updateDeleteEdgeModalOpen", {data:false});
        store.dispatch("modal/updateDeleteEdgeID", {data:null});
      }
      if ( type === "byClickAway" ){
        gaHelpers.interaction("close_delete_edge_modal_by_click_away", "delete_edge_modal")
      } else if ( type === "byButton" ) {
        gaHelpers.interaction("close_delete_edge_modal_by_button", "delete_edge_modal")
      }
    }

    const truncateString = (string, length) => {
      if (string.length <= length) {
        return string
      }
      return string.slice(0, length) + '...'
    }

    const clear = () => {
      window.removeEventListener("keydown", keydownEscHandler);
    }

    onUnmounted(clear)

    return {
      targetEdge,
      deleteEdgeModalOpen,
      currentHologram,
      startNodeTruncateContent,
      startNodeFullContent,
      endNodeTruncateContent,
      endNodeFullContent,
      prepare,
      deleteEdge,
      toggleDeleteEdgeModal,
      transactingStatus
    }
  }
};
</script>
<style>
</style>