<template>
  <div class="flex flex-col w-full mb-20 bg-sd-base03-brblack">
    <div
      class="px-12 mt-4 mb-2 font-sans text-xl font-bold text-left text-sd-base0-brblue"
    >
      Holograms
    </div>
    <div class="mx-12 border-t sm:mt-2 sm:mb-6 border-sd-base01-brgreen"></div>
    <div 
      class="grid grid-cols-1 gap-8 mx-12 mt-4 sm:grid-cols-2 md:grid-cols-4"
      v-if="hologramsBelongedToCurrentUser.length !== 0 && dataIsReady"
    >
      <div 
        v-for="hologram in hologramsBelongedToCurrentUser" 
        v-bind:key="hologram.id"
      >
        <router-link
          :to="{name:'Hologram', params:{hologramID:assembleHologramUrl(hologram)}}" 
          class="flex flex-col w-full overflow-hidden rounded-md shadow-lg border-sd-base0-brblue hover:bg-sd-base2-white hover:bg-opacity-10 focus:outline-none bg-sd-base02-black"
        >
          <div class="flex w-full h-32 bg-sd-base00-bryellow"></div>
          <div class="flex flex-row h-8 m-4 sm:h-12">
            <div
              v-if="hologram.accessPolicy == 'private'"
              class="flex items-center mr-2"
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="flex w-4 h-4 my-auto fill-current text-sd-base0-brblue" viewBox="0 0 16 16">
                <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"/>
              </svg>
            </div>
            <div class="flex font-sans font-bold text-left break-all text-md text-sd-base0-brblue">
              {{ hologram.title }}
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div
      v-else-if="hologramsBelongedToCurrentUser.length === 0 && dataIsReady"
      class="mx-12 mr-auto"
    >
      <button
        class="px-5 py-1 font-sans border rounded-md border-sd-base01-brgreen text-sd-base0-brblue hover:bg-sd-base02-black focus:outline-none"
        @click="toggleCreateHologramModal"
      >
        Create new hologram  
      </button>
    </div>
    <div
      v-else
      class="flex flex-grow w-full h-full"
    >
      <svg class="w-5 h-5 m-auto animate-spin text-sd-base0-brblue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div>
  </div>
</template>
<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
export default {
  name: "UserHolograms",
  setup() {
    const store = useStore();
    const user  = computed(() => store.getters["auth/user"]);
    const dataIsReady = ref(false);
    const hologramsBelongedToCurrentUser  = computed(() => store.getters["hologram/hologramsBelongedToCurrentUser"]);
    const createNewHologramModalOpen = computed(() => store.getters["hologram/createNewHologramModalOpen"]);

    const toggleCreateHologramModal = () => {
      if (!createNewHologramModalOpen.value){
        store.dispatch("modal/toggleCreateNewHologramModal", {data:true});
      }
    }
    
    const assembleHologramUrl = (hologram) => {
      return hologram.id
    }

    const prepare = async () => {
      window.document.title = "Holograms"
      try {
        await store.dispatch("auth/authAction");
        await store.dispatch("hologram/getHologramsBelongToCurrentUser", {data:user.value.attributes.sub});
        dataIsReady.value = true;
      } catch(err){
        console.log("Something went wrong when fetching data for Holograms page", err);
      }
    }
    onMounted(prepare);
    return {
      user,
      hologramsBelongedToCurrentUser,
      createNewHologramModalOpen,
      dataIsReady,
      prepare,
      toggleCreateHologramModal,
      assembleHologramUrl 
    }
  },
};
</script>
<style>
</style>