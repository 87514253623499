<template>
  <div class="flex flex-col w-full mt-4">
    <div class="flex flex-row group-block-placeholder">
      <div class="flex flex-row h-10 my-auto mr-4 rounded-md w-28">
        <iconGrid1x2
          :size="4"
          class="my-auto mr-4 text-sd-base0-brblue"
        />
        <div class="my-auto mr-auto font-sans text-sm font-medium cursor-pointer text-sd-base1-brcyan">
          Group
        </div>
      </div>
      <div class="flex flex-grow rounded-md hover:bg-sd-base2-white hover:bg-opacity-5 focus:outline-none">
        <singleSelect
          @update-single-selection-options-status="catchUpdateSingleSelectionOptionsStatusEvent"
          :options="currentHologramGroups"
          :selectedOption="selectedGroup"
          :confirmGroupDeletionModalOpen="confirmGroupDeletionModalOpen"
          @update-selected-option="catchUpdateEvent"
          @create-option="catchCreateEvent"
          @change-color="catchChangeColorEvent"
          @delete-option="catchDeleteEvent"
          @change-option-name="catchChangeNameEvent"
        />
      </div>
    </div>
    <teleport to="#modals">
      <div v-if="confirmGroupDeletionModalOpen">
        <confirmGroupDeletionModal
          :open="confirmGroupDeletionModalOpen"
          :hologramGroup="deleteHologramGroup"
          @toggle-confirm-group-deletion-modal="catchToggleConfirmGroupDeletionModalEvent"
          @deletion-complete="catchGroupDeletionCompleteEvent"
        />
      </div>
    </teleport>
  </div>
</template>
<script>
import { ref, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import iconGrid1x2 from "../icon/iconGrid1x2.vue";
import { gaHelpers } from "../../analytics/helper";
import singleSelect from "../input/singleSelect.vue";
import confirmGroupDeletionModal from "../modals/confirmGroupDeletionModal.vue";
export default {
  name: "NodeGroupBlock",
  components: {
    iconGrid1x2,
    singleSelect,
    confirmGroupDeletionModal,
  },
  props: {
    hologramNode: {
      type: Object,
      require: true,
    },
    userID: {
      type: String,
      require: true,
    },
    currentHologram: {
      type: Object,
      require: true,
    },
  },
  setup(props) {
    const store = useStore();
    const currentHologramGroups = computed(() => store.getters["hologram/currentHologramGroups"]);
    const currentHologramNodeGroups = computed(() => store.getters["hologram/currentHologramNodeGroups"]);
    const displayCluster = computed(() => store.getters["hologram/displayCluster"]);
    const selectedGroup = ref({});
    const hologramNodeGroup = ref(null);
    const confirmGroupDeletionModalOpen = ref(false);
    const deleteHologramGroup = ref(null);
    const singleSelectionOptionsStatus = ref(false);

    const prepare = () => {
      getTargetGroupData();
    };

    onMounted(prepare);

    const getTargetGroupData = () => {
      const hologramNodeGroupIndex = currentHologramNodeGroups.value.findIndex((e) => e.hologramNodeID === props.hologramNode.id);
      if (hologramNodeGroupIndex !== -1) {
        hologramNodeGroup.value = currentHologramNodeGroups.value[hologramNodeGroupIndex];
        const groupIndex = currentHologramGroups.value.findIndex((e) => e.groupID === hologramNodeGroup.value.groupID);
        if (groupIndex !== -1) {
          selectedGroup.value = currentHologramGroups.value[groupIndex];
        } else {
          selectedGroup.value = {};
        }
      } else {
        selectedGroup.value = {};
        hologramNodeGroup.value = null;
      }
    };

    const catchUpdateSingleSelectionOptionsStatusEvent = (message) => {
      singleSelectionOptionsStatus.value = message;
    };

    const catchUpdateEvent = async (message) => {
      if (selectedGroup.value.id === message.id) {
        return;
      }

      const newGroupID = message.groupID;
      selectedGroup.value = message;
      // User un-select option -> delete hologramNodeGroup

      if (Object.keys(message).length === 0) {
        const checkoutHologramNodeGroupInput = {
          hologramNodeGroupID: hologramNodeGroup.value.id,
          hologramNode: props.hologramNode
        }
        try {
          await store.dispatch("hologram/checkoutHologramNodeGroup", checkoutHologramNodeGroupInput);
          hologramNodeGroup.value = null;
        } catch (err) {
          console.error(err);
        }
        renderHologram();
        gaHelpers.engagement("remove_group", "group");
        return;
      }

      if (!hologramNodeGroup.value) {
        // User select group of the node for the first time
        const checkInHologramNodeGroupInput = {
          hologramNode: props.hologramNode,
          groupID: newGroupID,
        };
        try {
          hologramNodeGroup.value = await store.dispatch("hologram/checkInHologramNodeGroup", checkInHologramNodeGroupInput);
        } catch (err) {
          console.error("Something went wrong when user select a hologramGroup", err);
        }
      } else {
        // User select other groups

        const changeHologramNodeGroupInput = {
          hologramNodeGroupID: hologramNodeGroup.value.id,
          hologramNode: props.hologramNode,
          groupID: newGroupID,
        };
        try {
          hologramNodeGroup.value = await store.dispatch("hologram/switchHologramNodeGroup", changeHologramNodeGroupInput);
        } catch (err) {
          console.error(err);
        }
      }
      renderHologram();
      gaHelpers.engagement("add_group", "group");
    };

    const catchCreateEvent = async (message) => {
      const checkInInput = {
        title: message, 
        nodeID: props.hologramNode.nodeID, 
        hologramNodeID: props.hologramNode.id, 
        oldHologramNodeGroup: hologramNodeGroup.value,
      }
      try {
        const result = await store.dispatch("hologram/advancedCheckInNewGroup", checkInInput);
        selectedGroup.value = result.hologramGroup;
        hologramNodeGroup.value = result.hologramNodeGroup;
        renderHologram();
        gaHelpers.engagement("create_group", "group");
      } catch(err){
        console.error("Something went wrong when check in new group", err);
      }
      
      /*
      let group, hologramGroup;
      try {
        const createGroupVariables = {
          title: message,
          createdByID: props.userID,
          owner: props.userID,
        };
        console.log(createGroupVariables);
        group = await store.dispatch("hologram/createGroup", createGroupVariables);
      } catch (err) {
        console.error(err);
        return;
      }

      try {
        const createHologramGroupVariables = {
          hologramID: props.currentHologramID,
          groupID: group.id,
          owner: props.userID,
        };
        hologramGroup = await store.dispatch("hologram/createHologramGroup", createHologramGroupVariables);
        selectedGroup.value = hologramGroup;
      } catch (err) {
        console.error(err);
        return;
      }

      try {
        if (!hologramNodeGroup.value) {
          const createHologramNodeGroupVariables = {
            hologramNodeID: props.hologramNode.id,
            groupID: group.id,
            owner: props.userID,
          };
          hologramNodeGroup.value = await store.dispatch("hologram/createHologramNodeGroup", createHologramNodeGroupVariables);
        } else {
          const updateHologramNodeGroupVariables = {
            hologramNodeGroupID: hologramNodeGroup.value.id,
            newGroupID: group.id,
          };
          hologramNodeGroup.value = await store.dispatch("hologram/updateHologramNodeGroup", updateHologramNodeGroupVariables);
        }
      } catch (err) {
        console.error(err);
        return;
      }

      try {
        const toGraphVariables = {
          nodeID: props.hologramNode.nodeID,
          groupID: group.id,
        };

        await store.dispatch("hologram/updateHologramNodeGroupToGraph", toGraphVariables);
      } catch (err) {
        console.error(err);
      }
      renderHologram();
      gaHelpers.engagement("create_group", "group");
      */
    };

    const catchChangeColorEvent = async (message) => {

      const input = {
        hologramGroupID: message.option.id, 
        newColor: message.color.name
      }

      try {
        await store.dispatch("hologram/checkInNewGroupColor", input);
        if (selectedGroup.value.id === message.option.id) {
          selectedGroup.value.color = message.color.name;
        }
        store.dispatch("hologram/updateRenderHologramNodeColor", { data: true });
        gaHelpers.engagement("change_group_color", "group");
      } catch(err){
        console.error("Something went wrong when try to update group's color", err);
      }

      

      /*
      const variables = {
        hologramGroupID: message.option.id,
        newColor: message.color.name,
      };

      try {
        await store.dispatch("hologram/updateHologramGroupColor", variables);
        if (selectedGroup.value.id === message.option.id) {
          selectedGroup.value.color = message.color.name;
        }
        store.dispatch("hologram/updateRenderHologramNodeColor", { data: true });
        gaHelpers.engagement("change_group_color", "group");
      } catch (err) {
        console.error(err);
      }
      */
    };

    const catchDeleteEvent = async (message) => {
      toggleConfirmGroupDeletionModal();
      deleteHologramGroup.value = message.option;
      gaHelpers.interaction("open_confirm_group_deletion_modal", "group");
    };

    const catchChangeNameEvent = async (message) => {
      const input = {
        groupID: message.option.groupID,
        title: message.changedName,
      };
      try {
        await store.dispatch("hologram/changeHologramGroupTitle", input);
        
        if ( selectedGroup.value && input.groupID === selectedGroup.value.group.id ){
          selectedGroup.value.group.title = input.title;
        }

        gaHelpers.engagement("change_group_name", "group");
      } catch (err) {
        console.error(err);
      }
    };

    const toggleConfirmGroupDeletionModal = () => {
      if (confirmGroupDeletionModalOpen.value) {
        confirmGroupDeletionModalOpen.value = false;
        return;
      }
      confirmGroupDeletionModalOpen.value = true;
    };

    const catchToggleConfirmGroupDeletionModalEvent = () => {
      toggleConfirmGroupDeletionModal();
    };

    const catchGroupDeletionCompleteEvent = () => {
      if (hologramNodeGroup.value) {
        hologramNodeGroup.value = null;
        selectedGroup.value = {};
      }
    };

    const renderHologram = () => {
      if (displayCluster.value) {
        store.dispatch("hologram/updateRenderHologramNodeColor", { data: true });
        store.dispatch("hologram/updateRenderHologramGraph", { data: true });
        return;
      }
      store.dispatch("hologram/updateRenderHologramNodeColor", { data: true });
    };

    watch(
      () => props.hologramNode.id,
      () => {
        getTargetGroupData();
      }
    );

    return {
      // Local
      selectedGroup,
      confirmGroupDeletionModalOpen,
      deleteHologramGroup,

      // Store
      currentHologramGroups,

      // Method
      toggleConfirmGroupDeletionModal,

      // Event
      catchUpdateEvent,
      catchCreateEvent,
      catchChangeColorEvent,
      catchDeleteEvent,
      catchChangeNameEvent,
      catchToggleConfirmGroupDeletionModalEvent,
      catchGroupDeletionCompleteEvent,
      catchUpdateSingleSelectionOptionsStatusEvent,
    };
  },
};
</script>
<style>
</style>