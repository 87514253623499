<template>
  <div class="flex flex-col w-full -ml-1 bg-sd-base02-black">
    <div class="flex flex-row mb-2 ml-1 group ">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="flex w-4 h-4 my-auto mr-2 fill-current text-sd-base01-brgreen hover:text-sd-base1-brcyan"
        viewBox="0 0 16 16"
      >
        <path d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z" />
        <path
          fill-rule="evenodd"
          d="M9.146 8.146a.5.5 0 0 1 .708 0L11.5 9.793l1.646-1.647a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 0-.708z"
        />
        <path
          fill-rule="evenodd"
          d="M11.5 5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 1 .5-.5z"
        />
        <path d="M3.56 11V7.01h.056l1.428 3.239h.774l1.42-3.24h.056V11h1.073V5.001h-1.2l-1.71 3.894h-.039l-1.71-3.894H2.5V11h1.06z" />
      </svg>
      <p class="mr-auto text-sm opacity-0 text-sd-base00-bryellow group-hover:opacity-100">
        This editor support markdown syntax.
      </p>
    </div>
    <div class="inline-block w-full mb-2 border-b border-opacity-25 border-sd-base01-brgreen"></div>
    <textarea
      class="w-full font-sans text-left border-none shadow-none outline-none resize-none hololink-markdown-editor bg-sd-base02-black text-sd-base2-white focus:outline-none focus:border-none focus:shadow-none ring-0 focus:ring-0"
      id="hololink-markdown-editor"
    />
  </div>
</template>
<script>
/*
import { mapGetters } from "vuex";
import { gaHelpers } from "@/analytics/helper.js";
import { getHologramNodeWeight, getCrossHologramNodes, getOuterLink } from "@/graphql/helper/Get.js";
//import "@/assets/simplemde.css";
//import SimpleMDE from "simplemde";
export default {
  name: "mdEditor",
  props: {
    userNodeID: String,
    hologramID: String,
  },
  mounted() {
    this.initialize();
  },
  data() {
    return {
      simplemde: null,
      updateUserNodeEvent: [],
      updateUserNodeTimeInterval: 2000,
    };
  },
  methods: {
    initialize() {
      // set initial value if it exists
      let simpleMDEConfigs;
      if (this.userNodeID) {
        const currentUserNode = this.userNodes[`${this.userNodes.findIndex((e) => e.id == this.userNodeID)}`];

        simpleMDEConfigs = {
          toolbar: false,
          element: document.getElementById("hololink-markdown-editor"),
          autofocus: false,
          status: false,
          toolbarTips: false,
          spellChecker: false,
          placeholder: "Type anything here...",
          initialValue: currentUserNode.note,
        };
      } else if (this.hologramID) {
        simpleMDEConfigs = {
          toolbar: false,
          element: document.getElementById("hololink-markdown-editor"),
          autofocus: false,
          status: false,
          toolbarTips: false,
          spellChecker: false,
          placeholder: "Type anything here...",
          initialValue: this.currentHologram.intro,
        };
      }

      //this.simplemde = new SimpleMDE(simpleMDEConfigs);
      this.simplemde.codemirror.options.scrollbarStyle = "null";

      this.simplemde.codemirror.on("change", () => {
        this.$store.dispatch("modal/updateMkEditorChangesUnsaved", { data: true });
        clearTimeout(this.updateUserNodeEvent[0]);
        let updateEvent = setTimeout( () => {
          this.updateContentToDB(this.userNodeID);
        }, this.updateUserNodeTimeInterval);
        this.updateUserNodeEvent.splice(0, 1, updateEvent);
      });
    },
    async updateContentToDB(userNodeID) {
      if (this.simplemde) {
        if (userNodeID) {
          const currentUserNode = this.userNodes[`${this.userNodes.findIndex((e) => e.id == userNodeID)}`];
          const hologramNode = this.currentHologramNodes[`${this.currentHologramNodes.findIndex((e) => e.nodeID === currentUserNode.nodeID)}`];

          this.$store.dispatch("hologram/updateTransactingStatus", { data: true });
          const note = this.simplemde.value();
          const counts = countWords(note);

          const weight = await getHologramNodeWeight([], hologramNode.id);
          const version = JSON.parse(weight.result.version);
          console.log(weight, version);
          const crossHologram = await getCrossHologramNodes([], this.userData.id, hologramNode.nodeID);
          const outerLink = await getOuterLink([], this.userData.id, this.currentHologram.id, hologramNode.nodeID);
          const newVersion = {
            times: Date.now(),
            counts: {
              link: version[0].counts.link,
              note: counts,
              crossHologram: crossHologram.result.length,
              outerLink: outerLink.result.length,
            },
            weight: {
              link: 1,
              note: 0.01,
              crossHologram: 0.5,
              outerLink: 0.5,
            },
          };

          const newValue =
            newVersion.counts.link * newVersion.weight.link +
            newVersion.counts.note * newVersion.weight.note +
            newVersion.counts.crossHologram * newVersion.weight.crossHologram +
            newVersion.counts.outerLink * newVersion.weight.outerLink;

          version.splice(0, 0, newVersion);

          const updateWeight = {
            version: JSON.stringify(version),
            value: newValue,
          };

          try {
            const result = await this.$store.dispatch("weight/updateHologramNodeWeight", { hologramNodeID: hologramNode.id, weightData: updateWeight });
            this.$store.dispatch("weight/updateItemInCurrentHologramNodeWeight", result);
          } catch (err) {
            console.log("Something went wrong when update hologramNode weight", err);
          }

          try {
            await this.$store.dispatch("hologram/updateUserNodeNote", { "userNodeID": userNodeID, "note": note });
            gaHelpers.engagement("edit_note", "context");
          } catch (err) {
            console.log("Something went wrong when update userNode Note", err);
          }
          this.$store.dispatch("weight/updateWeightChange", { data: true });
          this.$store.dispatch("hologram/updateTransactingStatus", { data: false });
        } else if (this.hologramID) {
          this.$store.dispatch("hologram/updateTransactingStatus", { data: true });
          const intro = this.simplemde.value();
          try {
            await this.$store.dispatch("hologram/updateHologramIntro", { hologramID: this.hologramID, intro: intro });
            gaHelpers.engagement("edit_intro", "context");
          } catch (err) {
            console.error(err);
          }
          this.$store.dispatch("hologram/updateTransactingStatus", { data: false });
        }
      }
    },
    updateContentToDBNow: function () {
      if (this.simplemde) {
        if (this.userNodeID) {
          const note = this.simplemde.value();
          gaHelpers.engagement("edit_note", "context");
          this.$store.dispatch("hologram/updateUserNodeNote", { userNodeID: this.userNodeID, note: note });
          return;
        }
        if (this.hologramID) {
          const intro = this.simplemde.value();
          gaHelpers.engagement("edit_intro", "context");
          this.$store.dispatch("hologram/updateHologramIntro", { hologramID: this.hologramID, intro: intro });
          return;
        }
      }
    },
  },
  beforeUnmount() {
    this.updateContentToDBNow();
  },
  unmounted() {
    this.simplemde = null;
  },
  computed: {
    ...mapGetters({
      userData: "auth/userData",
      userNodes: "hologram/userNodes",
      currentHologram: "hologram/currentHologram",
      currentHologramEdges: "hologram/currentHologramEdges",
      currentHologramNodes: "hologram/currentHologramNodes",
    }),
  },
  watch: {
    userNodeID: function (newValue, oldValue) { // eslint-disable-line no-unused-vars
      const note = this.simplemde.value();
      this.$store.dispatch("hologram/updateUserNodeNote", { userNodeID: oldValue, note: note });
      this.initialize();
    },
  },
};

const countWords = (data) => {
  var pattern = /[a-zA-Z0-9_\u00A0-\u02AF\u0392-\u03c9\u0410-\u04F9]+|[\u4E00-\u9FFF\u3400-\u4dbf\uf900-\ufaff\u3040-\u309f\uac00-\ud7af]+/g;
  var m = data.match(pattern);
  var count = 0;
  if (m === null) return count;
  for (var i = 0; i < m.length; i++) {
    if (m[i].charCodeAt(0) >= 0x4e00) {
      count += m[i].length;
    } else {
      count += 1;
    }
  }
  return count;
};

*/
</script>

<style>
</style>