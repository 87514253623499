<template>
  <div>
    <div
      v-if="subType === 'categorySelection'"
    >
      <div
        class="flex flex-row mt-4 mr-4 w-52 h-16 px-4 py-2 cursor-pointer rounded-sm  border-sd-base0-brblue hover:bg-sd-base02-black"
        @click="updateActiveAnswer"
        v-bind:class="getAdditionStyle()"
      >
        <div
          class="flex w-6 h-6 my-auto rounded-full border border-sd-base0-brblue"
          v-if="modelValue.findIndex( e => e === option.id ) === -1"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg" 
            class="flex fill-current text-sd-base0-brblue w-5 h-5 m-auto" 
            viewBox="0 0 16 16"
          >
            <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
          </svg>
        </div>
        <div
          v-else
          class="flex w-6 h-6 my-auto rounded-full bg-sd-base0-brblue"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg" 
            class="flex fill-current text-sd-base02-black w-5 h-5 m-auto" 
            viewBox="0 0 16 16"
          >
            <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
          </svg>
        </div>
        <div
          class="flex flex-col ml-4 my-auto w-2/3"
        >
          <div
            class="w-full text-left font-sans font-semibold text-md text-sd-base2-white"
          >
            {{option.title}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "multiSelection",
  props:{
    modelValue: Array,
    option: {
      required: true,
      type: Object,
    },
    subType: {
      required: true,
      type: String,
    },
  },
  emits: ['update:modelValue'],
  methods: {
    updateActiveAnswer(){
      let temp = this.modelValue;
      const index = this.modelValue.findIndex( e => e === this.option.id );
      if ( index !== -1 ){
        temp.splice(index, 1);
      } else {
        temp.splice(0, 0, this.option.id);
      }
      this.$emit('update:modelValue', temp)
    },
    getAdditionStyle(){
      if (this.modelValue.findIndex( e => e === this.option.id ) !== -1 ){
        console.log(this.modelValue)
        return 'bg-sd-base02-black border'
      } else {
        return 'border'
      }
    }
  },
  computed: {
  },
};
</script>
<style>
</style>