import { API } from "aws-amplify";
import _ from "lodash";
import { v4 as uuidv4 } from 'uuid';
import { handle } from "../../../utilities/utilities"

import { createHologramGroup as createHologramGroupMutation } from "../../../graphql/mutations";
import { createHologramNodeGroup as createHologramNodeGroupMutation } from "../../../graphql/custom/customMutations";
import { updateHologramNodeGroup as updateHologramNodeGroupMutation } from "../../../graphql/custom/customMutations";
import { deleteHologramNodeGroup as deleteHologramNodeGroupMutation } from "../../../graphql/custom/customMutations";
import { groupByTitle as groupByTitleQuery } from "../../../graphql/custom/customMutations";
import { deleteHologramGroup as deleteHologramGroupMutation } from "../../../graphql/custom/customMutations";

import { createGroup as createGroupMutation } from "../../../graphql/custom/customMutations";
import { updateGroup as updateGroupMutation } from "../../../graphql/custom/customMutations";
import { deleteGroup as deleteGroupMutation } from "../../../graphql/custom/customMutations";
import { updateHologramGroup as updateHologramGroupMutation } from "../../../graphql/mutations";

export const group = {
  state: {
    currentHologramGroups:[],
    currentHologramNodeGroups:[],
  },
  mutations: {
    mutateCurrentHologramNodeGroups: (state, payload) => {
      state.currentHologramNodeGroups = payload;
    },
    mutateCurrentHologramGroups(state, payload){
      state.currentHologramGroups = payload;
    },
  },
  actions: {
    // Unit action
    updateCurrentHologramNodeGroups: (context, {data}) => {
      context.commit("mutateCurrentHologramNodeGroups", data)
    },
    updateCurrentHologramGroups(context, {data}){
      let groupID = data.id;
      let tempData = context.rootState.hologram.group.currentHologramGroups;
      const targetIndex = tempData.findIndex( e => e.id == groupID);
      tempData[`${targetIndex}`] = data;
      context.commit("mutateCurrentHologramGroups", tempData);
    },
    setCurrentHologramGroups: (context, hologramGroups) => {
      context.commit("mutateCurrentHologramGroups", hologramGroups);
    },
    addItemIntoCurrentHologramGroups: (context, hologramGroup) => {
      let temp = _.cloneDeep(context.state.currentHologramGroups);
      temp.splice(0, 0, hologramGroup);
      context.commit("mutateCurrentHologramGroups", temp);
    },
    updateItemInCurrentHologramGroups: (context, hologramGroup) => {
      let temp = _.cloneDeep(context.state.currentHologramGroups);
      const index = temp.findIndex( e => e.id === hologramGroup.id );
      temp[index] = hologramGroup;
      context.commit("mutateCurrentHologramGroups", temp);
    },
    removeItemFromCurrentHologramGroups: (context, hologramGroupID) => {
      let temp = _.cloneDeep(context.state.currentHologramGroups);
      const index = temp.findIndex( e => e.id === hologramGroupID );
      temp.splice(index, 1);
      context.commit("mutateCurrentHologramGroups", temp);
    },

    setCurrentHologramNodeGroups: (context, hologramNodeGroups) => {
      context.commit("mutateCurrentHologramNodeGroups", hologramNodeGroups);
    },
    addItemIntoCurrentHologramNodeGroups: (context, hologramNodeGroup) => {
      console.log('fffffuck')
      let temp = _.cloneDeep(context.state.currentHologramNodeGroups);
      temp.splice(0, 0, hologramNodeGroup);
      context.commit("mutateCurrentHologramNodeGroups", temp);
    },
    removeItemFromCurrentHologramNodeGroups: (context, hologramNodeGroupID) => {
      let temp = _.cloneDeep(context.state.currentHologramNodeGroups);
      const index = temp.findIndex( e => e.id === hologramNodeGroupID );
      temp.splice(index, 1);
      context.commit("mutateCurrentHologramNodeGroups", temp);
    },
    updateItemInCurrentHologramNodeGroups: (context, hologramNodeGroup) => {
      let temp = _.cloneDeep(context.state.currentHologramNodeGroups);
      const index = temp.findIndex( e => e.id === hologramNodeGroup.id );
      temp[index] = hologramNodeGroup;
      context.commit("mutateCurrentHologramNodeGroups", temp);
    },

    // Request action
    getGroup: async (_, title) => {
      const variables = {
        title: title
      };
      let result;
      try {
        const res = await API.graphql({
          query: groupByTitleQuery,
          variables: variables,
          authMode: "AWS_IAM"
        })
        if ( res.data.groupByTitle.items.length > 0 ){
          result = {
            status: "Found",
            result: res.data.groupByTitle.items[0]
          };
        } else {
          result = {
            status: "NotFound"
          };
        }
        return Promise.resolve(result)
      } catch(err){
        return Promise.reject(err)
      }
    },
    createGroup: async (_, { id, title, createdByID }) => { // eslint-disable-line no-unused-vars
      const variables = {
        input: {
          id: id,
          baseType: "Group",
          title: title,
          createdByID: createdByID,
        }
      };
      try {
        const res = await API.graphql({
          query: createGroupMutation,
          variables: variables,
          authMode: "AWS_IAM"
        });  
        return Promise.resolve(res.data.createGroup)
      } catch(err){
        return Promise.reject(err)
      }
    },
    updateGroup: async (_, group) => {
      const variables = {
        input: group
      };
      try {
        const res = await API.graphql({
          query: updateGroupMutation,
          variables: variables,
          authMode: "AWS_IAM"
        });  
        return Promise.resolve(res.data.updateGroup)
      } catch(err){
        return Promise.reject(err)
      }
    },
    deleteGroup: async (_, groupID) => {
      const variables = {
        input: {
          id: groupID
        }
      }
      try {
        const res = await API.graphql({
          query: deleteGroupMutation,
          variables: variables,
          authMode: "AWS_IAM"
        })
        return Promise.resolve(res.data.deleteGroup);
      } catch(err){
        return Promise.reject(err)
      }
    },
    updateHologramGroup: async (_, hologramGroup) => {
      const variables = {
        input: hologramGroup
      };
      try {
        const res = await API.graphql({
          query: updateHologramGroupMutation,
          variables: variables,
          authMode: "AWS_IAM"
        });  
        return Promise.resolve(res.data.updateHologramGroup)   
      } catch(err){
        return Promise.reject(err)
      }
    },
    createHologramGroup: async (_, { id, hologramID, groupID, color, owner }) => {
      const variables = {
        input: {
          id: id,
          baseType: "HologramGroup",
          hologramID: hologramID,
          groupID: groupID,
          owner: owner,
          color: color
        }
      }
      try {
        const res = await API.graphql({
          query: createHologramGroupMutation,
          variables: variables,
          authMode: "AWS_IAM"
        });  
        return Promise.resolve(res.data.createHologramGroup)        
      } catch(err){
        return Promise.reject(err)
      }
    },
    deleteHologramGroup: async (_, hologramGroupID) => {
      const variables = {
        input: {
          id: hologramGroupID
        }
      };
      try {
        const res = await API.graphql({
          query: deleteHologramGroupMutation,
          variables: variables,
          authMode: "AWS_IAM"
        })
        return Promise.resolve(res.data.deleteHologramGroup)
      } catch(err){
        return Promise.reject(err)
      }
    },
    createHologramNodeGroup: async (_, { id, hologramNodeID, groupID, owner }) => {
      const variables = {
        input: {
          id: id,
          baseType: "HologramNodeGroup",
          hologramNodeID: hologramNodeID,
          groupID: groupID,
          owner: owner
        }
      }
      try {
        const res = await API.graphql({
          query: createHologramNodeGroupMutation,
          variables: variables,
          authMode: "AWS_IAM"
        })
        return Promise.resolve(res.data.createHologramNodeGroup)
      } catch(err){
        return Promise.reject(err)
      }
    },
    updateHologramNodeGroup: async (_, hologramNodeGroup) => {
      const variables = {
        input: hologramNodeGroup
      };
      console.log(variables)
      try {
        const res = await API.graphql({
          query: updateHologramNodeGroupMutation,
          variables: variables,
          authMode: "AWS_IAM"
        })
        return Promise.resolve(res.data.updateHologramNodeGroup);
      } catch(err){
        return Promise.reject(err)
      }
    },
    deleteHologramNodeGroup: async (_, hologramNodeGroupID) => {
      const variables = {
        "input": {
          "id": hologramNodeGroupID
        }
      };
      try {
        const res = await API.graphql({
          query: deleteHologramNodeGroupMutation,
          variables: variables,
          authMode: "AWS_IAM"
        })      
        return Promise.resolve(res.data.deleteHologramNodeGroup);
      } catch(err){
        return Promise.reject(err)
      }
    },

    // Business action

    changeHologramGroupTitle: async (context, {groupID, title}) => {
      const currentHologram = context.rootState.hologram.currentHologram;
      const currentHologramGroups = context.state.currentHologramGroups;
      const checkHologramGroup = findHologramGroup(currentHologramGroups, groupID);

      if ( checkHologramGroup.status === "NotFound" ){
        return Promise.reject("Can't find target hologramGroup")
      }
      const oldTitle = checkHologramGroup.result.group.title
      let hologramGroup = checkHologramGroup.result;
      hologramGroup.group.title = title;
      context.dispatch("updateItemInCurrentHologramGroups", hologramGroup);

      let newHologram = constructChangeGroupTitleAction(currentHologram, hologramGroup.group);
      context.dispatch("setCurrentHologram", newHologram);

      const uploadInput = {
        hologramGroup: hologramGroup,
        hologram: newHologram,
        oldTitle: oldTitle
      }

      try {
        context.dispatch("uploadChangedHologramGroupTitle", uploadInput);
      } catch(err){
        return Promise.reject(err)
      }

    },
    uploadChangedHologramGroupTitle: async (context, {hologram, hologramGroup, oldTitle}) => {
      const updateHologramGroupInput = {
        id: hologramGroup.groupID,
        title: hologramGroup.group.title
      };
      
      const [error, ] = await handle(context.dispatch("updateGroup", updateHologramGroupInput));

      if ( error ){
        error.context = "Can't update hologramGroup";
        return Promise.reject(error)
      }

      const updateHologramInput = {
        id: hologram.id,
        action: hologram.action
      };

      const [updateHologramError, ] = await handle(context.dispatch("updateHologram", updateHologramInput));

      if ( updateHologramError ){
        updateHologramError.context = "Can't update hologram";
        context.dispatch("fallbackChangedHologramGroupTitle", { hologramGroup, oldTitle })
        return Promise.reject(updateHologramError)
      }

    },
    fallbackChangedHologramGroupTitle: async (context, {hologramGroup, oldTitle}) => {
      const input = {
        id: hologramGroup.groupID,
        title: oldTitle
      };
      context.dispatch("updateGroup", input);
    },

    checkoutWholeGroup: async (context, {hologramGroup, hologramNode}) => {
      const currentHologram = context.rootState.hologram.currentHologram;
      const currentHologramNodeGroups = context.state.currentHologramNodeGroups;
      let newHologram = currentHologram;
      let targetHologramNodeGroups = hologramNodeGroupIDReducer(currentHologramNodeGroups, hologramGroup.id);
      
      for (const hologramNodeGroup of targetHologramNodeGroups ) {
        context.dispatch("removeItemFromCurrentHologramNodeGroups", hologramNodeGroup.id);
        newHologram = removeNodeGroupAtGraph(newHologram, hologramNode.nodeID);
      }

      context.dispatch("setCurrentHologram", newHologram);
      context.dispatch("removeItemFromCurrentHologramGroups", hologramGroup.id);

      newHologram = constructCheckoutWholeGroupAction(newHologram, targetHologramNodeGroups, hologramGroup);

      context.dispatch("setCurrentHologram", newHologram);

      const uploadInput = {
        targetHologramNodeGroups: targetHologramNodeGroups,
        hologramGroupID: hologramGroup.id,
        hologram: newHologram
      }

      try {
        context.dispatch("uploadDeletedGroupData", uploadInput);
      } catch(err){
        return Promise.reject(err)
      }

    },
    uploadDeletedGroupData: async (context, {targetHologramNodeGroups, hologramGroupID, hologram}) => {
      let uploadedData = [];
      for (const hologramNodeGroup of targetHologramNodeGroups) {
        const [error, result] = await handle(context.dispatch("deleteHologramNodeGroup", hologramNodeGroup.id));

        if ( error ){
          error.context = "Can't delete hologramNodeGroup";
          await context.dispatch("fallbackDeleteGroupData", uploadedData);
          return Promise.reject(error)
        }

        uploadedData.splice(0, 0, result);
      }

      const [deleteHologramGroupError, deleteHologramGroupResult] = await handle(context.dispatch("deleteHologramGroup", hologramGroupID));

      if ( deleteHologramGroupError ){
        deleteHologramGroupError.context = "Can't delete hologramGroup";
        await context.dispatch("fallbackDeleteGroupData", uploadedData);
        return Promise.reject(deleteHologramGroupError)
      }

      uploadedData.splice(0, 0, deleteHologramGroupResult);

      const updateHologramInput = {
        id: hologram.id,
        action: hologram.action,
        graph: hologram.graph
      }

      const [updateHologramError, ] = await handle(context.dispatch("updateHologram", updateHologramInput));

      if ( updateHologramError ){
        updateHologramError.context = "Can't update hologram";
        await context.dispatch("fallbackDeleteGroupData", uploadedData);
        return Promise.reject(updateHologramError)
      }
    },
    fallbackDeleteGroupData: async (context, uploadedData) => {
      for ( const badData of uploadedData ){
        switch (badData.baseType) {
          case "HologramNodeGroup": {
            const fallbackInput = {
              id: badData.id, 
              hologramNodeID: badData.hologramNodeID, 
              groupID: badData.groupID, 
              owner: badData.owner
            };
            context.dispatch("createHologramNodeGroup", fallbackInput);
            break; 
          }
          case "HologramGroup": {
            const fallbackInput = {
              id: badData.id, 
              hologramID: badData.hologramID, 
              groupID: badData.groupID, 
              color: badData.color,
              owner: badData.owner
            };
            context.dispatch("createHologramGroup", fallbackInput);
            break
          }
        }
      }
    },

    switchHologramNodeGroup: async (context, { hologramNodeGroupID, groupID, hologramNode }) => {
      const currentHologram = context.rootState.hologram.currentHologram;
      const currentHologramNodeGroups = context.state.currentHologramNodeGroups;
      const currentHologramGroups = context.state.currentHologramGroups;
      
      const checkHologramNodeGroup = findHologramNodeGroupByID(currentHologramNodeGroups, hologramNodeGroupID);
      if ( checkHologramNodeGroup.status === "NotFound" ){
        return Promise.reject("Can't find target hologramNodeGroup")
      }
      let hologramNodeGroup = checkHologramNodeGroup.result;

      const checkHologramGroup = findHologramGroup(currentHologramGroups, groupID);
      if ( checkHologramGroup.status === "NotFound" ){
        return Promise.reject("Can't find target hologramGroup")
      }
      let hologramGroup = checkHologramGroup.result;

      hologramNodeGroup.groupID = groupID;
      hologramNodeGroup.group = hologramGroup.group;

      context.dispatch("updateItemInCurrentHologramNodeGroups", hologramNodeGroup);

      const constructHologramInput = {
        currentHologram: currentHologram,
        nodeID: hologramNode.nodeID,
        groupID: groupID
      }

      let newHologram = constructNodeGroupAtGraph(constructHologramInput);

      const constructActionInput = {
        hologram: newHologram,
        oldHologramNodeGroup: checkHologramNodeGroup.result,
        newHologramNodeGroup: hologramNodeGroup
      }

      newHologram = constructSwitchedHologramNodeGroupAction(constructActionInput);
      context.dispatch("setCurrentHologram", newHologram);

      const uploadInput = {
        oldHologramNodeGroup: checkHologramNodeGroup.result,
        hologramNodeGroup: hologramNodeGroup,
        hologram: newHologram
      }

      try {
        context.dispatch("uploadSwitchedHologramNodeGroup", uploadInput);
        return Promise.resolve(hologramNodeGroup)
      } catch(err){
        return Promise.reject(err)
      }

    },
    uploadSwitchedHologramNodeGroup: async (context, {hologramNodeGroup, oldHologramNodeGroup, hologram}) => {
      let fallbackInput = {
        oldHologramNodeGroup: oldHologramNodeGroup,
        uploadedData: []
      }
      const updateHologramNodeGroupInput = {
        id: hologramNodeGroup.id,
        groupID: hologramNodeGroup.groupID
      };
      
      const [updateHologramNodeGroupError, updateHologramNodeGroupResult] = await handle(context.dispatch("updateHologramNodeGroup", updateHologramNodeGroupInput));

      if ( updateHologramNodeGroupError ){
        updateHologramNodeGroupError.context = "Can't update hologramNodeGroup";
        return Promise.reject(updateHologramNodeGroupError)
      }

      fallbackInput.uploadedData.splice(0, 0, updateHologramNodeGroupResult);

      const updateHologramInput = {
        id: hologram.id,
        graph: hologram.graph,
        action: hologram.action
      }

      const [updateHologramError, ] = await handle(context.dispatch("updateHologram", updateHologramInput));

      if ( updateHologramError ){
        updateHologramError.context = "Can't update hologram";
        await context.dispatch("fallbackSwitchedHologramNodeGroup", fallbackInput);
        return Promise.reject(updateHologramError)
      }
    },
    fallbackSwitchedHologramNodeGroup: async (context, uploadedData, oldHologramNodeGroup) => {
      for ( const data of uploadedData ){
        switch (data.baseType) {
          case "HologramNodeGroup": {
            const fallbackInput = {
              id: oldHologramNodeGroup.id,
              groupID: oldHologramNodeGroup.groupID, 
            };
            context.dispatch("updateHologramNodeGroup", fallbackInput);
            break
          }
        }
      }
    },

    checkoutHologramNodeGroup: async (context, {hologramNodeGroupID, hologramNode}) => {
      const currentHologram = context.rootState.hologram.currentHologram;
      const currentHologramNodeGroups = context.state.currentHologramNodeGroups;
      const hologramNodeGroup = findHologramNodeGroupByID(currentHologramNodeGroups, hologramNodeGroupID);

      context.dispatch("removeItemFromCurrentHologramNodeGroups", hologramNodeGroupID);
      
      let newHologram = removeNodeGroupAtGraph(currentHologram, hologramNode.nodeID);

      const constructActionInput = {
        hologram: newHologram,
        hologramNodeGroup: hologramNodeGroup
      }

      newHologram = constructCheckoutHologramNodeGroupAction(constructActionInput);

      context.dispatch("setCurrentHologram", newHologram);

      const uploadInput = {
        hologramNodeGroupID: hologramNodeGroupID,
        hologram: newHologram
      };

      try {
        context.dispatch("uploadeDeletedHologramNodeGroup", uploadInput);
      } catch(err){
        return Promise.reject(err)
      }

    },
    uploadeDeletedHologramNodeGroup: async (context, {hologramNodeGroupID, hologram}) => {
      let uploadedData = [];
      const [deleteHologramNodeGroupError, deleteHologramNodeGroupResult] = await handle(context.dispatch("deleteHologramNodeGroup", hologramNodeGroupID));

      if ( deleteHologramNodeGroupError ){
        deleteHologramNodeGroupError.context = "Can't delete hologramNodeGroup";
        return Promise.reject(deleteHologramNodeGroupError)
      }

      uploadedData.splice(0, 0, deleteHologramNodeGroupResult);

      const updateHologramInput = {
        id: hologram.id,
        graph: hologram.graph,
        action: hologram.action
      }

      const [updateHologramError, ] = await handle(context.dispatch("updateHologram", updateHologramInput));

      if ( updateHologramError ){
        updateHologramError.context = "Can't update hologram";
        context.dispatch("fallbackDeletedHologramNodeGroup", uploadedData);
        return Promise.reject(updateHologramError)
      }
    },
    fallbackDeletedHologramNodeGroup: async (context, uploadedData) => {
      for ( const data of uploadedData ){
        switch (data.baseType) {
          case "HologramNodeGroup": {
            const fallbackInput = {
              id: data.id,
              hologramNodeID: data.hologramNodeID,
              groupID: data.groupID,
              owner: data.owner
            };
            context.dispatch("createHologramNodeGroup", fallbackInput);
          }
        }
      }
    },
    checkInHologramNodeGroup: async (context, { groupID, hologramNode }) => {
      const userID = context.rootState.auth.userData.id;
      const currentHologram = context.rootState.hologram.currentHologram;
      const currentHologramGroups = context.state.currentHologramGroups;
      const checkHologramGroup = findHologramGroup(currentHologramGroups, groupID);

      if ( checkHologramGroup.status === "NotFound" ){
        return Promise.reject("Can't find target hologramGroup")
      }

      let hologramNodeGroup = {
        id: uuidv4(),
        baseType: "HologramNodeGroup",
        hologramNodeID: hologramNode.id,
        hologramNode: hologramNode,
        groupID: groupID,
        group: checkHologramGroup.result.group,
        owner: userID
      }

      context.dispatch("addItemIntoCurrentHologramNodeGroups", hologramNodeGroup);

      const constructGraphInput = {
        currentHologram: currentHologram,
        nodeID: hologramNode.nodeID,
        groupID: groupID
      }

      let newHologram = constructNodeGroupAtGraph(constructGraphInput);

      const constructActionInput = {
        hologram: newHologram,
        hologramNodeGroup: hologramNodeGroup
      };

      newHologram = constructCheckInHologramNodeGroupAction(constructActionInput);

      context.dispatch("setCurrentHologram", newHologram);

      const uploadInput = {
        hologramNodeGroup: hologramNodeGroup,
        hologram: newHologram
      };

      try {
        context.dispatch("uploadCreatedHologramNodeGroup", uploadInput);
        return Promise.resolve(hologramNodeGroup);
      } catch(err){
        return Promise.reject(err)
      }
    },
    
    uploadCreatedHologramNodeGroup: async (context, { hologramNodeGroup, hologram }) => {
      let uploadedData = []; 
        const createHologramNodeGroupInput = {
        id: hologramNodeGroup.id, 
        hologramNodeID: hologramNodeGroup.hologramNodeID, 
        groupID: hologramNodeGroup.groupID,          
        owner: hologramNodeGroup.owner
      }

      const [createHologramNodeGroupError, createHologramNodeGroupResult] = await handle(context.dispatch("createHologramNodeGroup", createHologramNodeGroupInput));


      if ( createHologramNodeGroupError ){
        createHologramNodeGroupError.context = "Can't create hologramNodeGroup";
        return Promise.reject(createHologramNodeGroupError)
      }

      uploadedData.splice(0 ,0, createHologramNodeGroupResult);

      const updateHologramInput = {
        id: hologram.id,
        graph: hologram.graph,
        action: hologram.action
      }

      const [updateHologramError, ] = await handle(context.dispatch("updateHologram", updateHologramInput));

      if ( updateHologramError ){
        updateHologramError.context = "Can't update hologram";
        context.dispatch("fallbackCreatedHologramNodeGroup", uploadedData);
        return Promise.reject(updateHologramError)
      }

    },
    fallbackCreatedHologramNodeGroup: async (context, uploadedData) => {
      for ( const data of uploadedData ){
        switch (data.baseType) {
          case "HologramNodeGroup": {
            context.dispatch("deleteHologramNodeGroup", data.id);
            break
          }
        }
      }
    },

    checkInNewGroupColor: async (context, { hologramGroupID, newColor }) => {
      const currentHologram = context.rootState.hologram.currentHologram;
      const currentHologramGroups = context.state.currentHologramGroups;
      const index = currentHologramGroups.findIndex( e => e.id === hologramGroupID );

      if ( index === -1 ){
        return Promise.reject("Can't find target hologramGroup")
      }

      const hologramGroup = currentHologramGroups[index];
      const oldColor = hologramGroup.color
      let newHologramGroup = _.cloneDeep(hologramGroup);
      newHologramGroup.color = newColor;

      context.dispatch("updateItemInCurrentHologramGroups", newHologramGroup);

      const constructActionInput = {
        hologram: currentHologram,
        hologramGroup: newHologramGroup
      };

      let newHologram = constructCheckInNewColorAction(constructActionInput);

      context.dispatch("setCurrentHologram", newHologram);

      const uploadInput = {
        newHologram: newHologram,
        newHologramGroup: newHologramGroup,
        oldColor: oldColor
      };

      try {
        context.dispatch("uploadeNewGroupColor", uploadInput);
      } catch(err){
        return Promise.reject(err)
      }

    },
    uploadeNewGroupColor: async (context, {newHologramGroup, newHologram, oldColor}) => {
      const input = {
        id: newHologramGroup.id,
        color: newHologramGroup.color
      };

      const [error, ] = await handle(context.dispatch("updateHologramGroup", input));

      if ( error ){
        error.context = "Can't update hologramGroup's color";
        return Promise.reject(error);
      }

      const updateHologramInput = {
        id: newHologram.id,
        graph: newHologram.graph,
        action: newHologram.action
      };

      const [updateHologramError, ] = await handle(context.dispatch("updateHologram", updateHologramInput));

      if ( updateHologramError ){
        updateHologramError.context = "Can't update hologram";
        context.dispatch("fallbackNewGroupColor", {newHologramGroup, oldColor})
        return Promise.reject(updateHologramError);
      }
    },
    fallbackNewGroupColor: async (context, {newHologramGroup, oldColor}) => {
      const input = {
        id: newHologramGroup.id,
        color: oldColor
      }
      context.dispatch("updateHologramGroup", input)
    },

    advancedCheckInNewGroup: async (context, { title, nodeID, hologramNodeID, oldHologramNodeGroup }) => {
      const currentHologram = context.rootState.hologram.currentHologram;
      const currentHologramGroups = context.state.currentHologramGroups;
      const userID = context.rootState.auth.userData.id;
      
      let group, hologramGroup;
      let groupIsNew = false;
      let hologramNodeGroupIsNew = false;
      const [getGroupError, checkGroup] = await handle(context.dispatch("getGroup", title));

      if ( getGroupError ){
        getGroupError.context = "Can't check whether group exist";
        return Promise.reject(getGroupError)
      }

      if ( checkGroup.status === "Found" ){
        group = checkGroup.result;
      } else {
        groupIsNew = true;
        group = {
          id: uuidv4(),
          baseType: "Group",
          title: title,
          createdByID: userID
        };
      }

      const checkHologramGroup = findHologramGroup(currentHologramGroups, group.id);

      if ( checkHologramGroup.status === "Found" ){
        return Promise.reject("DuplicatedGroup")
      } else {
        hologramGroup = {
          id: uuidv4(),
          baseType: "HologramGroup",
          hologramID: currentHologram.id,
          groupID: group.id,
          group: group,
          owner: userID,
        };
      }

      context.dispatch("addItemIntoCurrentHologramGroups", hologramGroup);

      let hologramNodeGroup;

      if ( oldHologramNodeGroup ){
        hologramNodeGroup = oldHologramNodeGroup;
        hologramNodeGroup.groupID = group.id;
        hologramNodeGroup.group = group;
        context.dispatch("updateItemInCurrentHologramNodeGroups", hologramNodeGroup);
      } else {
        hologramNodeGroupIsNew = true;
        hologramNodeGroup = {
          id: uuidv4(),
          baseType: "HologramNodeGroup",
          hologramNodeID: hologramNodeID,
          groupID: group.id,
          group: group,
          owner: userID
        };
        context.dispatch("addItemIntoCurrentHologramNodeGroups", hologramNodeGroup);
      }

      const constructGraphInput = {
        currentHologram: currentHologram,
        nodeID: nodeID,
        groupID: group.id,
      };

      let newHologram = constructNodeGroupAtGraph(constructGraphInput)

      const constructActionInput = {
        currentHologram: newHologram, 
        hologramGroup: hologramGroup, 
        hologramNodeGroupIsNew: hologramNodeGroupIsNew, 
        hologramNodeGroup: hologramNodeGroup
      };

      newHologram = constructNewHologramGroupAction(constructActionInput);
      context.dispatch("setCurrentHologram", newHologram);

      const uploadInput = {
        groupIsNew: groupIsNew, 
        group: group, 
        hologramGroup: hologramGroup, 
        hologramNodeGroupIsNew: hologramNodeGroupIsNew, 
        hologramNodeGroup: hologramNodeGroup, 
        oldHologramNodeGroup: oldHologramNodeGroup,
        currentHologram: currentHologram
      };

      try {
        context.dispatch("uploadNewGroup", uploadInput);
        const result = {
          hologramGroup: hologramGroup,
          hologramNodeGroup: hologramNodeGroup
        };
        return Promise.resolve(result)
      } catch(err){
        return Promise.reject(err)
      }
    },
    uploadNewGroup: async (context, { groupIsNew, group, hologramGroup, hologramNodeGroupIsNew, hologramNodeGroup, oldHologramNodeGroup, currentHologram }) => {
      let uploadedData = [];
      let fallbackInput = {
        uploadedData: null,
        oldHologramNodeGroup: oldHologramNodeGroup,
        hologramNodeGroupIsNew: hologramNodeGroupIsNew
      }
      if ( groupIsNew ){
        const createGroupInput = {
          id: group.id,
          title: group.title,
          createdByID: group.createdByID
        };

        const [createGroupError, createGroupResult] = await handle(context.dispatch("createGroup", createGroupInput));
    
        if ( createGroupError ){
          createGroupError.context = "Can't create group";
          return Promise.reject(createGroupError)
        }
    
        uploadedData.splice(0, 0, createGroupResult);
      }
      
      const createHologramGroupInput = {
        id: hologramGroup.id,
        hologramID: hologramGroup.hologramID,
        groupID: hologramGroup.groupID,
        owner: hologramGroup.owner
      };
    
      const [createHologramGroupError, createHologramGroupResult] = await handle(context.dispatch("createHologramGroup", createHologramGroupInput));
    
      if ( createHologramGroupError ){
        createHologramGroupError.context = "Can't create hologramGroup";
        fallbackInput.uploadedData = uploadedData
        context.dispatch("fallbackNewGroupBadData", fallbackInput);
        return Promise.reject(createHologramGroupError)
      }
    
      uploadedData.splice(0, 0, createHologramGroupResult);
    
      let hologramNodeGroupError, createHologramNodeGroupResult, updateHologramNodeGroupResult;
    
      if ( hologramNodeGroupIsNew ){
        const createHologramNodeGroupInput = {
          id: hologramNodeGroup.id,
          hologramNodeID: hologramNodeGroup.hologramNodeID,
          groupID: hologramNodeGroup.groupID,
          owner: hologramNodeGroup.owner
        };
    
        [hologramNodeGroupError, createHologramNodeGroupResult] = await handle(context.dispatch("createHologramNodeGroup", createHologramNodeGroupInput));
    
        if ( hologramNodeGroupError ){
          hologramNodeGroupError.context = "Can't create hologramNodeGroup";
          fallbackInput.uploadedData = uploadedData
          context.dispatch("fallbackNewGroupBadData", fallbackInput);
          return Promise.reject(hologramNodeGroupError)
        }
    
        uploadedData.splice(0, 0, createHologramNodeGroupResult);
      } else {
        const updateHologramNodeGroupInput = {
          id: hologramNodeGroup.id,
          groupID: hologramNodeGroup.groupID
        };

        [hologramNodeGroupError, updateHologramNodeGroupResult] = await handle(context.dispatch("updateHologramNodeGroup", updateHologramNodeGroupInput));
    
        if ( hologramNodeGroupError ){
          hologramNodeGroupError.context = "Can't update hologramNodeGroup";
          fallbackInput.uploadedData = uploadedData
          context.dispatch("fallbackNewGroupBadData", fallbackInput);
          return Promise.reject(hologramNodeGroupError)
        }
    
        uploadedData.splice(0, 0, updateHologramNodeGroupResult);
    
      }
    
      const updateHologramInput = {
        id: currentHologram.id,
        action: currentHologram.action,
        graph: currentHologram.graph
      };
    
      const [updateHologramError, updateHologramResult] = await handle(context.dispatch("updateHologram", updateHologramInput));
    
      if ( updateHologramError ){
        updateHologramError.context = "Can't update hologram";
        fallbackInput.uploadedData = uploadedData
        context.dispatch("fallbackNewGroupBadData", fallbackInput);
        return Promise.reject(updateHologramError)
      }
    
      context.dispatch("updateItemInCurrentHologramGroups", createHologramGroupResult);
      if ( hologramNodeGroupIsNew ){
        context.dispatch("updateItemInCurrentHologramNodeGroups", createHologramNodeGroupResult);
      } else {
        context.dispatch("updateItemInCurrentHologramNodeGroups", updateHologramNodeGroupResult)
      }
    
      let newHologram = currentHologram;
      newHologram.action = updateHologramResult.action;
      newHologram.graph = updateHologramResult.graph;
    
      context.dispatch("setCurrentHologram", newHologram);
      
      return Promise.resolve("Success")
    },
    fallbackNewGroupBadData: async (context, {uploadedData, hologramNodeGroupIsNew, oldHologramNodeGroup}) => {
      console.log(uploadedData);
      for ( const badData of uploadedData ){
        switch (badData.baseType) {
          case "Group": {
            context.dispatch("deleteGroup", badData.id);
            break
          }
          case "HologramGroup": {
            context.dispatch("deleteHologramGroup", badData.id);
            break
          }
          case "HologramNodeGroup": {
            if ( hologramNodeGroupIsNew ){
              context.dispatch("deleteHologramNodeGroup", badData.id);
            } else {
              const fallbackInput = {
                id: badData.id,
                groupID: oldHologramNodeGroup.groupID
              }
              context.dispatch("updateHologramNodeGroup", fallbackInput);
            }
          }
        }
      }
    }
  },
  getters: {
    currentHologramGroups: (state) => state.currentHologramGroups,
    currentHologramNodeGroups: (state) => state.currentHologramNodeGroups,
  }
}

const findHologramGroup = (hologramGroups, groupID) => {
  let result;
  const index = hologramGroups.findIndex( e => e.groupID === groupID );
  if ( index !== -1 ){
    result = {
      status: "Found",
      result: hologramGroups[index]
    }
  } else {
    result = {
      status: "NotFound"
    }
  }
  return result
};

const findHologramNodeGroupByID = (hologramNodeGroups, hologramNodeGroupID) => {
  let result;
  const index = hologramNodeGroups.findIndex( e => e.id === hologramNodeGroupID );
  if ( index !== -1 ){
    result = {
      status: "Found",
      result: hologramNodeGroups[index]
    };
  } else {
    result = {
      status: "NotFound"
    };
  }
  return result
}


const constructNodeGroupAtGraph = ({currentHologram, nodeID, groupID}) => {
  let newHologram = currentHologram;
  let graph = JSON.parse(newHologram.graph);
  const nodeIndex = graph.nodes.findIndex( e => e.id === nodeID );
  graph.nodes[nodeIndex].groupID = groupID;
  newHologram.graph = JSON.stringify(graph);
  return newHologram
};



const removeNodeGroupAtGraph = (currentHologram, nodeID) => {
  let newHologram = currentHologram;
  let graph = JSON.parse(newHologram.graph);
  const index = graph.nodes.findIndex( e => e.id === nodeID )
  graph.nodes[index].groupID = null;
  newHologram.graph = JSON.stringify(graph);
  return newHologram
}

const constructNewHologramGroupAction = ({ currentHologram, hologramGroup, hologramNodeGroupIsNew, hologramNodeGroup }) => {
  let action = {
    version: null,
    timeStamp: null,
    type: null,
    add: {
      hologramNode: [],
      hologramEdge: [],
      hologramGroup: [],
      hologramNodeGroup: [],
      hologramNodeWeight: [],
    },
    remove: {
      hologramNode: [],
      hologramEdge: [],
      hologramGroup: [],
      hologramNodeGroup: [],
      hologramNodeWeight: [],
    },
    update: {
      hologramNode: [],
      hologramEdge: [],
      hologramGroup: [],
      hologramNodeGroup: [],
      hologramNodeWeight: []
    }
  };

  action.add.hologramGroup.splice(0, 0, hologramGroup);

  let actions = JSON.parse(currentHologram.action);

  if ( actions.length === 0 ){
    action.version = 1;
  } else {
    let previousAction = actions[0];
    action.version = previousAction.version + 1;
  }

  if ( actions.length > 3 ){
    actions.pop();
  }

  action.timeStamp = Date.now();

  if ( hologramNodeGroupIsNew ){
    action.type = "NewGroupNewHologramNodeGroup"
    action.add.hologramNodeGroup.splice(0, 0, hologramNodeGroup);
  } else {
    action.type = "NewGroupOldHologramNodeGroup"
    action.update.hologramNodeGroup.splice(0, 0, hologramNodeGroup);
  }

  action.add.hologramGroup.splice(0, 0, hologramGroup);
  actions.splice(0, 0, action);

  let newHologram = currentHologram;
  newHologram.action = JSON.stringify(actions);

  return newHologram
};

const hologramNodeGroupIDReducer = (hologramNodeGroups, groupID) => {
  let target = hologramNodeGroups.reduce((array, element) => {
    if (element.groupID === groupID) {
      array.push(element);
    }
    return array;
  }, []);
  return target
}

const constructChangeGroupTitleAction = (hologram, group) => {
  let action = {
    version: null,
    timeStamp: null,
    type: null,
    add: {
      hologramNode: [],
      hologramEdge: [],
      hologramGroup: [],
      hologramNodeGroup: [],
      hologramNodeWeight: [],
    },
    remove: {
      hologramNode: [],
      hologramEdge: [],
      hologramGroup: [],
      hologramNodeGroup: [],
      hologramNodeWeight: [],
    },
    update: {
      group: [],
      hologramNode: [],
      hologramEdge: [],
      hologramGroup: [],
      hologramNodeGroup: [],
      hologramNodeWeight: []
    }
  };

  let newHologram = hologram;
  let actions = JSON.parse(newHologram.action);

  if ( !actions ){
    action.version = 1;
    actions = []
  } else {
    let previousAction = actions[0];
    action.version = previousAction.version + 1;
    if ( actions.length > 3 ){
      actions.pop();
    }
  }

  

  action.type = "ChangeGroupTitle";
  action.timeStamp = Date.now();
  action.update.group = group;
  
  actions.splice(0, 0, action);

  newHologram.action = JSON.stringify(actions);

  return newHologram;
}

const constructCheckoutWholeGroupAction = (hologram, targetHologramNodeGroups, hologramGroup) => {
  let action = {
    version: null,
    timeStamp: null,
    type: null,
    add: {
      hologramNode: [],
      hologramEdge: [],
      hologramGroup: [],
      hologramNodeGroup: [],
      hologramNodeWeight: [],
    },
    remove: {
      hologramNode: [],
      hologramEdge: [],
      hologramGroup: [],
      hologramNodeGroup: [],
      hologramNodeWeight: [],
    },
    update: {
      group: [],
      hologramNode: [],
      hologramEdge: [],
      hologramGroup: [],
      hologramNodeGroup: [],
      hologramNodeWeight: []
    }
  };

  let newHologram = hologram;
  let actions = JSON.parse(newHologram.action);
  
  if ( !actions ){
    action.version = 1;
    actions = []
  } else {
    let previousAction = actions[0];
    action.version = previousAction.version + 1;
    if ( actions.length > 3 ){
      actions.pop();
    }
  }

  

  action.type = "CheckoutWholeGroup";
  action.timeStamp = Date.now();
  
  for (const hologramNodeGroup of targetHologramNodeGroups){
    action.remove.hologramNodeGroup.splice(0, 0, hologramNodeGroup);
  }

  action.remove.hologramGroup.splice(0, 0, hologramGroup)

  actions.splice(0, 0, action);
  newHologram.action = JSON.stringify(actions);

  return newHologram
};

const constructSwitchedHologramNodeGroupAction = ({ hologram, oldHologramNodeGroup, newHologramNodeGroup }) => {
  let action = {
    version: null,
    timeStamp: null,
    type: null,
    add: {
      hologramNode: [],
      hologramEdge: [],
      hologramGroup: [],
      hologramNodeGroup: [],
      hologramNodeWeight: [],
    },
    remove: {
      hologramNode: [],
      hologramEdge: [],
      hologramGroup: [],
      hologramNodeGroup: [],
      hologramNodeWeight: [],
    },
    update: {
      group: [],
      hologramNode: [],
      hologramEdge: [],
      hologramGroup: [],
      hologramNodeGroup: [],
      hologramNodeWeight: []
    }
  };

  let newHologram = hologram;
  let actions = JSON.parse(newHologram.action);

  if ( !actions ){
    action.version = 1;
    actions = []
  } else {
    let previousAction = actions[0];
    action.version = previousAction.version + 1;
    if ( actions.length > 3 ){
      actions.pop();
    }
  }

  

  action.type = "SwitchHologramNodeGroup";
  action.timeStamp = Date.now();
  action.add.hologramNodeGroup.splice(0, 0, newHologramNodeGroup);
  action.remove.hologramNodeGroup.splice(0, 0, oldHologramNodeGroup);

  actions.splice(0, 0, action);
  newHologram.action = JSON.stringify(actions);
  return newHologram;
}

const constructCheckoutHologramNodeGroupAction = ({hologram, hologramNodeGroup}) => {
  let action = {
    version: null,
    timeStamp: null,
    type: null,
    add: {
      hologramNode: [],
      hologramEdge: [],
      hologramGroup: [],
      hologramNodeGroup: [],
      hologramNodeWeight: [],
    },
    remove: {
      hologramNode: [],
      hologramEdge: [],
      hologramGroup: [],
      hologramNodeGroup: [],
      hologramNodeWeight: [],
    },
    update: {
      group: [],
      hologramNode: [],
      hologramEdge: [],
      hologramGroup: [],
      hologramNodeGroup: [],
      hologramNodeWeight: []
    }
  };

  let newHologram = hologram;
  let actions = JSON.parse(newHologram.action);
  
  if ( !actions ){
    action.version = 1;
    actions = []
  } else {
    let previousAction = actions[0];
    action.version = previousAction.version + 1;
    if ( actions.length > 3 ){
      actions.pop();
    }
  }

  

  action.type = "CheckoutHologramNodeGroup";
  action.timeStamp = Date.now();
  action.remove.hologramNodeGroup.splice(0, 0, hologramNodeGroup);

  actions.splice(0, 0, action);
  newHologram.action = JSON.stringify(actions);
  return newHologram;
};

const constructCheckInHologramNodeGroupAction = ({hologram, hologramNodeGroup}) => {
  let action = {
    version: null,
    timeStamp: null,
    type: null,
    add: {
      hologramNode: [],
      hologramEdge: [],
      hologramGroup: [],
      hologramNodeGroup: [],
      hologramNodeWeight: [],
    },
    remove: {
      hologramNode: [],
      hologramEdge: [],
      hologramGroup: [],
      hologramNodeGroup: [],
      hologramNodeWeight: [],
    },
    update: {
      group: [],
      hologramNode: [],
      hologramEdge: [],
      hologramGroup: [],
      hologramNodeGroup: [],
      hologramNodeWeight: []
    }
  };

  let newHologram = hologram;
  let actions = JSON.parse(newHologram.action);

  if ( !actions ){
    action.version = 1;
    actions = []
  } else {
    let previousAction = actions[0];
    action.version = previousAction.version + 1;
    if ( actions.length > 3 ){
      actions.pop();
    }
  }

  

  action.type = "CheckInHologramNodeGroup";
  action.timeStamp = Date.now();
  action.add.hologramNodeGroup.splice(0, 0, hologramNodeGroup);

  actions.splice(0, 0, action);
  newHologram.action = JSON.stringify(actions);
  return newHologram;
};

const constructCheckInNewColorAction = ({hologram, hologramGroup}) => {
  let action = {
    version: null,
    timeStamp: null,
    type: null,
    add: {
      hologramNode: [],
      hologramEdge: [],
      hologramGroup: [],
      hologramNodeGroup: [],
      hologramNodeWeight: [],
    },
    remove: {
      hologramNode: [],
      hologramEdge: [],
      hologramGroup: [],
      hologramNodeGroup: [],
      hologramNodeWeight: [],
    },
    update: {
      group: [],
      hologramNode: [],
      hologramEdge: [],
      hologramGroup: [],
      hologramNodeGroup: [],
      hologramNodeWeight: []
    }
  };

  let newHologram = hologram;
  let actions = JSON.parse(newHologram.action);
  
  if ( !actions ){
    action.version = 1;
    actions = []
  } else {
    let previousAction = actions[0];
    action.version = previousAction.version + 1;
    if ( actions.length > 3 ){
      actions.pop();
    }
  }

  

  action.type = "CheckInNewColor";
  action.timeStamp = Date.now();
  action.update.hologramGroup.splice(0, 0, hologramGroup);

  actions.splice(0, 0, action);
  newHologram.action = JSON.stringify(actions);
  return newHologram;
};
