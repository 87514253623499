<template>
  <div class="flex flex-col w-full h-full">
    <div
      v-if="currentHologramDataReady && $route.meta.showHologramNavbar"
    >
      <HologramNavbarForOwner 
        v-show="hologramOwnerOnboard" 
        :sidebarOpen="sidebarOpen"
        :dataIsReady="currentHologramDataReady"
        :titleEditorModalOpen="hologramTitleEditorModalOpen"
        :sharingModalOpen="hologramSharingModalOpen"
        :settingModalOpen="hologramSettingsModalOpen"
        :hologramTitle="currentHologram.title"
        :transactingStatus="transactingStatus"
      />
      <HologramNavbarForContributor
        v-show="hologramContributorOnboard"
        :sidebarOpen="sidebarOpen"
        :dataIsReady="currentHologramDataReady"
        :titleEditorModalOpen="hologramTitleEditorModalOpen"
        :sharingModalOpen="hologramSharingModalOpen"
        :hologramTitle="currentHologram.title"
        :hologramOwner="currentHologram.createdBy.username"
        :transactingStatus="transactingStatus"
      />
      <HologramNavbarForNonUser 
        v-show="nonUserOnboard"
        :hologramTitle="currentHologram.title"
        :hologramOwner="currentHologram.createdBy.username"
      />
      <HologramNavBarForRegularUser 
        v-show="regularUserOnBoard"
        :hologramTitle="currentHologram.title"
        :hologramOwner="currentHologram.createdBy.username"
      />
    </div>
    <div
      v-if="!currentHologramDataReady"
      class="flex flex-col w-full h-full"
    >
      <div
        v-if="hologramNotExist"
        class="flex flex-col h-full m-auto w-112"
      >
        <div
          class="w-full mt-auto font-sans text-2xl font-semibold text-sd-base2-white"
        >
          Hologram not exist.
        </div>
        <div
          class="mt-4 mb-auto font-sans text-lg font-medium text-sd-base1-brcyan"
        >
          We are sorry that this is not the page you are looking for. If you had
          already logged in our services, we suggest you find the content you want
          from Dock.
        </div>
        <button
          class="fixed flex flex-row px-4 py-2 rounded-md shadow-2xl return-to-dock bottom-6 left-6 bg-sd-base02-black hover:bg-sd-base2-white hover:bg-opacity-10 focus:outline-none"
          @click="backToDock"
          v-show="user"
        >
          <div
            class="my-auto mr-3 font-sans text-sm text-sd-base0-brblue"
          >
            Go to dock
          </div>
          <div
            class="my-auto"
          >
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              class="flex w-5 h-5 my-auto fill-current text-sd-base0-brblue" 
              viewBox="0 0 16 16"
            >
              <path d="M5.921 11.9 1.353 8.62a.719.719 0 0 1 0-1.238L5.921 4.1A.716.716 0 0 1 7 4.719V6c1.5 0 6 0 7 8-2.5-4.5-7-4-7-4v1.281c0 .56-.606.898-1.079.62z"/>
            </svg>
          </div>
        </button>
      </div>
      <div
        v-else
        class="flex flex-col w-64 h-full m-auto"
      >
        <div
          class="mx-auto mt-auto font-sans text-lg font-semibold text-center text-sd-base1-brcyan"
        >
          Wish your journey of seeking knowledge safe and sound.
        </div>
        <svg class="w-6 h-6 mx-auto mt-4 mb-auto animate-spin text-sd-base0-brblue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </div>
    </div>
    <div
      v-else
      class="w-full h-full"
    >
      <svg 
        id="hologram-canvas" 
        class="w-full h-full"
      >
      </svg>
      <div
        id="hologram-control-panel-area"
        class="fixed inset-x-0 flex flex-col mx-auto bottom-8"
      >
        <searchNodeToolbox />
        <hologramModeTooltip />
        <hologramControlPanel class="mt-4" v-show="hologramOwnerOnboard || hologramContributorOnboard" />
      </div>
      <button
        class="fixed flex flex-row px-4 py-2 rounded-md shadow-2xl return-to-dock bottom-6 left-6 bg-sd-base02-black hover:bg-sd-base2-white hover:bg-opacity-10 focus:outline-none"
        @click="backToDock"
        v-show="regularUserOnBoard"
      >
        <div
          class="my-auto mr-3 font-sans text-sm text-sd-base0-brblue"
        >
          Return to dock
        </div>
        <div
          class="my-auto"
        >
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            class="flex w-5 h-5 my-auto fill-current text-sd-base0-brblue" 
            viewBox="0 0 16 16"
          >
            <path d="M5.921 11.9 1.353 8.62a.719.719 0 0 1 0-1.238L5.921 4.1A.716.716 0 0 1 7 4.719V6c1.5 0 6 0 7 8-2.5-4.5-7-4-7-4v1.281c0 .56-.606.898-1.079.62z"/>
          </svg>
        </div>
      </button>
    </div>
    <teleport to="#modals">
      <div 
        v-if="nodeContextMenuOpen"
      >
        <nodeContextMenu />
      </div>
    </teleport>
    <teleport to="#modals">
      <div 
        v-show="deleteNodeModalOpen"
        v-if="deleteNodeModalOpen && userNodes && currentHologramNodes && currentHologramEdges"
      >
        <deleteNodeModal />
      </div>
    </teleport>
    <teleport to="#modals">
      <div 
        v-show="deleteEdgeModalOpen"
        v-if="deleteEdgeModalOpen && currentHologramNodes && currentHologramEdges"
      >
        <deleteEdgeModal />
      </div>
    </teleport>
    <teleport to="#modals">
      <div 
        v-show="hologramEntityDetailModalOpen"
        v-if="hologramEntityDetailModalOpen"
      >
        <hologramEntityDetailModal />
      </div>
    </teleport>
    <teleport to="#modals">
      <div 
        v-if="hologramNodeTooltipModalOpen"
      >
        <hologramNodeTooltipModal />
      </div>
    </teleport>
    <teleport to="#modals">
      <div 
        v-if="hologramTitleEditorModalOpen"
      >
        <hologramTitleEditorModal />
      </div>
    </teleport>
    <teleport to="#modals">
      <div v-if="hologramSharingModalOpen">
        <hologramSharingModal 
          :hologramOwnerOnboard="hologramOwnerOnboard"
        />
      </div>
    </teleport>
  </div>
</template>
<script>
import hologramSharingModal from "@/components/modals/hologramSharingModal.vue";
import HologramNavbarForOwner from "../components/hologram/HologramNavbarForOwner.vue"
import HologramNavbarForContributor from "../components/hologram/HologramNavbarForContributor.vue"
import HologramNavbarForNonUser from "../components/hologram/HologramNavbarForNonUser.vue"
import HologramNavBarForRegularUser from "../components/hologram/HologramNavbarForRegularUser.vue"
import * as d3 from 'd3';
import { mapGetters } from 'vuex';
import { API, graphqlOperation } from "aws-amplify";
import { getHologram as getHologramQuery} from "../graphql/queries"
import { getHologramAccessPolicy as getHologramAccessPolicyQuery } from "../graphql/custom/customQueries"
import nodeContextMenu from "@/components/modals/nodeContextMenu.vue"
import hologramControlPanel from "@/components/hologram/hologramControlPanel.vue"
import hologramModeTooltip from "@/components/hologram/hologramModeTooltip.vue"
import searchNodeToolbox from "@/components/hologram/searchNodeToolbox.vue"
import deleteNodeModal from "@/components/modals/deleteNodeModal.vue"
import deleteEdgeModal from "@/components/modals/deleteEdgeModal.vue"
import hologramNodeTooltipModal from "@/components/modals/hologramNodeTooltipModal.vue"
import hologramEntityDetailModal from "@/components/modals/hologramEntityDetailModal.vue"
import hologramTitleEditorModal from "../components/modals/hologramTitleEditorModal.vue"
//import { onUpdateHologramByContributor as onUpdateHologramByContributorSubscription } from "../graphql/subscriptions"
//import { onUpdateHologram as onUpdateHologramSubscription } from "../graphql/subscriptions"
import { onUpdateHologramByHologramId as onUpdateHologramByHologramIdSubscription } from "../graphql/custom/customeSubscriptions"
import { onCreateHologramNode as onCreateHologramNodeSubscription } from "../graphql/subscriptions"
import { onUpdateUserNode as onUpdateUserNodeSubscription } from "../graphql/subscriptions"
import _ from "lodash"
import { colors } from "../assets/colors"

import { TYPE } from "vue-toastification";
import { useToast } from 'vue-toastification'
import { gaHelpers } from "@/analytics/helper.js"
export default {
  name:"Hologram",
  components:{
    nodeContextMenu,
    hologramControlPanel,
    deleteNodeModal,
    searchNodeToolbox,
    hologramEntityDetailModal,
    hologramNodeTooltipModal,
    hologramModeTooltip,
    deleteEdgeModal,
    hologramTitleEditorModal,
    HologramNavbarForOwner,
    HologramNavbarForContributor,
    HologramNavbarForNonUser,
    HologramNavBarForRegularUser,
    hologramSharingModal,
  },
  data(){
    return {
      hologramSidebarTargetNodeID: null,
      resetChargeForce: null,
      hologramNotExist: false,
      weightValueSum: null,
      maximumWeight: null,
      miniumWeight: null,
      draggingNode: false,
      fakeData: {
        "nodes":[
          {
            "id": "239485",
            "title": "Who am i",
            "url": "https://gist.github.com/jwickens/0c4082c9724c552efef86a45b4f6d087"
          },
          {
            "id": "9494585",
            "title": "We are Hololink",
            "url": "https://gist.github.com/christianMurschall/786ffd07c9abd5365e806fe0c4bcbf7c"
          }
        ],
        "links":[
          {
            "edgeID": "ddeie-3jdk3id-3jdko3",
            "source": "239485",
            "target": "9494585"
          }
        ]
      },
      simulation: null,
      d3DiagramProperties: {
        maxTextSize:"",
        nominalTextSize:"",
        maxLinkStrokeWidth:"",
        nominalLinkStrokeWidth:"",
        simulationDurationInMs:3000,
        maxZoomLavel: 4,
        zoomLevel:1,
        centerForce: {
          enabled: false,
          strength: 0.5,
          x: 1,
          y: 1,
        },
        chargeForce: {
          enabled: true,
          strength: -200,
          distanceMin: 250,
          distanceMax: 2000
        },
        collideForce: {
          enabled: true,
          strength: 10,
          iterations: 1,
          radius: 100
        },
        xForce: {
          enabled: true,
          strength: 0.01,
          x: 1
        },
        yForce: {
          enabled: true,
          strength: 0.01,
          y: 1
        },
        linkForce: {
          enabled: true,
          distance: 100,
          iterations: 1,
          strength: 0.5,
        },
        nodeSizeThreshold:4,
        nominalNodeSize: 15,
        maxNodeSize: 25,
        textFadeThreshold:1.5,
        nodeTextDy: 16,
        maxNodeTextDy: 50,
        nominalNodeTextDy: 15
      },
      svg: null,
      width: null,
      height: null,
      g: null,
      node: null,
      link: null,
      graph: {"nodes":[], "links":[]},
      store: null,
      linkedByIndex:{},
      nodeById:{},
      zoomHandler: null,
      dragHandler: null,
      startTime: null,
      endTime: null,
      biggestNodeSize: null,
      smallestNodeSize: null,
      biggestLinkWeight: null,
      smallestLinkWeight: null,
      nodeSizes: [],
      subscribeToHologram: null,
      subscribeToHologramNode: null,
      subscribeToUserNode: null,
      nonUserOnboard: false,
      hologramOwnerOnboard: false,
      hologramContributorOnboard: false,
      regularUserOnBoard: false,
    }
  },
  async mounted(){

    // If user url to come to the Hologram directly we need to get the data here
    document.addEventListener("keydown", this.OpenSearchToolboxByKeyBind)
    let accessPolicyResult;
    if (this.user){
      try {
        accessPolicyResult = await getHologramAccessPolicy("private", this.currentHologramID);
        if (accessPolicyResult.createdByID === this.user.attributes.sub){
          this.$store.dispatch("hologram/updateEditingCurrentHologramPermission", {data:true});
        } else {
          this.$store.dispatch("hologram/updateEditingCurrentHologramPermission", {data:false});
        }
      } catch(err){
        try {
          accessPolicyResult = await getHologramAccessPolicy("public", this.currentHologramID)
          this.$store.dispatch("hologram/updateEditingCurrentHologramPermission", {data:false});
        } catch(err){
          this.$router.push({name:'404'})
        }
      }
    } else {
      try {
        accessPolicyResult = await getHologramAccessPolicy("public", this.currentHologramID)
        this.$store.dispatch("hologram/updateEditingCurrentHologramPermission", {data:false});
      } catch(err){
        console.error(err)
        this.$router.push({name:'404'})
      }
    }

    if (!accessPolicyResult){
      this.hologramNotExist = true;
      return
    }

    window.document.title = accessPolicyResult.title;

    await getHologramData(this.$store.dispatch, this.user, accessPolicyResult, this.currentHologramID);

    if (this.currentHologram.graph){
      this.graph = JSON.parse(this.currentHologram.graph);
    }
    for ( const weight of this.currentHologramNodeWeights){
      this.weightValueSum = this.weightValueSum + weight.value
      if ( weight.value > this.maximumWeight){
        this.maximumWeight = weight.value;
      } else if ( weight.value < this.miniumWeight ){
        this.miniumWeight = weight.value;
      }
    }

    this.checkWhomOnboard();
    //this.checkContributionMode();
    //this.subscribeChannel();
    
    this.$nextTick( function(){
      this.svg = d3.select("#hologram-canvas");
      // If we conclude that guest have no permssion access this hologram we will redirect to 404
      // So we make sure these codes won't execute
      if (this.$route.name !== "Hologram"){ return; }
      this.width = +this.svg.node().getBoundingClientRect().width;
      this.height = +this.svg.node().getBoundingClientRect().height;

      this.svg.attr("viewBox", `0 0 ${this.width*2} ${this.height*2}`)


      // for zoom 
      this.g = this.svg.append("g")
        .attr("class", "everything");

      // svg objects
      this.link = this.g.append("g")
                  .attr("class", "links") 
                  .selectAll('g');
      this.node = this.g.append("g")
                  .attr("class", "nodes")
                  .selectAll('g');

      //limit font-size when scaling
      this.d3DiagramProperties.maxTextSize = 24;
      this.d3DiagramProperties.nominalTextSize = 12;

      //limit link stroke-width when scaling
      this.d3DiagramProperties.maxLinkStrokeWidth = 4;
      this.d3DiagramProperties.nominalLinkStrokeWidth = 2;

      this.startTime = Date.now();
      this.endTime = this.startTime + this.d3DiagramProperties.simulationDurationInMs;

      this.zoomHandler = d3.zoom()
        .on("zoom", this.zoom_actions)
        .scaleExtent([0.1, this.d3DiagramProperties.maxZoomLavel]); //set zoom in and out limit

      this.dragHandler = d3.drag()
        .on("start", this.drag_start)
        .on("drag", this.drag_drag)
        .on("end", this.drag_end);	

      this.calculateSizeAndLinkWeight();
      
      this.initializeSimulation();
      this.initializeForce();
      this.updateData(this.graph); 
      this.startSimulation();
      this.updateDisplay();
      this.hideNodeText();
      this.updateForce();

      


      // Pull all graph to center
      this.pullGraphToCenterThenRelease()
      this.resetTickedTimer();

      // create a index to allow constant-time lookup with good performance
      // This must put after simulation, after that we can access the d3 calculated info
      
      this.graph.links.forEach(d => {
        this.linkedByIndex[`${d.source.index},${d.target.index}`] = 1;
      });
    })
  },
  setup() {
    // Get toast interface
    const toast = useToast();
    return { toast }
  },
  methods:{
    subscribeChannel: function(){
      if ( this.nonUserOnboard ){ return }
      if ( this.regularUserOnBoard ){ return }

      if ( this.user ){
        this.subscribeToHologram = API.graphql(graphqlOperation(onUpdateHologramByHologramIdSubscription, {id:this.currentHologram.id}))
          .subscribe({
            next: (data) => {
              console.log(data)
              const hologram = data.value.data.onUpdateHologramByHologramID;
              const status = this.checkContributorStatus(hologram.contributorID);
              const actions = JSON.parse(hologram.action);
              const newAction = actions[0];

              if ( !status ){
                this.removeContributorPermission();
                this.NotifyCurrentUserContributorStatusChanged()
                this.removeAllSubscribeChannel();
                return
              }

              const updateStatus = this.checkActionVersion(newAction);
              if ( !updateStatus ){
                return
              }

              const actionType = newAction.type;

              switch (actionType) {
                case "BuildNode":{
                  this.subscriptionBuildNodeHandler(hologram, newAction)
                  break
                }
                case "BuildEdge":{
                  this.subscriptionBuildEdgeHandler(hologram, newAction)
                }
              }

            },
            error: error => console.warn(error)
          })
      
        this.subscribeToHologramNode = API.graphql(graphqlOperation(onCreateHologramNodeSubscription, {owner:this.userData.id}))
          .subscribe({
            next: (data) => console.log(data),
            error: error => console.warn(error)
          })

        this.subscribeToUserNode = API.graphql(graphqlOperation(onUpdateUserNodeSubscription, {owner:this.userData.id}))
          .subscribe({
            next: (data) => console.log(data),
            error: error => console.warn(error)
          })
      }
    },
    subscriptionBuildEdgeHandler: function(subscribedHologram, action){
      const newHologramEdge = action.add.hologramEdge[0];
      this.$store.dispatch("addNewItemToCurrentHologramEdges", newHologramEdge);

      const updatedHologramNodeWeights = action.update.hologramNodeWeight;
      for ( const weight of updatedHologramNodeWeights ){
        this.$store.dispatch("updateItemInCurrentHologramNodeWeight", weight);
      }

      let newHologram = _.cloneDeep(this.currentHologram);
      newHologram.graph = subscribedHologram.graph;
      this.$store.dispatch("hologram/setCurrentHologram", newHologram);

      this.$store.dispatch("hologram/updateRenderHologramGraph", { data:true });
    },
    subscriptionBuildNodeHandler: function(subscribedHologram, action){
      const newHologramNode = action.add.hologramNode[0]
      this.$store.dispatch("hologram/addItemToCurrentHologramNodes", newHologramNode);
      
      const newHologramNodeWeight = action.add.hologramNodeWeight[0];
      this.$store.dispatch("weight/addItemIntoCurrentHologramNodeWeight", newHologramNodeWeight);

      let newHologram = _.cloneDeep(this.currentHologram);
      newHologram.graph = subscribedHologram.graph;
      this.$store.dispatch("hologram/setCurrentHologram", newHologram);
      
      this.$store.dispatch("hologram/updateRenderHologramGraph", { data:true });
    },
    checkContributionMode: function(){
      const contributorID = this.currentHologram.contributorID;
      if ( contributorID && contributorID.length > 1 ){
        this.$store.dispatch("hologram/setContributionMode", true);
      }
    },
    checkActionVersion: function(newAction){
      const currentActions = JSON.parse(this.currentHologram.action);
      if ( newAction.version !== currentActions[0].version ){
        return true
      } else {
        return false
      }
    },
    checkContributorStatus: function(contributors){
      const currentUserIndex = contributors.findIndex( e => e === this.userData.id );
      if ( currentUserIndex === -1 ){
        return false
      } else {
        return true
      }
    },
    NotifyCurrentUserContributorStatusChanged: function(){
      this.toast(`You had been remove from contributor list of current hologram. Please refresh this page or back to Dock.`, {
        type: TYPE.SUCCESS,
        toastClassName: "hologram-toast",
        timeout: 6000,
      })
    },
    removeContributorPermission: function(){
      this.hologramContributorOnboard = false;
      this.regularUserOnBoard = true;
    },
    removeAllSubscribeChannel: function(){
      if ( this.subscribeToHologram ){
        this.subscribeToHologram.unsubscribe();
        this.subscribeToHologram = null;
      }

      if ( this.subscribeToHologramNode ){
        this.subscribeToHologramNode.unsubscribe();
        this.subscribeToHologramNode = null
      }

      if ( this.subscribeToUserNode ){
        this.subscribeToUserNode.unsubscribe();
        this.subscribeToUserNode = null
      }
    },
    checkWhomOnboard: function(){
      if ( !this.userData ){
        this.nonUserOnboard = true
        return;
      }

      if ( this.currentHologram.owner === this.userData.id ){
        this.hologramOwnerOnboard = true;
        return;
      }

      const contributorIndex = this.currentHologram.contributorID.indexOf(this.userData.id)
      if ( contributorIndex !== -1 ){
        this.hologramContributorOnboard = true
        return;
      }

      this.regularUserOnBoard = true;
    },
    pullGraphToCenterThenRelease: function(){
      this.simulation.force("yForce")
        .strength(0.1);

      this.simulation.force("xForce")
        .strength(0.1);
      
      setTimeout(() => {
        this.simulation.force("xForce")
          .strength(this.d3DiagramProperties.xForce.strength);

        this.simulation.force("yForce")
          .strength(this.d3DiagramProperties.yForce.strength);

        this.resetTickedTimer();
      }, 1000)
    },
    OpenSearchToolboxByKeyBind: function($event){
      if ( this.hologramControlPanelInputOpen ) return;
      if ( $event.code === "KeyS" && $event.altKey ) {
        $event.preventDefault();
        this.$store.dispatch("modal/updateHologramControlPanelInputOpen", {data:true});
        this.$nextTick(() => {
          const element = document.getElementById("hologram-search-toolbox-input")
          element.focus();
        });
      }
    },
    calculateSizeAndLinkWeight: function(){
      let nodeSizeArray = []
      let linkWeightArray = []

      this.graph.nodes.forEach( d => {
        const nodeSize = getNodeSize(d, this.currentHologramNodes, this.currentHologramNodeWeights)
        nodeSizeArray.push(nodeSize)

        const data = {
          "id": d.id,
          "nodeSize": nodeSize
        }

        this.nodeSizes.push(data)

        d["r"] = nodeSize
      })
      
      this.biggestNodeSize = Math.max(...nodeSizeArray);
      this.smallestNodeSize = Math.min(...nodeSizeArray);

      this.graph.links.forEach( l => {
        const sourceNodeSizeIndex = this.nodeSizes.findIndex( e => e.id === l.source );
        const sourceNodeSize = this.nodeSizes[`${sourceNodeSizeIndex}`].nodeSize;
        const targetNodeSizeIndex = this.nodeSizes.findIndex( e => e.id === l.target );
        const targetNodeSize = this.nodeSizes[`${targetNodeSizeIndex}`].nodeSize;
        const weight = sourceNodeSize + targetNodeSize;
        linkWeightArray.push(weight);
      })
      
      this.smallestLinkWeight = Math.min(...linkWeightArray);
      this.biggestLinkWeight = Math.max(...linkWeightArray);
    },
    backToDock: function(){
      this.$router.push({name: 'Dock', params:{ username: this.user.username }});
    },
    calculateNodesRadius(node){ // eslint-disable-line no-unused-vars
      return 15
    },
    constructHologramGraphNodes(){
      for (var i=0; i<this.currentHologramNodes.length; i++){

        let tempNode = {
          id: this.currentHologramNodes[i].nodeID,
          title: this.currentHologramNodes[i].node.title,
          url: this.currentHologramNodes[i].node.url
        }
        this.graph.nodes.splice(0,0,tempNode);
      }
    },
    constructHologramGraphEdges(){
      for (var k=0; k<this.currentHologramEdges.length; k++){
        let tempLink = {
          edgeID: this.currentHologramEdges[k].edgeID,
          source: this.currentHologramEdges[k].edge.startNodeID,
          target: this.currentHologramEdges[k].edge.endNodeID,
        }
        this.graph.links.splice(0,0,tempLink);
      }
    },
    initializeSimulation(){
      let first_simulation = d3.forceSimulation()
        .on("tick", this.ticked);
      
      this.resetSimulation(first_simulation);
    },
    resetSimulation(newSim){
      if (this.simulation){
        this.simulation.stop();
      }
      if (newSim != undefined) {
        this.simulation = newSim;
      }
    },
    initializeForce() {
      // add forces and associate each with a name
      this.simulation
        .force("link", d3.forceLink())
        .force("charge", d3.forceManyBody())
        .force("center", d3.forceCenter())
        .force("xForce", d3.forceX())
        .force("yForce", d3.forceY())
        .force("clusterCollide", null)
        .force("cluster", null)
        .force("defaultCollide", d3.forceCollide());
    },
    startSimulation(){
      // set up the simulation and event to update locations after each tick
      this.simulation
        .nodes(this.graph.nodes);
      
      this.simulation.force("link")
        .id(function(d) {return d.id;})
        .links(this.graph.links)

      this.simulation
        .alphaDecay(0.01)
        .alpha(0.5)
        .restart()
    },
    updateForce() {
      // get each force by name and update the properties
      this.simulation.force("center")
        .strength(this.d3DiagramProperties.centerForce.strength * this.d3DiagramProperties.centerForce.enabled)
        .x(this.width * this.d3DiagramProperties.centerForce.x)
        .y(this.height * this.d3DiagramProperties.centerForce.y);
      this.simulation.force("charge")
        .strength(d => this.customChargeForceStrength(d))
        .distanceMin(this.d3DiagramProperties.chargeForce.distanceMin)
        .distanceMax(this.d3DiagramProperties.chargeForce.distanceMax);
      this.simulation.force("xForce")
        .strength(this.d3DiagramProperties.xForce.strength * this.d3DiagramProperties.xForce.enabled)
        .x(this.width * this.d3DiagramProperties.xForce.x);
      this.simulation.force("yForce")
        .strength(this.d3DiagramProperties.yForce.strength * this.d3DiagramProperties.yForce.enabled)
        .y(this.height * this.d3DiagramProperties.yForce.y);
      this.simulation.force("link")
        .id(function(d) {return d.id;})
        .distance(this.customLinkForceDistance)
        .strength(this.d3DiagramProperties.linkForce.strength)
        .iterations(this.d3DiagramProperties.linkForce.iterations)
        .links(this.d3DiagramProperties.linkForce.enabled ? this.graph.links : [])
      /*
        this.simulation.force("defaultCollide")
        .strength(this.d3DiagramProperties.collideForce.strength * this.d3DiagramProperties.collideForce.enabled)
        .radius(this.d3DiagramProperties.collideForce.radius)
        .iterations(this.d3DiagramProperties.collideForce.iterations);
      */
    },
    customChargeForceStrength: function(d){
      if ( this.biggestNodeSize === this.smallestNodeSize ){
        return -300
      }

      let i = d3.interpolateNumber(350, 150)
      const nodeSize = getNodeSize(d, this.currentHologramNodes, this.currentHologramNodeWeights)
      const gap = this.biggestNodeSize - this.smallestNodeSize;
      const minGap = nodeSize - this.smallestNodeSize;
      const r = minGap/gap
      const strength = -i(r)
      return strength
    },
    customLinkForceDistance: function(l){

      if ( this.smallestLinkWeight === this.biggestLinkWeight ){
        return 150
      }

      let i = d3.interpolateNumber(200, 150)
      const sourceNodeSizeIndex = this.nodeSizes.findIndex( e => e.id === l.source.id );
      const sourceNodeSize = this.nodeSizes[`${sourceNodeSizeIndex}`].nodeSize;
      const targetNodeSizeIndex = this.nodeSizes.findIndex( e => e.id === l.target.id );
      const targetNodeSize = this.nodeSizes[`${targetNodeSizeIndex}`].nodeSize;
      const weight = sourceNodeSize + targetNodeSize;
      const gap = this.biggestLinkWeight - this.smallestLinkWeight;
      const minGap = weight - this.smallestLinkWeight;
      const distance = i(minGap/gap)
      return distance
    },
    updateNodeText: function(graph){
      //TODO: Deep deeper into d3 data join to know why update only one element will update other text
      this.node
        .data(graph.nodes, node => node.title )
        .join(
          enter => enter,
          update => update
            .select("text")
              .text( d => {
                return d.title
              })
              .style('font-weight', '500')
              .style('fill', '#93a1a1')
              .style('font-family', 'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji')
              .style('font-size', this.d3DiagramProperties.nominalTextSize + "px")
              .style('opacity', 100)
              .attr('text-anchor', 'middle')
              .call(this.wrap, 200),
          exit => exit
        )
        
    },
    updateData(graph){
      // To avoid breaking the method chain, use selection.call, it return selection

      this.node = this.node
        .data(graph.nodes, d => d.id)
        .join(
          enter => enter.append("g")
              .attr('data-toggle', 'tooltip')
              .attr('data-placement', 'bottom')
              .attr('title', d => d.title)
            .call(enter => enter.append('circle')
              .attr("class", "cursor-pointer")
              .attr('data-toggle', 'tooltip')
              .attr('data-placement', 'bottom')
              .attr('title', d => d.title)
              .on("mouseenter", this.mouseenterOnNode)
              .on("mouseleave", this.mouseleaveOnNode)
              .on("click", this.nodeOnClickHandler))
              .on("contextmenu", this.nodeContextMenuHandler)           
            .call(enter => enter.append("text")
              .text(node => {
                return node.title
              })
              .style('font-weight', '500')
              .style('fill', '#93a1a1')
              .style('font-family', 'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji')
              .style('font-size', this.d3DiagramProperties.nominalTextSize + "px")
              .style('opacity', 100)
              .attr('text-anchor', 'middle')
              .call(this.wrap, 200)
            ),
          update => update,
          exit => exit
            .remove()
        );

      this.link = this.link
        .data(graph.links, d => d.edgeID)
        .join(
          enter => enter.append("g")
            .call(
              enter => enter.append('line')
                .attr("class", "display-line")
            )
            .call(
              enter => enter.append("line")
                .attr("class", "event-assist-line")
                .on("mouseenter", this.mouseenterOnEdge)
                .on("mouseleave", this.mouseleaveOnEdge)
                .on("click", this.edgeOnClickHandler)
            ),
            /*
            .call(
              enter => enter.append("title")
                .text(d => d.index + " : " + d.source.id + " -> " + d.target.id) 
            ),
            */
          update => update,
          exit => exit
            .remove()
        );

      this.zoomHandler(this.svg);
      this.dragHandler(this.node);
    },
    hideNodeText(){
      this.node.selectAll('text')
        .style("display", "none")
    },
    updateLinkDisplayOpacity: function(opacity){
      this.link
        .selectAll(".display-line")
        .style("opacity", opacity);
      
      if ( opacity !== 1){
        this.link
          .selectAll(".event-assist-line")
          .style("opacity", 0);
      }
      
    },
    updateDisplay() {
      //let t = d3.transition().duration(1000).ease(d3.easeExp);

      // update the display based on the forces (but not positions)
      this.link
        .selectAll(".display-line")
          .attr("stroke-width", this.d3DiagramProperties.linkForce.enabled ? 2 : .2)
          .style("stroke", '#839496')
          .style("opacity", this.d3DiagramProperties.linkForce.enabled ? 1 : 0);
          
      this.link
        .selectAll(".event-assist-line")
          .style("stroke", '#839496')
          .style("opacity", 0)
          .attr("stroke-width", this.d3DiagramProperties.nominalLinkStrokeWidth*4);

      this.node.selectAll('circle')
        .attr("r", (d) => getNodeSize(d, this.currentHologramNodes, this.currentHologramNodeWeights))
        .attr("fill", (d) => this.getNodeColor(d))
        .style("opacity", 1)
        .style("stroke", (d) => this.getNodeStroke(d))

      this.node.selectAll('text')
        .attr('y', (d) => {
          return d.r + 10
        });

    },
    updateNodeColor: function(){
      this.node.selectAll('circle')
        .attr("fill", (d) => this.getNodeColor(d));
    },
    getNodeStroke: function(d){
      if ( d.id !== this.hologramSidebarTargetNodeID ){ return "" }
      return "#fdf6e3"
    },
    getNodeColor: function(d){
      if ( this.connectionSetUpModeOpen ){
        if ( d.id === this.connectionStartNodeID || d.id === this.connectionEndNodeID ) return "#b58900"
        return "#93a1a1"
      }

      if ( this.displayColorMode ){
        const groupIndex = this.currentHologramGroups.findIndex( e => e.groupID === d.groupID );
        if ( groupIndex === -1 ) return "#93a1a1"
        
        const colorName = this.currentHologramGroups[`${groupIndex}`].color;
        if (  !colorName ) return "#93a1a1"

        const colorIndex = colors.findIndex( e => e.name ===  colorName );
        return colors[`${colorIndex}`].bgHexCode;
      }

      return "#93a1a1"
    },
    updateAll() {
      // convenient function to update everything (run after UI input)
      this.startTime = Date.now();
      this.endTime = this.startTime + this.d3DiagramProperties.simulationDurationInMs;
      this.reheatSimulation()
      this.updateForce();
      this.updateDisplay();
    },
    reheatSimulation(){
      this.simulation
        //.alphaDecay(0.001)
        .alpha(0.8)
        .restart();

      this.resetTickedTimer();
    },
    resetTickedTimer: function(){
      this.startTime = Date.now();
      this.endTime = this.startTime + this.d3DiagramProperties.simulationDurationInMs;
    },
    ticked() {
      // update the display positions after each simulation tick
      if (Date.now() < this.endTime) {
        this.link
          .selectAll(".display-line")
            .attr("x1", function(d) { return d.source.x; })
            .attr("y1", function(d) { return d.source.y; })
            .attr("x2", function(d) { return d.target.x; })
            .attr("y2", function(d) { return d.target.y; });
        
        this.link
          .selectAll(".event-assist-line")
            .attr("x1", function(d) { return d.source.x; })
            .attr("y1", function(d) { return d.source.y; })
            .attr("x2", function(d) { return d.target.x; })
            .attr("y2", function(d) { return d.target.y; });
        
        
        // we use transform is because we group circle and text together in g, and transform is g's position 
        this.node
          .attr("transform", (d) => { 
            return "translate(" + d.x + "," + d.y + ")"; 
          }) 
      } else {
        this.simulation.stop();
      }
    },
    mouseenterOnNode(event){
      if (!this.draggingNode && event.srcElement.__data__.id){
        this.$store.dispatch("modal/toggleHologramNodeTooltipModal", {data:true});
        this.$store.dispatch("modal/updateHologramNodeTooltipModalData", {type:'update', data:event.srcElement.__data__.id})
        const getElementPosition = this.getElementPosition(event.srcElement);
        this.$store.dispatch("modal/updateHologramNodeTooltipModalTriggeredPosition", {type:'update', data:getElementPosition})      
      }
    },
    async edgeOnClickHandler(event){
      if (!this.deleteModeOpen || this.deleteEdgeModalOpen) return;
      this.$store.dispatch("modal/updateDeleteEdgeModalOpen", {data:true});
      this.$store.dispatch("modal/updateDeleteEdgeID", {data:event.target.__data__.edgeID});
      this.$store.dispatch("hologram/updateDeleteModeOpen", {data:false});
      gaHelpers.interaction("open_delete_edge_modal", "delete_edge_modal");
      d3.select(event.currentTarget)
        .style("stroke", "grey")
        .attr("stroke-width", this.d3DiagramProperties.nominalLinkStrokeWidth)
    },
    async nodeOnClickHandler(event){ // eslint-disable-line no-unused-vars
      if (this.hologramOwnerOnboard || this.hologramContributorOnboard){

        if (this.deleteModeOpen){
          const nodeID = event.target.__data__.id
          this.$store.dispatch("modal/toggleDeleteNodeModal", {data:true});
          this.$store.dispatch("modal/updateDeleteNodeID", {type:"update", data:nodeID});
          this.$store.dispatch("hologram/updateDeleteModeOpen", {data:false});
          gaHelpers.interaction("open_delete_node_modal", "delete_node_modal");
          return;
        }

        if (event.ctrlKey || event.metaKey){ // metaKey is for mac user
          window.open(event.target.__data__.url, '_blank')
          return;
        }

        if (this.connectionSetUpModeOpen){
          if (!this.connectionStartNodeID){
            this.$store.dispatch("hologram/setConnectionStartNode", {data:event.target.__data__.id})
            this.$store.dispatch("hologram/updateRenderHologramNodeColor", {data:true})
            return;
          }

          if (this.connectionStartNodeID && this.connectionStartNodeID !== event.target.__data__.id){
            this.$store.dispatch("hologram/setConnectionEndNode", {data:event.target.__data__.id})
            this.$store.dispatch("hologram/updateRenderHologramNodeColor", {data:true}) // update endNode too
            this.$store.dispatch("hologram/updateTransactingStatus", {data:true});


            try {
              const result = await this.$store.dispatch("hologram/advancedBuildEdge");
              gaHelpers.engagement("build_edge", "core");
              this.$store.dispatch("hologram/updateTransactingStatus", {data:false});
              this.$store.dispatch("hologram/cleanUpConnectionSetupMode");
              this.$store.dispatch("hologram/updateRenderHologramNodeColor", {data:true})

              if ( result === "duplicated" ){
                const newNotification = {
                  location: "linkCreation",
                  type: "duplicated"
                }
                this.$store.dispatch("notification/updateHologramOperationNotification", {type:"update", data:newNotification})
              }
            } catch(err){
              console.error("Something went wrong when building edge", err);
            }
            return;
          }
        }

        this.$store.dispatch("hologram/setHologramNodeDetailTargetID", {data:event.target.__data__.id})
        this.hologramSidebarTargetNodeID = event.target.__data__.id;
        this.updateDisplay()
        if ( !this.hologramSidebarOpen ){
          this.$store.dispatch("sidebar/toggleHologramSidebar", {data:true})
        }

        /*
        if (this.hologramEntityDetailModalOpen){
          this.$store.dispatch("modal/toggleHologramEntityDetailModal", {data:false});
          this.$store.dispatch("modal/updateHologramEntityDetailModalData", {type:"reset"});
        } else {
          const index = this.userNodes.findIndex( e => e.nodeID == event.target.__data__.id);
          const targetUserNode = this.userNodes[`${index}`];
          this.$store.dispatch("modal/toggleHologramEntityDetailModal", {data:true});
          this.$store.dispatch("modal/updateHologramEntityDetailModalData", {type:"update", data:targetUserNode});
          gaHelpers.interaction("open_node_entity_modal", "modal");
        }
        */
      } else {
        if (event.ctrlKey || event.metaKey){ // metaKey is for mac user
          window.open(event.target.__data__.url, '_blank')
          return;
        }
      }
    },
    nodeContextMenuHandler(event){
      event.preventDefault();
      const getElementPosition = this.getElementPosition(event.srcElement);
      this.$store.dispatch("modal/toggleNodeContextMenu", {data:true})
      this.$store.dispatch("modal/updateNodeContextMenuPosition", {type:"update", data:getElementPosition})
      gaHelpers.interaction("open_node_context_menu", "node_context_menu");
      const targetNodeData = {
        id: event.target.__data__.id,
        title: event.target.__data__.title,
        url: event.target.__data__.url,
        groupID: event.target.__data__.groupID,
      }
      this.$store.dispatch("modal/updateNodeContextMenuData", {data:targetNodeData, type:"update"});

    },
    mouseenterOnEdge(event, d){ // eslint-disable-line no-unused-vars
      if (!this.deleteModeOpen) return;
      
      d3.select(event.currentTarget)
        .style("stroke", "#b58900")
        .style("opacity", 0.25)
        .attr("stroke-width", this.d3DiagramProperties.nominalLinkStrokeWidth*4)
    },
    mouseleaveOnEdge(event, d){ // eslint-disable-line no-unused-vars
      if (!this.deleteModeOpen) return;
      
      d3.select(event.currentTarget)
        .style("stroke", "grey")
        .attr("stroke-width", this.d3DiagramProperties.nominalLinkStrokeWidth)
    },
    mouseleaveOnNode(event){ // eslint-disable-line no-unused-vars
      this.$store.dispatch("modal/toggleHologramNodeTooltipModal", {data:false});
      this.$store.dispatch("modal/updateHologramNodeTooltipModalData", {type:'reset'})
      this.$store.dispatch("modal/updateHologramNodeTooltipModalTriggeredPosition", {type:'reset'})
    },
    getElementPosition(element){
      const getPosition = element.getBoundingClientRect();
      const result = {
        x:getPosition.x,
        y:getPosition.y,
        width: getPosition.width,
        height: getPosition.height,
      };
      return result;
    },
    drag_start(event, d) {
      if ( !event.sourceEvent.altKey ) return
      //DRAG FUNCTION
      this.resetTickedTimer();
      if (this.connectionSetUpModeOpen) return;
      this.draggingNode = true;
      if (this.hologramNodeTooltipModalOpen){
        this.$store.dispatch("modal/toggleHologramNodeTooltipModal", {data:false});
        this.$store.dispatch("modal/updateHologramNodeTooltipModalData", {type:'reset'})
        this.$store.dispatch("modal/updateHologramNodeTooltipModalTriggeredPosition", {type:'reset'})
      }
      if (!event.active) this.simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    },
    drag_drag(event, d) {
      if ( !event.sourceEvent.altKey ) return
      if (this.connectionSetUpModeOpen) return;
      //make sure you can't drag the circle outside the box
      d.fx = event.x;
      d.fy = event.y;
      this.resetTickedTimer();
    },
    drag_end(event, d) {
      if ( !event.sourceEvent.altKey ) return
      if (this.connectionSetUpModeOpen) return;
      this.draggingNode = false;
      if (!event.active) this.simulation.alphaTarget(0);
      d.fx = null; //sticky node - d.x
      d.fy = null; //sticky node - d.y
    },
    zoom_actions(event){
      this.g.attr("transform", event.transform)
      let transform = event.transform
      this.zoomLevel = transform.k
      // setup threshhold of displaying node's text
      let highFadeThreshhold = this.d3DiagramProperties.textFadeThreshold + 0.2;
      if (transform.k < this.d3DiagramProperties.textFadeThreshold){
        this.svg.selectAll('text').style("display","none")
      } else if ( highFadeThreshhold > transform.k >= this.d3DiagramProperties.textFadeThreshold) {
        this.svg.selectAll('text').style("display","block")
        this.svg.selectAll('text').style("opacity",(transform.k - this.d3DiagramProperties.textFadeThreshold)*2)
      } else {
        this.svg.selectAll('text').style("display","block")
        this.svg.selectAll('text').style("opacity",1)
      }
      /*
      if ( this.d3DiagramProperties.nominalNodeTextDy*transform.k > this.d3DiagramProperties.maxNodeTextDy ){
        const dy = this.d3DiagramProperties.maxNodeTextDy/transform.k
        this.svg.selectAll("text").attr("y", dy)
      }
      */
      
      let text_size = this.d3DiagramProperties.nominalTextSize
      if (this.d3DiagramProperties.nominalTextSize*transform.k > this.d3DiagramProperties.maxTextSize){
        text_size = this.d3DiagramProperties.maxTextSize/transform.k
      }
      this.svg.selectAll('text').style('font-size', text_size)
      
      
      if ( transform.k > this.d3DiagramProperties.nodeSizeThreshold ){
        this.node.selectAll('circle')
          .attr("r", (d) => {
            return getNodeSize(d, this.currentHologramNodes, this.currentHologramNodeWeights)*this.d3DiagramProperties.nodeSizeThreshold/transform.k 
          });
        
        this.svg.selectAll("text").attr("y", (d) => d.r + 10 )
      }
      

      //let nodeSize = this.d3DiagramProperties.nominalNodeSize;
      
      let link_stroke_width = this.d3DiagramProperties.nominalLinkStrokeWidth
      if (this.d3DiagramProperties.nominalLinkStrokeWidth*transform.k > this.d3DiagramProperties.maxLinkStrokeWidth){
        link_stroke_width = this.d3DiagramProperties.maxLinkStrokeWidth/transform.k
      }
      this.svg.selectAll('line').selectAll(".display-line").attr("stroke-width", link_stroke_width)

      let eventAssistLineStrokeWidth = this.d3DiagramProperties.nominalLinkStrokeWidth * 4
      if (this.d3DiagramProperties.nominalLinkStrokeWidth*transform.k > this.d3DiagramProperties.maxLinkStrokeWidth){
        eventAssistLineStrokeWidth = this.d3DiagramProperties.maxLinkStrokeWidth*4/transform.k
      }
      this.svg.selectAll('line').selectAll(".event-assist-line").attr("stroke-width", eventAssistLineStrokeWidth)
    },
    wrap(text, width) {
      text.each(function() {
        let text = d3.select(this),
          words = text.text(),
          line = [],
          lineHeight = 1.5, // ems
          dy = 1,
          tspan = text.text(null).append("tspan").attr("x", 0).attr("dy", dy + "em");
        for (var i=0; i<words.length; i++){
          line.push(words[i])
          tspan.text(line.join(""));
          if (tspan.node().getComputedTextLength() > width) {
            line = []
            tspan = text.append("tspan").attr("x", 0).attr("dy",lineHeight + "em").text(line.join(""));
          }
        }        
      });
    },
    forceCluster(strength) {
      let nodes;
      let self = this;

      function force(alpha) {
        const centroids = d3.rollup(nodes, self.centroid, (d) => {
          return d.groupID
        });
        const l = alpha * strength;
        for (const d of nodes) {
          const {x: cx, y: cy} = centroids.get(d.groupID);
          d.vx -= (d.x - cx) * l;
          d.vy -= (d.y - cy) * l;
        }
      }

      force.initialize = _ => nodes = _;

      return force;
    },
    centroid(nodes) {
      let x = 0;
      let y = 0;
      let z = 0;
      for (const d of nodes) {
        let k = d.r ** 2;
        x += d.x * k;
        y += d.y * k;
        z += k;
      }
      return {x: x / z, y: y / z};
    },
    forceCollide() {
      const alpha = 0.3; // fixed for greater rigidity!
      const padding1 = 100; // separation between same-color nodes
      const padding2 = 250; // separation between different-color nodes
      let nodes;
      let maxRadius;

      function force() {
        const quadtree = d3.quadtree(nodes, d => d.x, d => d.y);
        for (const d of nodes) {
          const r = d.r + maxRadius;
          const nx1 = d.x - r, ny1 = d.y - r;
          const nx2 = d.x + r, ny2 = d.y + r;
          quadtree.visit((q, x1, y1, x2, y2) => {
            if (!q.length) do {
              if (q.data !== d) {
                const r = d.r + q.data.r + (d.groupID === q.data.groupID ? padding1 : padding2);
                let x = d.x - q.data.x, y = d.y - q.data.y, l = Math.hypot(x, y);
                if (l < r) {
                  l = (l - r) / l * alpha;
                  d.x -= x *= l, d.y -= y *= l;
                  q.data.x += x, q.data.y += y;
                }
              }
            } while ((q = q.next));
            return x1 > nx2 || x2 < nx1 || y1 > ny2 || y2 < ny1;
          });
        }
      }

      force.initialize = _ => maxRadius = d3.max(nodes = _, d => d.r) + Math.max(padding1, padding2);

      return force;
    },
    updateHologramGraphData: function(){
      if (!this.currentHologramDataReady) { return }
      this.graph = JSON.parse(this.currentHologram.graph);
      if ( this.graph ){
        const old = new Map(this.node.data().map(d => [d.id, d]));
        this.graph.nodes = this.graph.nodes.map(d => Object.assign(old.get(d.id) || {}, d));
        this.graph.links = this.graph.links.map(d => Object.assign({}, d));
        this.calculateSizeAndLinkWeight();
        this.updateData(this.graph);
        this.updateNodeText(this.graph);
      }
    }
  },
  beforeUnmount() {
    this.removeAllSubscribeChannel();

    document.removeEventListener("keydown", this.OpenSearchToolboxByKeyBind)
    if (this.simulation){
      this.simulation.stop();
    }
    if (this.svg){
      this.svg.remove();
    }
    if (this.g){
      this.g.remove()
    }
    if (this.node){
      this.node.remove();
    }
    if (this.link){
      this.link.remove();
    }
  },
  computed:{
    currentSessionUserID: function(){
      return this.user.attributes.sub
    },
    currentHologramID: function(){
      return this.$route.params.hologramID
    },
    ...mapGetters({
      user: "auth/user",
      userData: "auth/userData",
      userNodes: "hologram/userNodes",
      currentHologram: "hologram/currentHologram",
      currentHologramNodes: "hologram/currentHologramNodes",
      currentHologramEdges: "hologram/currentHologramEdges",
      currentHologramGroups: "hologram/currentHologramGroups",
      hologramNodeTooltipModalOpen: "modal/hologramNodeTooltipModalOpen",
      editingCurrentHologramPermission: "hologram/editingCurrentHologramPermission",
      currentHologramDataReady: "hologram/currentHologramDataReady",
      hologramEntityDetailModalOpen: "modal/hologramEntityDetailModalOpen",
      renderHologramGraph: "hologram/renderHologramGraph",
      nodeContextMenuOpen: "modal/nodeContextMenuOpen",
      connectionSetUpModeOpen: "hologram/connectionSetUpModeOpen",
      connectionStartNodeID: "hologram/connectionStartNodeID",
      connectionEndNodeID: "hologram/connectionEndNodeID",
      renderHologramNodeColor: "hologram/renderHologramNodeColor",
      renderHologramGraphDataOnly: "hologram/renderHologramGraphDataOnly",
      deleteNodeModalOpen: "modal/deleteNodeModalOpen",
      displayCluster: "hologram/displayCluster",
      deleteNodeNotification: "modal/deleteNodeNotification",
      hologramOperationNotification: "notification/hologramOperationNotification",
      hologramsBelongedToCurrentUser: "hologram/hologramsBelongedToCurrentUser",
      currentHologramNodeWeights: "weight/currentHologramNodeWeights",
      weightChange: "weight/weightChange",
      deleteModeOpen: "hologram/deleteModeOpen",
      deleteEdgeModalOpen: "modal/deleteEdgeModalOpen",
      hologramTitleEditorModalOpen: "modal/hologramTitleEditorModalOpen",
      hologramSharingModalOpen: "modal/hologramSharingModalOpen",
      hologramSettingsModalOpen: "modal/hologramSettingsModalOpen",
      displayColorMode: "hologram/displayColorMode",
      hologramControlPanelInputOpen: "modal/hologramControlPanelInputOpen",
      hologramSidebarOpen: "sidebar/hologramSidebarOpen",
      sidebarOpen: "sidebar/sidebarOpen",
      transactingStatus: "hologram/transactingStatus",
    })
  },
  watch:{
    $route: async function(to, from){
      if (to.name === "Hologram" && from.name === "Hologram" && to.params.hologramID !== from.params.hologramID ){
        const index = this.hologramsBelongedToCurrentUser.findIndex( e => e.id === to.params.hologramID);
        const currentHologram = this.hologramsBelongedToCurrentUser[`${index}`];
        const accessPolicy = {
          id: to.params.hologramID,
          accessPolicy: currentHologram.accessPolicy,
          createdByID: currentHologram.createdBy.id,
        }
        await getHologramData(this.$store.dispatch, this.user, accessPolicy, to.params.hologramID)
        if (currentHologram.graph){this.graph = JSON.parse(currentHologram.graph);}
      }
    },
    
    hologramSidebarOpen: function(newValue){
      if ( !newValue ){
        this.d3DiagramProperties.maxTextSize = 24;
        return;
      }
      this.d3DiagramProperties.maxTextSize = 36;
      /*
      this.$nextTick(function(){
        this.width = +this.svg.node().getBoundingClientRect().width;
        this.height = +this.svg.node().getBoundingClientRect().height;
        this.svg.attr("viewBox", `0 0 ${this.width*2} ${this.height*2}`)
        console.log(this.width, this.height)
      })
      */
    },
    
    displayColorMode: function(){
      this.updateNodeColor()
    },
    renderHologramGraph:{
      deep:true,
      handler: function(newValue){
        if (!newValue) { return; }
        if (!this.currentHologramDataReady) { return }

        this.updateHologramGraphData();
        this.startSimulation();
        this.updateDisplay();
        this.updateForce();
        this.resetTickedTimer();

        this.simulation.force("charge")
          .strength( d => this.customChargeForceStrength(d));

        this.pullGraphToCenterThenRelease()
        
        this.$store.dispatch("hologram/updateRenderHologramGraph", {data:false});    
      }
    },
    renderHologramGraphDataOnly: function(newValue){
      if ( !newValue ) return;
      if ( !this.currentHologramDataReady ) return;
      this.updateHologramGraphData();
      this.calculateSizeAndLinkWeight();
      this.updateDisplay();
      this.$store.dispatch("hologram/updateRenderHologramGraphDataOnly", {data:false});
    },
    renderHologramNodeColor: function(newValue){
      if ( !newValue ){ return }
      this.updateHologramGraphData();
      this.updateNodeColor();
      this.$store.dispatch("hologram/updateRenderHologramNodeColor", {data:false})
    },
    displayCluster: function(newValue){
      this.reheatSimulation()
      if (newValue === true){
        this.simulation.force("defaultCollide", null);
        this.simulation.force("clusterCollide", this.forceCollide());
        this.simulation.force("cluster", this.forceCluster(0.5));
        
        this.simulation.force("xForce")
          .strength(0.1)

        this.simulation.force("yForce")
          .strength(0.1)

        this.simulation.force("link")
          .strength(0)
        

        this.updateLinkDisplayOpacity(0.25) // As same as mouseEnterEdge 
        this.resetTickedTimer();
        return;
      }

      this.updateLinkDisplayOpacity(1)

      this.simulation.force("xForce")
          .strength(this.d3DiagramProperties.xForce.strength);

      this.simulation.force("yForce")
          .strength(this.d3DiagramProperties.yForce.strength)

      this.simulation.force("link")
        .strength(this.d3DiagramProperties.linkForce.strength)

      this.simulation.force("cluster", null);
      this.simulation.force("clusterCollide", null)
      this.simulation.force("defaultCollide", d3.forceCollide())
      this.$store.dispatch("hologram/updateDisplayCluster", {data:false})
      this.resetTickedTimer();
    },
    deleteNodeNotification:{
      deep:true,
      handler: function(newValue){
        if ( Object.keys(newValue).length !== 0 ){
          if ( newValue.info == "Success" ){
            this.toast(`Sucessfully delete node`, {
              type: TYPE.SUCCESS,
              timeout: 2000,
              toastClassName: "hologram-toast",
              closeButton: false
            })
            this.$store.dispatch('modal/updateDeleteNodeNotification', {})
            return;
          } 

          if ( newValue.info == "Failed" ){
            this.toast(`Failed to delete node`, {
              type: TYPE.ERROR,
              timeout: 2000,
              toastClassName: "hologram-toast",
              closeButton: false
            })
            this.$store.dispatch('modal/updateDeleteNodeNotification', {})
          }
          
          
        }
      }
    },
    hologramOperationNotification: {
      deep: true,
      handler: function(newValue){
        if ( Object.keys(newValue).length === 0 ) return;
        const {type, location} = newValue;
        switch (location) {
          case "nodeCreation":{
            if ( type === "duplicated" ){
              this.toast(`Duplicated node`, {
                type: TYPE.ERROR,
                timeout: 2000,
                toastClassName: "hologram-toast",
                closeButton: false
              })
            }
            break;
          }
          case "linkCreation":{
            if ( type === "duplicated" ){
              this.toast(`Duplicated link`, {
                type: TYPE.ERROR,
                timeout: 2000,
                toastClassName: "hologram-toast",
                closeButton: false
              })
            }
            break;
          }
          case "nodeDeletion":{
            this.toast(`Sucessfully delete node`, {
              type: TYPE.SUCCESS,
              timeout: 3000,
              toastClassName: "hologram-toast",
              closeButton: false
            })
            break;
          }
          case "edgeDeletion":{
            this.toast(`Sucessfully delete connection`, {
              type: TYPE.SUCCESS,
              timeout: 3000,
              toastClassName: "hologram-toast",
              closeButton: false
            })
            break;
          }
        }
        this.$store.dispatch("notification/updateHologramOperationNotification", {type:"reset"})

      }
    },
    weightChange: function(newValue){
      if (newValue === true){
        this.updateDisplay();
        this.$store.dispatch("weight/updateWeightChange", {data:false})
      }
    }
  }
};

/**
 * @param {object} store - vuex store dispatch function
 * @param {object} user - user object
 * @param {object} access {accessPolicy, createdBy}
 * @param {id} hologramID 
 * return none
 */

const getHologramData = async (dispatch, user, access, hologramID) => {
  try {
    if (user && access){
      if (access.createdByID === user.attributes.sub ){
        await dispatch("hologram/superChargedGetHologram", { "type":"privateAccess", "hologramID":hologramID })
        const result = await dispatch("hologram/getUserNodes", {userID:user.attributes.sub, nextToken:null, limit:10});
        dispatch("hologram/setUserNodes", {data:result});
        dispatch("hologram/updateCurrentHologramDataReady", {data:true});
        gaHelpers.navigation("to_owned_hologram", "social");
      } else {
        await dispatch("hologram/superChargedGetHologram", { "type":"publicAccess", "hologramID":hologramID })
        dispatch("hologram/updateCurrentHologramDataReady", {data:true});
        gaHelpers.navigation("to_others_hologram", "social");
      }
    } else {
      await dispatch("hologram/superChargedGetHologram", { "type":"publicAccess", "hologramID":hologramID })
      dispatch("hologram/updateCurrentHologramDataReady", {data:true});
      gaHelpers.navigation("outside_view_public_hologram", "growth");
    }
  } catch(err){
    console.log("Something went wrong when fetching data for composing Hologram", err)
  }
}

/**
 * @param {object} node d3 node object
 * @param {list} hologramNodes list of hologramNode
 * @param {list} hologramNodeWeights list of weights belong to this hologram
 * @param {int} miniumWeight
 * @param {int} maximumWeight
 */

const getNodeSize = (d, hologramNodes, hologramNodeWeights) => { // eslint-disable-line no-unused-vars
  
  const hologramNodeIndex = hologramNodes.findIndex( e => e.nodeID === d.id );

  if ( hologramNodeIndex === -1 ){
    console.error("Can't find target hologramNode")
    return 5
  }

  const hologramNode = hologramNodes[`${hologramNodeIndex}`]
  const weightIndex = hologramNodeWeights.findIndex( e => e.hologramNodeID === hologramNode.id );

  if ( weightIndex === -1 ){
    console.error("Can't find target hologramNode's weight")
    return 0
  }

  const weight = hologramNodeWeights[`${weightIndex}`];
  const nodeSize = 6 + weight.value*2.5;
  if (nodeSize > 50){
    return 50;
  }
  return nodeSize
}

/**
 * @param {string} method public, private
 * @param {id} hologramID
 * @returns hologram object {id, accessPolicy, title}
 */

const getHologramAccessPolicy = async function(method, hologramID){
  try {
    let currentHologramAccessPolicy;
    if ( method === "public"){
      currentHologramAccessPolicy = await API.graphql({
        query: getHologramAccessPolicyQuery,
        variables: {id:hologramID},
        authMode: "AWS_IAM"
      })
    } else {
      currentHologramAccessPolicy = await API.graphql(graphqlOperation(getHologramQuery, {id: hologramID}));
    }
    return Promise.resolve(currentHologramAccessPolicy.data.getHologram);
  } catch(err){
    return Promise.reject(err);
  }
}

</script>
<style>
  .Vue-Toastification__toast--error.hologram-toast {
    background-color:#586e75;
    color: #eee8d5;
  }
  .Vue-Toastification__toast--success.hologram-toast {
    background-color:#586e75;
    color: #eee8d5;
  }
</style>