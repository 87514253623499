<template>
  <div
    class="fixed inset-0 z-20 h-full"
    v-if="open"
  >
    <div
      class="flex items-end justify-center h-full min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0"
      id="modal-container"
    >
      <transition
        enter-active-class="duration-300 ease-out"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          @click="toggleModal"
          class="fixed inset-0"
        >
          <div class="absolute inset-0"></div>
        </div>
      </transition>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
      >&#8203;</span>
      <transition
        name="modal-transition"
        enter-active-class="duration-300 ease-out"
        enter-from-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
        enter-to-class="translate-y-0 opacity-100 sm:scale-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="translate-y-0 opacity-100 sm:scale-100"
        leave-to-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
      >
        <div
          class="fixed flex flex-col overflow-y-auto text-left align-bottom transition-all transform border rounded-md shadow-xl max-h-72 bg-sd-base02-black border-sd-base01-brgreen"
          role="dialog"
          aria-modal="true"
          id="single-select-options"
          :style="modalPositionAndWidth"
          @keydown.down="hoverNextOption"
          @keydown.up="hoverPreviousOption"
          @keydown.enter="enterHandler"
        >
          <div class="flex flex-row h-10 px-2 border-b border-opacity-50 border-sd-base01-brgreen">
            <div
              v-if="data.selectedOption && data.selectedOption.group"
              class="flex flex-row h-5 px-2 my-auto mr-3 rounded-sm"
              :class="selectedGroupColor"
            >
              <div class="my-auto mr-2 font-sans text-sm">
                {{ truncateSelectedGroupTitle }}
              </div>
              <button
                class="flex focus:outline-none"
                @click="cancelOptionHandler"
              >
                <iconX
                  class="m-auto hover:opacity-50"
                  :size="5"
                />
              </button>
            </div>
            <input
              type="text"
              class="flex flex-grow h-5 my-auto font-sans text-sm border-0 bg-sd-base02-black focus:border-0 focus:outline-none ring-0 focus:ring-0 text-sd-base1-brcyan"
              v-model="searchTerm"
              id="single-selection-searchTerm"
              placeholder="Search for a option or create one"
            >
          </div>

          <div class="flex flex-col flex-grow h-48 mt-2 overflow-y-scroll">
            <div
              v-for="option in filteredOptions"
              v-bind:key="option.id"
              class="flex focus:outline-none group"
              tabindex="1"
              :class="getHoverStyle(option.id)"
              :id="option.id"
              @mouseenter="hoverNode(option.id)"
            >
              <button
                v-if="option.id === 'create-new-option'"
                class="px-4 py-1.5 w-full focus:outline-none"
                @click="clickHandler(option.id)"
              >
                <div class="flex flex-row">
                  <div class="mr-2 font-sans text-sm text-sd-base1-brcyan">
                    Create
                  </div>
                  <div class="h-5 px-2 font-sans text-sm rounded-sm bg-sd-base1-brcyan text-sd-base02-black">
                    {{ searchTerm }}
                  </div>
                </div>
              </button>
              <div
                v-else
                class="flex flex-row flex-grow my-auto"
              >
                <button
                  class="flex my-auto ml-4 py-1.5 flex-grow focus:outline-none mr-auto font-sans text-sm text-sd-base1-brcyan"
                  @click="clickHandler(option.id)"
                >
                  <div
                    class="flex w-3 h-3 my-auto mr-3 rounded-sm"
                    :class="getOptionsColor(option)"
                  >

                  </div>
                  <div>
                    {{ option.group.title }}
                  </div>

                </button>
                <button
                  class="flex px-0.5 ml-auto my-1.5 mr-4 focus:outline-none hover:bg-sd-base2-white hover:bg-opacity-5 rounded-sm"
                  @click="toggleOptionToolbox($event, option)"
                >
                  <iconThreeDots
                    class="m-auto group-hover:text-sd-base0-brblue text-sd-base02-black"
                    :size="4"
                  />
                </button>
              </div>
            </div>
          </div>
          <div class="flex border-t border-opacity-50 border-sd-base01-brgreen">
            <keybindingExplanation class="my-auto" />
          </div>
        </div>
      </transition>
    </div>
    <teleport to="#modals">
      <div v-if="singleSelectionOptionToolboxOpen">
        <singleSelectionOptionToolbox
          :open="singleSelectionOptionToolboxOpen"
          @toggle-option-toolbox="catchToggleOptionToolboxEvent"
          :position="singleSelectionOptionToolboxPosition"
          :confirmGroupDeletionModalOpen="confirmGroupDeletionModalOpen"
          :option="openToolboxOption"
          @change-color="changeColorHandler"
          @delete-option="deleteOptionHandler"
          @change-option-name="changeOptionNameHandler"
        />
      </div>
    </teleport>
  </div>
</template>

<script>
import _ from "lodash";
import keybindingExplanation from "../accessibility/keybindingExplanation.vue";
import singleSelectionOptionToolbox from "../input/singleSelectionOptionToolbox.vue";
import iconX from "../icon/iconX.vue";
import iconThreeDots from "../icon/iconThreeDots.vue";
import { colors } from "../../assets/colors";
import { gaHelpers } from "../../analytics/helper";
import { truncateString } from "../../utilities/utilities";

import { computed, ref, onMounted, nextTick, watch, onUnmounted } from "vue";
//import { useStore } from "vuex"
export default {
  name: "singleSelectOptions",
  components: {
    iconX,
    iconThreeDots,
    keybindingExplanation,
    singleSelectionOptionToolbox,
  },
  props: {
    open: {
      type: Boolean,
      require: true,
    },
    data: {
      type: Object,
      require: true,
    },
    position: {
      type: Object,
      require: true,
    },
    confirmGroupDeletionModalOpen: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["changeColor", "deleteOption", "toggleSingleSelectionOptions", "updateSingleSelectionOptionsAction", "changeOptionName"],
  setup(props, context) {
    // eslint-disable-line no-unused-vars
    const searchTerm = ref("");
    const singleSelectionOptionToolboxOpen = ref(false);
    const singleSelectionOptionToolboxPosition = ref({});
    const modalPositionAndWidth = ref(null);
    const hoveredOptionID = ref(null);
    const preventMouseEnter = ref(false);
    const openToolboxOption = ref({});
    const selectedGroupColor = ref(null);
    const truncateSelectedGroupTitle = computed(() => {
      if (props.data.selectedOption.group) {
        return truncateString(props.data.selectedOption.group.title, 6);
      } else {
        return null
      }
    })

    const filteredOptions = computed(() => {
      let result = [];
      let exactMatch = false;
      result = props.data.options.filter((element) => {
        if (element) {
          if (element.group.title === searchTerm.value) {
            exactMatch = true;
          }

          const filter = element.group.title.toLowerCase().includes(searchTerm.value.toLowerCase());
          if (!filter) {
            return false;
          } else {
            return true;
          }
        }
      });

      if (!exactMatch && searchTerm.value !== "") {
        const createNewOption = {
          id: "create-new-option",
          title: searchTerm.value,
        };
        result.push(createNewOption);
      }
      return result;
    });

    const prepare = () => {
      modalPositionAndWidth.value = {
        left: props.position.x + "px",
        top: props.position.y + "px",
        width: props.position.width + "px",
      };
      selectedGroupColor.value = getSelectedGroupColor(props.data.selectedOption.color);
      window.addEventListener("keydown", keydownEscHandler);
      nextTick(() => {
        const element = document.getElementById("single-selection-searchTerm");
        element.focus();
        if (filteredOptions.value.length > 0) {
          hoveredOptionID.value = filteredOptions.value[0].id;
        }
      });
      console.log(props.data);
    };

    const keydownEscHandler = (event) => {
      if (event.code !== "Escape") return;
      if (singleSelectionOptionToolboxOpen.value) return;
      if (props.confirmGroupDeletionModalOpen) return;
      if (!props.open) return;
      toggleModal();
    };

    onMounted(prepare);

    const toggleModal = () => {
      if (props.open) {
        context.emit("toggleSingleSelectionOptions", false);
      }
    };

    const hoverNode = (optionID) => {
      if (preventMouseEnter.value) {
        preventMouseEnter.value = false;
        return;
      }

      hoveredOptionID.value = optionID;
    };

    const changeColorHandler = (message) => {
      context.emit("changeColor", message);
    };

    const getSelectedGroupColor = (colorName) => {
      const colorIndex = colors.findIndex((e) => e.name === colorName);
      if (colorIndex !== -1) {
        const color = colors[`${colorIndex}`];
        return `${color.bgCode} ${color.textCode}`;
      } else {
        return "bg-sd-base1-brcyan text-sd-base02-black";
      }
    };

    const getOptionsColor = (option) => {
      const colorIndex = colors.findIndex((e) => e.name === option.color);
      if (colorIndex !== -1) {
        const color = colors[`${colorIndex}`];
        return `${color.bgCode} ${color.textCode}`;
      } else {
        return "bg-sd-base1-brcyan text-sd-base02-black";
      }
    };

    const getHoverStyle = (optionID) => {
      if (hoveredOptionID.value === optionID) {
        return "bg-sd-base2-white bg-opacity-5";
      }
    };

    const hoverNextOption = () => {
      const index = filteredOptions.value.findIndex((e) => e.id === hoveredOptionID.value);
      if (index + 2 > filteredOptions.value.length) return;
      hoveredOptionID.value = filteredOptions.value[`${index + 1}`].id;
    };

    const hoverPreviousOption = () => {
      const index = filteredOptions.value.findIndex((e) => e.id === hoveredOptionID.value);
      if (index - 1 < 0) return;
      hoveredOptionID.value = filteredOptions.value[`${index - 1}`].id;
    };

    const checkVisible = (element) => {
      // 48 - 12rem from top of options modal to options button
      // 240 - 60 rem options modal height
      // 10 buffer
      var rect = element.getBoundingClientRect();
      return !(rect.bottom <= props.position.y + 48 + 10 || rect.top > props.position.y + 240 - 10);
    };

    const toggleOptionToolbox = ($event, option) => {
      if (!singleSelectionOptionToolboxOpen.value) {
        openToolboxOption.value = option;
        const position = getElementPosition($event.currentTarget);
        singleSelectionOptionToolboxOpen.value = true;
        singleSelectionOptionToolboxPosition.value = position;
        gaHelpers.interaction("open_group_option_toolbox", "group");
      } else {
        const position = {};
        singleSelectionOptionToolboxOpen.value = false;
        singleSelectionOptionToolboxPosition.value = position;
        gaHelpers.interaction("close_group_option_toolbox", "group");
      }
    };
    const catchToggleOptionToolboxEvent = (message) => {
      const open = message;
      if (!open) {
        const position = {};
        singleSelectionOptionToolboxOpen.value = false;
        singleSelectionOptionToolboxPosition.value = position;
      }
    };

    const enterHandler = ($event) => {
      if ($event.isComposing) {
        return;
      }

      if (!hoveredOptionID.value) {
        return;
      }

      if (filteredOptions.value.length === 0) {
        return;
      }

      if (hoveredOptionID.value === "create-new-option") {
        const action = {
          action: "create",
          option: searchTerm.value,
        };
        context.emit("updateSingleSelectionOptionsAction", action);
        toggleModal();
        return;
      }

      const index = props.data.options.findIndex((e) => e.id === hoveredOptionID.value);
      const selectedOption = props.data.options[`${index}`];

      const temp = props.data;
      temp.selectedOption = selectedOption;

      const action = {
        action: "select",
        option: selectedOption,
      };
      context.emit("updateSingleSelectionOptionsAction", action);
      toggleModal();
    };

    const clickHandler = (optionID) => {
      if (optionID === "create-new-option") {
        const action = {
          action: "create",
          option: searchTerm.value,
        };
        context.emit("updateSingleSelectionOptionsAction", action);
        toggleModal();
        return;
      }

      const index = props.data.options.findIndex((e) => e.id === optionID);
      const selectedOption = props.data.options[`${index}`];

      const temp = props.data;
      temp.selectedOption = selectedOption;

      const action = {
        action: "select",
        option: selectedOption,
      };
      context.emit("updateSingleSelectionOptionsAction", action);
      toggleModal();
    };

    const cancelOptionHandler = () => {
      const action = {
        action: "remove",
        option: props.data.selectedOption,
      };
      context.emit("updateSingleSelectionOptionsAction", action);
      const element = document.getElementById("single-selection-searchTerm");
      element.focus();
    };

    const deleteOptionHandler = (message) => {
      context.emit("deleteOption", message);
    };

    const changeOptionNameHandler = (message) => {
      context.emit("changeOptionName", message);
    };

    watch(searchTerm, (newValue) => {
      if (newValue && filteredOptions.value[0].id === "create-new-option") {
        hoveredOptionID.value = "create-new-option";
      }
    });

    watch(
      () => _.cloneDeep(props.data),
      (newValue) => {
        selectedGroupColor.value = getSelectedGroupColor(newValue.selectedOption.color);
      }
    );

    watch(hoveredOptionID, (newValue) => {
      if (!newValue || newValue === "") {
        return;
      }

      if (newValue === "create-new-option") {
        return;
      }

      const scrollIntoViewOptions = {
        behavior: "smooth",
        block: "nearest",
      };

      const element = document.getElementById(`${newValue}`);
      const visible = checkVisible(element);
      if (!visible) {
        element.scrollIntoView(scrollIntoViewOptions);
        preventMouseEnter.value = true;
      }
    });

    const clear = () => {
      window.removeEventListener("keydown", keydownEscHandler);
    };

    onUnmounted(clear);

    return {
      searchTerm,
      hoveredOptionID,
      filteredOptions,
      toggleModal,
      hoverNode,
      clickHandler,
      enterHandler,
      cancelOptionHandler,
      deleteOptionHandler,
      getHoverStyle,
      hoverNextOption,
      hoverPreviousOption,
      modalPositionAndWidth,
      toggleOptionToolbox,
      singleSelectionOptionToolboxOpen,
      singleSelectionOptionToolboxPosition,
      openToolboxOption,
      changeColorHandler,
      changeOptionNameHandler,
      catchToggleOptionToolboxEvent,
      selectedGroupColor,
      getOptionsColor,
      truncateSelectedGroupTitle,
    };
  },
};

const getElementPosition = (element) => {
  const getPosition = element.getBoundingClientRect();
  const result = {
    x: getPosition.x,
    y: getPosition.y,
    width: getPosition.width,
    height: getPosition.height,
  };
  return result;
};
</script>

<style></style>