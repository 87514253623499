<template>
  <div class="min-w-full min-h-screen"
    v-if="!fetchingQuestionnaire"
  >
    <router-view/>
    <div
      class="fixed bottom-0 left-0 flex w-full h-16 questionnaire-footer"
      v-if="showQuestionnaireFooter"
    >
      <div
        class="flex flex-row h-full my-auto ml-auto mr-4"
      >
        <div
          v-if="submiting"
          class="my-auto mr-3"
        >
          <svg class="w-5 h-5 animate-spin text-sd-base0-brblue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
        <button
          class="flex flex-row items-center justify-center w-24 h-8 my-auto mr-4 font-sans text-base font-medium border rounded-sm text-sd-base2-white hover:bg-sd-base02-black border-sd-base00-bryellow focus:outline-none"
          v-if="questionnaireIsFinished"
          :disabled="!questionnaireIsFinished"
          @click="submitHandler"
        >
          Submit
        </button>
        <div
          v-for="question in questions"
          v-bind:key="question.id"
          class="flex flex-row my-auto"
        >
          <button
            class="h-2 mr-1 border rounded-full border-sd-base0-brblue focus:outline-none"
            :style="getProggressionBarStyle(question.id)"
            :class="getProggressionBarClass(question.id)"
            @click="progressionBarClickHandler(question.id)"
          ></button>
        </div>
        <div
          class="my-auto ml-4"
        >
          <button
            class="p-2 focus:outline-none hover:bg-sd-base02-black rounded-l-md"
            @click="previousQuestionHandler"
          >
            <svg xmlns="http://www.w3.org/2000/svg" class="flex w-5 h-5 m-auto fill-current text-sd-base0-brblue" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
            </svg>
          </button>
          <button
            class="p-2 focus:outline-none hover:bg-sd-base02-black rounded-r-md"
            @click="nextQuestionHandler"
          >
            <svg xmlns="http://www.w3.org/2000/svg" class="flex w-5 h-5 m-auto fill-current text-sd-base0-brblue" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="flex w-full h-full bg-sd-base03-brblack"
  >
    <div
      class="m-auto"
    >
      <svg class="w-6 h-6 animate-spin text-sd-base0-brblue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { API } from "aws-amplify";
import { createUserQuestionnaire as createUserQuestionnaireMutation } from "../../graphql/mutations"
export default {
  name:"QuestionnaireBase",
  data() {
    return {
      progressiongBarWidth: 240,
      fetchingQuestionnaire: true,
      submiting: false,
    }
  },
  async mounted() {
    window.document.title = "Totuslink - Questionnaire"
    await this.$store.dispatch("questionnaire/getQuestionnaire", {data:"user-onboarding-questionnaire-v1"})
    await this.$store.dispatch("questionnaire/getQuestions", {data:"user-onboarding-questionnaire-v1"})
    this.fetchingQuestionnaire = false;
  },
  methods: {
    async submitHandler(){
      if (!this.questionnaireIsFinished){
        return;
      }
      try {
        this.submiting = true;
        await uploadUserQuestionnaireAnswer(this.questionnaire.id, this.userData.id, this.answers)
        await this.$store.dispatch("auth/checkOnboardingQuestionnaireStauts", this.userData.id);
        this.submiting = false;
        this.$router.push({name:"QuestionnaireEnding"})
      } catch(err){
        this.submiting = false;
        console.log("Something went wrong when try to submit questionnaire result");
      }
    },
    nextQuestionHandler(){
      const index = this.questionnaire.questionsOrder.findIndex( e => e === this.currentQuestionID);
      if ( index+1 <= this.questionnaire.questionsOrder.length -1 ){
        this.$store.dispatch("questionnaire/updateCurrentQuestionID",{data:this.questionnaire.questionsOrder[`${index+1}`]})
      }
      
    },
    previousQuestionHandler(){
      const index = this.questionnaire.questionsOrder.findIndex( e => e === this.currentQuestionID);
      if ( index !== 0){
        this.$store.dispatch("questionnaire/updateCurrentQuestionID",{data:this.questionnaire.questionsOrder[`${index-1}`]})
      }
    },
    getProggressionBarStyle(questionID){
      const singleSlotWidth = this.progressiongBarWidth/10
      const answeredIndex = this.answeredQuestions.findIndex( e => e === questionID);
      if (answeredIndex !== -1){
        return {
          "width": `${singleSlotWidth}px`,
          "background-color": "#839496"
        }
      } else {
        return {
          "width": `${singleSlotWidth}px`,
        }
      }      
    },
    getProggressionBarClass(questionID){
      if (this.currentQuestionID === questionID){
        return "ring-1 ring-sd-base01-brgreen"
      }
    },
    progressionBarClickHandler(questionID){
      const scrollIntoViewOptions = { // eslint-disable-line no-unused-vars
        alignToTop: true,
        behavior: "smooth",
      }
      const targetElement = document.getElementById(`${questionID}`);
      this.$store.dispatch("questionnaire/updateCurrentQuestionID", {data:questionID})
      targetElement.scrollIntoView(scrollIntoViewOptions);
    }
  },
  computed: {
    ...mapGetters({
      userData: "auth/userData",
      currentQuestionID: "questionnaire/currentQuestionID",
      answeredQuestions: "questionnaire/answeredQuestions",
      questionsSum: "questionnaire/questionsSum",
      questions: "questionnaire/questions",
      answers: "questionnaire/answers",
      questionnaireIsFinished: "questionnaire/questionnaireIsFinished",
      questionnaire: "questionnaire/questionnaire",
    }),
    showQuestionnaireFooter: function(){
      return this.$route.meta.showQuestionnaireFooter;
    }
  },
  watch: {
    currentQuestionID: function(newValue, oldValue){
      if (oldValue){ // To prevent some non-exist error when we first time setup currentQuestionID 
        const scrollIntoViewOptions = { // eslint-disable-line no-unused-vars
          alignToTop: true,
          behavior: "smooth",
        }
        const targetElement = document.getElementById(`${newValue}`);
        console.log(targetElement, this.questions, newValue);
        targetElement.scrollIntoView(scrollIntoViewOptions);
      }
    },
    answeredQuestions: {
      deep: true,
      handler: function(){
        console.log(this.answeredQuestions.length,this.questionsSum-1 )
        if (this.answeredQuestions.length === this.questionsSum){ 
          this.$store.dispatch("questionnaire/updateQuestionnaireIsFinished", {data:true})
        } else {
          this.$store.dispatch("questionnaire/updateQuestionnaireIsFinished", {data:false})
        }
      }
    }
  },
};
const uploadUserQuestionnaireAnswer = async function(questionnaireID, userID, answer,){
  const jsonifyAnswer = JSON.stringify(answer)
  console.log(questionnaireID, userID)
  try {
    const newUserQuestionnaire = {
      userID: userID,
      questionnaireID: questionnaireID,
      isFinished: true,
      answer: jsonifyAnswer,
    }
    const createResult = await API.graphql({ 
      query: createUserQuestionnaireMutation,
      variables: {input:newUserQuestionnaire},
      authMode: "AWS_IAM"
    });
    return Promise.resolve(createResult.data.createUserQuestionnaire)
  } catch(err){
    return Promise.reject(err)
  }
}

</script>
<style>
</style>