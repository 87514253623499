<template>
  <div
    class="flex flex-col mx-auto overflow-y-auto rounded-md shadow-2xl w-124"
    v-show="searchNodeToolboxOpen && !deleteModeOpen && !connectionSetUpModeOpen"
    v-bind:class="getActivateStyle()"
  >
    <div
      v-if="searchToolboxStatus === 'Fetching'"
      class="flex flex-grow m-auto h-72"
    >
      <svg class="w-5 h-5 m-auto animate-spin text-sd-base0-brblue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div>
    <div
      v-else
      class="flex flex-col overflow-y-auto h-72"
    >
      <div
        class="flex flex-col flex-grow"
      >
        <div
          v-for="node in searchToolboxFilteredNode"
          v-bind:key="node.result.id"
          class="flex flex-col w-full focus:outline-none"
          :id="node.result.id"
          @click="searchToolboxClickHandler(node.result, $event)"
          tabindex="0"
        >
          <button
            v-if="node.status === 'notExist'"
            class="flex flex-row px-4 py-3 font-sans text-sm text-left focus:outline-none"
            v-bind:class="getHoverStyle(node.result.id)"
            @mouseenter="selectNode($event, node.result.id)"
          >
            <div
              class="mr-auto text-sm text-left text-sd-base2-white" 
            >
              {{node.result.title}}
            </div>
          </button>
          <button
            v-else-if="node.status === 'exist'"
            class="px-4 py-3 font-sans cursor-not-allowed focus:outline-none"
            disabled
          >
            <div
              class="flex flex-row text-sm text-left text-sd-base0-brblue"
            >
              <div>
                {{node.result.title}}
              </div>
              <div
                class="flex items-center px-2 my-auto ml-auto rounded-md bg-sd-yellow text-sd-base03-brblack"
              >
                exist
              </div>
            </div>
          </button>
          <button
            v-else-if="node.status === 'error'"
            class="px-4 py-3 text-sm text-left focus:outline-none text-sd-base2-white"
            disabled
          >
            Something went wrong, would you please email this url to us (eric525282@gmail.com)
          </button>
        </div>
      </div>
    </div>
    
    
    <div
      class="relative bottom-0 flex flex-row px-4 py-2 border-t border-opacity-50 border-sd-base01-brgreen"
    >
      <div
        class="flex flex-row mr-4"
      >
        <div
          class="p-1 mr-2 font-sans text-xs border rounded-lg shadow-2xl border-sd-base01-brgreen text-sd-base0-brblue"
        >
          esc
        </div>
        <div
          class="my-auto font-sans text-xs text-sd-base0-brblue"
        >
          to close
        </div>
      </div>
      <div
        class="flex flex-row mr-4"
      >
        <div
          class="flex p-1 mr-2 border rounded-lg shadow-2xl border-sd-base01-brgreen"
        >
          <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 m-auto fill-current text-sd-base00-bryellow" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z"/>
          </svg>
        </div>
        <div
          class="flex p-1 mr-2 border rounded-lg shadow-2xl border-sd-base01-brgreen"
        >
          <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 m-auto fill-current text-sd-base00-bryellow" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"/>
          </svg>
        </div>
        <div
          class="my-auto font-sans text-xs text-sd-base0-brblue"
        >
          to navigate
        </div>
      </div>
      <div
        class="flex flex-row mr-4"
      >
        <div
          class="p-1 mr-2 font-sans text-xs border rounded-lg shadow-2xl border-sd-base01-brgreen text-sd-base0-brblue"
        >
          enter
        </div>
        <div
          class="my-auto font-sans text-xs text-sd-base0-brblue"
        >
          to create
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { queryCorsContent } from "../../graphql/queries"
import { createIssue as createIssueMutation } from "../../graphql/mutations"
import { API, graphqlOperation } from 'aws-amplify'
import { mapGetters } from 'vuex';
import { gaHelpers } from "@/analytics/helper.js"
export default {
  name: "searchNodeToolbox",
  data() {
    return {
      filteredNodes: [],
      searchToolboxStatus: null,
      activated: false,
      preventMouseEnter: false,
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      userData: "auth/userData",
      searchToolboxInput: "hologram/searchToolboxInput",
      searchNodeToolboxOpen: "hologram/searchNodeToolboxOpen",
      currentHologramNodes: "hologram/currentHologramNodes",
      currentHologram: "hologram/currentHologram",
      userNodes: "hologram/userNodes",
      deleteModeOpen: "hologram/deleteModeOpen",
      connectionSetUpModeOpen: "hologram/connectionSetUpModeOpen",
      searchToolboxSelectedNodeID: "modal/searchToolboxSelectedNodeID",
      searchToolboxFilteredNode: "modal/searchToolboxFilteredNode",
    })
  },
  methods:{
    filterOrFetch: async function(){
      let filteredNodes = []
      const urlObject = getUrlObject(this.searchToolboxInput);
      if (urlObject === "invalidUrl"){
        const nodesByTitle = filterNodesByTitle(this.userNodes, this.searchToolboxInput);
        const nodesByUrl = filterNodesByUrl(this.userNodes, this.searchToolboxInput);
        nodesByTitle.forEach( element => {
          const index = this.currentHologramNodes.findIndex( e => e.nodeID === element.id)
          if (index !== -1){
            const result = {
              "status": "exist",
              "result": element
            }
            filteredNodes.splice(filteredNodes.length, 0, result)
          } else {
            const result = {
              "status": "notExist",
              "result": element
            }
            filteredNodes.splice(0, 0, result)
          }
        })
        nodesByUrl.forEach( element => {
          const index = filteredNodes.findIndex( e => e.result.id === element.id );
          if (index === -1){
            const hologramNodeIndex = this.currentHologramNodes.findIndex( e => e.nodeID === element.id)
            if (hologramNodeIndex !== -1){
              const result = {
                "status": "exist",
                "result": element
              }
              filteredNodes.splice(filteredNodes.length, 0, result)
            } else {
              const result = {
                "status": "notExist",
                "result": element
              }
              filteredNodes.splice(0, 0, result)
            }
          }
        });
        this.$store.dispatch("modal/updateSearchToolboxFilteredNode", {data:filteredNodes});
        return;
      }
      
      const nodesByUrl = filterNodesByUrl(this.userNodes, this.searchToolboxInput);

      if (nodesByUrl.length === 0){
        let tempNode = {};
        tempNode = { 
          status: "searching",
          result: {
            id: 'temp-node-searching',
            content: "Searching new url",
            url: ''
          } 
        }
        filteredNodes.splice(0, 0, tempNode);
        this.searchToolboxStatus = "Fetching";
        try {
          const response = await getTitle(this.searchToolboxInput);
          gaHelpers.engagement("fetch_url", "search")
          if (response.status === "reject" && response.message === "LinkedIn"){
            tempNode = {
              "status": "error",
              "result": {
                id: 'temp-node',
                title: "LinkedIn page is currently not-allowed because of LinkedIn block all query outside of its api.",
                url: "",
              }
            };
            filteredNodes.splice(0, 1, tempNode);
            this.searchToolboxStatus = "Finished"
            return;
          }

          if (response.status === "success"){
            tempNode = {
              status: "notExist",
              result: {
                id: 'temp-node',
                title: `${response.message}`,
                url: `${this.searchToolboxInput}`
              }
            };
          }
          filteredNodes.splice(0, 1, tempNode);
          this.searchToolboxStatus = "Finished"
          this.$store.dispatch("modal/updateSearchToolboxFilteredNode", {data:filteredNodes});
        } catch(err) {
          console.log(err)
          const tempNode = {
            status: "error",
            result: "Something went wrong when fetching url."
          }
          filteredNodes.splice(0, 0, tempNode)
          this.$store.dispatch("modal/updateSearchToolboxFilteredNode", {data:filteredNodes});
          this.searchToolboxStatus = "Finished"

          const newIssue = {
            "baseType": "Issue",
            "issueType": "InvalidURL",
            "url": this.searchToolboxInput,
            "description": "Encounter error when fetching url",
            "owner": this.userData.id
          }
          API.graphql(graphqlOperation(createIssueMutation, {input:newIssue}))
          return;
        }
      } else {
        const hologramNodeIndex = this.currentHologramNodes.findIndex( e => e.node.url === nodesByUrl[0].url)
        if (hologramNodeIndex !== -1){
          const tempNode = {
            "status": "exist",
            "result": nodesByUrl[0]
          }
          filteredNodes.splice(filteredNodes.length, 0, tempNode);
          this.$store.dispatch("modal/updateSearchToolboxFilteredNode", {data:filteredNodes});
          return;
        } else {
          const tempNode = {
            "status": "notExist",
            "result": nodesByUrl[0]
          }
          filteredNodes.splice(0, 0, tempNode);
          this.$store.dispatch("modal/updateSearchToolboxFilteredNode", {data:filteredNodes});
          return;
        }
      }
    },
    searchToolboxClickHandler: async function(node){
      this.$store.dispatch("hologram/updateTransactingStatus", {data:true});
      this.$store.dispatch("hologram/updateSearchNodeToolboxOpen", {data:false})
      const buildNodeVariables = {
        currentHologramNodes: this.currentHologramNodes,
        currentHologram: this.currentHologram,
        userID: this.userData.id,
        targetNode: node,
        userNodes: this.userNodes
      };
      const result = await this.$store.dispatch("hologram/advancedBuildNode", buildNodeVariables);
      //const result = await this.$store.dispatch("hologram/buildNodeTest");
      this.$store.dispatch("hologram/updateTransactingStatus", {data:false});
      if (result === "duplicated"){
        const newNotification = {
          "location": "nodeCreation",
          "type": "duplicated"
        }
        this.$store.dispatch("notification/updateHologramOperationNotification", {type:"update", data:newNotification})
      }
      gaHelpers.engagement("add_node", "core");
    },
    getHoverStyle: function(nodeID){
      if ( nodeID === this.searchToolboxSelectedNodeID ){
        return "bg-sd-base2-white bg-opacity-5"
      }
    },
    selectNode: function(event, nodeID){
      if ( this.preventMouseEnter ){
        this.preventMouseEnter = false;
        return;
      }

      const index = this.searchToolboxFilteredNode.findIndex( e => e.result.id === nodeID )

      if ( this.searchToolboxFilteredNode[`${index}`].status === "exist" ){
        return;
      }

      this.$store.dispatch("modal/updateSearchToolboxSelectedNodeID", {data:nodeID})
      this.activated = true
    },
    getActivateStyle: function(){
      if ( !this.activated ){
        return "bg-sd-base03-brblack";
      }
      return "bg-sd-base02-black";
    },
  },
  watch: {
    searchToolboxSelectedNodeID: function(newValue){
      if ( !newValue ){
        return;
      }

      const scrollIntoViewOptions = { // eslint-disable-line no-unused-vars
        behavior: "smooth",
        block: "nearest"
      }

      const targetElement = document.getElementById(`${newValue}`);

      if ( !checkVisible(targetElement) ){
        targetElement.scrollIntoView(scrollIntoViewOptions);
        this.preventMouseEnter = true; // scrollIntoView will activate mouseEnter one more time which will cause some confused interaction
      }
    },
    searchToolboxInput: async function(newValue){
      if (newValue === ""){
        this.$store.dispatch("hologram/updateSearchNodeToolboxOpen", {data:false})
        this.$store.dispatch("modal/updateSearchToolboxFilteredNode", {data:[]});
        return;
      }
      this.filterOrFetch();
      this.$nextTick( function(){
        this.activated = true;
        if ( this.searchToolboxFilteredNode.length === 0 ){
          return;
        }

        if ( this.searchToolboxFilteredNode[0].status === "exist" ){
          return;
        }

        this.$store.dispatch("modal/updateSearchToolboxSelectedNodeID", {data:this.searchToolboxFilteredNode[0].result.id})

      })
    }
  }
};


const checkVisible = (element) => {
  // Here 160 indicate the distance between bottom of searchToolbox and bottom of window screen
  // 10 - buffer
  // 400 
  var rect = element.getBoundingClientRect();
  var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
  return !(rect.bottom <= viewHeight - 400 - 10 || rect.top + 160 - 10 - viewHeight >= 0);
}

const filterNodesByUrl = function(userNodes, searchToolboxInput){
  let result = [];
  const filterUserNodes = userNodes.filter( element => {
    if (element){
      let filterNodesByUrl = element.node.url.toLowerCase().includes(searchToolboxInput.toLowerCase())
      if (!filterNodesByUrl){
        return false
      } else {
        return true
      }
    }
  });
  filterUserNodes.forEach(element => {
    result.splice(0, 0, element.node);
  });
  return result
}

const filterNodesByTitle = function(userNodes, searchToolboxInput){
  let result = [];
  const filterUserNodes = userNodes.filter( element => {
    if (element){
      let filterNodesByTitle = element.node.title.toLowerCase().includes(searchToolboxInput.toLowerCase())
      if (!filterNodesByTitle){
        return false
      } else {
        return true
      }
    }
  });
  filterUserNodes.forEach(element => {
    result.splice(0, 0, element.node);
  });
  return result
}

/**
 * @param {string} url - valid url
 * @returns {object} responseObject - { status:{}, message:{} }
 */

const getTitle = async function(url){
  try {
    const result = await API.graphql(graphqlOperation(queryCorsContent, {url:url}))
    const responseBody = JSON.parse(result.data.queryCorsContent).body
    return Promise.resolve(responseBody)
  } catch(err){
    return Promise.reject(err)
  }
}

const getUrlObject = function(url){
  // https://developer.mozilla.org/en-US/docs/Web/API/URL#properties
  try {
    const result = new URL(url)
    return result
  } catch(err) {
    return 'invalidUrl'
  }
}

</script>


<style>
</style>