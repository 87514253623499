<template>
  <div class="flex flex-row flex-shrink-0 w-full h-16 pl-6 pr-4">
    <div
      class="my-auto mr-2"
      v-show="!sidebarOpen"
    >
      <SidebarToggler />
    </div>
  </div>
</template>
<script>
import SidebarToggler from "../sidebar/SidebarToggler.vue";
export default {
  name: "HologramNavbarForOwner",
  components: {
    SidebarToggler,
  },
  props: {
    sidebarOpen: {
      type: Boolean,
      require: true,
    },
  },
};
</script>
<style>
</style>