<template>
  <div class="fixed inset-0 z-30 h-full" v-if="open">
    <div
      class="flex items-end justify-center h-full min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0"
      id="modal-container"
    >
      <transition
        enter-active-class="duration-300 ease-out"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div @click="clickAwayHandler" class="fixed inset-0">
          <div class="absolute inset-0"></div>
        </div>
      </transition>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <transition
        name="modal-transition"
        enter-active-class="duration-300 ease-out"
        enter-from-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
        enter-to-class="translate-y-0 opacity-100 sm:scale-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="translate-y-0 opacity-100 sm:scale-100"
        leave-to-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
      >
        <div
          class="fixed flex flex-col overflow-y-auto text-left align-bottom transition-all transform border rounded-md shadow-2xl bg-sd-base02-black border-sd-base01-brgreen"
          role="dialog"
          aria-modal="true"
          id="single-select-option-toolbox"
          :style="modalPosition"
          @keydown.enter="keyDownEnterHandler"
        >
          <div
            class="flex w-full p-3"
          >
            <input 
              type="text"
              v-model="optionTitle"
              class="w-full h-8 font-sans text-base font-normal border rounded-sm bg-sd-base02-black border-sd-base01-brgreen text-sd-base1-brcyan focus:outline-none focus:ring-0 focus:border-sd-base0-brblue"
            >
          </div>
          <button
            class="flex flex-row px-3 mt-1 min-h-8 hover:bg-sd-base2-white hover:bg-opacity-5 focus:outline-none"
            @click="deleteOptionHandler"
          >
            <iconTrashCanFillVue :size="4" class="my-auto mr-4 text-sd-base0-brblue" />
            <div
              class="my-auto font-sans text-sm font-normal text-sd-base0-brblue"
            >
              Delete
            </div>
          </button>
          <div
            class="inline-block my-2 border-b border-sd-base01-brgreen"
          ></div>
          <div
            class="flex flex-col"
          >
            <div
              class="px-3 font-sans text-sm font-normal text-sd-base0-brblue"
            >
              Choose color
            </div>
            <div
              class="flex flex-col h-64 my-2"
            >
              <button
                v-for="color in colors"
                v-bind:key="color.name"
                class="flex flex-row h-8 px-3 hover:bg-sd-base2-white hover:bg-opacity-5 focus:outline-none"
                @click="changeColorHandler(color)"
              >
                <div
                  class="flex w-3 h-3 my-auto mr-3 rounded-sm"
                  :class="color.bgCode"
                >
                  
                </div>
                <div
                  class="my-auto font-sans text-sm font-normal text-sd-base0-brblue"
                >
                  {{ color.name }}
                </div>
              </button>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { colors } from "../../assets/colors"
import { onMounted, ref, watch, onUnmounted } from "vue";
import iconTrashCanFillVue from '../icon/iconTrashCanFill.vue';
export default {
  name: "singleSelectionOptionToolbox",
  components: {
    iconTrashCanFillVue,
  },
  props:{
    option: {
      type: Object,
      require: true
    },
    open: {
      type: Boolean,
      require: true
    },
    position: {
      type: Object,
      require: true
    },
    confirmGroupDeletionModalOpen: {
      type: Boolean,
      required: true
    }
  },
  emits:["changeColor", "deleteOption", "toggleOptionToolbox", "changeOptionName"],
  setup(props, context) {
    const optionTitle = ref(null)
    const updateOptionTitle = ref(null)
    const modalPosition = ref(null)
    const modalWidth = ref(180)
    const modalHeight = ref(320)

    const toggleModal = () => {
      if ( props.open ){
        context.emit("toggleOptionToolbox", false);
      }
    }

    const deleteOptionHandler = () => {
      const message = {
        "option": props.option,
        "action": "delete"
      };
      context.emit("deleteOption", message);
      toggleModal();
    }

    const changeOptionName = () => {
      if ( updateOptionTitle.value ){
        const variables = {
          "changedName": updateOptionTitle.value,
          "option": props.option
        }
        context.emit("changeOptionName", variables)
      }
    }

    const keyDownEnterHandler = () => {
      changeOptionName();
      toggleModal();
    }

    const clickAwayHandler = () => {
      changeOptionName();
      toggleModal();
    }

    const changeColorHandler = (color) => {

      const message = {
        "option": props.option,
        "color": color,
        "action": "changeColor"
      }

      context.emit("changeColor", message);
      toggleModal();
    }

    const prepare = () => {
      modalPosition.value = {
        left: props.position.x + props.position.width - modalWidth.value + "px",
        top: props.position.y - modalHeight.value/2 + "px",
        width: modalWidth.value + "px",
        height: modalHeight.value + "px"
      }
      optionTitle.value = props.option.group.title
      window.addEventListener("keydown", keydownEscHandler)
    }

    const keydownEscHandler = (event) => {
      if ( event.code !== "Escape" ) return;
      if ( props.confirmGroupDeletionModalOpen ) return;
      if ( !props.open ) return;
      toggleModal()
    };

    onMounted(prepare)

    watch(optionTitle, (newValue) => {
      if ( newValue !== props.option.group.title ){
        updateOptionTitle.value = newValue;
      }
    })

    const clear = () => {
      window.removeEventListener("keydown", keydownEscHandler)
    }

    onUnmounted(clear)

    

    return {
      toggleModal,
      modalPosition,
      optionTitle,
      changeColorHandler,
      deleteOptionHandler,
      keyDownEnterHandler,
      clickAwayHandler,
      colors
    }
  },
};
</script>

<style></style>