<template>
  <div
    v-bind:style="getSidebarStyle()"
    class="fixed top-0 right-0 flex flex-col h-screen overflow-y-auto duration-300 transform w-124 bg-sd-base02-black"
    ref="root"
  >
    <div class="flex flex-row flex-shrink-0 h-16 px-3">
      <div
        class="flex mr-auto"
      >
        <button
          class="flex items-center w-8 h-8 my-auto rounded-md hover:bg-sd-base2-white hover:bg-opacity-5 focus:outline-none"
          @click="toggleSidebar()"
        >
          <iconChevronBarRight
            :size="5"
            class="m-auto text-sd-base0-brblue"
          />
        </button>
      </div>
      <!--
      <div
        class="flex flex-row ml-auto"
      >
        <button
          class="flex items-center w-8 h-8 my-auto rounded-md hover:bg-sd-base2-white hover:bg-opacity-5 focus:outline-none"
        >
          <iconCardHeading
            :size="5"
            class="m-auto text-sd-base0-brblue"
          />
        </button>
        <button
          class="flex items-center w-8 h-8 my-auto ml-3 rounded-md hover:bg-sd-base2-white hover:bg-opacity-5 focus:outline-none"
        >
          <iconChat
            :size="5"
            class="m-auto text-sd-base0-brblue"
          />
        </button>
        
      </div> 
      -->
    </div>
    <div
      v-if="hologramNodeDetailTargetID"
      class="px-6"
    >
      <HologramNodeEntityDetail />
    </div>
    
  </div>
</template>
<script>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { TweenMax } from "gsap";
import iconChevronBarRight from "../icon/iconChevronBarRight.vue";
//import iconChat from "../icon/iconChat.vue"
//import iconCardHeading from "../icon/iconCardHeading.vue"
import HologramNodeEntityDetail from "../HologramNodeEntityDetail.vue"
export default {
  name: "HologramSidebar",
  components: {
    iconChevronBarRight,
    //iconChat,
    //iconCardHeading,
    HologramNodeEntityDetail
  },
  setup() {
    const root = ref(null);
    const store = useStore();
    const hardcodeSidebarOffsetWidth = ref(496);
    const sidebarOpen = computed(() => store.getters["sidebar/hologramSidebarOpen"]);
    const sidebarWidth = computed(() => store.getters["sidebar/hologramSidebarWidth"]);
    const hologramNodeDetailTargetID = computed(() => store.getters["hologram/hologramNodeDetailTargetID"]);

    const prepare = () => {
      if (root.value) {
        TweenMax.set(root.value, {
          x: Math.abs(root.value.offsetWidth),
        });
        const sidebarWidth = root.value.offsetWidth;
        store.dispatch("sidebar/updateHologramSidebarWidth", { data: sidebarWidth });
      }
    };

    onMounted(prepare);

    const getSidebarStyle = () => {
      if (sidebarOpen.value) {
        if (root.value) {
          return {
            "margin-right": `${Math.abs(root.value.offsetWidth)}px`,
          };
        } else {
          return {
            "margin-right": `${Math.abs(hardcodeSidebarOffsetWidth.value)}px`,
          };
        }
      } else {
        return {
          "margin-right": "0px",
        };
      }
    };

    const toggleSidebar = () => {
      if (sidebarOpen.value) {
        store.dispatch("sidebar/toggleHologramSidebar", { data: false });
        return;
      }
      store.dispatch("sidebar/toggleHologramSidebar", { data: true });
    };

    return {
      // Ref
      root,

      // Method
      getSidebarStyle,
      toggleSidebar,

      // Store
      hologramNodeDetailTargetID,
      sidebarOpen,
      sidebarWidth,
      
    };
  },
};
</script>
<style>
</style>