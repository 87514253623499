<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    v-bind:class="iconStyle"
    viewBox="0 0 16 16"
  >
    <path
      fill-rule="evenodd"
      d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
    />
  </svg>
</template>
<script>
import { computed } from "vue";
export default {
  name: "iconList",
  props: {
    size: {
      type: Number,
      require: true,
    },
  },
  setup(props) {
    const iconStyle = computed(() => {
      return `fill-current w-${props.size} h-${props.size}`;
    });
    return {
      iconStyle,
    };
  },
};
</script>
<style>
</style>