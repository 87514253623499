import { API, graphqlOperation } from "aws-amplify";
import { listHologramEdges as listHologramEdgesQuery } from "../queries"
import { listGroupNodes as listGroupNodesQuery } from "../queries"
import { listNodes as listNodesQuery} from "../queries"
import { listDomains as listDomainsQuery } from "../queries"
import { listUserEdges as listUserEdgesQuery } from "../queries";
import { listEdges as listEdgesQuery } from "../queries";
import { createHologramEdge as createHologramEdgeMutation } from "../mutations";
import { createGroupNode as createGroupNodeMutation } from "../mutations"
import { createHologramNode as createHologramNodeMutation } from "../mutations"
import { createUserNode as createUserNodeMutation } from "../mutations"
import { createNode as createNodeMutation } from "../mutations"
import { createDomain as createDomainMutation } from "../mutations";
import { createUserEdge as createUserEdgeMutation } from "../mutations";
import { createEdge as createEdgeMutation } from "../mutations";

/**
 * 
 * @param {id} hologramID 
 * @param {id} edgeID 
 * @param {id} startNodeID
 * @param {id} endNodeID
 * @param {string} owner username
 * @returns {object} { status:duplicated/create, result:hologramEdge }
 */

export const getOrCreateHologramEdge = async (hologramID, edgeID, startNodeID, endNodeID, owner) => {
  let hologramEdge = {};
  const filter = {
    and:[
      {
        "hologramID":{
          eq: hologramID
        },
        "edgeID":{
          eq: edgeID
        }
      }
    ]
  };
  try {
    const listHologramEdgeResult = await API.graphql(graphqlOperation(listHologramEdgesQuery, { filter:filter, limit:1000 }));
    if (listHologramEdgeResult.data.listHologramEdges.items.length !== 0){
      hologramEdge.result = listHologramEdgeResult.data.listHologramEdges.items[0];
      hologramEdge.status = "duplicated"
    } else {
      const newHologramEdge = {
        "hologramID": hologramID,
        "edgeID": edgeID,
        "startNodeID": startNodeID,
        "endNodeID": endNodeID,
        "owner": owner
      };
      const createHologramEdge = await API.graphql(graphqlOperation(createHologramEdgeMutation, {input:newHologramEdge}));
      hologramEdge.result = createHologramEdge.data.createHologramEdge;
      hologramEdge.status = "create"
    }
    return Promise.resolve(hologramEdge)
  } catch(err){
    return Promise.reject(err)
  }
}

/**
 * 
 * @param {list} currentHologramNodes 
 * @param {object} currentHologram 
 * @param {id} userID
 * @param {object} node 
 * @param {object} weight 
 * @param {string} owner username
 * @returns {object} { status:duplicated/create, result:hologramNode }
 */

export const getOrCreateHologramNode = async (currentHologramNodes, currentHologram, userID, node, weight, owner) => { 
  let hologramNode = {};
  try {
    const index = currentHologramNodes.findIndex( e => e.nodeID === node.id && e.hologramID === currentHologram.id);
    if ( index != -1 ){
      hologramNode.result = currentHologramNodes[`${index}`];
      hologramNode.status = "duplicated";
    } else {
      const newHologramNode = {
        "hologramID": currentHologram.id,
        "nodeID": node.id,
        "accessPolicy": currentHologram.accessPolicy,
        "weightID": weight.id,
        "createdBy": userID,
        "owner": owner
      };
      const createHologramNode = await API.graphql(graphqlOperation(createHologramNodeMutation, {input:newHologramNode}))
      hologramNode.result = createHologramNode.data.createHologramNode;
      hologramNode.status = "create";
    }
    return Promise.resolve(hologramNode);
  } catch(err){
    return Promise.reject(err);
  } 
}

/**
 * 
 * @param {id} groupID 
 * @param {id} nodeID 
 * @param {string} owner username
 * @returns {object} { status:duplicated/create, result:groupNode }
 */

export const getOrCreateGroupNode = async (groupID, nodeID, owner) => {
  let groupNode = {};
  const filter = {
    and:[
      {
        "groupID":{
          eq: groupID
        },
        "nodeID":{
          eq: nodeID
        }
      }
    ]
  }
  try {
    const listGroupNodes = await API.graphql(graphqlOperation(listGroupNodesQuery, { filter:filter, limit:1000 }));
    if (listGroupNodes.data.listGroupNodes.items.length !== 0){
      groupNode.result = listGroupNodes.data.listGroupNodes.items[0];
      groupNode.status = "duplicated";
    } else {
      const newGroupNode = {
        "groupID": groupID,
        "nodeID": nodeID,
        "owner": owner
      };
      const createGroupNode = await API.graphql(graphqlOperation(createGroupNodeMutation, {input:newGroupNode}));
      groupNode.result = createGroupNode.data.createGroupNode;
      groupNode.status = "create";
    }
    return Promise.resolve(groupNode);
  } catch(err){
    return Promise.reject(err);
  }
}

/**
 * 
 * @param {list} userNodes 
 * @param {string} userID 
 * @param {object} node 
 * @param {string} owner username
 * @returns {object} { status:duplicated/create, result:userNode }
 */

export const getOrCreateUserNode = async (userNodes, userID, node, owner) => { 
  let userNode = {}; 
  try {
    const index = userNodes.findIndex( e => e.userID === userID && e.nodeID == node.id);
    if (index != -1){
      userNode.result = userNodes[`${index}`];
      userNode.status = "duplicated";
    } else {
      const newUserNode = {
        "userID": userID,
        "nodeID": node.id,
        "owner": owner
      }
      const createUserNode = await API.graphql(graphqlOperation(createUserNodeMutation, {input:newUserNode}))
      userNode.result = createUserNode.data.createUserNode;
      userNode.status = "create";
    }
    return Promise.resolve(userNode);
  } catch(err){
    return Promise.reject(err);
  }
}

/**
 * 
 * @param {object} nodeData {url, title}
 * @param {id} domainID 
 * @param {string} owner username
 * @returns {object} { status:duplicated/create, result:node }
 */

export const getOrCreateNode = async (nodeData, domainID, owner) => {
  let node = {};
  const filter = {
    "url":{
      "eq": nodeData.url
    }
  }
  try {
    let listNode = await API.graphql(graphqlOperation(listNodesQuery, {filter:filter, limit:1000}));
    if (listNode.data.listNodes.items.length != 0){
      node.result = listNode.data.listNodes.items[0];
      node.status = "duplicated";
    } else {
      const newNode = {
        "baseType": "Node",
        "domainID": domainID,
        "title": nodeData.title,
        "url": nodeData.url,
        "owner": owner
      }
      let createNode = await API.graphql(graphqlOperation(createNodeMutation, {input:newNode}))
      node.result = createNode.data.createNode;
      node.status = "create";
    }
    return Promise.resolve(node);
  } catch (err){  
    return Promise.reject(err);
  }
}

/**
 * 
 * @param {string} hostName - URL object's hostName, like totuslink.com
 * @returns {object} { status:duplicated/create, result:domain }
 */

export const getOrCreateDomain = async (hostName, owner) => {
  let domain = {};
  const filter = {
    "hostName":{
      "eq": hostName
    }
  }
  try {  
    let listDomain = await API.graphql(graphqlOperation(listDomainsQuery, { filter:filter, limit:1000 }))
    if (listDomain.data.listDomains.items.length != 0){
      domain.result = listDomain.data.listDomains.items[0]
      domain.status = "duplicated"
    } else {
      const newDomain = {
        "baseType": "Domain",
        "hostName": hostName,
        "owner": owner
      }
      let createDomain = await API.graphql(graphqlOperation(createDomainMutation, {input:newDomain}))
      domain.result = createDomain.data.createDomain
      domain.status = "create"
    }
    return Promise.resolve(domain);
  } catch(err){
    console.log(err)
    return Promise.reject(err);
  }
}

/**
 * 
 * @param {id} userID 
 * @param {id} edgeID 
 * @param {string} owner username
 * @returns {object} { status:duplicated/create, result:userEdge }
 */

export const getOrCreateUserEdge = async function(userID, edgeID, owner){
  let userEdge = {};
  const filter = {
    and:[
      {
        "userID":{
          eq: userID
        },
        "edgeID":{
          eq: edgeID
        }
      }
    ]
  };
  try {
    const listUserEdge = await API.graphql(graphqlOperation(listUserEdgesQuery, { filter:filter, limit:1000 }));
    if (listUserEdge.data.listUserEdges.items.length !== 0){
      userEdge.result = listUserEdge.data.listUserEdges.items[0];
      userEdge.status = "duplicated";
    } else {
      const newUserEdge = {
        "userID": userID,
        "edgeID": edgeID,
        "owner": owner
      }
      const resultUserEdge = await API.graphql(graphqlOperation(createUserEdgeMutation, {input:newUserEdge}));
      userEdge.result = resultUserEdge.data.createUserEdge;
      userEdge.status = "create";
    }
    return Promise.resolve(userEdge);
  } catch(err){
    return Promise.reject(err);
  }
}

/**
 * 
 * @param {id} startNodeID 
 * @param {id} endNodeID 
 * @param {string} owner username
 * @returns {object} { status:duplicated/create, result:edge }
 */

export const getOrCreateEdge = async function(startNodeID, endNodeID, owner){
  let edge = {};
  const filter = {
    and:[
      {
        "startNodeID":{
          eq: startNodeID
        },
        "endNodeID":{
          eq: endNodeID
        }
      }
    ]
  }
  try {
    const listEdgeResult = await API.graphql(graphqlOperation(listEdgesQuery, { filter:filter, limit:1000 }));
    if (listEdgeResult.data.listEdges.items.length !== 0){
      edge.result = listEdgeResult.data.listEdges.items[0]
      edge.status = "duplicated"
    } else {
      const newEdge = {
        "baseType": "Edge",
        "startNodeID": startNodeID,
        "endNodeID": endNodeID,
        "owner": owner
      }
      const createEdge = await API.graphql(graphqlOperation(createEdgeMutation, {input:newEdge}))
      edge.result = createEdge.data.createEdge
      edge.status = "create"
    }
    return Promise.resolve(edge);
  } catch(err){
    return Promise.reject(err);
  }
}