<template>
  <div 
    class="questionnaire-content min-h-screen min-w-full"
  >
    <questions />
  </div>
</template>
<script>
import questions from "@/components/questionnaire/questions.vue"
export default {
  name:"QuestionnaireQuestions",
  components:{
    questions
  }
};
</script>
<style>
</style>