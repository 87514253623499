<template>
  <div
    class="flex flex-col"
    v-if="targetNodeID"
  >
    <NodeTitleBlock
      v-if="dataIsReady"
      :key="targetUserNode.id"
      :nodeTitle="nodeTitle"
      @update-title="catchUpdateTitleEvent"
    />
    <NodeUrlBlock
      v-if="dataIsReady"
      :key="targetUserNode.id"
      :hologramNode="hologramNode"
      :maxTextLength="36"
    />
    <NodeEdgeBlock
      v-if="dataIsReady"
      :key="targetUserNode.id"
      :edges="targetEdges"
      :targetHologramNode="hologramNode"
      :hologramNodes="currentHologramNodes"
    />
    <NodeGroupBlock
      v-if="dataIsReady"
      :key="targetUserNode.id"
      :hologramNode="hologramNode"
      :userID="userData.id"
      :currentHologram="currentHologram"
    />
    <NodeNoteBlock
      v-if="dataIsReady"
      :key="targetUserNode.id"
      :hologramNode="hologramNode"
      :hologramNodeWeight="hologramNodeWeight"
      :targetUserNode="targetUserNode"
      class="w-full mt-6 font-sans text-left"
    />
  </div>
</template>
<script>
import NodeUrlBlock from "./hologram/NodeUrlBlock.vue";
import NodeTitleBlock from "./hologram/NodeTitleBlock.vue";
import NodeEdgeBlock from "./hologram/NodeEdgeBlock.vue";
import NodeGroupBlock from "./hologram/NodeGroupBlock.vue";
import NodeNoteBlock from "./hologram/NodeNoteBlock.vue";
import { ref, onMounted, computed, watch } from "vue";
import { useStore } from "vuex";
import { getNodeTitle } from "../utilities/utilities";
export default {
  name: "HologramNodeEntityDetail",
  components: {
    NodeTitleBlock,
    NodeUrlBlock,
    NodeEdgeBlock,
    NodeGroupBlock,
    NodeNoteBlock,
  },
  setup() {
    const store = useStore();
    const nodeTitle = ref(null);
    const hologramNode = ref(null);
    const hologramNodeWeight = ref({});
    const dataIsReady = ref(false);
    const targetNodeID = computed(() => store.getters["hologram/hologramNodeDetailTargetID"]);
    const userData = computed(() => store.getters["auth/userData"]);
    const currentHologram = computed(() => store.getters["hologram/currentHologram"]);
    const currentHologramNodes = computed(() => store.getters["hologram/currentHologramNodes"]);
    const currentHologramEdges = computed(() => store.getters["hologram/currentHologramEdges"]);
    const currentHologramNodeWeights = computed(() => store.getters["weight/currentHologramNodeWeights"]);
    const userNodes = computed(() => store.getters["hologram/userNodes"]);
    const targetUserNode = ref(null);
    const targetEdges = ref([]);

    const prepare = () => {
      getHologramNode();
      getUserNode();
      getNodeEdges();
      getHologramNodeWeights();
      nodeTitle.value = getNodeTitle(targetUserNode.value, hologramNode.value);
      dataIsReady.value = true;
    };
    onMounted(prepare);

    const getHologramNode = () => {
      const index = currentHologramNodes.value.findIndex((e) => e.nodeID === targetNodeID.value);
      if (index !== -1) {
        hologramNode.value = currentHologramNodes.value[index];
      }
    };

    const getHologramNodeWeights = () => {
      const index = currentHologramNodeWeights.value.findIndex((e) => e.hologramNodeID === hologramNode.value.id);
      if (index !== -1) {
        hologramNodeWeight.value = currentHologramNodeWeights.value[index];
      }
    };

    const getUserNode = () => {
      const userNodeIndex = userNodes.value.findIndex((e) => e.nodeID === targetNodeID.value);
      if (userNodeIndex !== -1) {
        targetUserNode.value = userNodes.value[userNodeIndex];
      }
    };

    const getNodeEdges = () => {
      targetEdges.value = currentHologramEdges.value.reduce(function (array, element) {
        if (element.edge.startNodeID === targetUserNode.value.nodeID) {
          array.push(element.edge);
        } else if (element.edge.endNodeID === targetUserNode.value.nodeID) {
          array.push(element.edge);
        }
        return array;
      }, []);
    };

    const catchUpdateTitleEvent = async (message) => {
      store.dispatch("hologram/updateTransactingStatus", { data: true });
      const updateTitleVariables = {
        currentHologram: currentHologram.value,
        nodeID: hologramNode.value.nodeID,
        hologramNodeID: hologramNode.value.id,
        title: message,
      };
      try {
        await store.dispatch("hologram/updateHologramNodeTitle", updateTitleVariables);
        store.dispatch("hologram/updateRenderHologramGraphDataOnly", { data: true });
      } catch (err) {
        console.error(err);
      }
      store.dispatch("hologram/updateTransactingStatus", { data: false });
    };

    watch(targetNodeID, () => {
      dataIsReady.value = false;
      getHologramNode();
      getUserNode();
      getNodeEdges();
      getHologramNodeWeights();
      nodeTitle.value = getNodeTitle(hologramNode.value);
      dataIsReady.value = true;
    });

    return {
      // Local
      nodeTitle,
      hologramNode,
      dataIsReady,
      targetEdges,
      hologramNodeWeight,
      targetUserNode,

      // Store
      currentHologramNodes,
      userNodes,
      userData,
      currentHologram,
      targetNodeID,

      // Method

      // Event
      catchUpdateTitleEvent,
    };
  },
};
</script>
<style >
</style>