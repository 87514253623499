<template>
  <div class="flex flex-col w-full bg-sd-base02-black node-note-block-editor">
    <div class="flex flex-row group">
      <iconMarkdown
        :size="5"
        class="mr-2 text-sd-base01-brgreen hover:text-sd-base1-brcyan"
      />
      <div class="mr-auto text-sm opacity-0 text-sd-base00-bryellow group-hover:opacity-100">
        This editor support markdown syntax.
      </div>
    </div>
    <div class="inline-block w-full my-2 border-b border-sd-base01-brgreen"></div>
    <textarea
      class="w-full"
      id="totuslink-markdown-editor"
    />
  </div>
</template>
<script>
import { onMounted, ref, watch } from "vue";
import EasyMDE from "easymde";
import "../assets/easymde.css";
import iconMarkdown from "./icon/iconMarkdown.vue";
export default {
  name: "markdownEditor",
  props: {
    targetNote: {
      type: String,
      require: true,
    },
    targetUserNodeID: {
      type: String,
      require: true,
    },
  },
  components: {
    iconMarkdown,
  },
  emits: ["updateNote"],
  setup(props, context) {
    const editor = ref(null);

    const initialize = () => {
      const MDEConfig = {
        toolbar: false,
        element: document.getElementById("totuslink-markdown-editor"),
        scrollbarStyle: "null",
        toolbarTips: false,
        placeholder: "Type anything here...",
        autofocus: false,
        status: false,
        spellChecker: false,
      };

      if (props.targetNote) {
        if (props.targetNote.length > 0) {
          MDEConfig.initialValue = props.targetNote;
        } else {
          MDEConfig.initialValue = "";
        }
      } else {
        MDEConfig.initialValue = "";
      }
      editor.value = new EasyMDE(MDEConfig);
      editor.value.codemirror.on("change", () => {
        const note = editor.value.value();
        context.emit("updateNote", note);
      });
    };

    onMounted(initialize);

    watch(
      () => props.targetUserNodeID,
      () => {
        editor.value.cleanup();
        editor.value = null;
        const element = document.querySelector(".node-note-block-editor .EasyMDEContainer");
        element.parentNode.removeChild(element);
        initialize();
      }
    );
  },
};
</script>
<style>
</style>