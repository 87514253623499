<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    v-bind:class="iconStyle"
    viewBox="0 0 16 16"
  >
    <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
  </svg>
</template>
<script>
import { computed } from "vue";
export default {
  name: "iconArrowUpRight",
  props: {
    size: {
      type: Number,
      require: true,
    },
  },
  setup(props) {
    const iconStyle = computed(() => {
      return `fill-current w-${props.size} h-${props.size}`;
    });
    return {
      iconStyle,
    };
  },
};
</script>
<style>
</style>