<template>
  <div 
    class="fixed inset-0 h-full"
  >
    <div
      class="flex items-end justify-center h-full min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0"
      id="modal-container"
    >
      <transition
        enter-active-class="duration-300 ease-out"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div 
          @click="toggleMainSettingsModal('byClickAway')" 
          class="fixed inset-0"
        >
          <div  class="absolute inset-0 bg-black opacity-50"></div>
        </div>
      </transition>
      
      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        v-show="mainSettingsModalOpen"
        >&#8203;</span
      >
      <transition
        name="modal-transition"
        enter-active-class="duration-300 ease-out"
        enter-from-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
        enter-to-class="translate-y-0 opacity-100 sm:scale-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="translate-y-0 opacity-100 sm:scale-100"
        leave-to-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
      >
        <div
          class="inline-block overflow-y-auto text-left align-bottom transition-all transform rounded-lg shadow-xl h-5/6 sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full"
          role="dialog"
          aria-modal="true"
          id="settings-container"
          v-if="userData"
          v-show="mainSettingsModalOpen"
        >
          <div class="w-full bg-sd-base02-black">
            <div class="flex-col flex-grow w-full px-4 sm:flex sm:p-8 sm:items-start">
              <div class="mt-3 sm:mt-0 sm:text-left">
                <h2
                  class="text-xl font-medium leading-6 text-sd-base1-brcyan"
                  id="modal-headline"
                >
                  My account settings
                </h2>
              </div>
              
              <div class="text-base font-medium sm:mt-8 sm:text-left text-sd-base1-brcyan">
                <h3 class="">Photo</h3>
              </div>
              <div class="w-full border-t sm:mt-2 sm:mb-6 border-sd-base01-brgreen"> </div>
              <form 
                class="flex flex-row"
                enctype="multipart/form-data" 
                novalidate
              >
                <div class="flex w-32 h-32">
                  <div 
                    v-if="userData"
                    class="my-auto"
                  >
                    <div 
                      v-if="userData.avatar.key == 'avatars/defaultAvatarDark.png'"
                      class="flex items-center"
                    >
                      <img 
                        src="@/assets/avatar1_dark.png" 
                        alt="default_avatar"
                        class="w-32 h-32"
                      >
                    </div>
                    <div
                      v-else
                      class="flex items-center"
                    >
                      <amplify-s3-image 
                        :key="userData.avatar.key"
                        :img-key="userData.avatar.key"
                        level="protected"
                        class="w-32 h-32 rounded-full"
                      />
                    </div>
                  </div>
                </div>
                <!--
                <label class="flex flex-row px-4 py-2 my-auto ml-4 font-sans text-sm font-medium border rounded-md border-sd-base01-brgreen text-sd-base1-brcyan hover:bg-sd-base2-white hover:bg-opacity-5 focus:outline-none">
                  Upload photo
                  <div
                    v-if="uploadingImage"
                    class="ml-2"
                  >
                    <svg class="w-5 h-5 animate-spin text-sd-base0-brblue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  </div>
                  <input 
                    @change="onFileChange" 
                    accept="image/*" 
                    type="file" 
                    class="hidden" 
                  />
                </label>
                -->
              </form>
              <form class="w-full">
                <div class="text-base font-medium sm:mt-8 sm:text-left text-sd-base1-brcyan">
                  <h3 class="">Personal info</h3>
                </div>
                <div class="w-full border-t sm:mt-2 sm:mb-6 border-sd-base01-brgreen"></div>
                <div class="flex flex-col sm:text-left ">
                  <h4 class="text-sm font-normal text-sd-base1-brcyan">Email</h4>
                  <p class="mt-2 text-sm font-normal text-sd-base2-white">{{userData.email}}</p>
                </div>
                <div class="flex flex-col mt-4 text-sm font-normal sm:text-left text-sd-base1-brcyan">
                  <h4>First name</h4>
                  <input type="text" name="firstName" id="firstName" class="mt-2 bg-sd-base02-black border border-sd-base00-bryellow font-sans rounded font-normal text-sm text-sd-base0-brblue py-1.5 px-3 focus:outline-none focus:ring-1 focus:ring-sd-base1-brcyan"> 
                </div>
                <div class="flex flex-col mt-4 text-sm font-normal sm:text-left text-sd-base1-brcyan">
                  <h4>Last name</h4>
                  <input type="text" name="lastName" id="lastName" class="mt-2 bg-sd-base02-black border border-sd-base00-bryellow font-sans rounded font-normal text-sm text-sd-base0-brblue py-1.5 px-3 focus:outline-none focus:ring-1 focus:ring-sd-base1-brcyan"> 
                </div>
              </form>
              
            </div>
            <footer class="sticky bottom-0 flex flex-row border-t border-opacity-50 bg-sd-base02-black border-sd-base01-brgreen sm:py-4 sm:px-4">
              <button class="px-4 py-2 font-sans text-sm font-medium border rounded-md border-sd-base01-brgreen text-sd-base2-white hover:bg-sd-base2-white hover:bg-opacity-5 focus:outline-none">
                Update
              </button>
              <button @click="toggleMainSettingsModal('byClickButton')" class="px-4 py-2 ml-4 font-sans text-sm font-medium border rounded-md border-sd-base01-brgreen text-sd-base01-brgreen hover:bg-sd-base2-white hover:bg-opacity-5 focus:outline-none">
                Cancel
              </button>
            </footer>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { mapGetters } from 'vuex';
import awsconfig from "@/aws-exports";
import { API, graphqlOperation, Storage } from 'aws-amplify'
import { createImage as createImageMutation } from "../../graphql/mutations"
import { updateUser as updateUserMutation} from "../../graphql/mutations"
import { deleteImage as deleteImageMutation } from "../../graphql/mutations"
import { gaHelpers } from "@/analytics/helper.js"
export default {
  name:"mainSettingsModal",
  data() {
    return {
      uploadingImage: false,
    }
  },
  methods:{
    toggleMainSettingsModal(type){
      if (this.mainSettingsModalOpen){
        this.$store.dispatch('modal/toggleMainSettingsModal', {data:false});
      } else {
        this.$store.dispatch('modal/toggleMainSettingsModal', {data:true});
      }
      if ( type === "byClickAway" ){
        gaHelpers.interaction("close_main_setting_by_click_away", "modal");
      } else {
        gaHelpers.interaction("close_main_setting_by_button", "modal");
      }
    },
    async onFileChange(file) {
      if (!file.target || !file.target.files[0]) {
        return;
      }
      this.createImageAndUpdateDB({file:file.target.files[0]});
    },
    async createImageAndUpdateDB({file}){
      this.uploadingImage = true;
      let oldImageID = this.userData.avatar.key.split('/');
      oldImageID = oldImageID[1].split('.');
      oldImageID = oldImageID[0];
      const oldImageKey = this.userData.avatar.key;

      //delete old image
      const deleteResult = await API.graphql(graphqlOperation(deleteImageMutation, { input:{id:oldImageID}}));
      console.log(deleteResult,oldImageID)

      const mimeType = file.type
      const {
        aws_user_files_s3_bucket_region: region,
        aws_user_files_s3_bucket: bucket
      } = awsconfig;
      console.log(mimeType, file)
      const extension = file.name.substr(file.name.lastIndexOf(".") + 1);
      const imageID = uuidv4();
      const key = `avatars/${imageID}.${extension}`;
      const newImage = {
        baseType: "Image",
        createdByID: this.userData.id,
        fullsize: {
          key,
          region,
          bucket
        },
        contentType: mimeType,
        id:imageID,
        owner: this.user.username
      }
      try {
        await Storage.put(key, file, {
            level: "protected",
            contentType: mimeType,
            metadata: { imageID }
        })

        await Storage.remove(oldImageKey, {
          level: "protected",
        });
        
        await API.graphql(graphqlOperation(createImageMutation, { input: newImage }))

        const updateUser = {
          avatar:{
            key,
            region,
            bucket
          },
          id: this.userData.id,
        }
        await API.graphql(graphqlOperation(updateUserMutation, {input: updateUser}));
        await this.$store.dispatch("auth/authAction");
        this.uploadingImage = false;
        return Promise.resolve("success");
      } catch (error) {
        console.log("createImage error", error)
        return Promise.reject(error);
      }
    }
  },
  computed:{
    mainSettingsModalOpen: function(){
      return this.$store.state.modal.mainSettingsModalOpen
    },
    ...mapGetters({
      user: "auth/user",
      userData: "auth/userData",
    })
  }
};
</script>

<style scoped>
amplify-s3-image {
  --height: 128px;
  --width: 128px;
}
</style>