<template>
  <div class="flex w-full h-full base bg-sd-base03-brblack">
    <div class="flex flex-col w-full mx-4 my-auto sm:w-360 sm:mx-auto">
      <div class="mb-12 font-sans text-5xl font-bold title lg:text-5xl text-sd-base2-white">Sign In</div>
      <form @submit.prevent>
        <div class="font-sans text-sm font-light leading-4 text-left text-sd-base1-brcyan">Username</div>
        <div class="flex flex-row mx-auto mt-3 rounded-md sm:w-360 border-sd-base00-bryellow">
          <input 
            class="flex-1 bg-sd-base02-black font-sans rounded-md font-normal text-sm text-sd-base0-brblue py-1.5 px-3 focus:outline-none focus:ring-1 focus:ring-sd-base1-brcyan" 
            placeholder="Enter your username..."
            type="text"
            v-model="username"
            id="username"
            autocomplete="off"
          />
        </div>
        <div class="mt-4 font-sans text-sm font-light leading-4 text-left text-sd-base1-brcyan">Password</div>
        <div class="flex flex-row mx-auto mt-3 rounded-md sm:w-360 border-sd-base00-bryellow">
          <input 
            class="flex-1 bg-sd-base02-black font-sans rounded-md font-normal text-sm text-sd-base0-brblue py-1.5 px-3 focus:outline-none focus:ring-1 focus:ring-sd-base1-brcyan" 
            placeholder="Enter your password..."
            type="password"
            v-model="password"
            id="password"
            autocomplete="off"
          />
        </div>
        <router-link
          to="/reset_password"
          class="flex mt-3 font-sans text-sm font-normal underline text-sd-base0-brblue"
        >
          Forgot password?
        </router-link>
        <div v-if="error" class="mt-6 font-sans text-sm font-bold text-sd-yellow">{{ error.message }}</div>
        <button 
          class="flex flex-row w-full mt-6 border rounded sm:w-360 border-sd-base00-bryellow focus:outline-none focus:ring-1 focus:ring-sd-base1-brcyan hover:bg-sd-base02-black"
          @click="login"
          :disabled="logInProcessing || !password || !username "
        >
          <div 
            v-if="!logInProcessing"
            class="w-full font-sans font-medium text-sm text-sd-base0-brblue py-1.5 px-3"
          >
            <p>Login</p>
          </div>
          <div
            v-else
            class="flex flex-row items-center mx-auto py-1.5"
          >
            <svg class="w-5 h-5 mr-3 -ml-1 animate-spin text-sd-base0-brblue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <span class="font-sans text-sm font-medium text-sd-base0-brblue">Onboarding</span>
          </div>
        </button>
      </form>
      <!--
      <div class="mt-6 font-sans text-xs font-normal text-sd-base00-bryellow">
        <p>By clicking “Continue with Google/Email" above, you acknowledge that you have read and understood, and agree to Hololink’s <a href="" class="underline">Term of Uses</a> and <a href="" class="underline">Privacy Policy</a></p>
      </div>
      -->
    </div>
  </div>
</template>

<script>
import totuslink_config from "../totuslink.config"
import { gaHelpers } from "@/analytics/helper.js"
import { mapGetters } from 'vuex';
export default {
  name: "LoginView",
  data: () => ({
    username: "",
    password: "",
    email: "",
    error: "",
    logInProcessing: false,
  }),
  async mounted() {
    window.document.title = "Totuslink - Log in"
    if ( this.user ){
      try {
        const session = await this.$store.dispatch("auth/retrieveCurrentSession");
        this.setupCookies(session);
      } catch(err){
        console.error(err)
      }
      this.$router.push({name: 'Dock', params:{ username: this.user.username }});
    }
  },
  methods: {
    async federationSignIn(){
      try {
        await this.$store.dispatch("auth/federationSignIn")
      } catch (error){
        this.error = error;
        console.log(error)
      }
    },
    async login() {
      try {
        const { username, password } = this;
        
        this.logInProcessing = true;

        const session = await this.$store.dispatch("auth/login", {
          username,
          password
        });

        this.setupCookies(session);
        
        gaHelpers.engagement("login", "auth")
        this.$router.push("/");
        await this.$store.dispatch("auth/authAction")
      } catch (error) {
        this.error = error;
        console.log(error)
      }
    },
    setupCookies: function(session){
      this.$cookie.setCookie("idToken", session.idToken.jwtToken, {
        expire: totuslink_config.cookies_expire,
        domain: totuslink_config.cookies_domain,
        secure: totuslink_config.cookies_secure
      });

      this.$cookie.setCookie("accessToken", session.accessToken.jwtToken, {
        expire: totuslink_config.cookies_expire,
        domain: totuslink_config.cookies_domain,
        secure: totuslink_config.cookies_secure
      }) 

      this.$cookie.setCookie("refreshToken", session.refreshToken.token, {
        expire: totuslink_config.cookies_expire,
        domain: totuslink_config.cookies_domain,
        secure: totuslink_config.cookies_secure
      })

      this.$cookie.setCookie("clockDrift", session.clockDrift, {
        expire: totuslink_config.cookies_expire,
        domain: totuslink_config.cookies_domain,
        secure: totuslink_config.cookies_secure
      }) 
      
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user"
    })
  },
};
</script>

<style>
</style>