<template>
  <div class="fixed inset-0 h-full">
    <div
      class="flex items-end justify-center h-full min-h-screen px-4 pt-4 text-center sm:block sm:p-0"
      id="modal-container"
    >
      <transition
        enter-active-class="duration-300 ease-out"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          @click="toggleConfirmHologramDeletionModal"
          class="fixed inset-0"
          v-if="modalOpen"
        >
          <div class="absolute inset-0 bg-black opacity-50"></div>
        </div>
      </transition>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        v-show="modalOpen"
      >&#8203;</span>
      <transition
        name="modal-transition"
        enter-active-class="duration-300 ease-out"
        enter-from-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
        enter-to-class="translate-y-0 opacity-100 sm:scale-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="translate-y-0 opacity-100 sm:scale-100"
        leave-to-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
      >
        <div
          class="items-center inline-block overflow-y-auto text-left align-middle transition-all transform rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-xl sm:w-full"
          role="dialog"
          aria-modal="true"
          id="settings-container"
          v-show="modalOpen"
        >
          <div class="w-full bg-sd-base02-black">
            <div class="flex-col flex-grow w-full px-4 sm:flex sm:p-8 sm:items-start">
              <div class="mt-3 sm:mt-0 sm:text-left">
                <h2
                  class="text-xl font-medium leading-6 text-sd-base1-brcyan"
                  id="modal-headline"
                >
                  Delete Hologram
                </h2>
                <span class="font-sans text-sm font-normal text-sd-base00-bryellow">
                  This action will delete the hologram <span class="font-semibold text-sd-base1-brcyan">{{currentHologram.title}}</span>
                </span>
              </div>
            </div>
            <div class="w-full px-8 pb-8 mt-3 sm:mt-0 sm:text-left sm:items-start text-sd-base1-brcyan">
              <p class="text-sd-base2-white">This action will delete:</p>
              <p class="mt-0.5 text-sm">This will only delete the groups and the permutation of nodes, edges.</p>
              <div class="mt-3">
                <div class="flex flex-row">
                  <div class="mr-2 py-0.5 px-2 text-sm rounded bg-sd-base03-brblack">
                    Group
                  </div>
                  <div class="flex items-center text-sm font-normal text-left">
                    {{ deleteGroupLength }}
                  </div>
                </div>
                <div class="flex flex-row mt-2">
                  <div class="mr-2 py-0.5 px-2 text-sm rounded bg-sd-base03-brblack">
                    Node
                  </div>
                  <div class="flex items-center text-sm font-normal text-left">
                    {{ deleteNodeLength }}
                  </div>
                </div>
                <div class="flex flex-row mt-2">
                  <div class="flex mr-2 py-0.5 px-2 text-sm rounded bg-sd-base03-brblack">
                    Connection
                  </div>
                  <div class="flex items-center text-sm font-normal text-left">
                    {{ deleteEdgeLength }}
                  </div>
                </div>
              </div>
              <p class="mt-8 text-sd-base2-white">This action won't delete:</p>
              <ul class="pl-4 mt-2 text-sm list-disc">
                <li>
                  The data you store under ths nodes and edges, such as quotes and notes.
                </li>
                <li class="mt-2">
                  The whole node or edge entity in your account, if you want to delete the node globally you have to delete it in your account setting.
                </li>
              </ul>
            </div>
            <footer class="sticky bottom-0 flex flex-col border-t border-opacity-50 bg-sd-base02-black border-sd-base01-brgreen sm:py-4 sm:px-4">
              <p class="font-sans text-sd-base0-brblue">
                Please type <span class="px-1 font-bold text-sd-base1-brcyan">{{currentHologram.title}}</span> to confirm.
              </p>
              <input
                class="flex-1 py-1.5 px-3 mt-2 bg-sd-base02-black font-sans rounded font-normal text-sm text-sd-base0-brblue border border-sd-base00-bryellow focus:outline-none focus:ring-1 focus:ring-sd-base1-brcyan"
                :placeholder="currentHologram.title"
                v-model="confirmationCode"
                autocomplete="off"
                id="confirmationCode"
              />
              <div class="flex flex-row mt-4">
                <button
                  @click="deleteHologramHandler"
                  class="px-4 py-2 font-sans text-sm font-medium border rounded-md border-sd-base01-brgreen focus:outline-none"
                  :disabled="confirmationCode !== currentHologram.title"
                  v-bind:class="getButtonAdditionalClass()"
                >
                  Delete
                </button>
                <button
                  @click="toggleConfirmHologramDeletionModal"
                  class="px-4 py-2 ml-4 font-sans text-sm font-medium border rounded-md border-sd-base01-brgreen text-sd-base0-brblue hover:bg-sd-base2-white hover:bg-opacity-5 focus:outline-none"
                >
                  Cancel
                </button>
                <div
                  class="flex items-center ml-4"
                  v-if="deleteHologramProcedure"
                >
                  <svg
                    class="w-5 h-5 animate-spin text-sd-base0-brblue"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { gaHelpers } from "@/analytics/helper.js";
import { onMounted, onUnmounted, computed, ref } from "vue";
export default {
  name: "confirmHologramDeletionModal",
  setup() {
    const store = useStore();
    const modalOpen = computed(() => store.getters["modal/confirmHologramDeletionModalOpen"]);
    const confirmationCode = ref(null);
    const deleteGroupLength = ref(null);
    const deleteNodeLength = ref(null);
    const deleteEdgeLength = ref(null);
    const currentHologram = computed(() => store.getters["hologram/currentHologram"]);
    const currentHologramNodes = computed(() => store.getters["hologram/currentHologramNodes"]);
    const currentHologramEdges = computed(() => store.getters["hologram/currentHologramEdges"]);
    const prepare = () => {
      deleteGroupLength.value = currentHologram.value.group.items.length;
      deleteNodeLength.value = currentHologramNodes.value.length;
      deleteEdgeLength.value = currentHologramEdges.value.length;
      window.addEventListener("keydown", keydownEscHandler);
    };
    onMounted(prepare);

    const toggleConfirmHologramDeletionModal = () => {
      if (modalOpen.value) {
        store.dispatch("modal/toggleConfirmHologramDeletionModal", { data: false });
      }
    };

    const keydownEscHandler = (event) => {
      if (event.code !== "Escape") return;
      if (!modalOpen.value) return;
      toggleConfirmHologramDeletionModal();
    };

    const getButtonAdditionalClass = () => {
      if (confirmationCode.value === currentHologram.value.title) {
        return "text-sd-base2-white hover:bg-sd-base2-white hover:bg-opacity-5";
      } else {
        return "text-sd-base01-brgreen cursor-default";
      }
    };

    const router = useRouter();
    const deleteHologramProcedure = ref(false);
    const deleteHologramHandler = async () => {
      deleteHologramProcedure.value = true;
      store.dispatch("sidebar/toggleHologramSidebar", { data: false });
      try {
        await store.dispatch("hologram/checkoutWholeHologram");
      } catch(err){
        console.error("Something went wrong when try to delete whole hologram", err)
      }
      gaHelpers.engagement("delete_hologram", "core");
      deleteHologramProcedure.value = false;
      router.push({ name: "UserHolograms" });
    };

    const clear = () => {
      window.removeEventListener("keydown", keydownEscHandler);
    };
    onUnmounted(clear);

    return {
      // Local
      deleteGroupLength,
      deleteNodeLength,
      deleteEdgeLength,
      confirmationCode,
      deleteHologramProcedure,

      // Store
      modalOpen,
      currentHologram,

      // Method
      getButtonAdditionalClass,
      deleteHologramHandler,
    };
  },
};
</script>
<style>
</style>