<template>
  <div
    v-if="displayFullUrl"
    class="flex flex-row pt-2 font-sans text-sm font-medium text-left hologram-entity-url text-sd-base00-bryellow "
  >
    <a
      class="flex items-center mr-2"
      :href="fullUrl"
      target="_blank"
      rel="noopener noreferrer"
    >
      {{ fullUrl }}
    </a>
  </div>
  <div
    v-else
    class="flex flex-row pt-2 font-sans text-sm font-medium text-left hologram-entity-url text-sd-base00-bryellow "
  >
    <a
      class="flex mb-auto mr-2"
      :href="fullUrl"
      target="_blank"
      rel="noopener noreferrer"
    >
      {{ truncateUrl }}
    </a>
    <button
      class="flex w-8 h-4 px-1 my-auto bg-opacity-50 rounded-full bg-sd-base01-brgreen opacity-90 hover:bg-sd-base01-brgreen hover:opacity-80 focus:outline-none"
      @click="expandUrl"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="flex w-4 h-4 m-auto fill-current text-sd-base0-brblue"
        viewBox="0 0 16 16"
      >
        <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
      </svg>
    </button>
  </div>
</template>
<script>
import { ref, computed, watch } from "vue";
import { truncateString } from "../../utilities/utilities";
export default {
  name: "NodeUrlBlock",
  props: {
    hologramNode: {
      type: Object,
      require: true,
    },
    maxTextLength: {
      type: Number,
      require: true,
    },
  },
  setup(props) {
    const displayFullUrl = ref(false);

    const truncateUrl = computed(() => {
      if ( props.hologramNode ){
        return truncateString(props.hologramNode.node.url, props.maxTextLength);
      } else {
        return null
      }
    });

    const fullUrl = computed(() => {
      if ( props.hologramNode ){
        return props.hologramNode.node.url
      } else { 
        return null
      }
    })

    const expandUrl = () => {
      displayFullUrl.value = true;
    };

    watch(() => props.hologramNode.id, (newValue, oldValue) => {
      if ( newValue === oldValue ) return;
      displayFullUrl.value = false
    })

    return {
      // Local
      fullUrl,
      truncateUrl,
      displayFullUrl,

      // Method
      expandUrl
    };
  },
};
</script>

<style>
</style>