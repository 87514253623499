<template>
  <div
    class="w-full flex flex-col"
  >
    <div
      class="w-full grid grid-flow-row grid-cols-10 gap-3"
    >
      <div
        v-for="index in 10"
        v-bind:key="index"
        class="flex h-12 items-center justify-center border-sd-base0-brblue hover:bg-sd-base02-black rounded-sm cursor-pointer text-sd-base2-white text-lg font-sans font-semibold"
        @click="updateActiveAnswer(index)"
        v-bind:class="getAdditionStyle(index)"
      >
        {{index}}
      </div>
    </div>
    <div
      class="flex flex-row mt-2 font-sans font-normal text-md text-sd-base0-brblue"
    >
      <div
        class="mr-auto"
      >
        {{minium}}
      </div>
      <div
        class="ml-auto"
      >
        {{maximum}}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "linearScale",
  props:{
    modelValue: String,
    minium: {
      type: String,
      require: true,
    },
    maximum: {
      type: String,
      require: true,
    }
  },
  emits: ['update:modelValue'],
  methods: {
    updateActiveAnswer(index){
      this.$emit('update:modelValue', index)
    },
    getAdditionStyle(index){
      if (this.modelValue === index){
        return "bg-sd-base02-black border-2"
      } else {
        return "border"
      }
    }
  },
  computed: {
  },
};


</script>
<style>
</style>