<template>
  <div class="flex flex-col w-full h-full base bg-sd-base03-brblack">
    <div class="flex">
      <LandingNav />
    </div>
  </div>
</template>
<script>
import LandingNav from "@/components/LandingNav.vue"
export default {
  name:"AboutUs",
  components:{
    LandingNav
  },
  mounted() {
    window.document.title = "Totuslink - About us"
  },
};
</script>
<style>
</style>