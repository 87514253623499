<template>
  <div class="flex flex-row w-full">
    <ul class="flex justify-start w-full p-5 mr-auto bg-sd-base03-brblack">
      <li
        class="mr-6"
        v-if="!user"
      >
        <router-link to="/login">
          <a class="font-sans font-semibold cursor-pointer text-sd-base00-bryellow hover:text-sd-base3-brwhite">Login</a>
        </router-link>
      </li>
      <li
        class="mr-6"
        v-if="!user"
      >
        <router-link to="/signup">
          <a class="font-sans font-semibold cursor-pointer text-sd-base00-bryellow hover:text-sd-base3-brwhite">Sign up</a>
        </router-link>
      </li>
      <li
        class="mr-6"
        v-if="user"
      >
        <router-link
          :to="{ name: 'Dock', params:{ username: this.user.username}}"
          class="font-sans font-bold text-sd-base3-brwhite"
        >
          {{ user.username }}
        </router-link>
      </li>
      <!--
    <li class="mr-6">
      <router-link
        to="/questionnaire/intro"
        class="font-sans font-semibold cursor-pointer text-sd-base00-bryellow hover:text-sd-base3-brwhite" 
      >
        Questionnaire
      </router-link>
    </li>
    -->
      <li
        class="mr-6 font-sans font-semibold cursor-pointer text-sd-base00-bryellow hover:text-sd-base3-brwhite"
        v-if="user"
      >
        <div
          class="cursor-pointer "
          @click="logout"
        >
          Logout
        </div>
      </li>
      <!--
    <li
      class="mr-6"
    >
      <router-link to="/aboutus">
        <a class="font-sans font-semibold cursor-pointer text-sd-base00-bryellow hover:text-sd-base3-brwhite">About us</a>
      </router-link>
    </li>
    -->
      <li></li>
    </ul>
    <div class="pr-8 my-auto ml-auto">
      <a
        href="https://discord.gg/fEjktW6FQc"
        class="flex rounded-md w-9 h-9 hover:bg-sd-base02-black focus:outline-none"
        target="_blank"
        rel="noopener noreferrer"
        v-tooltip="{
          content: 'Official discord channel',
          theme: 'info-tooltip',
        }"
      >
        <iconDiscord
          :size="5"
          class="m-auto text-sd-base1-brcyan"
        />
      </a>

    </div>
  </div>

</template>

<script>
import { mapGetters } from "vuex";
import iconDiscord from "./icon/iconDiscord.vue";
export default {
  name: "LandingNav",
  components: {
    iconDiscord,
  },
  methods: {
    async logout() {
      await this.$store.dispatch("auth/logout");
      this.$router.push("/");
    },
    checkWhetherUserLoginWithGoogle() {
      if (this.user != null && this.user.attributes.identities) {
        let provider = JSON.parse(this.user.attributes.identities);
        console.log(this.user);
        if (provider[0].providerName == "Google") {
          return true;
        } else {
          return false;
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
};
</script>
  
<style>
</style>