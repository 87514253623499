<template>
  <div class="fixed inset-0 z-40 h-full">
    <div
      class="flex items-end justify-center h-full min-h-screen px-4 pt-4 text-center sm:block sm:p-0"
      id="modal-container"
    >
      <transition
        enter-active-class="duration-300 ease-out"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          @click="toggleModal"
          class="fixed inset-0"
          v-if="open"
        >
          <div class="absolute inset-0 bg-black opacity-50"></div>
        </div>
      </transition>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        v-show="open"
      >&#8203;</span>
      <transition
        name="modal-transition"
        enter-active-class="duration-300 ease-out"
        enter-from-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
        enter-to-class="translate-y-0 opacity-100 sm:scale-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="translate-y-0 opacity-100 sm:scale-100"
        leave-to-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
      >
        <div
          class="items-center inline-block overflow-y-auto text-left align-middle transition-all transform rounded-lg shadow-xl w-100 bg-sd-base02-black sm:align-middle"
          role="dialog"
          aria-modal="true"
          v-show="open"
        >
          <div
            class="flex flex-col py-6"
            v-if="dataIsReady"
          >
            <div class="flex px-6 font-sans text-xl font-semibold text-sd-base1-brcyan">
              Delete group
            </div>
            <div class="flex px-6 mt-2 font-sans text-base font-normal text-sd-base1-brcyan">
              This will delete group <span class="px-2 mx-2 my-auto rounded-md bg-sd-base1-brcyan text-sd-base03-brblack">{{targetHologramGroup.group.title}}</span>
            </div>
            <div class="flex flex-row px-6 mt-16">
              <button
                class="flex px-12 py-2 mr-4 border rounded-sm border-opacity-60 hover:bg-sd-red bg-opacity-30 border-sd-orange text-sd-base1-brcyan hover:bg-opacity-30 focus:outline-none focus:ring-1 focus:ring-sd-base1-brcyan"
                @click="deleteGroup"
              >
                Delete
              </button>
              <button
                class="flex px-12 py-2 mr-6 border rounded-sm border-sd-base00-bryellow text-sd-base1-brcyan hover:bg-sd-base2-white hover:bg-opacity-5 focus:outline-none focus:ring-1 focus:ring-sd-base1-brcyan"
                @click="toggleModal"
              >
                Cancel
              </button>
              <div
                class="flex"
                v-if="deletionProcessing"
              >
                <loadingSpin
                  :size="5"
                  class="my-auto text-sd-base0-brblue"
                />
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { onMounted, ref, computed, onUnmounted } from "vue";
import { useStore } from "vuex";
import loadingSpin from "../animation/loadingSpin";
import { gaHelpers } from "../../analytics/helper";
export default {
  name: "confirmGroupDeletionModal",
  props: {
    open: {
      type: Boolean,
      require: true,
    },
    hologramGroup: {
      type: Object,
      require: true,
    },
  },
  emits: ["toggleConfirmGroupDeletionModal", "deletionComplete"],
  components: {
    loadingSpin,
  },
  setup(props, context) {
    const store = useStore();
    
    const deletionProcessing = ref(false);
    const toggleModal = () => {
      if (deletionProcessing.value) return;
      if (props.open) {
        context.emit("toggleConfirmGroupDeletionModal", false);
        gaHelpers.interaction("close_confirm_group_deletion_modal", "group");
      }
    };

    const dataIsReady = ref(false);
    const currentHologramGroups = computed(() => store.getters["hologram/currentHologramGroups"]);
    const targetHologramGroup = ref(null);
    const prepare = () => {
      const hologramGroupIndex = currentHologramGroups.value.findIndex((e) => e.id === props.hologramGroup.id);
      targetHologramGroup.value = currentHologramGroups.value[`${hologramGroupIndex}`];
      dataIsReady.value = true;
      window.addEventListener("keydown", keydownEscHandler);
    };

    onMounted(prepare);

    const keydownEscHandler = (event) => {
      if (event.code !== "Escape") return;
      if (!props.open) return;
      if (deletionProcessing.value) return;
      toggleModal();
    };
    
    const deleteGroup = async () => {
      deletionProcessing.value = true;

      const checkWholeGroupInput = {
        hologramGroup: props.hologramGroup,
        hologramNode: props.hologramNode
      }

      try {
        await store.dispatch("hologram/checkoutWholeGroup", checkWholeGroupInput)
      } catch(err){
        console.error("Something went wrong when checkout whole group", err)
      }
      /*
      let targetHologramNodeGroups = currentHologramNodeGroups.value.reduce((array, element) => {
        if (element.groupID === targetHologramGroup.value.groupID) {
          array.push(element);
        }
        return array;
      }, []);
      
      for (const hologramNodeGroup of targetHologramNodeGroups) {
        try {
          await store.dispatch("hologram/deleteHologramNodeGroup",  hologramNodeGroup.id);
        } catch (err) {
          console.error(err);
        }

        const hologramNodeIndex = currentHologramNodes.value.findIndex((e) => e.id === hologramNodeGroup.hologramNodeID);
        const hologramNode = currentHologramNodes.value[hologramNodeIndex];

        const toGraphVariables = {
          nodeID: hologramNode.nodeID,
          groupID: null,
        };
        try {
          await store.dispatch("hologram/updateHologramNodeGroupToGraph", toGraphVariables);
        } catch (err) {
          console.error(err);
        }
      }

      const deleteHologramGroupVariables = {
        hologramGroupID: props.hologramGroupID,
      };
      
      try {
        await store.dispatch("hologram/deleteHologramGroup", deleteHologramGroupVariables);
      } catch (err) {
        console.error(err);
      }
      */

      context.emit("deletionComplete", true);
      store.dispatch("hologram/updateRenderHologramNodeColor", { data: true });
      deletionProcessing.value = false;
      gaHelpers.engagement("delete_group", "group");
      toggleModal();
    };

    const clear = () => {
      window.removeEventListener("keydown", keydownEscHandler);
    };

    onUnmounted(clear);

    return {
      dataIsReady,
      targetHologramGroup,
      deletionProcessing,
      toggleModal,
      deleteGroup,
    };
  },
};
</script>
<style>
</style>