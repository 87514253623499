import { listQuestionnaireQuestions as listQuestionnaireQuestionsQuery } from "../../graphql/queries"
import { getQuestionnaire as getQuestionnaireQuery } from "../../graphql/queries"
import { API } from "aws-amplify";

export const questionnaire = {
  namespaced: true,
  state: { 
    currentQuestionID: null,
    answers:{},
    questionnaire: {},
    answeredQuestions: [],
    questionsSum: null,
    questions:[],
    questionnaireIsFinished: false,
  },
  mutations: {
    mutateQuestionnaire(state, payload){
      state.questionnaire = payload;
    },
    mutateQuestionnaireIsFinished(state, payload){
      state.questionnaireIsFinished = payload;
    },
    mutateAnswers(state, payload){
      state.answers = payload;
    },
    mutateCurrentQuestionID(state, payload){
      state.currentQuestionID = payload;
    },
    mutateAnsweredQuestions(state, payload){
      state.answeredQuestions = payload;
    },
    mutateQuestionsSum(state, payload){
      state.questionsSum = payload;
    },
    mutateQuestions(state, payload){
      state.questions = payload;
    }
  },
  actions: {
    async getQuestionnaire(context, {data}){
      try {
        const getResult = await API.graphql({
          query: getQuestionnaireQuery,
          variables: {id:data},
          authMode: "AWS_IAM"
        });
        getResult.data.getQuestionnaire.questionsOrder = JSON.parse(getResult.data.getQuestionnaire.questionsOrder);
        getResult.data.getQuestionnaire.ending = JSON.parse(getResult.data.getQuestionnaire.ending);
        context.commit("mutateQuestionnaire", getResult.data.getQuestionnaire);
        return Promise.resolve("Success");
      } catch(err){
        console.log(err)
        return Promise.reject(err);
      }
    },
    async getQuestions(context, {data}){
      let questions = [];
      try {
        const questionnaireQuestions = await listQuestionnaireQuestions(data)
        const questionsOrder = context.state.questionnaire.questionsOrder;
        for (var i=0; i<questionnaireQuestions.length; i++){
          if (questionnaireQuestions[i].question.options){
            questionnaireQuestions[i].question.options = JSON.parse(questionnaireQuestions[i].question.options)
          }
          delete questionnaireQuestions[i].question.owner;
          delete questionnaireQuestions[i].question.createdAt;
          delete questionnaireQuestions[i].question.updatedAt;
          questions.splice(0, 0, questionnaireQuestions[i].question)
        }

        questions.sort(function(a,b){
          return questionsOrder.indexOf(a.id) - questionsOrder.indexOf(b.id);
        })

        for (var j=0; j<questions.length; j++){
          questions[j].index = j+1
        }

        console.log(questions, questionsOrder)

        context.commit("mutateCurrentQuestionID", questions[0].id)
        context.commit("mutateQuestionsSum", questions.length)
        context.commit("mutateQuestions", questions)
        return Promise.resolve("Success");
      } catch(err){
        console.log(err)
        return Promise.reject(err);
      }
    },
    updateQuestionnaireIsFinished(context, {data}){
      context.commit("mutateQuestionnaireIsFinished", data)
    },
    updateAnswers(context, {data}){
      let temp = context.state.answers;
      Object.assign(temp, data)
      context.commit("mutateAnswers", temp)
    },
    updateCurrentQuestionID(context, {data}){
      context.commit("mutateCurrentQuestionID", data)
    },
    updateAnsweredQuestions(context, {type, data}){
      switch (type) {
        case "update":{
          const temp = context.state.answeredQuestions;
          const index = temp.findIndex( e => e === data)
          if ( index === -1 ){
            temp.splice(0,0, data);
          }
          context.commit("mutateAnsweredQuestions", temp);
          break;
        }
        case "delete":{
          const temp = context.state.answeredQuestions;
          const index = temp.findIndex( e => e === data );
          temp.splice(index,1);
          context.commit("mutateAnsweredQuestions", temp);
          break;
        }
      }
      
    },
    updateQuestionsSum(context, {data}){
      context.commit("mutateQuestionsSum", data)
    },
    updateQuestions(context, {data}){
      context.commit("mutateQuestions", data)
    }
  },
  getters: {
    currentQuestionID: state => state.currentQuestionID,
    answeredQuestions: state => state.answeredQuestions,
    questionsSum: state => state.questionsSum,
    questions: state => state.questions,
    answers: state => state.answers,
    questionnaireIsFinished: state => state.questionnaireIsFinished,
    questionnaire: state => state.questionnaire,
  }
}


const listQuestionnaireQuestions = async function(questionnaireID){
  const listResult = await API.graphql({
    query: listQuestionnaireQuestionsQuery,
    variables: {filter:{questionnaireID:{eq:`${questionnaireID}`}}},
    authMode: "AWS_IAM"
  });
  return listResult.data.listQuestionnaireQuestions.items
}
