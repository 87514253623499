import { Amplify, Auth, API, graphqlOperation } from "aws-amplify";
import awsconfig from "../../aws-exports";
import { getUser as getUserQuery } from "../../graphql/queries";
import { updateUser as updateUserMutation } from "../../graphql/mutations";
import { userByUsernameForCollaboration as userByUsernameForCollaborationQuery } from "../../graphql/custom/customQueries";
import { userByEmailForCollaboration as userByEmailForCollaborationQuery } from "../../graphql/custom/customQueries";

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

/*
class localStorage {
  // the promise returned from sync function
  syncPromise = null;
  // set item with the key
  static setItem(key, value){
    chrome.storage.local.set({[key]: JSON.stringify(value)}, () => { // eslint-disable-line no-undef
      console.log('token stored');
    });
  }
  // get item with the key
  static getItem(key){
    return new Promise((resolve) => {
      chrome.storage.local.get([key], (result) =>  { // eslint-disable-line no-undef
        resolve(result)
      });
    })
  }
  // remove item with the key
  static removeItem(key){
    chrome.storage.local.remove(key, () => { // eslint-disable-line no-undef
      console.log("item removed");
    })
  }
  // clear out the storage
  static clear(){
    chrome.storage.local.clear(() => { // eslint-disable-line no-undef
      console.log("storage cleared");
    })
  }
  // If the storage operations are async(i.e AsyncStorage)
  // Then you need to sync those items into the memory in this method
  //sync(){
  //    if (!MyStorage.syncPromise) {
  //        MyStorage.syncPromise = new Promise((res, rej) => {});
  //    }
  //    return MyStorage.syncPromise;
  //}
}
*/

export const auth = {
  namespaced: true,
  state: {
    user: null,
    userData: null,
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setUserData(state, payload) {
      state.userData = payload;
    },
  },
  actions: {
    async logout({ commit }) {
      commit("setUser", null);
      commit("setUserData", null);
      return await Auth.signOut();
    },
    retrieveCurrentSession: async () => {
      try {
        const session = await Auth.currentSession();
        return Promise.resolve(session);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async login({ commit }, { username, password }) {
      try {
        await Auth.signIn({
          username,
          password,
        });
        const session = await Auth.currentSession();
        const userInfo = await Auth.currentUserInfo();
        commit("setUser", userInfo);
        if (userInfo) {
          const userData = await API.graphql(
            graphqlOperation(getUserQuery, { id: userInfo.attributes.sub })
          );
          commit("setUserData", userData.data.getUser);
        }
        return Promise.resolve(session);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async federationSignIn() {
      try {
        // because website will redirect after federattion singin so we need to get user data elsewhere.
        await Auth.federatedSignIn({ provider: "Google" });
        return Promise.resolve("Success");
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async signUp(_, { username, password, email }) {
      try {
        await Auth.signUp({
          username,
          password,
          attributes: {
            email,
          },
        });
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async confirmSignUp(_, { username, code }) {
      try {
        await Auth.confirmSignUp(username, code);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async authAction({ commit }) {
      const userInfo = await Auth.currentUserInfo();
      commit("setUser", userInfo);
      if (userInfo) {
        const userData = await API.graphql(
          graphqlOperation(getUserQuery, { id: userInfo.attributes.sub })
        );
        commit("setUserData", userData.data.getUser);
      }
    },

    /**
     *
     * @param {object} context vuex context object
     * @param {id} userID
     * @returns {object} userData object
     */

    checkOnboardingQuestionnaireStauts: async (context, userID) => {
      const data = {
        id: userID,
        onBoardingQuestionnaireIsFinished: true,
      };
      try {
        const result = await API.graphql(
          graphqlOperation(updateUserMutation, { input: data })
        );
        context.commit("setUserData", result.data.updateUser);
        return Promise.resolve(result.data.updateUser);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    getUserIDByUsername: async (_, { username }) => {
      const variables = {
        username: username,
      };
      try {
        let result = await API.graphql({
          query: userByUsernameForCollaborationQuery,
          variables: variables,
          authMode: "AWS_IAM"
        });
        return Promise.resolve(result.data.userByUsername.items[0]);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    getUserIDByEmail: async (_, { email }) => {
      const variables = {
        email: email,
      };
      try {
        let result = await API.graphql({
          query: userByEmailForCollaborationQuery,
          variables: variables,
          authMode: "AWS_IAM"
        });
        return Promise.resolve(result.data.userByEmail.items[0]);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
  getters: {
    user: (state) => state.user,
    userData: (state) => state.userData,
  },
};
