<template>
  <div
    class="flex mx-auto rounded-full shadow-2xl cursor-pointer w-9 h-9 ring-1 ring-sd-base01-brgreen hover:bg-sd-base02-black"
    @click="toggleHelpPanel"
    v-if="currentHologramDataReady"
  >
    <iconQuestionMark :size="6" class="m-auto text-sd-base1-brcyan" />
    <teleport to="#modals">
      <div 
        v-if="helpPanelOpen"
      >
        <helpPanel 
          :open="helpPanelOpen" 
          :position="helpPanelPosition" 
          @toggle-help-panel="catchToggleHelperPanelEvent"
        />
      </div>
    </teleport>
  </div>
</template>
<script>
import { ref, computed } from "vue"
import iconQuestionMark from "../icon/iconQuestionMark"
import { useStore } from "vuex"
import helpPanel from "./helpPanel"
import { gaHelpers } from "../../analytics/helper.js"

export default {
  name: "helpButton",
  components: {
    iconQuestionMark,
    helpPanel
  },
  setup() {
    const store = useStore();
    const helpPanelOpen = ref(false);
    const helpPanelPosition = ref({});
    const currentHologramDataReady = computed(() => store.getters["hologram/currentHologramDataReady"]);

    const toggleHelpPanel = ($event) => {
      if ( helpPanelOpen.value ){
        helpPanelOpen.value = false;
        helpPanelPosition.value = {};
        gaHelpers.interaction("close_help_panel", "help");
        return;
      }
      helpPanelOpen.value = true;
      gaHelpers.interaction("open_help_panel", "help");
      helpPanelPosition.value = getElementPosition($event.currentTarget);
    }

    const catchToggleHelperPanelEvent = (message) => {
      const open = message
      if ( !open ){
        toggleHelpPanel();
      }
    }

    return {
      helpPanelOpen,
      toggleHelpPanel,
      helpPanelPosition,
      catchToggleHelperPanelEvent,
      currentHologramDataReady
    }

  }
};

const getElementPosition = (element) => {
  const getPosition = element.getBoundingClientRect();
  const result = {
    x:getPosition.x,
    y:getPosition.y,
    width: getPosition.width,
    height: getPosition.height,
  };
  return result;
}
</script>
<style>
</style>