export const sidebar = {
  namespaced: true,
  state: { 
    sidebarOpen: false,
    sidebarWidth: "",
    editorSidebarOpen: false,
    editorSidebarWidth: "",
    hologramSidebarOpen: false,
    hologramSidebarWidth: ""
  },
  mutations:{
    mutateHologramSidebarOpen: (state, payload) => {
      state.hologramSidebarOpen = payload;
    },
    mutateHologramSidebarWidth: (state, payload) => {
      state.hologramSidebarWidth = payload;
    },
    mutateSidebarOpen(state, payload){
      state.sidebarOpen = payload;
    },
    mutateSidebarWidth(state, payload){
      state.sidebarWidth = payload;
    },
    mutateEditorSidebarStatus(state, payload){
      state.editorSidebarOpen = payload;
    },
    mutateEditorSidebarWidth(state, payload){
      state.editorSidebarWidth = payload;
    },
  },
  actions: {
    toggleHologramSidebar: (context, {data}) => {
      context.commit("mutateHologramSidebarOpen", data);
    },
    updateHologramSidebarWidth: (context, {data}) => {
      context.commit("mutateHologramSidebarWidth", data)
    },
    toggleSidebar (context, {data}) {
      context.commit("mutateSidebarOpen", data)
    },
    toggleSidebarWidth (context, sidebarWidth){
      context.commit("mutateSidebarWidth", sidebarWidth);
    },
    toggleEditorSidebar (context, {data}) {
      context.commit("mutateEditorSidebarStatus", data)
    },
    toggleEditorSidebarWidth (context, editorSidebarWidth){
      context.commit("mutateEditorSidebarWidth", editorSidebarWidth);
    },
  },
  getters:{
    sidebarOpen: state => state.sidebarOpen,
    sidebarWidth: state => state.sidebarWidth,
    editorSidebarOpen: state => state.editorSidebarOpen,
    editorSidebarWidth: state => state.editorSidebarWidth,
    hologramSidebarOpen: state => state.hologramSidebarOpen,
    hologramSidebarWidth: state => state.hologramSidebarWidth,
  }
}