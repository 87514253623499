<template>
  <div class="fixed inset-0 h-full">
    <div
      class="flex items-end justify-center h-full min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0"
      id="modal-container"
    >
      <transition
        enter-active-class="duration-300 ease-out"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          @click="toggleCreateNewHologramModal('byClickAway')"
          class="fixed inset-0"
          v-if="createNewHologramModalOpen"
        >
          <div class="absolute inset-0 bg-black opacity-50"></div>
        </div>
      </transition>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        v-if="createNewHologramModalOpen"
        >&#8203;</span
      >
      <transition
        name="modal-transition"
        enter-active-class="duration-300 ease-out"
        enter-from-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
        enter-to-class="translate-y-0 opacity-100 sm:scale-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="translate-y-0 opacity-100 sm:scale-100"
        leave-to-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
      >
        <div
          class="inline-block overflow-y-auto text-left align-bottom transition-all transform rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full"
          role="dialog"
          aria-modal="true"
          id="new-hologram-container"
          v-if="createNewHologramModalOpen"
        >
          <div
            class="flex w-full"
          >
            <div class="w-full my-auto rounded-lg bg-sd-base02-black">
              <div
                class="flex-col flex-grow w-full px-4 sm:flex sm:p-8 sm:items-start"
              >
                <div class="mt-3 sm:mt-0 sm:text-left">
                  <h2
                    class="text-xl font-medium leading-6 text-sd-base1-brcyan"
                    id="modal-headline"
                  >
                    Create new hologram
                  </h2>
                  <span class="font-sans text-sm font-normal text-sd-base00-bryellow">You can change these settings after creation.</span>
                </div>

                <form class="w-full">
                  <div
                    class="items-center text-base font-medium sm:flex sm:flex-row sm:mt-8 sm:text-left text-sd-base1-brcyan"
                  >
                    <h3>Basic info</h3>
                    <div
                      class="flex items-center h-5 px-2 ml-2 font-sans text-xs font-normal rounded-md bg-sd-base0-brblue bg-opacity-80 text-sd-base02-black"
                    >
                      <p>required</p>
                    </div>
                  </div>
                  <div
                    class="w-full border-t sm:mt-2 sm:mb-6 border-sd-base01-brgreen"
                  ></div>
                  <div
                    class="flex flex-col mt-4 text-sm font-normal sm:text-left text-sd-base1-brcyan"
                  >
                    <h4>Hologram title</h4>
                    <input
                      type="text"
                      name="title"
                      v-model="hologramTitle"
                      class="mt-2 bg-sd-base02-black border border-sd-base00-bryellow font-sans rounded font-normal text-sm text-sd-base0-brblue py-1.5 px-3 focus:outline-none focus:ring-1 focus:ring-sd-base1-brcyan"
                    />
                  </div>
                  <div
                    class="flex flex-col mt-4 text-sm font-normal sm:text-left text-sd-base1-brcyan"
                  >
                    <h4>Access policy</h4>
                    <div class="mt-2">
                      <label class="inline-flex items-center">
                        <input
                          type="radio"
                          class="form-radio focus:shadow-none focus:outline-none focus:ring-transparent"
                          v-model="accessPolicy"
                          id="private"
                          value="private"
                        />
                        <span class="ml-2">Private</span>
                      </label>
                      <label class="inline-flex items-center ml-6">
                        <input
                          type="radio"
                          class="form-radio focus:shadow-none focus:outline-none focus:ring-transparent"
                          id="public"
                          value="public"
                          v-model="accessPolicy"
                        />
                        <span class="ml-2">Public</span>
                      </label>
                    </div>
                  </div>
                  <!--
                  <div
                    class="items-center text-base font-medium sm:flex sm:flex-row sm:mt-8 sm:text-left text-sd-base1-brcyan"
                  >
                    <h3>Advanced info</h3>
                  </div>
                  <div
                    class="w-full border-t sm:mt-2 sm:mb-6 border-sd-base01-brgreen"
                  ></div>
                  <div
                    class="flex flex-col mt-4 text-sm font-normal sm:text-left text-sd-base1-brcyan"
                  >
                    <h4>Community</h4>
                  </div>
                  <div class="mt-2">
                    <Multiselect
                      v-model="hologramCommunity"
                      :options="communityOptions"
                      mode="tags"
                      :searchable="true"
                    />
                  </div>
                  -->
                </form>
              </div>
              <footer
                class="sticky bottom-0 flex flex-row border-t border-opacity-50 rounded-lg bg-sd-base02-black border-sd-base01-brgreen sm:py-4 sm:px-4"
              >
                <button
                  @click="createHologram"
                  class="px-4 py-2 font-sans text-sm font-medium border rounded-md border-sd-base01-brgreen text-sd-base2-white hover:bg-sd-base2-white hover:bg-opacity-5 focus:outline-none"
                >
                  Create
                </button>
                <button
                  @click="toggleCreateNewHologramModal('byButton')"
                  class="px-4 py-2 ml-4 font-sans text-sm font-medium border rounded-md border-sd-base01-brgreen text-sd-base01-brgreen hover:bg-sd-base2-white hover:bg-opacity-5 focus:outline-none"
                >
                  Cancel
                </button>
                <div class="flex items-center ml-4 font-sans text-sm font-medium text-sd-orange ">{{ error }}</div>
              </footer>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { ref, computed, nextTick, onMounted, onUnmounted } from 'vue'
import { useStore } from "vuex";
import { useRouter } from 'vue-router'
import { gaHelpers } from "@/analytics/helper.js"
export default {
  name: "createNewHologramModal",
  setup() {
    const store = useStore();
    const router = useRouter();
    const hologramTitle =  ref(null);
    const error = ref("");
    const accessPolicy = ref("public");
    const userData = computed(() => store.getters["auth/userData"]);
    const createNewHologramModalOpen = computed(() => store.getters["modal/createNewHologramModalOpen"]);

    const prepare = () => {
      window.addEventListener("keydown", keydownEscHandler)
    }

    onMounted(prepare)

    const toggleCreateNewHologramModal = (type) => {
      if (createNewHologramModalOpen.value){
        store.dispatch("modal/toggleCreateNewHologramModal", {data:false});
      } 
      
      if ( type === "byClickAway" ){
        gaHelpers.interaction("close_create_new_hologram_by_click_away", "modal")
      } else {
        gaHelpers.interaction("close_create_new_hologram_by_button", "modal")
      }
    }

    const keydownEscHandler = (event) => {
      if ( event.code !== "Escape" ) return;
      if ( !createNewHologramModalOpen.value ) return;
      toggleCreateNewHologramModal()
    };

    const createHologram = async function(){
      if (!hologramTitle.value){
        error.value = "Please enter a hologram title";
        return;
      }
      if (!accessPolicy.value){
        error.value = "Please select hologram access policy.";
        return;
      }

      const action = [{
        version: 1,
        timeStamp: Date.now(),
        type: "NewHologram",
        add:{},
        remove:{},
        update: {}
      }];

      const userPresent = {
        present: {},
        presentList: [],
        unPresent: {},
        unPresentList: []
      }

      const graph = {
        nodes: [],
        links: []
      }

      const createHologramVariables = {
        baseType: "Hologram",
        title: hologramTitle.value,
        accessPolicy: accessPolicy.value,
        createdByID: userData.value.id,
        owner: userData.value.id,
        action: JSON.stringify(action),
        userPresent: JSON.stringify(userPresent),
        graph: JSON.stringify(graph),
      }

      let newHologram;
      
      try {
        newHologram = await store.dispatch("hologram/createHologram", createHologramVariables)
        gaHelpers.engagement("create_hologram", "core")
      } catch(error){
        console.error('Something went wrong when create hologram:', error)
        return;
      }

      const createHologramContributorVariables = {
        hologramID: newHologram.id,
        contributorID: userData.value.id,
        owner: userData.value.id
      }

      try {
        await store.dispatch("hologram/createHologramContributor", createHologramContributorVariables)
      } catch(error){
        console.error('Something went wrong when create hologramContributor:', error)
      }

      nextTick(() => {
        router.push({name:"Hologram", params:{hologramID:newHologram.id, username:userData.value.username}});  
      })

      toggleCreateNewHologramModal();
    }

    const clear = () => {
      window.removeEventListener("keydown", keydownEscHandler)
    }

    onUnmounted(clear)

    return {
      userData,
      hologramTitle,
      error,
      accessPolicy,
      createNewHologramModalOpen,
      toggleCreateNewHologramModal,
      createHologram,
    }
  },
};
</script>
<style></style>