// Finish: getEdge, getHologramEdge, getHologramNode, getUserNode


import { API, graphqlOperation } from "aws-amplify";
import { listEdges as listEdgesQuery } from "../queries";
import { listHologramEdges as listHologramEdgesQuery } from "../queries"
import { listHologramNodes as listHologramNodesQuery } from "../queries"
import { listWeights as listWeightsQuery } from "../queries"
import { superChargedGetHologram as superChargedGetHologramQuery } from "../custom/customQueries"
import { userNodeByUser as userNodeByUserQuery } from "../custom/customQueries"
import { nodeByUrl as nodeByUrlQuery } from "../custom/customQueries"




export const getNode = async ( url ) => {
  const variables = {
    url: url
  };
  try {
    let result;
    const response = await API.graphql(graphqlOperation(nodeByUrlQuery, variables));
    const node = response.data.nodeByUrl.items[0];
    if ( node ){
      result = {
        status: "Found",
        result: node
      };
    } else {
      result = {
        status: "NotFound",
      }
    }
    return Promise.resolve(result)
  } catch(error){
    return Promise.reject(error)
  }
}



/**
 * 
 * @param {string} type publicAccess/privateAccess 
 * @param {id} hologramID 
 * @param {int} [groupLimit] 
 * @param {string} [groupNextToken] 
 * @param {int} [hologramNodeLimit] 
 * @param {string} [hologramNodeNextToken] 
 * @param {int} [hologramEdgeLimit] 
 * @param {string} [hologramEdgeNextToken] 
 */

export const superChargedGetHologram = async (type, hologramID, 
  groupLimit, groupNextToken, hologramNodeLimit, hologramNodeNextToken, hologramEdgeLimit, 
  hologramEdgeNextToken, hologramContributorLimit, hologramContributorNextToken) => {
  const variables = {
    "id": hologramID,
    "groupLimit": groupLimit,
    "groupNextToken": groupNextToken,
    "hologramNodeLimit": hologramNodeLimit,
    "hologramNodeNextToken": hologramNodeNextToken,
    "hologramEdgeLimit": hologramEdgeLimit,
    "hologramEdgeNextToken": hologramEdgeNextToken,
    "hologramContributorLimit": hologramContributorLimit,
    "hologramContributorNextToken": hologramContributorNextToken
  }

  if ( type === "privateAccess" ){
    try {
      let result = await API.graphql({
        query: superChargedGetHologramQuery,
        variables: variables,
        authMode: "AWS_IAM"
      });
      if ( result.data.getHologram.node.nextToken ){
        let nodeRecursiveResult = await superChargedGetHologram(type, hologramID, null, null, hologramNodeLimit, result.data.getHologram.node.nextToken, null, null, null, null)
        result.data.getHologram.node.items.push(...nodeRecursiveResult.node.items);
      }

      if ( result.data.getHologram.group.nextToken ){
        let groupRecursiveResult = await superChargedGetHologram(type, hologramID, groupLimit, result.data.getHologram.group.nextToken, null, null, null, null, null, null);
        result.data.getHologram.group.items.push(...groupRecursiveResult.group.items);
      }

      if ( result.data.getHologram.edge.nextToken ){
        let edgeRecursiveResult = await superChargedGetHologram(type, hologramID, null, null, null, null, hologramEdgeLimit, result.data.getHologram.edge.nextToken, null, null);
        result.data.getHologram.edge.items.push(...edgeRecursiveResult.edge.items);
      }

      if ( result.data.getHologram.contributor.nextToken ){
        let contributorRecursiveResult = await superChargedGetHologram(type, hologramID, null, null, null, null, null, null, hologramContributorLimit, result.data.getHologram.contributor.nextToken);
        result.data.getHologram.contributor.items.push(...contributorRecursiveResult.contributor.items);
      }
      return Promise.resolve(result.data.getHologram)
    } catch(err){
      return Promise.reject(err)
    }
  } else {
    try {
      let result = await API.graphql({
        query: superChargedGetHologramQuery,
        variables: variables,
        authMode: "AWS_IAM"
      });

      if ( result.data.getHologram.node.nextToken ){
        let nodeRecursiveResult = await superChargedGetHologram(type, hologramID, null, null, hologramNodeLimit, result.data.getHologram.node.nextToken, null, null)
        result.data.getHologram.node.items.push(...nodeRecursiveResult.node.items);
      }

      if ( result.data.getHologram.group.nextToken ){
        let groupRecursiveResult = await superChargedGetHologram(type, hologramID, groupLimit, result.data.getHologram.group.nextToken, null, null, null, null);
        result.data.getHologram.group.items.push(...groupRecursiveResult.group.items);
      }

      if ( result.data.getHologram.edge.nextToken ){
        let edgeRecursiveResult = await superChargedGetHologram(type, hologramID, null, null, null, null, hologramEdgeLimit, result.data.getHologram.edge.nextToken);
        result.data.getHologram.edge.items.push(...edgeRecursiveResult.edge.items);
      }

      return Promise.resolve(result.data.getHologram)
    } catch(err){
      return Promise.reject(err)
    }
  }
}


/**
 * 
 * @param {list} hologramNodes list of all hologramNodes user have
 * @param {*} userID 
 * @param {*} nodeID 
 * @returns {object} {status:found/notFound, result:hologramNodes}
 */

export const getCrossHologramNodes = async (hologramNodes ,userID, nodeID) => {
  const filter = {
    "and": [
      {
        "createdByID":{
          eq: userID
        }
      },
      {
        "nodeID":{
          eq: nodeID
        }
      }
    ]
  }
  let target;
  try {
    if (hologramNodes.length !== 0){
      target = hologramNodes.reduce(
        (array, element) => {
          if (element.nodeID === nodeID){
            array.push(element);
          }
          return array;
        },[]
      );
    } else {
      const result = await API.graphql(graphqlOperation(listHologramNodesQuery, { filter:filter, limit:1000 }));
      target = result.data.listHologramNodes.items;
    }

    let result = {};

    if (target) {
      result = {
        "status": "found",
        "result": target
      }
    } else {
      result = {
        "status": "notFound",
      }
    }
    return Promise.resolve(result);
  } catch(err){
    return Promise.reject(err)
  }
  
}


/**
 * 
 * @param {list} hologramNodeWeights list of weights belong to hologramNode
 * @param {*} hologramNodeID 
 * @returns {object} {status:found/notFound, result:weight}
 */

export const getHologramNodeWeight = async (hologramNodeWeights, hologramNodeID) => {
  let target;
  const filter = {
    "hologramNodeID": {
      "eq": hologramNodeID
    }
  }
  try {
    if (hologramNodeWeights.length !== 0){
      const index = hologramNodeWeights.findIndex( e => e.hologramNodeID === hologramNodeID )
      target = hologramNodeWeights[`${index}`];
    } else {
      const result = await API.graphql(graphqlOperation(listWeightsQuery, { filter:filter, limit:1000 }));
      target = result.data.listWeights.items[0];
    }

    let result = {};

    if (target) {
      result = {
        "status": "found",
        "result": target
      }
    } else {
      result = {
        "status": "notFound",
      }
    }
    return Promise.resolve(result);
  } catch(err){
    return Promise.reject(err);
  }
}


/**
 * 
 * @param {list} edges if = null, will query DB
 * @param {id} startNodeID 
 * @param {id} endNodeID 
 * @returns {object} {status:found/notFound, result:edge}
 */

export const getEdge = async (edges, startNodeID, endNodeID) => {
  let target;
  const filter = {
    "and": [
      {
        "startNodeID": {
          "eq": startNodeID
        },
        "endNodeID": {
          "eq": endNodeID
        }
      }
    ]
  }
  try {
    if (edges.length !== 0) {
      const index = edges.findIndex(e => e.startNodeID === startNodeID && e.endNodeID === endNodeID);
      target = edges[`${index}`];
    } else {
      const listEdgeResult = await API.graphql(graphqlOperation(listEdgesQuery, { filter: filter, limit:1000 }));
      target = listEdgeResult.data.listEdges.items[0];
    }

    let result = {};

    if (target) {
      result = {
        "status": "found",
        "result": target
      }
    } else {
      result = {
        "status": "notFound",
      }
    }
    return Promise.resolve(result);
  } catch (err) {
    return Promise.reject(err)
  }
}

/**
 * We get link and backlink.
 * @param {list} hologramEdges list of all hologramNodes user have
 * @param {*} userID 
 * @param {*} currentHologramID 
 * @param {*} nodeID 
 * @returns 
 */

export const getOuterLink = async (hologramEdges, userID, currentHologramID, nodeID) => {
  let target;
  const filter = {
    "and": [
      {
        "createdByID": {
          eq: userID
        }
      },
      {
        "or": [
          {
            "endNodeID": {
              eq: nodeID
            }
          },
          {
            "startNodeID": {
              eq: nodeID
            }
          },
        ]
      } 
    ]
  };
  try {
    if (hologramEdges.length !== 0){
      target = hologramEdges.reduce(
        (array, element) => {
          if ( element.hologramID === currentHologramID ){ return array; }
          if (element.edge.startNodeID === nodeID){
            array.push(element);
          } else if (element.edge.endNodeID === nodeID){
            array.push(element);
          }
          return array;
        },[]
      );
    } else {
      const result = await API.graphql(graphqlOperation(listHologramEdgesQuery, { filter:filter, limit:1000 }));
      target = result.data.listHologramEdges.items.reduce(
        (array, element) => {
          if ( element.hologramID !== currentHologramID ){
            array.push(element);
          }
          return array;
        }, []
      );
    }

    let result = {};

    if (target){
      result = {
        "status": "found",
        "result": target
      }
    } else {
      result = {
        "status": "notFound",
      }
    }
    return Promise.resolve(result)
  } catch(err){
    return Promise.reject(err);
  }
}

/**
 * 
 * @param {*} edges Will query DB if edges is empty list
 * @param {*} startNodeID 
 * @param {*} endNodeID 
 * @returns {object} {status:found/notFound, result:edge} - can be at most two edges
 */
export const getBiDirectionalEdge = async (edges, startNodeID, endNodeID) => {
  let target, index;
  const filter = {
    "or": [
      {
        "and": [
          {
            "startNodeID": {
              "eq": startNodeID
            },
            "endNodeID": {
              "eq": endNodeID
            }
          }
        ]
      },
      {
        "and": [
          {
            "startNodeID": {
              "eq": endNodeID
            },
            "endNodeID": {
              "eq": startNodeID
            }
          }
        ]
      }
    ]
  };
  try {
    if (edges.length !== 0) {
      index = edges.findIndex(e => e.startNodeID === startNodeID && e.endNodeID === endNodeID);
      if (index === -1){
        index = edges.findIndex(e => e.startNodeID === endNodeID && e.endNodeID === startNodeID);
      }
      target = edges[`${index}`];
    } else {
      const listEdgeResult = await API.graphql(graphqlOperation(listEdgesQuery, { filter: filter, limit:1000 }));
      target = listEdgeResult.data.listEdges.items
    }

    let result = {};

    if (target) {
      result = {
        "status": "found",
        "result": target
      }
    } else {
      result = {
        "status": "notFound",
      }
    }
    return Promise.resolve(result);
  } catch (err) {
    return Promise.reject(err)
  }
}

/**
* 
* @param {list} currentHologramNodes if = null, will query DB
* @param {id} hologramID 
* @param {id} edgeID 
* @returns {object} {status:found/notFound, result:hologramEdge}
*/

export const getHologramEdge = async (currentHologramEdges, hologramID, edgeID) => {
  let target;
  const filter = {
    "and": [
      {
        "hologramID": {
          "eq": hologramID
        },
        "edgeID": {
          "eq": edgeID
        }
      }  
    ]
  };
  try {
    if (currentHologramEdges.length !== 0) {
      const index = currentHologramEdges.findIndex(e => e.hologramID === hologramID && e.edgeID === edgeID);
      target = currentHologramEdges[`${index}`];
    } else {
      const listHologramEdgeResult = await API.graphql(graphqlOperation(listHologramEdgesQuery, { filter: filter, limit:1000 }));
      target = listHologramEdgeResult.data.listHologramEdges.items[0];
    }

    let result = {};

    if (target) {
      result = {
        "status": "found",
        "result": target
      }
    } else {
      result = {
        "status": "notFound",
      }
    }
    return Promise.resolve(result);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 
 * @param {object} currentHologramNodes if = null, will query DB
 * @param {id} hologramID 
 * @param {id} nodeID 
 * @returns {object} {status:found/notFound, result:hologramNode}
 */

export const getHologramNode = async (currentHologramNodes, hologramID, nodeID) => {
  let target;
  const filter = {
    "and": [
      {
        "hologramID": {
          "eq": hologramID
        },
        "nodeID": {
          "eq": nodeID
        }
      }  
    ]
  }
  try {
    if (currentHologramNodes.length !== 0){
      const index = currentHologramNodes.findIndex( e => e.nodeID === nodeID && e.hologramID === hologramID );
      target = currentHologramNodes[`${index}`];
    } else {
      const listResult = await API.graphql(graphqlOperation(listHologramNodesQuery, { filter:filter, limit:1000 }));
      target = listResult.data.listHologramNodes.items[0];
    }
    
    let result = {};

    if (target){
      result = {
        "status": "found",
        "result": target
      }
    } else {
      result = {
        "status": "notFound",
      }
    }
    return Promise.resolve(result)
  } catch(err){
    return Promise.reject(err);
  }
}

/**
 * 
 * @param {list} userNodes if = null, will query DB
 * @param {id} userID 
 * @param {id} nodeID 
 * @returns {object} {status:found/notFound, result:userNode}
 */

export const getUserNode = async (userID, nodeID) => {
  const variables = {
    "userID": userID,
    "nodeID": {
      "eq": nodeID
    }
  };

  let result;

  try {
    const response = await API.graphql(graphqlOperation(userNodeByUserQuery, variables));
    const userNode = response.data.userNodeByUser.items[0];
    if ( userNode ){
      result = {
        status: "Found",
        result: userNode
      };
    } else {
      result = {
        status: "NotFound"
      }
    }
    return Promise.resolve(result)
  } catch(err){
    return Promise.reject(err)
  }
}