<template>
  <button
    v-if="!hologramSidebarOpen"
    class="flex items-center w-8 h-8 my-auto mr-2 rounded-md hover:bg-sd-base2-white hover:bg-opacity-5 focus:outline-none"
    @click="toggleHologramSidebar($event)"
  >
    <iconList :size="5" class="m-auto text-sd-base0-brblue" />
  </button>
</template>
<script>
import iconList from "../icon/iconList.vue"
import { useStore } from "vuex"
import { computed } from "vue"
export default {
  name: "HologramSidebarToggler",
  components: {
    iconList
  },
  setup() {
    const store = useStore();
    const hologramSidebarOpen = computed(() => store.getters["sidebar/hologramSidebarOpen"]);
    const toggleHologramSidebar = () => {
      console.log("init")
      if (hologramSidebarOpen.value) {
        store.dispatch("sidebar/toggleHologramSidebar", { data: false });
        return;
      }
      store.dispatch("sidebar/toggleHologramSidebar", { data: true });
    };

    return {
      toggleHologramSidebar,
      hologramSidebarOpen
    }
    
  }
};
</script>
<style>
</style>