import { useGtag } from "vue-gtag-next";
const { event } = useGtag()

/*
  label: core, assist, modal
*/

export const gaHelpers = {
  navigation: (action, label) => {
    event(action, {
      event_category: 'navigation',
      event_label: label,
    })
  },
  interaction: (action, label) => {
    event(action, {
      event_category: 'interaction',
      event_label: label,
    })
  },
  engagement: (action, label) => {
    event(action, {
      event_category: 'engagement',
      event_label: label,
    })
  },
}