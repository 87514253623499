<template>
  <div class="flex flex-col w-full h-full base bg-sd-base03-brblack">
    <div class="flex">
      <LandingNav />
    </div>
    <div
      class="flex flex-col mx-8 my-12 md:flex-row md:my-auto md:w-7/12 md:mx-auto"
    >
      <div
        class="flex-shrink-0 w-24 h-24 mx-auto md:my-auto md:mr-10 lg:mr-20"
      >
        <img src="@/assets/Hololink_logo.png" class="w-24 h-24">
      </div>
      <div>
        <div class="mt-8 font-sans text-2xl font-bold leading-tight text-center md:text-left md:text-3xl punchline text-sd-base2-white">
          <p>An url-based graph-oriented platform built for efficient knowledge sharing.</p>
        </div>
        <div class="mt-8 font-sans font-medium leading-normal text-center text-md md:text-left md:text-lg explanation text-sd-base1-brcyan">
          <p><span class="font-bold">Hologram</span> is an force graph for organizing information. It takes url as single node, hyperlink between url as connection or you can set up connection manually. With hologram you can gain more insights from informaion.</p>
        </div>
        <div class="mt-10 font-sans text-xl font-bold leading-normal text-center md:text-left creator-list-link text-sd-base2-white">
          <router-link 
            to="/signup"
            class="hover:text-sd-base3-brwhite"
          >
            Join the creator list
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LandingNav from "@/components/LandingNav.vue"
export default {
  name: 'Home',
  components:{
    LandingNav
  }
}
</script>