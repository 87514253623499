<template>
  <div
    class="flex flex-row w-full h-12 px-4"
  >
    <div
      class="flex flex-row mr-4"
    >
      <div
        class="flex px-1 my-auto mr-2 font-sans text-xs border rounded-lg shadow-2xl h-7 border-sd-base01-brgreen text-sd-base0-brblue"
      >
        <div class="my-auto">esc</div>
      </div>
      <div
        class="my-auto font-sans text-xs text-sd-base0-brblue"
      >
        to close
      </div>
    </div>
    <div
      class="flex flex-row mr-4"
    >
      <div
        class="flex px-1 my-auto mr-2 border rounded-lg shadow-2xl h-7 border-sd-base01-brgreen"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 m-auto fill-current text-sd-base00-bryellow" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z"/>
        </svg>
      </div>
      <div
        class="flex px-1 my-auto mr-2 border rounded-lg shadow-2xl h-7 border-sd-base01-brgreen"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 m-auto fill-current text-sd-base00-bryellow" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"/>
        </svg>
      </div>
      <div
        class="my-auto font-sans text-xs text-sd-base0-brblue"
      >
        to navigate
      </div>
    </div>
    <div
      class="flex flex-row mr-4"
    >
      <div
        class="flex px-1 my-auto mr-2 font-sans text-xs border rounded-lg shadow-2xl h-7 border-sd-base01-brgreen text-sd-base0-brblue"
      >
        <div class="my-auto">enter</div> 
      </div>
      <div
        class="my-auto font-sans text-xs text-sd-base0-brblue"
      >
        to create
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "keybindingExplanation"
};
</script>
<style>
</style>